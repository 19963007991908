import {
  Box, CustomTheme, Tab, styled,
} from '@mui/material';

export const TabBox = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',
  }),
);

export const TabStyled = styled(Tab)({
  textTransform: 'capitalize',
});
