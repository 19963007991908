/* eslint-disable import/no-extraneous-dependencies */
import {
  FC, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { WebUserContext } from '@providers';
import { useYupValidationResolver } from '@hooks';
import { User } from '@gql/types/graphql';
import { useQuery } from '@apollo/client';
import { SEARCH_USERS } from '@gql/queries/users';
import { DevTool } from '@hookform/devtools';
import {
  ButtonStyled,
  FeatureGate,
  FormErrorComponent,
  RadioInput,
} from '@components';
import { UserModel } from '@types';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import featureFlags from '@utils/featureFlags';
import { ModifyTeamMemberFormSchema, ModifyTeamMemberWithPrimaryFormSchema } from './ModifyTeamMembersForm.schema';
import {
  AutocompleteTableArray,
} from './AutocompleteTableArray/AutocompleteTableArray';
import {
  AutocompleteTableArrayWithPrimary,
} from './AutocompleteTableArrayWithPrimary/AutocompleteTableArrayWithPrimary';
import {
  ModifyTeamMembersFormFields,
  ModifyTeamMembersFormUserRoles,
  userRoleOptions,
} from './ModifyTeamMembersForm.models';
import {
  AutocompleteTableContainer,
  ButtonContainer,
} from './ModifyTeamMembersForm.styled';
import { AutocompleteChipsArray } from './AutocompleteChipsArray/AutocompleteChipsArray';

export interface ModifyTeamMembersFormInput {
  primaryBuyerId?: string;
  additionalBuyerIds: string[];
  primaryOperatorId?: string;
  additionalOperatorIds: string[];
  viewOnlyIds: string[];
}

interface ModifyTeamMembersFormProps {
  onFormSubmit: SubmitHandler<ModifyTeamMembersFormInput>;
  onPrevious?: () => void;
  isFormSaving: boolean;
  defaultValues: ModifyTeamMembersFormFields;
  isAddMemberFlow?: boolean;
  withPrimarySelection?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const ModifyTeamMembersForm: FC<ModifyTeamMembersFormProps> = ({
  onFormSubmit,
  onPrevious,
  isFormSaving,
  defaultValues,
  withPrimarySelection,
  isAddMemberFlow,
}) => {
  const appInsights = useAppInsightsContext();
  const user = useContext(WebUserContext);
  const resolver = useYupValidationResolver(withPrimarySelection
    ? ModifyTeamMemberWithPrimaryFormSchema
    : ModifyTeamMemberFormSchema);

  const [userSearchVal, setUserSearchVal] = useState('');
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const submitText = isAddMemberFlow ? 'Create' : 'Save';

  const {
    formState: { isDirty },
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<ModifyTeamMembersFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { loading: usersLoading, data: usersData } = useQuery(SEARCH_USERS, {
    errorPolicy: 'all',
    variables: { searchTerm: userSearchVal },
    skip: userSearchVal.length === 0,
  });

  const userOptions: UserModel[] = useMemo(() => {
    if (!usersData?.users) {
      return [];
    }

    return usersData.users
      .filter((userItem): userItem is User => userItem !== null);
  }, [usersData]);

  const userRole = watch('userRole');
  const buyersFormValue = watch('buyers');
  const operatorsFormValue = watch('operators');
  const viewOnlyFormValue = watch('viewOnly');
  const nosEmailDistributionFormValue = watch('nosEmailDistribution');

  const handleAutocompleteSelect = (
    value: string,
    role: ModifyTeamMembersFormUserRoles,
  ) => {
    const isBuyer = role === ModifyTeamMembersFormUserRoles.buyer;
    const primaryRadio = isBuyer ? 'primaryBuyerId' : 'primaryOperatorId';
    const primaryRadioValue = getValues(primaryRadio);
    if (primaryRadioValue || primaryRadioValue === value) {
      return;
    }
    setValue(primaryRadio, value);
  };

  const handleRadioChange = (
    field: `operators.${number}` | `buyers.${number}`,
    role: ModifyTeamMembersFormUserRoles,
  ) => {
    const isBuyer = role === ModifyTeamMembersFormUserRoles.buyer;
    const primaryRadio = isBuyer ? 'primaryBuyerId' : 'primaryOperatorId';
    const primaryRadioValue = getValues(primaryRadio);
    const newPrimary = getValues(field);
    if (!newPrimary || newPrimary.id === primaryRadioValue) {
      return;
    }
    setValue(primaryRadio, newPrimary.id, { shouldDirty: true });
  };

  const filterOutForBuyers = useMemo(
    () => {
      const filterOutIds = buyersFormValue
        .filter((buyer): buyer is UserModel => buyer !== null)
        .map((buyer) => buyer.id);
      return filterOutIds;
    },
    [buyersFormValue, usersData],
  );

  const filterOutForOperators = useMemo(
    () => {
      const filterOutIds = operatorsFormValue
        .filter((operator): operator is UserModel => operator !== null)
        .map((operator) => operator.id);
      return filterOutIds;
    },
    [operatorsFormValue, usersData],
  );

  const filterOutForViewOnly = useMemo(
    () => {
      const viewOnlyIds = viewOnlyFormValue
        .filter((viewOnly): viewOnly is UserModel => viewOnly !== null)
        .map((viewOnly) => viewOnly.id);
      return viewOnlyIds;
    },
    [viewOnlyFormValue, usersData],
  );

  const filterOutForNosEmailDistribution = useMemo(
    () => {
      if (!nosEmailDistributionFormValue) {
        return [];
      }
      const nosEmailDistributionIds = nosEmailDistributionFormValue
        .filter((email): email is UserModel => email !== null)
        .map((email) => email.id);
      return nosEmailDistributionIds;
    },
    [nosEmailDistributionFormValue, usersData],
  );

  useEffect(() => {
    if (!isDirty || isFormSaving) {
      return;
    }

    const isBuyer = userRole === ModifyTeamMembersFormUserRoles.buyer;

    const {
      buyers, operators,
    } = getValues();

    // Remove user from old list
    const userOldList = isBuyer ? operators : buyers;
    const userOldLocation = isBuyer ? 'operators' : 'buyers';

    // Add user to new list
    const userNewList = isBuyer ? buyers : operators;
    const userNewLocation = isBuyer ? 'buyers' : 'operators';

    const oldListWithoutUser = userOldList.filter((userItem) => userItem && userItem.id !== user?.id);

    // Add user to new list if not already present
    const isNewUserInList = userNewList.some((userItem) => userItem && userItem.id === user?.id);
    const newListWithUser = isNewUserInList ? userNewList : [
      {
        id: user?.id || '',
        email: user?.email || '',
        name: user?.name || '',
      },
      ...userNewList,
    ];

    // check if user was selected as primary, if so remove it
    if (withPrimarySelection && oldListWithoutUser.length > 1) {
      const oldPrimaryId = isBuyer ? 'primaryOperatorId' : 'primaryBuyerId';
      const primaryId = getValues(oldPrimaryId);
      if (primaryId === user?.id) {
        setValue(oldPrimaryId, '');
      }
    }

    setValue(userNewLocation, newListWithUser);
    setValue(userOldLocation, oldListWithoutUser);
  }, [userRole]);

  useEffect(() => {
    if (!withPrimarySelection) return;

    const { buyers } = getValues();
    if (buyers && buyers.length === 1 && buyers[0]) {
      setValue('primaryBuyerId', buyers[0].id);
    }
    if (!buyers || !buyers.length) {
      setValue('primaryBuyerId', '');
    }
  }, [buyersFormValue]);

  useEffect(() => {
    if (!withPrimarySelection) return;

    const { operators } = getValues();
    if (operators && operators.length === 1 && operators[0]) {
      setValue('primaryOperatorId', operators[0].id);
    }
    if (!operators || !operators.length) {
      setValue('primaryOperatorId', '');
    }
  }, [operatorsFormValue]);

  const handleValidSubmit: SubmitHandler<ModifyTeamMembersFormFields> = async (
    data: ModifyTeamMembersFormFields,
  ) => {
    setIsFormErrorOpen(false);
    const {
      primaryOperatorId,
      primaryBuyerId,
      buyers,
      operators,
      viewOnly,
      nosEmailDistribution = [],
    } = data;

    const buyerIds = buyers
      .map((userModel) => userModel?.id)
      .filter((id): id is string => id !== null);
    const operatorIds = operators
      .map((userModel) => userModel?.id)
      .filter((id): id is string => id !== null);
    const viewOnlyIds = viewOnly
      .map((userModel) => userModel?.id)
      .filter((id): id is string => id !== null);
    const nosEmailDistributionIds = nosEmailDistribution
      .map((userModel) => userModel?.id)
      .filter((id): id is string => id !== null);

    const teamData = {
      primaryOperatorId,
      additionalOperatorIds: operatorIds.filter(
        (operatorId) => operatorId !== primaryOperatorId,
      ),
      primaryBuyerId,
      additionalBuyerIds: buyerIds.filter(
        (buyerId) => buyerId !== primaryBuyerId,
      ),
      viewOnlyIds,
      nosEmailDistributionIds,
    };

    try {
      await onFormSubmit(teamData);
    } catch (err) {
      logError(appInsights, 'modifyTeamMembers submit error', err);
      setIsFormErrorOpen(true);
    }
  };

  const handleInvalidSubmit: SubmitErrorHandler<ModifyTeamMembersFormFields> = (error) => {
    const latestErrorMessage = Object.values(error)
      .map((errorData) => errorData.message)
      .filter((message): message is string => message !== undefined);
    setErrorMessage(latestErrorMessage.join(' '));
    setIsFormErrorOpen(true);
  };

  const handleOnBuyerChange = (val: SetStateAction<string>) => {
    setUserSearchVal(val);
    if (val === '' && buyersFormValue.length === 1) {
      setValue('primaryBuyerId', '');
    }
  };

  const handleOnOperatorChange = (val: SetStateAction<string>) => {
    setUserSearchVal(val);
    if (val === '' && operatorsFormValue.length === 1) {
      setValue('primaryOperatorId', '');
    }
  };

  const includesNullInputs = () => {
    const formValues = getValues();
    if (formValues.viewOnly.includes(null)) {
      return true;
    }
    if (formValues.buyers.includes(null)) {
      return true;
    }
    if (formValues.operators.includes(null)) {
      return true;
    }
    return false;
  };

  if (!user) {
    return <>Loading...</>;
  }

  // If user is both a buyer and an operator, they should be able to remove themselves from one of them
  const canUserRemoveSelf = buyersFormValue.some((u) => u?.id === user.id)
    && operatorsFormValue.some((u) => u?.id === user.id);

  return (
    <>
      {
        isFormErrorOpen
        && <FormErrorComponent
          isDismissable={false}
          isOpen={isFormErrorOpen}
          setIsOpen={setIsFormErrorOpen}
          errorMessage={errorMessage}
        />
      }
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <RadioInput
          fieldName="userRole"
          control={control}
          label={'My Role'}
          options={userRoleOptions}
        />
        <AutocompleteTableContainer>
          {withPrimarySelection
            ? <AutocompleteTableArrayWithPrimary
                control={control}
                fieldName="operators"
                fieldNameRadio="primaryOperatorId"
                subjectName="Operator"
                placeholderText="Type User's Name or Email Address"
                options={userOptions}
                onInputChange={(val) => handleOnOperatorChange(val)}
                isRequired
                currentUserId={user.id}
                canUserRemoveSelf={canUserRemoveSelf}
                formGetValues={getValues}
                onSelect={(field) => handleAutocompleteSelect(field, ModifyTeamMembersFormUserRoles.operator)}
                radioChange={(field) => handleRadioChange(field, ModifyTeamMembersFormUserRoles.operator)}
                filterOut={filterOutForViewOnly.concat(filterOutForOperators)}
                areOptionsLoading={usersLoading}
              />
            : <AutocompleteTableArray
                control={control}
                fieldName="operators"
                subjectName="Operator"
                placeholderText="Type User's Name or Email Address"
                options={userOptions}
                onInputChange={(val) => handleOnOperatorChange(val)}
                isRequired
                currentUserId={user.id}
                canUserRemoveSelf={canUserRemoveSelf}
                formGetValues={getValues}
                filterOut={filterOutForViewOnly.concat(filterOutForOperators)}
                areOptionsLoading={usersLoading}
              />
          }
        </AutocompleteTableContainer>
        <AutocompleteTableContainer>
          {withPrimarySelection
            ? <AutocompleteTableArrayWithPrimary
              control={control}
              fieldName="buyers"
              fieldNameRadio="primaryBuyerId"
              subjectName="Buyer"
              placeholderText="Type User's Name or Email Address"
              options={userOptions}
              onInputChange={(val) => handleOnBuyerChange(val)}
              isRequired
              currentUserId={user.id}
              canUserRemoveSelf={canUserRemoveSelf}
              formGetValues={getValues}
              onSelect={(field) => handleAutocompleteSelect(field, ModifyTeamMembersFormUserRoles.buyer)}
              radioChange={(field) => handleRadioChange(field, ModifyTeamMembersFormUserRoles.buyer)}
              filterOut={filterOutForViewOnly.concat(filterOutForBuyers)}
              areOptionsLoading={usersLoading}
            />
            : <AutocompleteTableArray
              control={control}
              fieldName="buyers"
              subjectName="Buyer"
              placeholderText="Type User's Name or Email Address"
              options={userOptions}
              onInputChange={(val) => handleOnBuyerChange(val)}
              isRequired
              currentUserId={user.id}
              canUserRemoveSelf={canUserRemoveSelf}
              formGetValues={getValues}
              filterOut={filterOutForViewOnly.concat(filterOutForBuyers)}
              areOptionsLoading={usersLoading}
            />
          }
        </AutocompleteTableContainer>
        <AutocompleteTableContainer>
          <AutocompleteTableArray
            control={control}
            fieldName="viewOnly"
            labelSuffix="(View Only)"
            subjectName="Other Member"
            placeholderText="Type User's Name or Email Address"
            options={userOptions}
            onInputChange={setUserSearchVal}
            currentUserId={user.id}
            formGetValues={getValues}
            filterOut={filterOutForViewOnly.concat(filterOutForBuyers).concat(filterOutForOperators)}
            areOptionsLoading={usersLoading}
          />
        </AutocompleteTableContainer>
        {defaultValues.nosEmailDistribution && (
          <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_EMAIL}>
            <AutocompleteTableContainer>
              <AutocompleteChipsArray
                control={control}
                fieldName="nosEmailDistribution"
                subjectName="Internal NOS Summary Email Distribution"
                options={userOptions}
                onInputChange={setUserSearchVal}
                filterOut={filterOutForNosEmailDistribution}
                areOptionsLoading={usersLoading}
              />
            </AutocompleteTableContainer>
          </FeatureGate>
        )}
        <ButtonContainer>
          {isAddMemberFlow
            ? <ButtonStyled
              variant="outlined"
              data-testid="tour-member-form-previous"
              type="button"
              onClick={() => onPrevious?.()}
              disabled={isFormSaving}
            >
              Previous
            </ButtonStyled> : null
          }
          <ButtonStyled
            variant="contained"
            data-testid="tour-member-form-submit"
            type="submit"
            disabled={isFormSaving || !isDirty || includesNullInputs()}
          >
            {submitText}
          </ButtonStyled>
        </ButtonContainer>
      </form>
      <DevTool control={control} />
    </>
  );
};
