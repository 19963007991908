/* eslint-disable max-lines-per-function */
import { useState } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { Divider, Grid, Tabs } from '@mui/material';
import { DetailsMetadataItem, TabPanel, TabStyled } from '@components';
import { kebabCase } from '@utils/stringHelpers';
import { SpaceConfiguration } from '@gql/types/graphql';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  BackToButton,
  SelectedVenueHeader,
  VenueMetadata,
  VenueTabContainer,
} from './AddEventToOffer.styled';
import { CloneTab } from '../CloneTab/CloneTab';
import { QdTab } from '../QdTab/QdTab';
import { VenueGridData, VenueRowHandler } from '../VenueSearch/VenueSearch.types';

export function VenueDetails({
  openDetails,
  setOpenDetails,
  createEvent,
}: {
  openDetails: VenueGridData | null;
  setOpenDetails: React.Dispatch<React.SetStateAction<VenueGridData | null>>;
  createEvent: VenueRowHandler;
}) {
  if (!openDetails) return null;

  const [value, setValue] = useState(0);
  const tabs = ['Q&D', 'Clone'];

  const onTabSelect = (
    _: React.SyntheticEvent<Element, Event>,
    newVal: number,
  ) => {
    setValue(newVal);
  };

  const metadata = [];
  metadata.push({
    title: 'Venue',
    value: openDetails?.venueName || '',
  });
  metadata.push({
    title: 'Market',
    value: openDetails?.market || '',
  });

  if (openDetails?.metroArea) {
    metadata.push({
      title: 'DMA',
      value: openDetails.metroArea,
    });
  }

  metadata.push({
    title: 'Venue Type',
    value: openDetails?.spaceType || '',
  });

  metadata.push({
    title: 'Max. Capacity',
    value: openDetails?.maxCapacity || '',
  });

  const metadataRowItems = metadata.map((metadataItem, index) => (
    <DetailsMetadataItem
      key={index}
      metadata={metadataItem}
      first={index === 0}
    />
  ));

  return (
    <>
      <SelectedVenueHeader>
        <BackToButton
          data-testid="venue-details-back-to-search-results"
          onClick={() => {
            setOpenDetails(null);
          }}
        >
          <ArrowBackIos fontSize="inherit" /> Search Results
        </BackToButton>
      </SelectedVenueHeader>
      <Divider />
      <VenueMetadata>
        <Grid
          container
          direction="row"
          alignItems="center"
          data-testid="venue-details-metadata"
          justifyContent="space-between"
        >
          {metadataRowItems}
        </Grid>
      </VenueMetadata>
      <VenueTabContainer>
        <Tabs
          value={value}
          onChange={onTabSelect}
          data-testid="venue-details-tabs"
        >
          {tabs.map((tab) => (
            <TabStyled
              data-testid={`${kebabCase(tab)}-tab`}
              key={`${tab}-tab`}
              label={tab}
            />
          ))}
        </Tabs>
        <TabPanel
          value={value}
          index={0}
          idPrefix="venue-details"
          data-testid="venue-details-tab-qd"
        >
          {openDetails && (
            <QdTab
              handleAddEvent={async (spaceConfigurationId: string) => {
                await createEvent({ row: openDetails } as GridCellParams<VenueGridData>, spaceConfigurationId);
              }}
              configurations={
                openDetails.configurations as SpaceConfiguration[]
              }
              lastUpdatedDate={openDetails?.updatedDate}
            />
          )}
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          idPrefix="venue-details"
          data-testid="venue-details-tab-clone"
        >
          {openDetails && (
            <CloneTab
              venueId={openDetails.venueId ?? ''}
              spaceId={openDetails.id ?? ''}
            />
          )}
        </TabPanel>
      </VenueTabContainer>
    </>
  );
}
