import { Button } from '@mui/base';
import { CustomTheme, styled, Box } from '@mui/material';

export const DateCellContainer = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: theme?.spacing(1),
  }),
);

export const AddShowContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: 'none',
  backgroundColor: 'transparent',
  alignItems: 'center',
  '& .MuiTypography-root': {
    marginLeft: theme.spacing(0.25),
  },
  cursor: 'pointer',
  margin: theme.spacing(1),
  maxWidth: 'fit-content',
}));

export const ShowTableContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
}));
