// lint exceptions in place because errors are always of an 'any' type
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Maybe,
  SpaceConfiguration,
  TierScaling,
} from '@gql/types/graphql';
import {
  ConfigGridFields,
  FeeItemWithErrors,
  SpaceConfigWithErrors,
  ValidationErrorInfo,
} from '@types';

export const validateConfig = (
  config: SpaceConfiguration,
  updatedConfigurations: SpaceConfigWithErrors[],
  validationErrors: any,
  newConfig?: boolean,
  oldRowName?: string,
) => {
  let configErrors: ValidationErrorInfo[] = [];

  if (validationErrors && Object.keys(validationErrors).length) {
    configErrors = Object.keys(validationErrors).map((key) => {
      // configuration name key can have two forms:
      // '[7].name' for row-level errors (e.g. blank/missing name)
      // 'name' for array-level errors (e.g. duplicate names)

      const fieldMatch = key.match(/\[(\d+)\]/); // look for row-level errors
      const fieldIndex: number = fieldMatch ? parseInt(fieldMatch[1], 10) : -1;
      const field = key.split('.').pop();

      return {
        field: field === 'type' ? ConfigGridFields.CONFIG_TYPE : field || '',
        message: validationErrors[key].message,
        ids: field === ConfigGridFields.NAME && fieldIndex < 0 // -1 for dup names
          ? validationErrors[key].value
          : [updatedConfigurations[fieldIndex].id],
      };
    });
  }

  const validatedConfig = { ...config, errors: configErrors };

  let configurations = [
    ...updatedConfigurations.filter((c) => c.id !== validatedConfig.id),
    validatedConfig,
  ];

  // if exactly one other config has the oldRowName, remove its duplicate name error
  const existingNames = configurations.map((c) => c.name);
  if (oldRowName && existingNames.filter((name) => name === oldRowName).length === 1) {
    const configToUpdate = configurations.find((c) => c.name === oldRowName);

    configurations = configurations.map((c) => {
      if (c !== configToUpdate) {
        return c;
      }
      return {
        ...c,
        errors: c.errors?.filter((e) => e.field !== ConfigGridFields.NAME),
      };
    });
  }

  // ensure new config is added as last row. otherwise, sort by name
  if (!newConfig) {
    configurations = configurations.sort((a, b) => a?.name.localeCompare(b?.name));
  }

  return {
    validatedConfig,
    configurations,
  };
};

export const validatePriceTier = (
  priceTier: TierScaling,
  updatedPriceTiers: Maybe<TierScaling>[],
  validationErrors: any,
) => {
  let tierErrors: ValidationErrorInfo[] = [];

  if (validationErrors && Object.keys(validationErrors).length) {
    tierErrors = Object.keys(validationErrors).map((key) => ({
      field: key,
      message: validationErrors[key].message,
      ids: priceTier.id,
    }));
  }

  const validatedTier = { ...priceTier, errors: tierErrors };

  return {
    validatedTier,
    validatedTiers: [
      ...updatedPriceTiers.filter((t) => t?.id !== validatedTier.id),
      validatedTier,
    ].sort((a, b) => a?.name.localeCompare(b?.name)),
  };
};

// eslint-disable-next-line max-lines-per-function
export const validateFeeItem = (feeItem: FeeItemWithErrors, validationErrors: any) => {
  let feeErrors: ValidationErrorInfo[] = [];

  if (validationErrors && Object.keys(validationErrors).length) {
    feeErrors = Object.keys(validationErrors).map((key) => ({
      field: key,
      message: validationErrors[key].message,
    }));
  }

  const validatedFee = { ...feeItem, errors: feeErrors };

  return validatedFee;
};
