import { Typography } from '@mui/material';
import { ListViewHeaderContainerStyled } from './ListView.styled';

type ListViewHeaderProps = {
  headerText: string;
  fullWidth?: boolean;
};

export function ListViewHeader({ headerText, fullWidth = true }: ListViewHeaderProps) {
  return (
    <ListViewHeaderContainerStyled fullWidth={fullWidth}>
      <Typography data-testid="listViewHeaderText" variant="h5">
        {headerText}
      </Typography>
    </ListViewHeaderContainerStyled>
  );
}
