import {
  styled, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '4px',
  marginBottom: '20px',
  maxWidth: `calc(100vw - ${theme.spacing(23)})`,
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: '#fff',
  },
  '&.MuiAccordionSummary-root': {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '32px',
  minHeight: '72px',
  '&:hover': {
    cursor: 'default !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    justifyContent: 'space-between',
  },
});

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
