export enum SnackBarMessages {
  NosFinalizeComplete = 'NOS Settlement has been accounting settled.',
  NosSubmitComplete = 'NOS Settlement has been submitted successfully.',
  NosResubmitComplete = 'NOS Settlement has been saved.',
  PullDataComplete = 'Pull New Data Complete',
  SortByShowDateComplete = 'Sort Worksheets by Date Complete.',
  SortByMarketComplete = 'Sort Worksheets by Market Complete.',
  SubmitError = 'We experienced an issue. Please try again.',
  SubmitSettlementSuccessful = 'Settlement has been submitted successfully.',
  VenueChangesSaved = 'You have successfully saved your changes!',
  VenueChangesFailed = 'We were unable to Save Changes. Please try clicking the save button again.',
  VenueUpdateSaved = 'Success! Your worksheet has been updated with the selected venue.',
  VenueUpdateFailed = 'We were unable to update your worksheet, please try selecting the venue again.',
  VenueConfigurationNameRequired = 'Configuration Name is required.',
  VenueConfigurationTypeRequired = 'Configuration Type is required.',
  WorkbookSyncComplete = 'Workbook Sync Complete',
}
