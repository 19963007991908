import * as React from 'react';
import { msalScopes } from '@services/msal-config';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Divider, Typography } from '@mui/material';
import logo from '@images/logo.svg';
import elvisLogo from '@images/elvis.svg';
import {
  AegLogoBox,
  ContentBox,
  LoginButton,
  LoginTroubleBox,
  SignInBox,
  SignInContainer,
} from './SignIn.styled';

export function SignIn() {
  const { instance } = useMsal();
  const [searchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const isInProgress = inProgress !== 'none';

  const handleSignIn = () => {
    if (searchParams.get('isExcel') === 'yes') {
      void instance.loginPopup({ scopes: msalScopes });
    } else {
      void instance.loginRedirect({ scopes: msalScopes });
    }
  };

  if (isInProgress) {
    return <></>;
  }
  if (!isInProgress && isAuthenticated) {
    const redirect = searchParams.get('returnUrl') ?? '/';
    return <Navigate to={redirect} />;
  }

  return (
    <React.Fragment>
      <SignInContainer maxWidth="sm" data-testid="sign-in-container">
        <AegLogoBox>
          <img alt="aeg-logo" src={logo} width={251} height={139} />
          <img alt="elvis-logo" src={elvisLogo} width={51} height={62} />
        </AegLogoBox>
        <Divider />
        <ContentBox>
          <SignInBox>
            <Typography variant="h4"> Sign In </Typography>
            <Typography variant="body1-medium">
              {' '}
              Please sign in with Active Directory.{' '}
            </Typography>
          </SignInBox>
          <LoginButton
            onClick={handleSignIn}
            variant="contained"
            size="large"
            color="secondary"
            data-testid="sign-in-button"
          >
            Continue
          </LoginButton>
          <LoginTroubleBox>
            Having trouble logging in? <a href="/">Request access</a>
          </LoginTroubleBox>
        </ContentBox>
      </SignInContainer>
    </React.Fragment>
  );
}
