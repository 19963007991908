import { GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function AddInErrorWarning() {
  return (
    <LayoutWarning header={'Add-in Error'}>
          <GenericMessage>
            Please ensure you have the correct Add-in loaded for this tour.
          </GenericMessage>
          <GenericMessage>
            Example: if you're launching a tour from the staging environment, use the staging version of the Add-in.
          </GenericMessage>
          <GenericMessage>
            Need Help? <br/>
            bookingsupport@aegpresents.com
          </GenericMessage>
    </LayoutWarning>
  );
}
