import { graphql } from '@gql/types';

export const ADD_PARENT_TOUR = graphql(`
  mutation CreateParentTour($parentTour: AddParentTourInput!) {
    addParentTour(parentTour: $parentTour) {
      id
      name
      numberOfTours
    }
  }
`);

export const MODIFY_PARENT_TOUR = graphql(`
  mutation ModifyParentTour($parentTour: ModifyParentTourInput!) {
    modifyParentTour(parentTour: $parentTour) {
      id
      name
      tours {
        id
        name
      }
    }
  }
`);
