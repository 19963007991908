import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { EnvConfig } from '@types';

export const msalScopes = ['openid', 'profile', 'offline_access'];

export const createMsalConfig = (envConfig: EnvConfig) => {
  const msalConfig = {
    auth: {
      clientId: envConfig.AZURE_AD_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${envConfig.AZURE_AD_TENANT_ID}`,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  const accessTokenRequest = {
    // What MSAL requests by default
    scopes: msalScopes,
  };

  const getBearerToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (account) {
      try {
        // Check if the id Token needs to be refreshed, as MSAL checks expiration on the access token only
        const { idTokenClaims } = account;
        const idTokenExpiry = idTokenClaims?.exp ?? 0;
        const tokenRefreshNeeded = (new Date(idTokenExpiry * 1000) < new Date());

        const tokenResult = await msalInstance.acquireTokenSilent({
          ...accessTokenRequest,
          forceRefresh: tokenRefreshNeeded,
          account,
        });

        // using id token as Bearer Token
        return tokenResult.idToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // Fallback to redirect
          return msalInstance.acquireTokenRedirect({
            ...accessTokenRequest,
            account,
          });
        }
      }
    }

    // TODO: See if we need to handle redirects if have no active accounts
    return null;
  };

  return { msalInstance, getBearerToken };
};
