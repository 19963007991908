import React from 'react';
import { useQuery } from '@apollo/client';

import { ArtistModel } from '@types';
import { AegAutocomplete, SearchInputComponent } from '@components';
import { SEARCH_ARTISTS } from '@gql/queries/artists';

type ArtistSearchProps = {
  selectedHeadliner: ArtistModel | null;
  testId: string;
  onChange: (selectedHeadliner: ArtistModel | null) => void;
};

export const ArtistSearch = ({
  selectedHeadliner,
  testId,
  onChange,
}: ArtistSearchProps) => {
  const [artistSearchVal, setArtistSearchVal] = React.useState('');
  const { loading: loadingArtists, data: artistOptions } = useQuery(SEARCH_ARTISTS, {
    errorPolicy: 'all',
    variables: { searchTerm: artistSearchVal },
    skip: !artistSearchVal,
  });

  return (
    <AegAutocomplete
      areOptionsLoading={loadingArtists}
      testId={testId}
      options={!loadingArtists && artistOptions?.artists ? artistOptions.artists : []}
      dataDisplayField={{ primary: 'name' }}
      dataFilterField={'id'}
      onInputChange={setArtistSearchVal}
      placeholderText="Artist's Name"
      value={selectedHeadliner}
      onChange={(artist) => {
        onChange(artist as unknown as ArtistModel);
      }}
      InputComponent={SearchInputComponent}
    />
  );
};
