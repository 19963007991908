import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { EnvStatusContext, ExcelUserContext, WebUserContext } from '@providers';
import { useLazyQuery } from '@apollo/client';
import { CHECK_USER_PERMISSION } from '@gql/queries/users';
import { PermissionCheck } from '@types';

type ProtectedComponentProps = {
  children: React.ReactNode;
  checkPermission: PermissionCheck;
  navigateAway?: string;
};

export function ProtectedComponent({
  children,
  checkPermission,
  navigateAway,
}: ProtectedComponentProps) {
  // If user is not authenticated
  const { envStatus } = React.useContext(EnvStatusContext);
  const { mode } = envStatus;

  const user = React.useContext(
    mode === 'excel' ? ExcelUserContext : WebUserContext,
  );

  // eslint-disable-next-line operator-linebreak
  const [runCheckPermission, { called, loading: checkLoading, data }] =
    useLazyQuery(CHECK_USER_PERMISSION, {
      errorPolicy: 'all',
      variables: {
        checkPermissionInput: checkPermission,
      },
    });

  React.useEffect(() => {
    if (user && !called) {
      void runCheckPermission();
    }
  }, [user]);

  if (!user || !user.id) {
    // No user means auth isn't ready yet
    return <></>;
  }

  if (checkLoading || !called) {
    return <></>;
  }

  if (data?.checkUserPermission?.hasPermission) {
    return <>{children}</>;
  }
  if (navigateAway) {
    return <Navigate to={navigateAway ?? ''} replace />;
  }
  return <></>;
}
