/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

/** recursively loops through a payload and removes the __typename prop from an object */
export const removeTypenamesAndErrors = (obj: any): any => {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeTypenamesAndErrors);
  }

  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (key !== '__typename' && key !== 'errors') {
      newObj[key] = removeTypenamesAndErrors(value);
    }
    return newObj;
  }, {} as any);
};

/** recursively loops through a payload and creates a clean object, removing any fields with null values
* (GQL does not accept properties with null values for denoted schema types ...
* ... the property must have a value or be excluded from the payload)
*/
export const removeNullProperties = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj
      .map(removeNullProperties)
      .filter((item) => item !== undefined);
  }

  if (typeof obj === 'object') {
    return Object.entries(obj)
      .reduce((newObj, [key, value]) => {
        const newValue = removeNullProperties(value);
        if (newValue !== undefined) {
          newObj[key] = newValue;
        }
        return newObj;
      }, {} as any);
  }

  return obj;
};
