import { styled } from '@mui/material';

export const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(78),
  width: theme.spacing(132),
  maxWidth: '100%',
  maxHeight: '80vh',
}));
