import { Tooltip } from '@mui/material';

type ConditionalTooltipProps = {
  title: string;
  display: boolean;
  children: React.ReactNode,
};

export function ConditionalTooltip(props: ConditionalTooltipProps) {
  const { display, children, title } = props;

  const slotProps = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -14],
          },
        },
      ],
    },
  };

  if (display) {
    return (<Tooltip
      title={title}
      slotProps={slotProps}
      data-test-id="conditional-tooltip"
    >
      <span>
        {children}
      </span>
    </Tooltip>
    );
  }

  return children;
}
