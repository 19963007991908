/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Event, ExpensePhase } from '@gql/types/graphql';
import { EventStatus } from '@types';
import { Phases } from '@utils/excel/shared/constants';
import { getTimezoneDate, toStringOrEmptyString } from '@utils/stringHelpers';
import { toNumberOrUndefined } from '@utils/numberHelpers';

type ExchangeRateVariableAndTotal = string | number | undefined;

interface GetExchangeRateVariableAndTotalType {
  exchangeRate: ExchangeRateVariableAndTotal,
  variable: ExchangeRateVariableAndTotal,
  total: ExchangeRateVariableAndTotal,
}

// Column count: 6
export const createEventSectionForShowDetail = (event: Event) => {
  const [formattedEventDate] = getTimezoneDate(event?.date, event?.venue?.timezone);

  const result = [
    // Column: Event ID, index 0, column A
    toStringOrEmptyString(event?.id),
    // Column: Phase, index 1, Column B
    toStringOrEmptyString(event?.status),
    // Column: Date, index 2, Column C
    formattedEventDate,
    // Column: City, index 3, Column D
    toStringOrEmptyString(event?.venue?.city),
    // Column: Venue, index 4, Column E
    toStringOrEmptyString(event?.space?.name),
    // Column: Shows, index 5, Column F
    toNumberOrUndefined(event?.shows?.length),
  ];
  return result;
};

// Column count: 16
export const createActualProjectedSectionForShowDetail = (event: Event) => {
  if (event.status === EventStatus.Confirmed) {
    const expensePhase = event.expense?.phases?.find((phase) => phase.id === Phases.MODELING);
    const ancillaryCategoryItems = expensePhase?.ancillaryCategoryItems || [];

    return [
      // Column: Capacity, index 6, Column G
      toNumberOrUndefined(event?.projectedBoxOfficeOverview?.totalAvailableCapacity),
      // Column: Sold, index 7, Column H
      toNumberOrUndefined(event?.projectedSales),
      // Column: Gross Receipts, index 8, Column I
      toNumberOrUndefined(event?.projectedShowIncome?.grossShowReceiptsToBase),
      // Column: Net Gross Receipts, index 9, Column J
      toNumberOrUndefined(event?.projectedShowIncome?.netGrossReceiptsToBase),
      // Column: Adjustments 1, index 10, column K
      toNumberOrUndefined(''),
      // Column: Adjustments 2, index 11, column L
      toNumberOrUndefined(''),
      // Column: Adjustments 3, index 12, column M
      toNumberOrUndefined(''),
      // Column: Adjustments 4, index 13, column N
      toNumberOrUndefined(''),
      // Column: Adjustments 5, index 14, column O
      toNumberOrUndefined(''),
      // Column: Expenses, index 15, Column P
      toNumberOrUndefined(Math.abs(event?.projectedNetShowReceipts?.netShowExpensesTotalToBase || 0)),
      // Column: Net Show Receipts, index 16, Column Q
      toNumberOrUndefined(event?.projectedNetShowReceipts?.sequentialNetToPoolToBase),
      // Column: Guarantee, index 17, Column R
      toNumberOrUndefined(event?.projectedArtistOffer?.guaranteeToBase),
      // Column: Artist Earnings, index 18, Column S
      toNumberOrUndefined(event?.projectedArtistOffer?.artistEarningSeqRoundedToBase),
      // Column: Ancillary Earnings, index 19, Column T
      toNumberOrUndefined(ancillaryCategoryItems.length ? ancillaryCategoryItems[0].totalProjectedToBase : ''),
      // Column: Co-Promoter Share, index 20, Column U
      toNumberOrUndefined(event?.projectedAegEarnings?.coPromoterShareTotalToBase),
      // Column: Net Net, index 21, Column V
      toNumberOrUndefined(event?.projectedAegEarnings?.aegNetShowEarningsTotalToBase),
    ];
  }

  if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
    const showAdjustments = event?.primaryNosSettlement?.internalSettlement?.netShowReceipts?.showAdjustments || [];
    return [
      // Column: Capacity, index 6, Column G
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.actualBoxOfficeOverview?.totalAvailableCapacity),
      // Column: Sold, index 7, Column H
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.actualBoxOfficeOverview?.totalSellableCapacity),
      // Column: Gross Receipts, index 8, Column I
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.actualBoxOfficeOverview?.totalGrossToBase),
      // Column: Net Gross Receipts, index 9, Column J
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.netShowReceipts?.netGrossReceiptsTotalToBase),
      // Column: Adjustments 1, index 10, column K
      toNumberOrUndefined(showAdjustments[0]?.adjustmentTotalToBase),
      // Column: Adjustments 2, index 11, column L
      toNumberOrUndefined(showAdjustments[1]?.adjustmentTotalToBase),
      // Column: Adjustments 3, index 12, column M
      toNumberOrUndefined(showAdjustments[2]?.adjustmentTotalToBase),
      // Column: Adjustments 4, index 13, column N
      toNumberOrUndefined(showAdjustments[3]?.adjustmentTotalToBase),
      // Column: Adjustments 5, index 14, column O
      toNumberOrUndefined(showAdjustments[4]?.adjustmentTotalToBase),
      // Column: Expenses, index 15, Column P
      toNumberOrUndefined(Math.abs(event?.primaryNosSettlement?.internalSettlement?.netShowReceipts?.netShowExpensesTotalToBase || 0)),
      // Column: Net Show Receipts, index 16, Column Q
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.netShowReceipts?.netShowReceiptsTotalToBase),
      // Column: Guarantee, index 17, Column R
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.artistSettlement?.guaranteeToBase),
      // Column: Artist Earnings, index 18, Column S
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.artistSettlement?.artistNosSettlementTotalToBase),
      // Column: Ancillary Earnings, index 19, Column T
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.aegSettlement?.ancillaryIncomeTotalToBase),
      // Column: Co-Promoter Share, index 20, Column U
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.aegSettlement?.coPromoterShareTotalToBase),
      // Column: Net Net, index 21, Column V
      toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.aegSettlement?.netProfitOrLossToAegTotalToBase),
    ];
  }

  return Array(16).fill('') as string[];
};

// Column count: 11
export const createBudgetSectionForShowDetail = (event: Event) => {
  const totalSellableCapacity = () => {
    if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
      return event?.primaryNosSettlement?.internalSettlement?.actualBoxOfficeOverview?.totalAvailableCapacity;
    }
    if (event.status === EventStatus.Confirmed || event.status === EventStatus.Canceled) {
      return event?.projectedBoxOfficeOverview?.totalSellableCapacity;
    }
    return '';
  };

  const expensePhase = event.expense?.phases?.find((phase) => phase.id === Phases.MODELING);
  const ancillaryCategoryItems = expensePhase?.ancillaryCategoryItems || [];

  return [
    // Column: Capacity, index 22, Column W
    toNumberOrUndefined(totalSellableCapacity()),
    // Column: Sold, index 23, Column X
    toNumberOrUndefined(event?.projectedSales),
    // Column: Gross Receipts, index 24, Column Y
    toNumberOrUndefined(event?.projectedShowIncome?.grossShowReceiptsToBase),
    // Column: Net Gross Receipts, index 25, Column Z
    toNumberOrUndefined(event?.projectedShowIncome?.netGrossReceiptsToBase),
    // Column: Adjustments, index 26, Column AA
    toNumberOrUndefined(''),
    // Column: Expenses, index 27, Column AB
    toNumberOrUndefined(event?.projectedNetShowReceipts?.netShowExpensesTotalToBase),
    // Column: Net Show Receipts, index 28, Column AC
    toNumberOrUndefined(event?.projectedNetShowReceipts?.sequentialNetToPoolToBase),
    // Column: Guarantee, index 29, Column AD
    toNumberOrUndefined(event?.projectedArtistOffer?.guaranteeToBase),
    // Column: Ancillary Earnings, index 30, Column AE
    toNumberOrUndefined(
      ancillaryCategoryItems.length ? ancillaryCategoryItems[0].totalProjectedToBase : '',
    ),
    // Column: Co-Promoter Share, index 31, Column AF
    toNumberOrUndefined(event?.projectedAegEarnings?.coPromoterShareTotalToBase),
    // Column: Net Net, index 32, Column AG
    toNumberOrUndefined(event?.projectedAegEarnings?.aegNetShowEarningsTotalToBase),
  ];
};

// Column count: 15
export const createCostingTotalsSectionForShowDetail = (event: Event) => {
  const versionSuffix = event.primaryNosVersion && event.primaryNosVersion > 1 ? `_v${event.primaryNosVersion}` : '';
  const expensePhaseId = event.status === EventStatus.Confirmed ? Phases.MODELING : `${Phases.INTERNAL_SETTLEMENT}${versionSuffix}`;
  const expensePhase: ExpensePhase | undefined = event.expense?.phases?.find((phase) => phase.id === expensePhaseId);

  const getFixedCategoryItemByCategoryId = (categoryId: string) =>
    expensePhase?.fixedCategoryItems?.find((item) => item.categoryId === categoryId);

  const getExchangeRateVariableAndTotal = (): GetExchangeRateVariableAndTotalType => {
    if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
      return {
        exchangeRate: toNumberOrUndefined(event?.primaryNosSettlement?.internalSettlement?.exchangeRate),
        variable: toNumberOrUndefined(expensePhase?.totalVariableCostsToBase),
        total: toNumberOrUndefined(expensePhase?.totalBudgetVariableFixedCosts),
      };
    }
    if (event.status === EventStatus.Confirmed) {
      const variable: number = expensePhase?.seqVariableCostsToBase || 0;

      return {
        exchangeRate: toNumberOrUndefined(event.exchangeRate),
        variable: toNumberOrUndefined(variable * -1),
        total: '=SUM(ShowDetailFar[@[costingTotalsAdvertising]:[costingTotalsVariable]])',
      };
    }
    return {
      exchangeRate: '',
      variable: '',
      total: '',
    };
  };

  const { exchangeRate, variable, total } = getExchangeRateVariableAndTotal();

  if (
    event.status === EventStatus.Confirmed
    || event.status === EventStatus.NOSSettled
    || event?.status === EventStatus.AccountingSettled
  ) {
    return [
      // Column: Exchange Rate, index 33, Column AH
      exchangeRate,
      // Column: Advertising, index 34, Column AI
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Advertising')?.totalBudgetToBase),
      // Column: Artist Prorates, index 35, Column AJ
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Artist Prorates')?.totalBudgetToBase),
      // Column: Production Prorates, index 36, Column AK
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Production Prorates 1')?.totalBudgetToBase),
      // Column: Support, index 37, Column AL
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Support')?.totalBudgetToBase),
      // Column: Rent, index 38, Column AM
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Rent')?.totalBudgetToBase),
      // Column: Stagehands, index 39, Column AN
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Stagehands')?.totalBudgetToBase),
      // Column: Catering, index 40, Column AO
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Catering')?.totalBudgetToBase),
      // Column: Staffing, index 41, Column AP
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Staffing')?.totalBudgetToBase),
      // Column: Venue, index 42, Column AQ
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Venue')?.totalBudgetToBase),
      // Column: Production, index 43, Column AR
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Production')?.totalBudgetToBase),
      // Column: Transport, index 44, Column AS
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Band Transport')?.totalBudgetToBase),
      // Column: Other Costs, index 45, Column AT
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Other Costs')?.totalBudgetToBase),
      // Column: Variable, index 46, Column AU
      variable,
      // Column: Total, index 47, Column AV
      total,
    ];
  }

  return Array(15).fill('') as string[];
};

// Column count 3
export const createFarSectionForShowDetail = () => [
  // Column: FAR Expenses, index 48, Column AW
  '=SUM([@costingTotalsAdvertising]:[@costingTotalsVariable])',
  // Column: FAR Revenue, index 49, Column AX
  '=SUM([@actualNetGrossReceipts],[@actualAdjustment1]:[@actualAdjustment5])',
  // Column: FAR Co-Pro, index 50, Column AY
  '=-[@actualCoProShare]',
];

export const generateShowDetailRowForFar = (
  event: Event,
) => {
  // must be 51 columns
  const showDetailValues = [
    // 6 columns
    ...createEventSectionForShowDetail(event),
    // 16 columns
    ...createActualProjectedSectionForShowDetail(event),
    // 11 columns
    ...createBudgetSectionForShowDetail(event),
    // 15 columns
    ...createCostingTotalsSectionForShowDetail(event),
    // 3 columns
    ...createFarSectionForShowDetail(),
  ];

  return showDetailValues;
};
