import {
  Box, CustomTheme, styled, Typography,
} from '@mui/material';

export const ContainerStyled = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    background: theme?.palette.primary.contrast,
    borderRadius: theme?.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxShadow: theme?.shadows[3],
    maxWidth: '100%',
  }),
);

export const FlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme?.spacing(1),
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(1.5, 0, 1.4, 2),
}));

export const EventMetadata = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    marginBottom: theme?.spacing(2),
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  }),
);
