/* eslint-disable max-lines-per-function */
import {
  FormHelperText, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { kebabCase } from '@utils/stringHelpers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  InputLabel,
  DropdownContainer,
  PlaceholderContainer,
  InputLabelContainer,
} from './Form.styled';

type DropdownOptions = {
  value: string;
  label: string;
};

type DropdownInputProps<
  TFieldValues extends FieldValues = any,
  TContext = any,
> = {
  fieldName: string;
  control: Control<TFieldValues, TContext>;
  label: string;
  options: Array<DropdownOptions>;
  placeholderText: string;
  isRequired?: boolean;
  showInfo?: boolean;
  isDisabled?: boolean;
  includeLabel?: boolean;
  handleChange?: (value: string) => void;
};

type QueryData = ({
  id?: string | null;
  name?: string | null;
} | null)[];

/**
 * Typesafe mapper function to map GQL queries into an array of valid DropdownOptions.
 * @param objects - an array of objects expected to each have an `id` and `name`
 * @returns {DropdownOptions[]}
 */
// prettier-ignore
export const mapDropdownOptions = (
  objects?: QueryData | null,
): DropdownOptions[] => {
  if (objects) {
    const validObjects = objects.filter(
      (object) => object?.id,
    ) as {
      id: string;
      name: string;
    }[];

    return validObjects.map(({ name, id }) => ({
      label: name ?? id,
      value: id,
    }));
  }
  return [];
};

export const DropdownInput = ({
  fieldName,
  control,
  label,
  options,
  placeholderText,
  isRequired,
  showInfo = false,
  isDisabled = false,
  includeLabel = true,
  handleChange,
}: DropdownInputProps) => {
  const dropdownOptions = options.map((option, index: number) => (
    <MenuItem
      key={`dropdown-option-${index}`}
      value={option.value}
      data-testid={`${kebabCase(label)}-dropdown-option-${index}`}
    >
      {option.label}
    </MenuItem>
  ));

  return (
    <>
      {includeLabel ? <InputLabelContainer>
        <InputLabel
          required={isRequired}
          data-testid={`${kebabCase(label)}-label`}
        >
          {label}
        </InputLabel>{' '}
        {showInfo ? <InfoOutlinedIcon /> : null}
      </InputLabelContainer> : null}
      <Controller
        name={fieldName}
        control={control}
        render={({
          field: {
            onChange, onBlur, value, name, ref,
          },
          formState,
        }) => (
          <DropdownContainer>
            <Select
              value={value as string}
              displayEmpty
              onChange={(onChangeValue: SelectChangeEvent<string>) => {
                onChange(onChangeValue);
                if (handleChange) {
                  handleChange(onChangeValue.target.value);
                }
              }}
              renderValue={(selectedValue) => (selectedValue ? (
                options.find((option) => option.value === value)?.label
              ) : (
                  <PlaceholderContainer
                    data-testid={`${kebabCase(label)}-dropdown-placeholder`}
                  >
                    {placeholderText}
                  </PlaceholderContainer>
              ))}
              defaultValue={formState.defaultValues?.[fieldName] as string}
              inputProps={{
                'data-testid': `${kebabCase(label)}-child-dropdown`,
              }}
              data-testid={`${kebabCase(label)}-dropdown`}
              error={formState.errors[fieldName] !== undefined}
              disabled={isDisabled}
            >
              {dropdownOptions}
            </Select>
            <FormHelperText
              data-testid={`${kebabCase(label)}-dropdown-error-message`}
            >
              {formState.errors[fieldName]?.message?.toString()}
            </FormHelperText>
          </DropdownContainer>
        )}
      />
    </>
  );
};
