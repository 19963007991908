/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable max-lines-per-function */
import { useContext, useMemo, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Divider } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ArrowBackIos } from '@mui/icons-material';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  AltRowColorGrid, FeatureGate, Loading, SnackbarType, StatusChip,
} from '@components';
import { getFragmentData } from '@gql/types';
import { EVENT_FRAGMENT, SEARCH_EVENTS } from '@gql/queries/events';
import { GET_PRIMARY_NOS_SETTLEMENT_DOWNLOAD_URL } from '@gql/queries/nosSettlements';
import { addColumn } from '@utils/datagridHelpers';
import { getTimezoneDate, sortDates } from '@utils/stringHelpers';
import { canSaveNosWorkbookByStatus } from '@utils/statusHelpers';
import { SearchEventFieldsFragment } from '@gql/types/graphql';
import { useAegModeling } from '@hooks';
import { NotificationDispatchContext } from '@providers';
import { logError } from '@services/telemetry-service';
import FeatureFlags from '@utils/featureFlags';
import {
  BackToButton,
  CloneOfferButtonContainer,
  SelectedVenueHeader,
  VenueMetadata,
} from '../AddEventToOffer/AddEventToOffer.styled';
import { SearchEventsLoadingContainer } from './CloneTab.styled';

// Un-Comment this block to re-enable row expansion
// import { expandColum, manageExpandedRows } from '@utils/datagridHelpers';
// import {
//   GRID_DETAIL_PANEL_TOGGLE_FIELD,
//   GridColDef,
//   GridEventListener,
//   GridRowId,
//   useGridApiRef,
// } from '@mui/x-data-grid-pro';
// import { TicketTierPanel } from './TicketTierPanel';

interface CloneTabGridData extends SearchEventFieldsFragment {
  numShows?: number | null;
}

export const CloneTab = ({
  venueId,
  spaceId,
  disableVirtualization = false,
}: {
  venueId: string;
  spaceId: string;
  disableVirtualization?: boolean;
}) => {
  const [singleEventSelected, setSingleOfferSelected] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<SearchEventFieldsFragment | null>(null);
  const appInsights = useAppInsightsContext();
  const { cloneEvent } = useAegModeling();
  const setNotification = useContext(NotificationDispatchContext);

  const [getPrimaryNosDownloadUrl] = useLazyQuery(GET_PRIMARY_NOS_SETTLEMENT_DOWNLOAD_URL);

  // Un-Comment this block to re-enable row expansion
  // const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  // const expandRowClick = (gridRowId: GridRowId) => {
  //   manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  // };

  const cloneEventRowToOffer = async (row: SearchEventFieldsFragment) => {
    if (!row.id) {
      return;
    }
    try {
      await cloneEvent(row.id);
    } catch (e) {
      logError(appInsights, 'clone event error', e);
      setNotification({
        type: SnackbarType.ERROR,
        text: 'Event could not be added. Please try again.',
        duration: 6000,
      });
    }
  };

  const columns: GridColDef<CloneTabGridData>[] = [
    // ...expandColumn(expandedRows, expandRowClick), // Un-Comment to re-enable row expansion
    ...addColumn<CloneTabGridData>(cloneEventRowToOffer),
    // {
    //   field: 'lastUpdatedDate',
    //   headerName: 'Last Updated',
    //   minWidth: 115,
    // },
    {
      field: 'eventDate',
      headerName: 'Event Date',
      minWidth: 115,
      sortComparator: sortDates,
      valueGetter: ({ row }) => {
        if (row.shows?.length) {
          const [firstShow] = row.shows;

          if (firstShow?.showDateTime) {
            return getTimezoneDate(firstShow.showDateTime, row.venue?.timezone)[1];
          }
        }

        return undefined;
      },
      valueFormatter(params) {
        if (!params.value) {
          return 'TBD';
        }

        const sortedDate = params.value as Dayjs;
        return sortedDate.format('L');
      },
    },
    {
      field: 'headliner',
      headerName: 'Headliner',
      minWidth: 130,
      valueGetter: (params) => params.row.headliner?.name,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      minWidth: 110,
    },
    {
      field: 'configuration',
      headerName: 'Configuration',
      minWidth: 125,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => <StatusChip status={params.row.status || ''} />,
    },
    {
      field: 'numShows',
      headerName: '# of Shows',
      minWidth: 100,
      valueGetter: ({ row }) => row.shows?.length,
    },
    {
      field: 'sellableCapacity',
      headerName: 'Sellable Capacity',
      minWidth: 145,
    },
  ];

  const { data, error, loading: eventsSearchLoading } = useQuery(SEARCH_EVENTS, {
    errorPolicy: 'ignore',
    variables: {
      filter: {
        startDate: dayjs().subtract(2, 'year').format('YYYY-MM-DD'),
        returnTBD: true, // NOTE: this filter requires startDate to be set
        top: 200,
        venueId,
        spaceId,
      },
    },
  });

  const rows = useMemo<CloneTabGridData[]>(() => {
    if (data?.searchEvents) {
      const { searchEvents: events } = data;

      return events
        .filter((eventData) => eventData?.canSelectEventFromCloneList)
        .map((eventData, i) => {
          const event = getFragmentData(EVENT_FRAGMENT, eventData);
          return {
            key: event?.id as string,
            ...event,
            name: event?.name as string,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            buyer: event?.primaryBuyer?.name as string,
            sellableCapacity: event?.sellableCapacity as number,
            lastUpdatedDate: 'tbd', // temporary
          };
        });
    }

    return [];
  }, [data]);

  // Un-Comment this block to re-enable row expansion
  // const getDetailPanelContent = useCallback(
  //   ({ row }: { row: CloneTabGridData }) => (
  //     <TicketTierPanel
  //       disableVirtualization={disableVirtualization}
  //       defaultBoxOffice={row?.defaultBoxOffice}
  //       currency={row.venue?.localCurrencyCode}
  //     />
  //   ),
  //   [],
  // );
  // const apiRef = useGridApiRef();
  // const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
  //   (params) => {
  //     expandRowClick(params.id);
  //     apiRef.current.toggleDetailPanel(params.id);
  //   },
  //   [apiRef, expandedRows],
  // );

  if (eventsSearchLoading) {
    return <SearchEventsLoadingContainer>
      <Loading />
    </SearchEventsLoadingContainer>;
  }

  const onRowClick = (params: { row: SearchEventFieldsFragment }) => {
    setSingleOfferSelected(true);
    setSelectedEvent(params.row);
  };

  if (singleEventSelected && selectedEvent) {
    const downloadPrimarySettlementFile = async () => {
      if (selectedEvent.id) {
        const downloadUrlResponse = await getPrimaryNosDownloadUrl(
          { variables: { eventId: selectedEvent.id } },
        );
        const url = downloadUrlResponse?.data?.getPrimaryNOSSettlementDownloadUrl?.url;
        if (url) {
          window.open(url);
        }
      }
    };

    return (
      <div>
        <SelectedVenueHeader>
          <BackToButton
            data-testid="venue-details-back-to-offer-results"
            onClick={() => {
              setSingleOfferSelected(false);
            }}
          >
            <ArrowBackIos fontSize="inherit" /> Offers
          </BackToButton>
        </SelectedVenueHeader>
        <Divider />
        <VenueMetadata>
          {/* <Grid
            container
            direction="row"
            alignItems="center"
            data-testid="offer-details-metadata"
            justifyContent="space-between"
          >
            {singleOfferMetadataRowItems}
          </Grid> */}
        </VenueMetadata>
        <CloneOfferButtonContainer>
          <FeatureGate configFlag={FeatureFlags.DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON}>
            <Button
              variant="outlined"
              data-testid="download-settlement-button"
              disabled={!canSaveNosWorkbookByStatus(selectedEvent.status)}
              style={{
                marginRight: '12px',
              }}
              onClick={() => {
                void downloadPrimarySettlementFile();
              }}>
              Download Settlement
            </Button>
          </FeatureGate>
          <Button
            variant="contained"
            data-testid="clone-offer-button"
            onClick={() => {
              void cloneEventRowToOffer(selectedEvent);
            }}>
            Clone Offer
          </Button>
        </CloneOfferButtonContainer>
      </div>);
  }

  return (
    data?.searchEvents && (
      <AltRowColorGrid
        hideFooter
        columns={columns}
        rows={rows}
        onRowClick={onRowClick}
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        initialState={{
          pinnedColumns: {
            // left: [GRID_DETAIL_PANEL_TOGGLE_FIELD], // Un-Comment to re-enable row expansion
            right: ['addEvent'],
          },
        }}
      // Un-Comment props below to re-enable row expansion
      // apiRef={apiRef}
      // onRowClick={onRowClick}
      // getDetailPanelContent={getDetailPanelContent}
      // getDetailPanelHeight={() => 'auto'
      />
    )
  );
};
