import { graphql } from '@gql/types';

export const GET_PL_CATEGORIES = graphql(`
  query GetProfitLossCategories {
    profitLossCategories {
      id
      categoryParent
      category
      subCategory
      isDisplayByDefaultModel
      isDisplayByDefaultSettlement
      displayOrder
      isActive
      objectAccount
      subsidiaryAccount
      isNegativeByDefault
    }
  }
`);
