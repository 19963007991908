import { Control, Controller, FieldValues } from 'react-hook-form';
import { Checkbox, Typography } from '@mui/material';
import { kebabCase } from '@utils/stringHelpers';
import { CheckboxContainer, DisabledTypography } from './Form.styled';

interface CheckboxInputProps<
  TFieldValues extends FieldValues = any,
  TContext = any,
> {
  fieldName: string;
  control: Control<TFieldValues, TContext>;
  label: string;
  isDisabled?: boolean;
}

export function CheckboxInput({
  control,
  fieldName,
  label,
  isDisabled = false,
}: CheckboxInputProps) {
  return (
    <CheckboxContainer>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            inputProps={{
              'aria-label': `${kebabCase(fieldName)}-checkbox`,
            }}
            checked={value as boolean}
            value={value as boolean}
            onChange={onChange}
            disabled={isDisabled}
            data-testid={`${kebabCase(fieldName)}-checkbox`}
          />
        )}
      ></Controller>
      {isDisabled ? (
        <DisabledTypography>{label}</DisabledTypography>
      ) : (
        <Typography>{label}</Typography>
      )}
    </CheckboxContainer>
  );
}
