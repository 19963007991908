import { useContext } from 'react';
import { FeatureFlagsContext } from '@providers';

/**
 * This hook is for consuming the flags from the FeatureFlagsProvider.
 * The hierarchy is as follows:
 *   1. flags from the public/config.json file -> 'flags'
 *   2. flags from Launch Darkly
 *   3. flags from the public/config.json file -> 'flagOverrides'
 * @returns {Record<string, any>} flags
 */
export function useFeatureFlags() {
  const flags = useContext(FeatureFlagsContext);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const getFlag = (keyPath: string) : boolean => Boolean(flags[keyPath]);

  return { getFlag };
}
