import * as React from 'react';
import { BreadcrumbDispatchContext } from '@providers';

export function Reports() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  React.useEffect(() => {
    setBreadcrumbs([
      {
        text: 'Reports',
        href: '/reports/',
      },
    ]);
  });

  return <div>Reports Page is Under Construction</div>;
}
