import { useContext, useMemo } from 'react';
import {
  FarSubmitButton,
  PullButton,
  PULL_NEW_DATA_DIALOG,
  SyncButton,
  TaskPaneHeader,
  TourSettlementContainer,
} from '@components';
import { useQuery } from '@apollo/client';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { EnvStatusContext } from '@providers';
import { useAegTourSettlementFar } from '@hooks';
import { ButtonContainer, TaskPanePaperContainer } from './TaskPane.styled';

export const TourSettlementFARView = () => {
  const {
    syncTourSettlementFARWorkbook,
    populateTourSettlementFarShowDetailSheet,
    submitTourSettlementFARWorkbook,
  } = useAegTourSettlementFar();
  const { envStatus } = useContext(EnvStatusContext);
  const { offerId, tourId } = envStatus;

  const tourSettlementModalOptions = useMemo(() => PULL_NEW_DATA_DIALOG(), []);

  const {
    data,
    error,
    loading,
  } = useQuery(GET_TOUR_AND_OFFER_ONLY, {
    variables: { tourId: tourId ?? '', offerId: offerId ?? '' },
  });

  const [tour, offer] = useMemo(() => {
    if (data?.tour?.offers) {
      return [data.tour, data.tour.offers[0]];
    }
    return [];
  }, [data]);

  return loading ? null : (
    <>
      <TaskPaneHeader
        loading={loading}
        tourName={tour?.name}
        offerName={offer?.name}
        offerStatus={offer?.status}
        actions={
          <ButtonContainer>
            <SyncButton syncFn={syncTourSettlementFARWorkbook} outlined={true}/>
            <PullButton
              pullFn={populateTourSettlementFarShowDetailSheet}
              modalOptions={tourSettlementModalOptions}
              outlined={true}
            />
            <FarSubmitButton submitFn={submitTourSettlementFARWorkbook} />
          </ButtonContainer>
        }
      >
      </TaskPaneHeader>
      <TaskPanePaperContainer>
        <TourSettlementContainer isInternal={true}/>
      </TaskPanePaperContainer>
    </>
  );
};
