import { useMutation } from '@apollo/client';
import { UPSERT_USER } from '@gql/mutations/users';
import { useActiveAccount } from '@hooks';
import { loadIntercom } from '@services/intercom';
import React, { useEffect } from 'react';
import { User } from './UserContextProvider.types';

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const WebUserContext = React.createContext<User>(null);
const WebUserDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<User>>
>(() => {});

type ProviderProps = {
  intercomEnabled: boolean;
  intercomAppId: string;
  children: React.ReactNode;
};
function WebUserProvider({ intercomEnabled, intercomAppId, children }: ProviderProps) {
  const [user, setUser] = React.useState<User>(null);

  const { account, instance } = useActiveAccount();
  const [upsertUser, { error }] = useMutation(UPSERT_USER);

  const awaitUpsertUser = async (signal: AbortSignal) => {
    const activeAccount = instance.getActiveAccount();

    if (activeAccount?.idTokenClaims && activeAccount?.idTokenClaims?.oid) {
      const userInputPayload = {
        id: activeAccount?.idTokenClaims?.oid,
        name: activeAccount?.name ?? '',
        email: activeAccount?.username,
        intercomEnabled,
      };
      const response = await upsertUser({
        variables: {
          userInput: userInputPayload,
        },
        context: {
          fetchOptions: {
            signal,
          },
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setUser(response.data?.upsertUser as User);

      // load intercom only when its enabled and user hash is available
      if (intercomEnabled && response.data?.upsertUser?.intercomUserHash) {
        loadIntercom(intercomAppId, {
          name: userInputPayload.name,
          email: userInputPayload.email,
          user_hash: response.data?.upsertUser?.intercomUserHash,
          created_at: Math.floor(new Date().getTime() / 1000),
        });
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!user && account && !error) {
      void awaitUpsertUser(controller.signal);
    }

    return () => controller.abort();
  }, [account]);

  return (
    <WebUserContext.Provider value={user}>
      <WebUserDispatchContext.Provider value={setUser}>
        {children}
      </WebUserDispatchContext.Provider>
    </WebUserContext.Provider>
  );
}

export { WebUserProvider, WebUserContext, WebUserDispatchContext };
