import {
  Button, ButtonProps, styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CreateNewAgentBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.spacing(1.625),
  padding: `${theme?.spacing(0.75)} ${theme?.spacing(2)}`,
}));

export const PrimaryText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
}));

export const HeadlinerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: theme.spacing(1.75),
  marginTop: theme.spacing(1.5),
  '& .MuiSvgIcon-root': {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
  '& .MuiFormControl-root': {
    padding: 0,
  },
  '& .MuiAutocomplete-root': {
    width: '94%',
  },
}));

export const AutocompleteContainer = styled('div')(({ theme }) => ({
  width: '100%',
}));

export const CloseIconStyle = styled(CloseIcon)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
