/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/**
 * replaces an item in an array with the same id, or adds it if it doesn't exist
 */
export const addOrReplaceItemById = (array: any[], item: any) => {
  const index = array.findIndex((i: any) => i.id === item.id);
  if (index === -1) {
    return [...array, item];
  }

  const newArray = [...array];
  newArray[index] = item;
  return newArray;
};

/**
 * sorts items alphabetically
 */
export const sortAlphabetically = (a: string, b: string) => (a.toLowerCase().localeCompare(b.toLowerCase()));

/**
 * This is a type guard function to use on .filter() to remove null values
 * @param value - the value to check whether it is not null
 * @returns whether the value is not null
 */
export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}
