import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Tooltip } from '@mui/material';

import { KebabPopperMenu } from '@components';
import { getUserTimezoneDate } from '@utils/stringHelpers';

import { StyledAccessTime, StyledContainer, StyledMoreVert } from './EventOfferActionsCell.styled';

interface EventOfferActionsCellProps {
  updatedDate: string;
  // eventId: string;
  offerId: string;
  // settlementId: string;
}

export function EventOfferActionsCell({
  updatedDate,
  // eventId,
  // offerId,
  // settlementId,
}: EventOfferActionsCellProps) {
  return (
    <StyledContainer>
      <Tooltip
        title={`Last Updated: ${getUserTimezoneDate(updatedDate, 'L (LT)')[0]}`}
        placement='top'
        arrow
      >
        <StyledAccessTime color='disabled' />
      </Tooltip>
      <KebabPopperMenu kebabIcon={<StyledMoreVert color='disabled' />}>
        {/* todo: add links to event, offer, and settlement */}
        <MenuItem>
          <Link to="#">View Event</Link>
        </MenuItem>
        <MenuItem>
          <Link to="#">View Offer</Link>
        </MenuItem>
        <MenuItem>
          <Link to="#">View Settlement</Link>
        </MenuItem>
      </KebabPopperMenu>
    </StyledContainer>
  );
}
