import { Chip } from '@mui/material';
import { useTheme, CustomTheme } from '@mui/material/styles';

type StatusChipProps = {
  status: string;
};

export function StatusChip({ status }: StatusChipProps) {
  const theme: CustomTheme = useTheme();

  const statusChipColorMap: { [key:string]: string; } = {
    // shared offer/tour & event statuses
    Draft: theme.palette.primary.states?.outlineBorder,
    'Pending Approval': '#FF8F00',
    Approved: theme.palette.info.main,
    'Routing Confirmed': '#0B7A75',
    'Sent to Agent': theme.palette.alert?.errorContent,
    'Accounting Settled': theme.palette.success.main,
    Canceled: theme.palette.primary.states?.outlineBorder,
    Confirmed: '#0B7A75',

    // event statuses
    'NOS Settled': '#ED6C02',
    Inactive: theme.palette.primary.states?.outlineBorder,
    'Canceled Settled': theme.palette.primary.states?.outlineBorder,

    // offer/tour statuses
    'On Tour': theme.palette.success.light,
    'Settlement in Progress': theme.palette.primary.main,
    Dead: theme.palette.secondary.main,

    // other statuses
    Complete: '#0B7A75',
    'On Sale': '#4CAF50',
  };

  return (
    <Chip
      label={status}
      sx={{
        backgroundColor: statusChipColorMap[status] || theme.palette.primary.states?.outlineBorder,
        color: 'white',
      }}
    />
  );
}
