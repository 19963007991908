/* eslint-disable no-confusing-arrow */

import { Event } from '@gql/types/graphql';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
// eslint-disable-next-line import/no-named-default
import { default as tz } from 'dayjs/plugin/timezone';
import dayjs, { Dayjs } from 'dayjs';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);

// converts any string to camelCase
export const camelCase = (str: string): string => str
  .toLowerCase()
  .replace(/[_-]/g, ' ')
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
  .replace(/\s+/g, '');

// converts any string to kebab-case
//  Strips out non-numeric numbers first
export const kebabCase = (str: string): string => str
  ? str
    .replace(/\(|\)/g, '')
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[[\]']+/g, '')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
  : str;

/**
 * Regular expression to validate that a string does not contain any special characters.
 */
export const strWithoutSpecialCharsRegex = /^(?:(?!\p{Extended_Pictographic}|[|:/\\*<>?%"]).)*$/u;

/**
 * Takes a full name and returns the initials of the first and last name,
 * excluding all middle names. Returns one letter if there is only
 * one name.
 * @returns The initials of a full name
 */
export const extractInitials = (fullName?: string) => {
  if (!fullName) {
    return null;
  }

  const names = fullName.split(' ');

  const firstInitial = names[0].charAt(0);
  const lastInitial = names.length > 1 ? names.slice(-1)[0].charAt(0) : '';

  return `${firstInitial}${lastInitial}`;
};

/**
 * Formats a date to a UTC dayjs object in MM/DD/YYYY format, local
 * to the timezone provided.
 * @see getUserTimezoneDate for a version that uses the user's timeonze
 * @param date - potentially undefined date string
 * @param timezone - potentially undefined timezone string
 * @param format - dayjs format option, defaults to 'L'
 * @returns - tuple of formatted date string and dayjs object
*/
export const getTimezoneDate = (
  date?:string | null,
  timezone?: string | null,
  format = 'L',
) : [string, Dayjs | null] => {
  let formattedDate = '';
  let dateObj: Dayjs | null = null;

  if (date) {
    dateObj = timezone
      ? dayjs.utc(date).tz(timezone)
      : dayjs.utc(date);

    formattedDate = dateObj.format(format);
  }

  return [formattedDate, dateObj];
};

/**
 * Formats a date to a UTC dayjs object in the format provided (default is MM/DD/YYYY h:mm A),
 * local to the user's timezone.
 * @see getTimezoneDate for a version with a timezone parameter
 * @param date - potentially undefined date string
 * @param format - dayhs format option, defaults to 'L LT'
 * @returns - tuple of formatted date string and dayjs object
 */
export const getUserTimezoneDate = (
  date?: string | null,
  format = 'L LT',
): [string, Dayjs | null] => {
  const userTimezone = dayjs.tz.guess();

  return getTimezoneDate(date, userTimezone, format);
};

export const toStringOrUndefined = (value?: any) => (value && String(value) ? String(value) : undefined);

export const toStringOrEmptyString = (value?: any) => (value && String(value) ? String(value) : '');

export const eventMarketName = (event: Event): string => {
  if (event.venue?.stateCode) {
    return `${event.venue?.city}, ${event.venue?.stateCode}`;
  }
  return event.venue?.city ?? '';
};

export const sortDates = (a: Date, b: Date) => dayjs(a).diff(dayjs(b), 'hour');

export const appendEllipses = (value: string, charCount: number) => {
  if (value.length > charCount) {
    return `${value.slice(0, charCount)}...`;
  }
  return value;
};

export const uuidRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g;
// eslint-disable-next-line max-len
export const nosIdWithVersionRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}_v[1-9]{1,5}/g;
// Example: 7c0c4ae6-d9e4-4deb-b8f9-997b103d3670_v2

/**
 * An NOS Settlement ID is structured like ${EventID}_${VersionID}
 * where the underscore and Version ID may optionally be present.
 * This helper function returns the version ID if it exists
 */
export const getVersionIDFromNOSId = (nosId: string) => {
  const nosIdRegEx = new RegExp(nosIdWithVersionRegex);

  // Check that we have a valid NOS ID with Version
  if (!nosIdRegEx.test(nosId)) {
    return null;
  }

  const idParts = nosId.split('_');

  if (idParts.length === 2) {
    return idParts[1];
  }

  return null;
};

/**
 * Takes in a phase and returns a concatenated phase with VersionID if it
 * exists for the provided NOS ID. Else, returns the passed in phase
 */

export const getPhaseIDForNOS = (nosId: string, phase: string) => {
  let phaseId = phase;
  if (nosId) {
    const versionId = getVersionIDFromNOSId(nosId);
    phaseId = versionId ? `${phaseId}_${versionId}` : phaseId;
  }

  return phaseId;
};

export const getExcelWorkbookLaunchUrl = (url: string) => `ms-excel:ofe|u|${url}`;

export const getBookingSupportUrl = (subject: string) =>
  `mailto:${encodeURIComponent('bookingsupport@aegpresents.com')}?subject=${encodeURIComponent(subject)}`;
