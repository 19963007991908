import { graphql } from '@gql/types';

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const ADD_OR_UPDATE_EXPENSE = graphql(`
  mutation AddOrUpdateExpense($expense: ExpenseInput!) {
    addOrUpdateEventExpense(expense: $expense) {
      id
    }
  }
`);
