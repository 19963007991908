import { Venue } from '@gql/types/graphql';
import { ProjectionType } from './ScenarioBuilder';

export interface Projection {
  id: string;
  totalActiveProjection: TotalActiveProjection;
  perShowProjection: ShowProjection;
  eventProjections: EventProjection[]
}

export interface EventProjection extends ProjectionBase, TotalActiveProjection {
  id: string;
  eventId: string;
  offerId: string;
  settlementId: string;
  venue: Venue;
  status: EventProjectionStatus;
  date: string;
  method: ProjectionType;
  updatedDate: string;
}

export interface TotalActiveProjection extends ProjectionBase {
  showsCount: number;
  salesPercentage: number;
  method: ProjectionType | 'Varies';
  averageGrossPrice: number;
  averageNetPrice: number;
}

export type ShowProjection = ProjectionBase;

interface ProjectionBase {
  sellableCapacity: number;
  ticketSales: number;
  netGross: number;
  gross: number;
  poolAdjustments: number;
  fixedCosts: number;
  bonusAllocation: number;
  avgBreakEven: number;
  seqBreakEven: number;
  variableCosts: number;
  totalShowExpenses: number;
  netShowReceipts: number;
  guarantee: number;
  overage: number;
  artistEarnings: number;
  promoterProfit: number;
  ancillaries: number;
  eventPL: number;
  coProShare: number;
  aegPL: number;
}

export enum EventProjectionStatus {
  Draft = 'Draft',
  Confirmed = 'Confirmed',
  Approved = 'Approved',
}
