/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOS_DETAILS } from '@gql/queries/nosSettlements';
import { NosSettlement } from '@gql/types/graphql';
import { EnvStatusContext, ExcelErrorType } from '@providers';
import {
  DetailsMetadataItem, MetadataItemTypes, LastSyncedFooter, ProtectedComponent, FeatureGate,
  NOSSummaryEmail,
} from '@components';
import { AegResourceTypes, UserPermissions } from '@types';
import { Grid, Divider } from '@mui/material';
import { getTimezoneDate, getUserTimezoneDate, kebabCase } from '@utils/stringHelpers';
import { getMarketDisplayName } from '@utils/venueHelpers';
import {
  useAegNOSSettlement, useExcelErrorHandler, useLoading,
} from '@hooks';
import featureFlags from '@utils/featureFlags';
import MailIcon from '@mui/icons-material/Mail';
import { createVersionedId, getVersionIDNumberFromNOSId } from '@utils/settlementVersioning';
import { DropdownButton, StatusChip } from '../../shared';
import {
  EventMetadata, ContainerStyled, TypographyStyled, FlexBox,
} from './NosSettlementContainer.styled';

export enum ContainerViewMode {
  Default,
  SendNOSEmail,
}

enum NosSettlementDropdownOptions {
  SendNOSEmail,
}

export const NosSettlementContainer = () => {
  const { envStatus } = useContext(EnvStatusContext);
  const { eventId, nosId } = envStatus;
  const { initNOSSettlementWorkbook, syncNosSettlementWorkbook } = useAegNOSSettlement();
  const [initCompleted, setInitCompleted] = useState(false);
  const [viewMode, setViewMode] = useState(ContainerViewMode.Default);
  const [nosVersion, setNosVersion] = useState<number>();
  const { displayLoadingSpinner, hideLoadingSpinner } = useLoading();
  const setErrorState = useExcelErrorHandler();

  const { data } = useQuery(GET_NOS_DETAILS, {
    variables: { nosId: nosId ?? eventId ?? '' },
  });

  useEffect(() => {
    const idToInitWith = nosId ?? eventId ?? '';
    const initAndPopulateWorkbook = async () => {
      try {
        await initNOSSettlementWorkbook(idToInitWith);
        setInitCompleted(true);
      } catch (err) {
        setErrorState({
          type: ExcelErrorType.InitFail,
          message: err as string,
        }, 'init and populate NOS workbook error');
      }
    };
    void initAndPopulateWorkbook();
    setNosVersion(getVersionIDNumberFromNOSId(idToInitWith));
  }, []);

  const metadataArray = useMemo(() => {
    if (data?.nosSettlement) {
      const nosSettlement = data.nosSettlement as NosSettlement;
      const { event } = nosSettlement;

      const [firstEventDate] = getTimezoneDate(event?.date, event?.venue?.timezone);

      const metadata = [
        {
          title: 'Venue',
          value: event?.space?.name ?? '',
        },
        {
          title: 'Market',
          value: event?.venue ? getMarketDisplayName(event.venue) : '',
        },
        {
          title: 'Venue Type',
          value: event?.space?.type ?? '',
        },
        {
          title: 'First Event Date',
          value: firstEventDate ?? '',
        },
        {
          title: 'Event Status',
          element: <StatusChip status={event?.status ?? ''} />,
        },
      ];

      if (nosSettlement?.submittedBy?.name) {
        const { submittedBy, submittedOn } = nosSettlement;

        const [date] = getUserTimezoneDate(submittedOn);

        metadata.push(
          {
            title: 'Submitted By',
            value: submittedBy.name,
          },
          {
            title: 'Submitted On',
            value: date,
          },
        );
      }

      return metadata;
    }
    return [];
  }, [data]);

  const metadataRowItems = metadataArray.map((metadataItem, index) => (
    <DetailsMetadataItem
      key={`${kebabCase(metadataItem.title)}-${index}`}
      type={MetadataItemTypes.Component}
      metadata={metadataItem}
      first={index === 0}
    />
  ));

  const syncWorkbookAndDisplayNOSSummaryEmail = async () => {
    displayLoadingSpinner();
    await syncNosSettlementWorkbook();
    hideLoadingSpinner();
    setViewMode(ContainerViewMode.SendNOSEmail);
  };

  const handleDropdownMenu = (value: NosSettlementDropdownOptions) => {
    switch (value) {
      case NosSettlementDropdownOptions.SendNOSEmail: {
        void syncWorkbookAndDisplayNOSSummaryEmail();
        break;
      }
      // TODO: upcoming stories will refactor Sync and Submit buttons here.
      default:
        break;
    }
  };

  return initCompleted ? (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.ViewNos,
        resourceType: AegResourceTypes.Tour,
        resourceId: envStatus.tourId,
      }}
      navigateAway={'/unauthorized'}
    >
      <ContainerStyled data-testid="settlement-container">
        {viewMode === ContainerViewMode.Default && (
          <>
            <FlexBox>
              <TypographyStyled variant="h6" data-testid="nos-details-header">
                NOS Details
              </TypographyStyled>
              <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_EMAIL}>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <DropdownButton
                  label="Actions"
                  onSelect={handleDropdownMenu}
                  data-testid="settlement-container-dropdown"
                  dropdownOptions={[
                    {
                      label: 'Send NOS Email',
                      value: NosSettlementDropdownOptions.SendNOSEmail,
                      icon: <MailIcon />,
                    },
                  ]}
                ></DropdownButton>
              </FeatureGate>
            </FlexBox>
            <Divider />
            <EventMetadata>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                data-testid="nos-details-metadata"
                justifyContent="space-between"
              >
                {metadataRowItems}
              </Grid>
            </EventMetadata>
          </>
        )}
        {viewMode === ContainerViewMode.SendNOSEmail && (
          <NOSSummaryEmail
            onEmailSend={() => setViewMode(ContainerViewMode.Default)}
            onEmailDiscarded={() => setViewMode(ContainerViewMode.Default)}
            tourId={envStatus.tourId || ''}
            nosSettlementId={nosId || ''}
            expenseId={eventId || ''}
            expensePhaseId={createVersionedId('internal_settlement', nosVersion)}
            version={nosVersion}
          />
        )}
      </ContainerStyled>
      <LastSyncedFooter lastSynced={data?.nosSettlement?.updatedDate ?? undefined} />
    </ProtectedComponent>
  ) : (
    <>Loading...</>
  );
};
