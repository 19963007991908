/* eslint-disable max-lines-per-function */
import { useState, useMemo } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { CustomTheme, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  AddElementLinkContainer,
  AddSubjectTypography,
  AutocompleteInput,
  ButtonStyled,
  CloseIconGrey,
  DropdownInput,
} from '@components';
import { useYupValidationResolver } from '@hooks';
import { useQuery } from '@apollo/client';
import { SEARCH_USERS, GET_BOOKERS_BY_COMPANY_ID } from '@ooagg-gql/queries/users';
import { AegAggregator, UserModel } from '@types';
import { User } from '@ooagg-gql/types/graphql';

import { ManageTeamMembersFormSchema } from './ManageTeamMembersForm.schema';
import {
  ManageTeamMembersFormFields,
  HallPassRole,
  RosterRole,
  MANAGE_TEAM_MEMBERS_DEFAULTS,
} from './ManageTeamMembersForm.models';
import {
  AutocompleteContainer,
  FlexBox,
  ButtonContainer,
  CloseButtonContainer,
  ColoredLabelContainer,
  DropdownContainer,
  RedTypographyWithLineHeight,
  ColoredLabel,
} from './ManageTeamMembersForm.styled';

const createRoleOptionFromString = (role: string) => ({
  label: role,
  value: role,
});
const hallPassRoleOptions = Object.values(HallPassRole).map(createRoleOptionFromString);
const rosterRoleOptions = Object.values(RosterRole).map(createRoleOptionFromString);
interface ManageTeamMembersFormProps {
  companyId: string;
  eventFormData?: ManageTeamMembersFormFields;
  onFormSubmit: SubmitHandler<ManageTeamMembersFormFields>;
  isFormSaving: boolean;
  onPrevious: (data: ManageTeamMembersFormFields) => void;
}

export const ManageTeamMembersForm: React.FC<ManageTeamMembersFormProps> = ({
  companyId,
  eventFormData,
  onFormSubmit,
  onPrevious,
  isFormSaving,
}) => {
  const theme: CustomTheme = useTheme();
  const resolver = useYupValidationResolver(ManageTeamMembersFormSchema);

  const defaultValues = useMemo(() => eventFormData || MANAGE_TEAM_MEMBERS_DEFAULTS, []);
  const [membersUsersSearchVal, setMembersUsersSearchVal] = useState('');

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isDirty, dirtyFields },
  } = useForm<ManageTeamMembersFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { loading: primaryBuyerUsersLoading, data: primaryBuyerUsersData } = useQuery(GET_BOOKERS_BY_COMPANY_ID, {
    variables: {
      companyId,
    },
    context: { clientName: AegAggregator.OOAGG },
  });

  const primaryBuyerUserOptions = (): UserModel[] => {
    if (primaryBuyerUsersLoading || !primaryBuyerUsersData?.getBookersByCompanyId) {
      return [];
    }
    return primaryBuyerUsersData.getBookersByCompanyId
      .filter((userItem): userItem is User => userItem !== null);
  };

  const {
    fields: hallPassesFields,
    remove: removeHallPass,
    append: appendHallPass,
  } = useFieldArray({ control, name: 'hallPasses' });

  const {
    fields: rostersFields,
    remove: removeRoster,
    append: appendRoster,
  } = useFieldArray({ control, name: 'rosters' });

  const primaryBuyerFormValue = watch('primaryBuyer');
  const hallPassesFormValue = watch('hallPasses');
  const rostersFormValue = watch('rosters');

  const { loading: membersUsersLoading, data: membersUsersData } = useQuery(SEARCH_USERS, {
    errorPolicy: 'all',
    variables: { searchTerm: membersUsersSearchVal },
    context: { clientName: AegAggregator.OOAGG },
  });

  const memberUserOptions = (): UserModel[] => {
    if (membersUsersLoading || !membersUsersData?.users) {
      return [];
    }
    return membersUsersData.users.filter((userItem): userItem is UserModel => userItem !== null);
  };

  const filterOutPrimaryBuyer = useMemo(() => {
    if (!primaryBuyerFormValue) {
      return [];
    }

    return [primaryBuyerFormValue.id];
  }, [primaryBuyerFormValue]);

  const filterOutHallPasses = useMemo(() => {
    if (!isDirty || !dirtyFields.hallPasses) {
      return [];
    }
    const hallPassIds = (hallPassesFormValue || [])
      .flatMap((row) => (row.user?.id && row.role ? [row.user?.id] : []));
    return hallPassIds;
  }, [hallPassesFormValue, membersUsersData]);

  const filterOutRosters = useMemo(() => {
    if (!isDirty || !dirtyFields.hallPasses) {
      return [];
    }
    const rostersIds = (rostersFormValue || []).flatMap((row) => (row.user?.id && row.role ? [row.user?.id] : []));
    return rostersIds;
  }, [rostersFormValue, membersUsersData]);

  const handleValidSubmit = (data: ManageTeamMembersFormFields) => {
    onFormSubmit(data);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(handleValidSubmit)}>
      <ColoredLabelContainer>
        <ColoredLabel>
          Primary Buyer
          <span>
            <RedTypographyWithLineHeight> *</RedTypographyWithLineHeight>
          </span>
        </ColoredLabel>
      </ColoredLabelContainer>
      <AutocompleteInput
        control={control}
        options={primaryBuyerUserOptions()}
        areOptionsLoading={primaryBuyerUsersLoading}
        fieldName="primaryBuyer"
        dataDisplayField={{ primary: 'name', secondary: 'email' }}
        dataFilterField="id"
        placeholderText="Enter Primary Buyer Name"
        isRequired
        filterOut={filterOutHallPasses}
      />

      <ColoredLabelContainer>
        <ColoredLabel>Hall Passes</ColoredLabel>
        <ColoredLabel>Role</ColoredLabel>
      </ColoredLabelContainer>

      {hallPassesFields.map((field, idx) => (
        <FlexBox key={`${field.id}-${idx}`}>
          <AutocompleteContainer>
            <AutocompleteInput
              control={control}
              options={memberUserOptions()}
              areOptionsLoading={membersUsersLoading}
              onInputChange={setMembersUsersSearchVal}
              fieldName={`hallPasses[${idx}].user`}
              dataDisplayField={{ primary: 'name', secondary: 'email' }}
              dataFilterField="id"
              placeholderText="Enter Hall Pass Member"
              filterOut={filterOutPrimaryBuyer.concat(filterOutHallPasses)}
            />
          </AutocompleteContainer>
          <DropdownContainer>
            <DropdownInput
              label="role"
              fieldName={`hallPasses[${idx}].role`}
              data-testid={`dropdown-hall-pass-role-${idx}`}
              control={control}
              includeLabel={false}
              options={hallPassRoleOptions}
              placeholderText="Select Role"
            />
          </DropdownContainer>
          <CloseButtonContainer>
            <CloseIconGrey
              onClick={() => {
                removeHallPass(idx);
              }}
              data-testid={`remove-hall-pass-${idx}-button`}
            />
          </CloseButtonContainer>
        </FlexBox>
      ))}

      <AddElementLinkContainer>
        <AddIcon />
        <AddSubjectTypography
          variant="body2"
          data-testid="append-hall-pass-link"
          onClick={() => appendHallPass({ user: null, role: HallPassRole.ViewOnly })}
        >
          Add Hall Pass Member
        </AddSubjectTypography>
        <Typography variant="caption" color={theme?.palette.input?.standard.enabledBorder}>
          (optional)
        </Typography>
      </AddElementLinkContainer>

      <ColoredLabelContainer>
        <ColoredLabel>Roster</ColoredLabel>
        <ColoredLabel>Role</ColoredLabel>
      </ColoredLabelContainer>

      {rostersFields.map((field, idx) => (
        <FlexBox key={`${field.id}-${idx}`}>
          <AutocompleteContainer>
            <AutocompleteInput
              control={control}
              options={memberUserOptions()}
              areOptionsLoading={membersUsersLoading}
              onInputChange={setMembersUsersSearchVal}
              fieldName={`rosters[${idx}].user`}
              dataDisplayField={{ primary: 'name', secondary: 'email' }}
              dataFilterField="id"
              placeholderText="Enter Roster Member"
              filterOut={filterOutRosters}
            />
          </AutocompleteContainer>
          <DropdownContainer>
            <DropdownInput
              label="role"
              fieldName={`rosters[${idx}].role`}
              data-testid={`dropdown-roster-role-${idx}`}
              control={control}
              includeLabel={false}
              options={rosterRoleOptions}
              placeholderText="Select Role"
            />
          </DropdownContainer>

          <CloseButtonContainer>
            <CloseIconGrey
              data-testid={`remove-roster-${idx}-button`}
              onClick={() => {
                removeRoster(idx);
              }}
            />
          </CloseButtonContainer>
        </FlexBox>
      ))}

      <AddElementLinkContainer>
        <AddIcon />
        <AddSubjectTypography
          variant="body2"
          data-testid="append-roster-link"
          onClick={() => appendRoster({ user: null, role: RosterRole.Assistant })}
        >
          Add Roster Member
        </AddSubjectTypography>
        <Typography variant="caption" color={theme?.palette.input?.standard.enabledBorder}>
          (optional)
        </Typography>
      </AddElementLinkContainer>

      <ButtonContainer>
        <ButtonStyled
          data-testid="manage-team-members-form-previous"
          type="button"
          onClick={() => {
            const formValues = getValues();
            onPrevious(formValues);
          }}
          variant="outlined"
          disabled={isFormSaving}
        >
          Go back
        </ButtonStyled>
        <ButtonStyled
          variant="contained"
          data-testid="manage-team-members-form-submit"
          type="submit"
          disabled={isFormSaving}
        >
          Create
        </ButtonStyled>
      </ButtonContainer>
    </form>
  );
};
