import * as React from 'react';
import { Grid } from '@mui/material';
// import { Accordion, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { camelCase, kebabCase } from '@utils/stringHelpers';
import { Event } from '@gql/types/graphql';
import { EventsList } from '@components';
// import { mockTourSummaryData } from '@mocks';
// import {
//   AccordionDataRow,
//   AccordionDetails,
//   AccordionSummary,
// } from './TourSummary.styled';
// import { TourSummaryData } from './TourSummary.models';

interface TourSummaryEventDataProps {
  events?: Event[];
}

const COLUMNS = [
  'venueName',
  'market',
  'showCount',
  'firstEventDate',
  'capacity',
  'subledger',
  'status',
  'moreActions',
];

export function TourSummary({ events } : TourSummaryEventDataProps) {
  const [expanded, setExpanded] = React.useState<string[]>(['summary']);

  const handleChange = (panel: string) => () => {
    const updatedItems = [...expanded];
    const panelIndex = updatedItems.indexOf(panel);
    if (panelIndex > -1) {
      updatedItems.splice(panelIndex, 1);
    } else {
      updatedItems.push(panel);
    }
    setExpanded(updatedItems);
  };

  // NOTE: Tour Summary Accordion will be used at a later date. Commenting out for now

  // const accordionItems: any[] = [
  //   // Placeholder data. Each section will eventually have unique data.
  //   { header: 'Summary', data: mockTourSummaryData },
  //   { header: 'Artist Earnings', data: mockTourSummaryData },
  //   { header: 'Promotor Earnings', data: mockTourSummaryData },
  //   { header: 'Breakeven Calc', data: mockTourSummaryData },
  // ];

  return (
    <Grid container>
      {/* <Grid item xs={3} data-testid="accordion-container">
        {accordionItems.map((item: any, idx: number) => (
          <Accordion
            key={idx}
            expanded={expanded.includes(camelCase(item.header))}
            onChange={handleChange(camelCase(item.header))}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${kebabCase(item.header)}-content`}
              id={`${kebabCase(item.header)}-header`}
              data-testid={`${kebabCase(item.header)}-section`}
            >
              <Typography variant="body1-bold">{item.header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.data.map((row: TourSummaryData, i: number) => (
                <AccordionDataRow key={i}>
                  <Typography variant={!row.total ? 'body2' : 'h6'}>
                    {row.label}
                  </Typography>
                  <Typography variant="body2">{row.value}</Typography>
                </AccordionDataRow>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid> */}
      <Grid item xs={12}>
        <EventsList
          events={events}
          columns={COLUMNS}
          noEventsFoundText="Select the Launch Offer button to launch Excel and add your first event."
        ></EventsList>
      </Grid>
    </Grid>
  );
}
