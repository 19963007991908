import { Select, Typography, styled } from '@mui/material';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
})) as typeof Typography;

export const SelectStyled = styled(Select)(({ theme }) => ({
  '&.Mui-disabled, & .MuiOutlinedInput-input.Mui-disabled': {
    backgroundColor: theme.palette.action.hoverOpacity,
    color: theme.palette.primary.main,
    '-webkit-text-fill-color': `${theme.palette.primary.main}!important`,
  },
}));
