export function BundlesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        // eslint-disable-next-line max-len
        d="M20 20h-6v2h8v-5h-2v3zM14 2v2h6v3h2V2h-8zM4 4h6V2H2v5h2V4zm0 13H2v5h8v-2H4v-3zm13-9l-4 4 4 4v-3h5v-2h-5V8zM7 8l4 4-4 4v-3H2v-2h5V8z"
        fill="currentColor"
      />
    </svg>
  );
}
