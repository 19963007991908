import {
  Artist, User, Company, Event,
} from '@gql/types/graphql';
import { MetadataObject } from '@components';

export type OneOffMetadata = Array<MetadataObject>;

export interface Agent {
  id: string;
  name: string;
  email: string;
  phone?: string;
}

interface Agency {
  id: string;
  name?: string;
  isActive?: boolean;
}

export interface Headliner extends Artist {
  agency?: Agency;
  agent?: Agent[];
}

export interface OneOff {
  id: string;
  name: string;
  headlinerIds: string[];
  eventIds: string[];
  pdfPath?: string;

  // other props needed that don't come from BE
  events: Event[];
  headliners: Headliner[];
  status?: string;
  ticketCount?: number;
  company?: Company;
  primaryBooker?: User;
  eventType?: string;
  genre?: string;
  firstShow?: string;
  eventNotes?: string;
}

export const mockEventDraft: Event = {
  id: 'test-guid',
  name: 'Test Event',
  status: 'Draft',
  date: '2023-10-09T23:07:47.650Z',
  venue: {
    id: 'test-guid',
    name: 'Test Venue',
    stateCode: 'CA',
    country: 'Test Country',
    spaces: [],
    city: 'Test City',
    cityCode: 'test',
    ownership: 'test',
    status: 'test',
  },
  space: {
    id: 'test-guid',
    name: 'Test Space',
  },
  shows: [
    {
      id: 'test-guid-show-0',
      name: 'dummy name to override',
      showDateTime: '2023-10-09T23:07:47.650Z',
    },
  ],
  defaultBoxOffice: {
    id: 'test-guid',
    phases: [],
  },
  isActive: true,
  canConfirm: {
    result: true,
    requirements: [],
  },
};

export const mockEventDraftTwoShows: Event = {
  id: 'test-guid',
  name: 'Test Event',
  status: 'Draft',
  date: '2023-10-09T23:07:47.650Z',
  venue: {
    id: 'test-guid',
    name: 'Test Venue',
    stateCode: 'CA',
    country: 'Test Country',
    spaces: [],
    city: 'Test City',
    cityCode: 'test',
    ownership: 'test',
    status: 'test',
  },
  space: {
    id: 'test-guid',
    name: 'Test Space',
  },
  shows: [
    {
      id: 'test-guid-show-0',
      name: 'dummy name to override',
      showDateTime: '2023-10-09T23:07:47.650Z',
    },
    {
      id: 'test-guid-show-1',
      name: 'dummy name to override',
      showDateTime: '2023-10-10T23:07:47.650Z',
    },
  ],
  defaultBoxOffice: {
    id: 'test-guid',
    phases: [],
  },
  isActive: true,
  canConfirm: {
    result: true,
    requirements: [],
  },
};

export const mockOneOffDetailData: OneOff = {
  id: '1',
  name: '“Weird Al” Yankovic & Adele',
  headlinerIds: ['1', '2'],
  eventIds: [],
  pdfPath: 'https://www.google.com',
  headliners: [
    {
      id: '1',
      name: 'Adele',
      agency: {
        id: '1',
        name: 'BPA Live/The Bob Paterson Agency',
        isActive: true,
      },
      agent: [
        {
          id: '1',
          name: 'Pieter Jansons',
          email: 'pieterjansons94@gmail.com',
          phone: '916-345-8675',
        },
        {
          id: '2',
          name: 'Luz Angela Orjuela',
          email: 'angela_orjuela@epam.com',
        },
      ],
    },
    {
      id: '2',
      name: '“Weird Al” Yankovic',
      agency: {
        id: '2',
        name: 'Eriksson Music',
        isActive: true,
      },
      agent: [
        {
          id: '3',
          name: 'Kelly Alexander',
          email: 'kalexander@aegpresents.com',
          phone: '',
        },
      ],
    },
  ],
  events: [mockEventDraftTwoShows, mockEventDraft],
  status: 'Draft',
  ticketCount: 18000,
  company: {
    id: '1',
    name: 'The Showbox',
    isActive: true,
    lobType: 'Tours',
    subledgerAutomationCode: 'test',
    jdeCompanyNumber: 1,
  },
  primaryBooker: {
    id: '1',
    name: 'John Smith',
    email: 'john@mail.com',
  },
  eventType: 'Promoted',
  genre: 'Rock, Misc Rock, Pop|R&B',
  firstShow: '2024-07-22T19:36:18.300Z',
  eventNotes: 'Announce: Mon 03/01 12pm ET Spotify Pre-Sale: Tues 03/02',
};
