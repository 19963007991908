/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

// Initialize Workbook Keys
export const TOUR_SETTLEMENT_FAR_INITIALIZED_KEY = 'tour_settlement_far_workbook_initialized';

export const SHEET_NAMES = {
  DEAL_SUMMARY: 'Deal Summary',
  SHOW_DETAIL: 'Show Detail',
  LATERAL_SUM: 'Lateral Sum',
};

export const TABLE_NAMES = {
  SHOW_DETAIL_TABLES: {
    SHOW_DETAIL_FAR: 'ShowDetailFar',
  },
};

export const NAMED_RANGES = {
  DEAL_SUMMARY: {
    TOUR_NAME: 'TourName',

    COMMENTS: 'Comments',
    TOTAL_POOL_INCOME: {
      SOLD_OFFER: 'TotalPoolIncomeSold_Offer',
      GROSS_RECEIPTS_OFFER: 'TotalPoolIncomeGrossReceipts_Offer',
      NET_GROSS_RECEIPTS_OFFER: 'TotalPoolIncomeNetGrossReceipts_Offer',
      SHOW_EXPENSES_OFFER: 'TotalPoolIncomeShowExpenses_Offer',
      ADJUSTMENTS_OFFER: 'TotalPoolIncomeAdjustments_Offer',
      OTHER_ADJUSTMENTS_OFFER: 'TotalPoolIncomeOtherAdjustments_Offer',
      NUM_SHOWS_OFFER: 'TotalPoolIncomeNumShows_Offer',
      AVAILABLE_CAPACITY_OFFER: 'TotalPoolIncomeAvailableCapacity_Offer',
      PREMIUM_TICKETING_REVENUE: {
        SETTLED: 'TotalPoolIncomePremiumTicketingRevenue_Settled',
        NOT_SETTLED: 'TotalPoolIncomePremiumTicketingRevenue_NonSettled',
        OFFER: 'TotalPoolIncomePremiumTicketingRevenue_Offer',
      },
      MAX: { MANUAL_ADJUSTMENTS: 8 },
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalPoolIncomeManualAdjustment_Text_',
        SETTLED: 'TotalPoolIncomeManualAdjustment_Settled_',
        NOT_SETTLED: 'TotalPoolIncomeManualAdjustment_NonSettled_',
        OFFER: 'TotalPoolIncomeManualAdjustment_Offer_',
      },
    },

    TOTAL_ARTIST_EARNINGS: {
      TOTAL_GUARANTEES_OFFER: 'TotalArtistEarningsTotalGuarantees_Offer',
      SPLIT_POINT: 'TotalArtistEarningsSplitPoint_Settlement',
      TOTAL_SPLIT_POINT: 'TotalArtistEarningsTotalSplitPoint_Settlement',
      SPLIT_POINT_OFFER: 'TotalArtistEarningsSplitPoint_Offer',
      TOTAL_SPLIT_POINT_OFFER: 'TotalArtistEarningsTotalSplitPoint_Offer',
      MAX: { MANUAL_ADJUSTMENTS: 6 },
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalArtistEarningsManualAdjustment_Text_',
        SETTLED: 'TotalArtistEarningsManualAdjustment_Settled_',
        NOT_SETTLED: 'TotalArtistEarningsManualAdjustment_NonSettled_',
        OFFER: 'TotalArtistEarningsManualAdjustment_Offer_',
      },
    },

    TOTAL_PROMOTER_EARNINGS: {
      REVENUE_VARIANCE_OFFER: 'TotalPromoterEarningsRevenueVariance_Offer',
      EXPENSE_VARIANCE_OFFER: 'TotalPromoterEarningsExpenseVariance_Offer',
      CO_PRO_SHARE_OFFER: 'TotalPromoterEarningsCoProShare_Offer',
      ANCILLARY_EARNINGS_OFFER: 'TotalPromoterEarningsAncillaryEarnings_Offer',
      DIRECT_OVERHEADS: {
        SETTLED: 'TotalPromoterEarningsDirectOverheads_Settled',
        NOT_SETTLED: 'TotalPromoterEarningsDirectOverheads_NonSettled',
        OFFER: 'TotalPromoterEarningsDirectOverheads_Offer',
      },
      MAX: { MANUAL_ADJUSTMENTS: 6 },
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalPromoterEarningsManualAdjustment_Text_',
        SETTLED: 'TotalPromoterEarningsManualAdjustment_Settled_',
        NOT_SETTLED: 'TotalPromoterEarningsManualAdjustment_NonSettled_',
        OFFER: 'TotalPromoterEarningsManualAdjustment_Offer_',
      },
    },
  },
};
