import { GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function InitializeFailWarning() {
  return (
      <LayoutWarning header={'Server Error'}>
        <GenericMessage data-testid="generic-message">
          Please wait for a communication from Booking Support that all systems are back up and running.
        </GenericMessage>
      </LayoutWarning>
  );
}
