import React from 'react';
import {
  GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';

import { ArtistModel } from '@types';
import { ArtistSearch } from '@components';
import { AutocompleteContainer } from '../Tours.styled';

export const HeadlinerFilter = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const handleOnChange = (selectedHeadliner: ArtistModel | null) => {
    if (!selectedHeadliner) {
      applyValue({ ...item, value: null });
    } else {
      applyValue({ ...item, value: selectedHeadliner });
    }
  };

  return (
    <AutocompleteContainer>
      <ArtistSearch
        selectedHeadliner={item.value as unknown as ArtistModel}
        onChange={handleOnChange}
        testId='autocomplete-tours-headliner'
      />
    </AutocompleteContainer>
  );
};
