import { UserRoles } from '@types';

export const roleOptions = [
  { value: UserRoles.Accountant, label: 'Accountant' },
  { value: UserRoles.Booker, label: 'Booker / Buyer' },
  { value: UserRoles.Settler, label: 'Settler' },
  { value: UserRoles.ViewOnly, label: 'View Only' },
  { value: UserRoles.CompanyAdmin, label: 'Company Admin' },
  { value: UserRoles.VenueAdmin, label: 'Venue Admin' },
];
