import { styled, Chip } from '@mui/material';

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  color: theme.palette.text.primary,
  height: '32px',
  '& .MuiChip-avatar': {
    color: 'white',
  },
  '& .MuiChip-label': {
    padding: '3px, 6px, 3px, 6px',
  },
}));
