// maps currency ISO codes to currency symbols
// sourced from: https://www.eurochange.co.uk/travel/tips/world-currency-abbreviations-symbols-and-codes-travel-money

const currencySymbols: { [key: string]: string } = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'Lek',
  ANG: 'ƒ',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BGN: 'лв',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BWP: 'P',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CHF: '₣',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  EGP: '£',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GGP: '£',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JPY: '¥',
  KGS: 'лв',
  KHR: '៛',
  KPW: '₩',
  KRW: '₩',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  MKD: 'ден',
  MNT: '₮',
  MUR: '₨',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SOS: 'S',
  SRD: '$',
  SVC: '₡',
  SYP: '£',
  THB: '฿',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  UAH: '₴',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  XCD: '$',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$',
};

// Function to get currency symbol by ISO code
export function getCurrencySymbol(isoCode: string): string | undefined {
  return currencySymbols[isoCode];
}
