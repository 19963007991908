import { EventStatus } from '@types';

export const canLaunchNosWorkbookByStatus = (status?: string | null) =>
  (status === EventStatus.Canceled
  || status === EventStatus.Confirmed
  || status === EventStatus.NOSSettled
  || status === EventStatus.AccountingSettled
  || status === EventStatus.CanceledSettled);

export const canSaveNosWorkbookByStatus = (status?: string | null) =>
  (status === EventStatus.NOSSettled
  || status === EventStatus.AccountingSettled
  || status === EventStatus.CanceledSettled);

export const canSendNosEmailByStatus = (status?: string | null) =>
  (status === EventStatus.NOSSettled
  || status === EventStatus.AccountingSettled);
