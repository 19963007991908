import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import MailOutlined from '@mui/icons-material/MailOutlineOutlined';
import { styled } from '@mui/material';

export const AddIcon = styled(Add)(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const CloseIconGrey = styled(Close)(({ theme }) => ({
  color: theme?.palette.text.disabled,
  cursor: 'pointer',
}));

export const MailOutlinedIcon = styled(MailOutlined)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
