import { styled } from '@mui/material/styles';
import { SIDE_NAV_WIDTH } from '../components/SideNav/SideNav.styled';

export const AppDiv = styled('div')({
  display: 'flex',
  minHeight: '100vh',
});
export const ContentDiv = styled('div')({
  display: 'flex',
  minWidth: `calc(100% - ${SIDE_NAV_WIDTH})`,
  flexDirection: 'column',
});
export const Main = styled('main')({
  flex: '1',
  padding: '32px 48px',
});
export const ContainerDiv = styled('div')({
  display: 'flex',
  flex: '1',
});
