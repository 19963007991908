import React, { useContext } from 'react';
import {
  NavBreadcrumbs, SideNav, AegSnackbar, AegDialog,
} from '@components';
import { DialogContext, NotificationContext } from '@providers';
import { Outlet } from 'react-router-dom';
import {
  ContainerDiv,
  ContentDiv,
  Main,
  AppDiv,
} from './ModernElvisLayout.styled';

export const ModernElvisLayout = () => {
  const notification = useContext(NotificationContext);
  const dialog = useContext(DialogContext);
  return (
    <>
      <AppDiv>
        <SideNav />
        <ContentDiv>
          <ContainerDiv>
            <Main data-testid="main-container">
              <NavBreadcrumbs />
              <Outlet />
            </Main>
          </ContainerDiv>
        </ContentDiv>
        <AegSnackbar
          type={notification?.type}
          message={notification?.text || ''}
          duration={notification?.duration}
        />
        <AegDialog
          titles={dialog?.titles}
          description={dialog?.description}
          submit={dialog?.submit}
          cancel={dialog?.cancel}
          isLoading={dialog?.isLoading}
        />
      </AppDiv>
    </>
  );
};
