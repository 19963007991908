/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useContext } from 'react';
import { DialogDispatchContext } from '@providers';
import { useAddInAction } from '@hooks';
import { SnackBarMessages } from '@types';
import { ButtonStyled } from './PullButton.styled';

type ModalOptions = {
  TITLES: {
    dialogTitle: string;
    alertTitle: string;
  },
  SUBMIT?: string;
  CANCEL?: string;
};

type PullButtonProps = {
  pullFn: () => Promise<void>;
  modalOptions: ModalOptions;
  outlined?: boolean;
};

export const PullButton = ({ pullFn, modalOptions, outlined }: PullButtonProps) => {
  const { handleAddInAction } = useAddInAction();
  const setDialog = useContext(DialogDispatchContext);

  return (
  <ButtonStyled
    data-testid="pull-button"
    color={!outlined ? 'primary' : undefined}
    variant={outlined ? 'outlined' : 'contained'}
    onClick={() => {
      const { TITLES, SUBMIT, CANCEL } = modalOptions;
      setDialog({
        titles: {
          dialogTitle: TITLES.dialogTitle,
          alertTitle: TITLES.alertTitle,
        },
        submit: {
          text: SUBMIT || 'Submit',
          action: () => {
            void handleAddInAction(
              pullFn,
              SnackBarMessages.PullDataComplete,
              SnackBarMessages.SubmitError,
            );
            setDialog(null);
          },
        },
        cancel: {
          text: CANCEL || 'Cancel',
          action: () => setDialog(null),
        },
      });
    }}
  >
    Pull New Data
  </ButtonStyled>
  );
};
