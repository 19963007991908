/* eslint-disable max-lines-per-function */
import {
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowId,
} from '@mui/x-data-grid-pro';
import {
  FinancesSupport,
  FinancesHeadliners,
  FinancesFixedCosts,
  FinancesVariableCosts,
  FinancesBoxOfficeTier,
} from '@types';
import {
  Accordion, NestedDataGrid, DataGridWithTotals,
} from '@components';
import { disableColumnInteractions } from '@utils/datagridHelpers';
import { kebabCase } from '@utils/stringHelpers';
import { useMemo } from 'react';
import { GET_BUNDLE_OFFER_FINANCES } from '@gql/queries/bundles';
import { useQuery } from '@apollo/client';
import { supportColumns, supportEventOfferColumns } from './columns/Support.columns';
import { boxOfficeColumns, boxOfficeEventOfferColumns } from './columns/BoxOffice.columns';
import { headlinersColumns, headlinersEventOfferColumns } from './columns/Headliners.columns';
import { fixedCostsColumns, fixedCostsEventOfferColumns } from './columns/FixedCosts.columns';
import { variableCostsColumns, variableCostsEventOfferColumns } from './columns/VariableCosts.columns';

// TODO: Update with real data
import {
  mockFinancesSupport,
  mockFinancesHeadliners,
  mockFinancesFixedCosts,
  mockFinancesVariableCosts,
} from '../../mocks/mockFinancesData';
import { NestedGridContainer } from './BundleFinancesTab.styled';

type BundleFinancesRow =
  FinancesBoxOfficeTier | FinancesHeadliners | FinancesFixedCosts | FinancesVariableCosts | FinancesSupport;

interface BundleFinancesSection {
  header: string;
  rowsGroup?: string;
  rows: BundleFinancesRow[];
  columns: GridColDef[];
  eventOffersColumns: GridColDef[];
  totalRows?: FinancesBoxOfficeTier[];
  selectComponent?: React.ReactNode;
}

interface BundleFinancesTabProps {
  bundleId: string
  bundleOfferId: string
}

export function BundleFinancesTab({ bundleId, bundleOfferId }: BundleFinancesTabProps) {
  // const [show, setShow] = useState(0);
  const { data } = useQuery(GET_BUNDLE_OFFER_FINANCES, {
    variables: { bundleId, bundleOfferId },
    fetchPolicy: 'cache-first',
  });

  const sections: BundleFinancesSection[] = useMemo(() => {
    const bundleOffer = data?.bundleOffer;
    if (!bundleOffer) return [];

    // const showOptions = Array.from(
    //   Array(data?.bundleOffer?.boxOffice?.maxShows ?? 0),
    //   (_, index) => ({
    //     label: `Show #${index + 1}`,
    //     value: index,
    //   }),
    // );

    return [{
      header: 'Box Office',
      rows: bundleOffer?.boxOffice?.tiers,
      columns: boxOfficeColumns.map(disableColumnInteractions),
      eventOffersColumns: boxOfficeEventOfferColumns,
      // selectComponent: (
      //   <ShowSelectContainer>
      //     <SelectWithPrimary
      //       label=''
      //       onChange={(val) => setShow(Number(val))}
      //       items={showOptions}
      //       value={showOptions?.[0]?.value}
      //     />
      //   </ShowSelectContainer>
      // ),
      totalRows: [
        {
          id: 'box-office-totals-row-id',
          name: 'Avg/Total (All Shows)',
          eventOffers: [],
          localOfferTotal: 0,
          boxOffice: bundleOffer?.boxOffice?.totals,
        },
      ],
    },
    {
      header: 'Headliners',
      rows: mockFinancesHeadliners,
      columns: headlinersColumns.map(disableColumnInteractions),
      eventOffersColumns: headlinersEventOfferColumns,
    },
    {
      header: 'Support',
      rows: mockFinancesSupport,
      columns: supportColumns.map(disableColumnInteractions),
      eventOffersColumns: supportEventOfferColumns,
    },
    {
      header: 'Fixed Costs',
      rowsGroup: 'category',
      rows: mockFinancesFixedCosts,
      columns: fixedCostsColumns.map(disableColumnInteractions),
      eventOffersColumns: fixedCostsEventOfferColumns,
    },
    {
      header: 'Variable Costs',
      rows: mockFinancesVariableCosts,
      columns: variableCostsColumns.map(disableColumnInteractions),
      eventOffersColumns: variableCostsEventOfferColumns,
    }];
  }, [data]);

  if (sections.length === 0) {
    // The prefetch in BundleDetails should prevent this scenario from happenning,
    // but this is here in case something goes wrong
    return 'Loading...';
  }

  return (
    sections.map(({
      header,
      eventOffersColumns,
      ...props
    }) => (
      <Accordion header={header} key={`one-off-accordion-${header}`} showDivider>
        {props.selectComponent}
        <DataGridWithTotals
          {...props}
          getRowId={(row) => (row as { id: GridRowId }).id}
          testId={kebabCase(header)}
          isRowSelectable={() => false}
          scrollable={false}
          getDetailPanelContent={({ row }) => (
            <NestedGridContainer>
              <NestedDataGrid
                rows={(row as BundleFinancesRow).eventOffers}
                columns={eventOffersColumns}
                hideFooter
                slots={{
                  columnHeaders: () => null,
                }}
                isRowSelectable={() => false}
                data-testid={`${kebabCase(header)}-event-offers-data-grid`}
                getRowId={(eventOffer: { id: GridRowId }) => eventOffer.id}
                initialState={{
                  pinnedColumns: {
                    left: ['venueName', 'whiteSpace'],
                  },
                }}
              />
            </NestedGridContainer>
          )}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            pinnedColumns: {
              left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name'],
            },
          }}
        />
      </Accordion>
    ))
  );
}
