import { graphql } from '@gql/types';

export const BOX_OFFICE_FRAGMENT = graphql(`
  fragment BoxOfficeItemFields on BoxOfficeItem {
    name
    capacity
    price
  }
`);

export const SHOW_FRAGMENT = graphql(`
  fragment ShowFields on Show {
    id
    name
    showDateTime
  }
`);

export const EVENT_FRAGMENT = graphql(`
  fragment SearchEventFields on Event {
    id
    name
    status
    configuration
    sellableCapacity
    headliner {
      id
      name
    }
    shows {
      id
      name
      showDateTime
    }
    venue {
      localCurrencyCode
      timezone
    }
    defaultBoxOffice {
      id
      phases(id: "modeling") {
        id
        percentSold
        items {
          ...BoxOfficeItemFields
        }
      }
    }
    artistOffer {
      artistAdjustment
      artistAdjustmentNotes
      guarantee
      percentNetPoolEarnings
    }
    primaryBuyer {
      id
      email
      name
    }
  }
`);

export const GET_EVENT = graphql(`
  query GetTourInformation($eventId: ID!, $phaseId: String) {
    event(eventId: $eventId) {
      id
      name
      status
      date
      headliner {
        name
      }
      exchangeRate
      baseCurrency
      localCurrency
      venue {
        id
        name
        city
        stateCode
        timezone
      }
      space {
        id
        name
        type
      }
      tourInformation {
        numberOfTrucks
        offerName
        offerStatus
        tourName
      }
      shows {
        id
        showDateTime
        boxOffice {
          id
          phases {
            id
            fees {
              profitLossCategoryId
              formula
              amount
              notes
            }
          }
        }
      }
      expense {
        id
        phases(id: $phaseId) {
          id
          fixedCategoryItems {
            categoryId
            items {
              id
              budget
            }
          }
        }
      }
      isSettledInOldElvis
      nosSettlement {
        submittedBy {
          name
        }
        submittedOn
      }
    }
  }
`);

export const GET_EVENT_FOR_NOS = graphql(`
  query GetEventForNos($eventId: ID!, $phaseId: String) {
    event(eventId: $eventId) {
      id
      name
      status
      date
      headliner {
        id
        name
      }
      exchangeRate
      baseCurrency
      localCurrency
      venue {
        id
        name
        city
        stateCode
        country
        timezone
      }
      space {
        id
        name
      }
      tourInformation {
        numberOfTrucks
        tourName
      }
      shows {
        id
        showDateTime
        boxOffice {
          id
          phases {
            id
            fees {
              profitLossCategoryId
              formula
              amount
              notes
            }
          }
        }
      }
      expense {
        id
        phases(id: $phaseId) {
          id
          fixedCategoryItems {
            categoryId
            items {
              id
              budget
              notes
            }
          }
          variableCategoryItems {
            categoryId
            items {
              id
              rate
              minimum
              maximum
              calculatedCost
            }
          }
        }
      }
      nosSettlement {
        id
        artistSettlement {
          localCurrency
          baseCurrency
          artistSettlement {
            guarantee
            pctOfNetPoolEarnings
            artistAdjustmentNote
            artistAdjustmentTotal
          }
          notesAndComments
        }
        internalSettlement {
          localCurrency
          baseCurrency
        }
      }
    }
  }
`);

export const SEARCH_EVENTS = graphql(`
  query SearchEvents($filter: EventFilter) {
    searchEvents(filter: $filter) {
      ...SearchEventFields,
      canSelectEventFromCloneList,
    }
  }
`);

export const GET_EVENT_TOUR_INFORMATION = graphql(`
  query GetEventTourInformation($eventId: ID!) {
    event(eventId: $eventId) {
      id
      tourInformation {
        numberOfTrucks
        offerName
        offerStatus
        tourName
      }
    }
  }
`);

export const VERSION_TABLE_FRAGMENT = graphql(`
  fragment NosVersionTable on NosSettlement {
    id
    isPrimary
    version
    submittedBy {
      name
    }
    submittedOn
    updatedBy {
      name
    }
    updatedDate
    workbookItemId
  }
`);

export const NOS_VERSION_TABLE_VENUE = graphql(`
    fragment NosVersionVenue on Venue {
      id
      city
      stateCode
      timezone
    }
`);

export const GET_EVENT_NOS_VERSIONS = graphql(`
  query GetEventNOSVersions($eventId: ID!) {
    event(eventId: $eventId) {
      id
      date
      status
      isSettledInOldElvis
      workbookFileId
      localCurrency
      tourInformation {
        tourName
      }
      venue {
      ...NosVersionVenue
      }
      nosSettlementVersions {
        ...NosVersionTable
      }
    }
}`);
