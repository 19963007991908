import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import {
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  VenueNameCell,
  ShowsCountCell,
  EventOfferActionsCell,
  TotalsCell,
} from '@components';
import { leftPinnedColumn } from '@utils/datagridHelpers';
import { FinancesFixedCosts, FinancesFixedCostsEventOffer } from '@types';
import { formatNumberToCurrency, formatTotalsToCurrencyOrDash } from '@utils/numberHelpers';

const budgetColumn = {
  field: 'budget',
  headerName: 'Budget',
  flex: 0.09,
  minWidth: 88,
};

const internalColumn = {
  field: 'internal',
  headerName: 'Internal',
  flex: 0.09,
  minWidth: 89,
};

const artistReimbursementColumn: GridColDef = {
  field: 'artistReimbursement',
  headerName: 'Artist Reimbursement',
  flex: 0.15,
  minWidth: 143,
};

const notesColumn: GridColDef = {
  field: 'note',
  headerName: 'Notes',
  flex: 0.25,
  minWidth: 170,
};

type FixedCostsColumns = GridColDef<FinancesFixedCosts>[];

export const fixedCostsColumns: FixedCostsColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => !params.row.isGroupRow && <ExpandButtonCell {...params} />,
  },
  {
    field: 'name',
    ...leftPinnedColumn,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell fontWeight={row.isGroupRow ? '500' : 'normal'}>{row.name}</TextWrapPrettyCell>
        {!row.isGroupRow && <ShowsCountCell count={row.eventOffers.length} total={row.showsCount} />}
      </LeftPinnedCell>
    ),
  },
  {
    ...budgetColumn,
    valueGetter: ({ row }) => !row.isGroupRow && formatTotalsToCurrencyOrDash(row.budget),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Budget' />
    ),
  },
  {
    ...internalColumn,
    valueGetter: ({ row }) => !row.isGroupRow && formatTotalsToCurrencyOrDash(row.internal),
    renderCell: ({ formattedValue }) => (
        <TotalsCell {...formattedValue} name='Internal' />
    ),
  },
  artistReimbursementColumn,
  notesColumn,
];

type FixedCostsEventOffer = GridColDef<FinancesFixedCostsEventOffer>[];

export const fixedCostsEventOfferColumns: FixedCostsEventOffer = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          // eventId={row.eventId}
          offerId={row.offerId}
          // settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  {
    ...budgetColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.budget),
  },
  {
    ...internalColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.internal),
  },
  {
    ...artistReimbursementColumn,
    valueGetter: ({ row }) => row.artistReimbursement,
  },
  {
    ...notesColumn,
    valueGetter: ({ row }) => row.note,
  },
];
