import { AegLogoBox } from '@components';
import deadComputerSVG from '@images/aeg-error-page-img.svg';
import {
  PageContainer,
  Header,
  ContentContainer,
  ErrorMessage,
  ErrorImage,
} from '../ErrorPage.styled';

type LayoutWarningProps = {
  header: string;
  children: React.ReactNode;
};

export const LayoutWarning = ({ header, children }: LayoutWarningProps) => (
    <PageContainer data-testid="error-page-container">
        <Header>
            <AegLogoBox />
        </Header>
        <ContentContainer>
            <ErrorImage src={deadComputerSVG} alt="error-page-image" />
            <ErrorMessage data-testid="error-message">
            {header}
            </ErrorMessage>
            {children}
        </ContentContainer>
    </PageContainer>
);
