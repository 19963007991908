import { ApolloError } from '@apollo/client';
import { BadRequestTitles } from '@types';

interface BadRequestMessages {
  message: string[];
  formattedMessage: {
    title: string;
    fields: string[];
  }[];
}

function parseBadRequestMessages(messages: string[]): BadRequestMessages {
  const badRequestMessages: BadRequestMessages = {
    message: [],
    formattedMessage: [],
  };
  messages.forEach((errorMessage) => {
    // TODO: Add additional regexes for other error types as they surface
    const invalidValueRegex = /invalid value (.*?) at "(.*?)"; (.*?):/;
    const invalidValueMatch = errorMessage.match(invalidValueRegex);
    const errorDescription = invalidValueMatch?.[3].trim() as string;

    if (invalidValueMatch) {
      const nonIntegerRegex = /^Int cannot represent non-integer value$/;
      const nonIntegerMatch = nonIntegerRegex.test(errorDescription);

      if (nonIntegerMatch) {
        const invalidFields = invalidValueMatch[2].split('.');
        const parsedFields = invalidFields
          .slice(invalidFields.length > 1 ? 1 : 0)
          .map((field) => field.charAt(0).toUpperCase() + field.slice(1))
          .join(' ');

        const nonIntegerErrors = badRequestMessages.formattedMessage
          .find((m) => m.title === BadRequestTitles.NonIntegerValue);

        if (nonIntegerErrors) {
          nonIntegerErrors.fields.push(parsedFields);
        } else {
          badRequestMessages.formattedMessage.push({
            title: BadRequestTitles.NonIntegerValue,
            fields: [parsedFields],
          });
        }
      } else {
        badRequestMessages.message.push(errorMessage);
      }
    } else {
      badRequestMessages.message.push(errorMessage);
    }
  });

  return badRequestMessages;
}

interface PossibleGQLError { errors: { message: string }[] }

export function getNetworkErrorInfo(error: ApolloError | undefined | unknown): {
  message?: string | string[];
  status: number;
  formattedMessage?: {
    title: string,
    fields: string[],
  }[];
} | null {
  const typedError = error as {
    networkError?: {
      result?: { errors?: { message?: string }[] };
      statusCode?: number;
    };
  } | undefined;

  if (!typedError?.networkError) {
    return null;
  }

  const { statusCode, result: results } = (typedError.networkError) as {
    result: PossibleGQLError | PossibleGQLError[];
    statusCode: number;
  };

  const result = Array.isArray(results)
    ? results.find((r) => r.errors)
    : results;

  let message: string | string[] = 'We experienced an issue. Please try again.';

  switch (statusCode) {
    // specific errors our gql layer may return
    case 400:
      if (result?.errors?.length) {
        const badRequestMessages = parseBadRequestMessages(result.errors.map((e) => e.message));
        return {
          message: badRequestMessages.message,
          status: statusCode,
          formattedMessage: badRequestMessages.formattedMessage,
        };
      }
      return {
        message,
        status: statusCode,
      };
    case 401:
    case 403:
    case 404:
    case 409:
    case 502:
      if (result?.errors?.length) {
        message = result.errors[0].message || message;
      }
      return {
        message,
        status: statusCode,
      };
    // non-specific errors, 500, etc..
    default:
      return {
        message,
        status: statusCode,
      };
  }
}
