/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-await-in-loop */
import { useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { EnvStatusContext } from '@providers';
import { useLoading } from '@hooks';
import {
  TOUR_SETTLEMENT_ARTIST_INITIALIZED_KEY,
  NAMED_RANGES,
  SHEET_NAMES,
  TABLE_NAMES,
} from '@utils/excel/tourSettlementArtist/constants';
import {
  WORKBOOK_ORIGINAL_PARENT_DIR_PATH,
  WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER,
} from '@utils/excel/shared/constants';
import { toNumberOrUndefined } from '@utils/numberHelpers';
import { toStringOrUndefined } from '@utils/stringHelpers';

import { GET_TOUR_AND_OFFER_ONLY, GET_OFFER_EVENTS_TO_POPULATE_SHOW_DETAIL } from '@gql/queries/tours';
import { SYNC_TOUR_SETTLEMENT } from '@gql/mutations/settlement';
import { TourSettlementInput, Event } from '@gql/types/graphql';
import { generateShowDetailRowForArtist } from '@utils/excel/tourSettlementArtist/helpers';
import { useExcel, TableDataArray } from './useExcel';

export function useAegTourSettlementArtist() {
  const { DEAL_SUMMARY } = NAMED_RANGES;
  const { TOTAL_POOL_INCOME, TOTAL_ARTIST_EARNINGS, COMMENTS } = DEAL_SUMMARY;
  const { SHOW_DETAIL } = SHEET_NAMES;
  const { SHOW_DETAIL_TABLES } = TABLE_NAMES;

  const { envStatus } = useContext(EnvStatusContext);
  const { displayAegLoadingDialog } = useLoading();

  const { tourId, offerId } = envStatus;

  const [getTourAndOfferOnly] = useLazyQuery(GET_TOUR_AND_OFFER_ONLY);
  const [getEventsToPopulate] = useLazyQuery(GET_OFFER_EVENTS_TO_POPULATE_SHOW_DETAIL);

  const [syncTourSettlement, { error: syncTourSettlementError }] = useMutation(SYNC_TOUR_SETTLEMENT);

  const {
    applyValuesToNamedRanges,
    addActivationListenerToCheckForSharepoint,
    saveWorkbookOriginalFileLocation,
    checkIfWorkbookIsSyncedToSharepoint,
    loadExcelParentDirectory,
    getCustomProperties,
    getValuesFromNamedRanges,
    setCustomProperty,
    populateMultipleTables,
  } = useExcel();

  const generateArtistShowDetailRows = async (events: Event[]) => {
    const validData: TableDataArray = [];

    // Avoid Promise.all in Excel ops
    /* eslint-disable-next-line no-restricted-syntax */
    for (const event of events) {
      validData.push(generateShowDetailRowForArtist(event) as Array<boolean | string | number>);
    }

    const showDetailsTableData = {
      tableData: {
        name: SHOW_DETAIL_TABLES.SHOW_DETAIL_ARTIST,
        data: validData,
      },
      cleanInsert: true,
      batchInsert: true,
    };

    await populateMultipleTables(SHOW_DETAIL, [showDetailsTableData]);
  };

  const populateTourSettlementArtistShowDetailSheet = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    const tourEventsData = await getEventsToPopulate({ variables: { tourId, offerId } });
    const events = tourEventsData?.data?.getOfferEvents;

    if (events && events.length) {
      await generateArtistShowDetailRows(events as Event[]);
    }
  };

  const initTourSettlementArtistWorkbook = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }
    const workbookParentDir = await loadExcelParentDirectory();

    const workbookProperties = await getCustomProperties();
    if (TOUR_SETTLEMENT_ARTIST_INITIALIZED_KEY in workbookProperties) {
      // eslint-disable-next-line max-len
      if (!(WORKBOOK_ORIGINAL_PARENT_DIR_PATH in workbookProperties) || !(WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER in workbookProperties)) {
        await saveWorkbookOriginalFileLocation(workbookParentDir);
      }
      // check if file location changed when wb is first opened with Add-In
      await checkIfWorkbookIsSyncedToSharepoint();
      await addActivationListenerToCheckForSharepoint();

      return;
    }

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const tourLatest = await getTourAndOfferOnly({ variables: { tourId, offerId } });
    const tour = tourLatest.data?.tour;
    try {
      if (!tour) {
        throw new Error('Failed to load data for workbook init');
      }

      // Set Tour Name
      await applyValuesToNamedRanges({
        sheet: SHEET_NAMES.DEAL_SUMMARY,
        namedRangeAssignments: [
          {
            range: DEAL_SUMMARY.TOUR_NAME,
            value: tour?.name,
          },
        ],
      });

      await saveWorkbookOriginalFileLocation(workbookParentDir);

      // Populate Show Detail Sheet from database data
      await populateTourSettlementArtistShowDetailSheet();

      // Populate a Custom Property to Signal that this file has been initialized before
      await setCustomProperty(TOUR_SETTLEMENT_ARTIST_INITIALIZED_KEY, new Date().toString());
    } finally {
      hideAegLoadingDialog();
    }
  };

  const generateTourSettlementArtistInput = async (): Promise<TourSettlementInput> => {
    const {
      PREMIUM_TICKETING_REVENUE_SETTLED,
      PREMIUM_TICKETING_REVENUE_NON_SETTLED,
      MANUAL_ADJUSTMENTS: TPI_MANUAL_ADJUSTMENTS,
    } = TOTAL_POOL_INCOME;

    const {
      SPLIT_POINT_SETTLEMENT,
      TOTAL_SPLIT_POINT_SETTLEMENT,
      MANUAL_ADJUSTMENTS: TAE_MANUAL_ADJUSTMENTS,
    } = TOTAL_ARTIST_EARNINGS;

    const namedRangeLookups: string[] = [
      // Total Pool Income Ranges
      PREMIUM_TICKETING_REVENUE_SETTLED,
      PREMIUM_TICKETING_REVENUE_NON_SETTLED,
      // Total Artist Earnings Ranges
      SPLIT_POINT_SETTLEMENT,
      TOTAL_SPLIT_POINT_SETTLEMENT,
      // Sheet comments
      COMMENTS,
    ];

    // Manual Adjustments enumeration for Total Pool Income
    for (let i = 0; i < TOTAL_POOL_INCOME.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${TPI_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
      namedRangeLookups.push(`${TPI_MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`);
      namedRangeLookups.push(`${TPI_MANUAL_ADJUSTMENTS.NON_SETTLED}${i + 1}`);
    }

    // Manual Adjustments enumeration for Total Artist Earnings
    for (let i = 0; i < TOTAL_ARTIST_EARNINGS.MAX_MANUAL_ADJUSTMENTS; i += 1) {
      namedRangeLookups.push(`${TAE_MANUAL_ADJUSTMENTS.NAME}${i + 1}`);
      namedRangeLookups.push(`${TAE_MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`);
      namedRangeLookups.push(`${TAE_MANUAL_ADJUSTMENTS.NON_SETTLED}${i + 1}`);
    }

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: SHEET_NAMES.DEAL_SUMMARY,
      namedRanges: namedRangeLookups,
    });

    const getString = (key: string) => toStringOrUndefined(namedRangeValues[key]);
    const getNum = (key: string) => toNumberOrUndefined(namedRangeValues[key]);

    return {
      artistDealSummary: {
        totalPoolIncome: {
          premiumTicketingRevenue: {
            settled: getNum(PREMIUM_TICKETING_REVENUE_SETTLED),
            nonSettled: getNum(PREMIUM_TICKETING_REVENUE_NON_SETTLED),
          },
          manualAdjustments: [...Array(TOTAL_POOL_INCOME.MAX_MANUAL_ADJUSTMENTS)].map((_, index) => ({
            name: getString(`${TPI_MANUAL_ADJUSTMENTS.NAME}${index + 1}`),
            settled: getNum(`${TPI_MANUAL_ADJUSTMENTS.SETTLED}${index + 1}`),
            nonSettled: getNum(`${TPI_MANUAL_ADJUSTMENTS.NON_SETTLED}${index + 1}`),
          })),
        },
        totalArtistEarnings: {
          splitPoint: getNum(SPLIT_POINT_SETTLEMENT),
          totalSplitPoint: getNum(TOTAL_SPLIT_POINT_SETTLEMENT),
          manualAdjustments: [...Array(TOTAL_ARTIST_EARNINGS.MAX_MANUAL_ADJUSTMENTS)].map((_, index) => ({
            name: getString(`${TAE_MANUAL_ADJUSTMENTS.NAME}${index + 1}`),
            settled: getNum(`${TAE_MANUAL_ADJUSTMENTS.SETTLED}${index + 1}`),
            nonSettled: getNum(`${TAE_MANUAL_ADJUSTMENTS.NON_SETTLED}${index + 1}`),
          })),
        },
        comments: getString(COMMENTS),
      },
    };
  };

  const syncTourSettlementArtistWorkbook = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    // Get the Tour Settlement payload
    const tourSettlement = await generateTourSettlementArtistInput();
    const tourSettlementPayload = {
      variables: {
        tourSettlementId: tourId,
        tourSettlement,
      },
    };
    await syncTourSettlement(tourSettlementPayload);
  };

  return {
    initTourSettlementArtistWorkbook,
    syncTourSettlementArtistWorkbook,
    populateTourSettlementArtistShowDetailSheet,
  };
}
