import {
  GridActionsCellItem, GridCellParams, GridColDef, GridRowModel,
} from '@mui/x-data-grid-pro';
import { AddTextStyled } from '@utils/datagridHelpers.styled';
import { Typography } from '@mui/material';
import { SpaceConfiguration, Venue } from '@gql/types/graphql';
import { VenueGridData, VenueRowHandler, VenueSearchOptions } from './VenueSearch.types';
import { ClickableTypography } from './VenueSearch.styled';

export const getVenueGridColumns = (
  onVenueRowSelect: VenueRowHandler,
  venueSelectLabel: string,
  onVenueSelect?: VenueRowHandler,
): GridColDef<VenueGridData>[] => [
  {
    field: 'venueName',
    headerName: 'Venue',
    flex: 0.35,
    renderCell: (params: GridCellParams<VenueGridData>) => (
      <ClickableTypography
        data-testid="search-venue-result-venue-name-cell"
        onClick={() => {
          void onVenueRowSelect(params);
        }}
      >
        {params.row.venueName}
      </ClickableTypography>
    ),
  },
  {
    field: 'market',
    headerName: 'Market',
    flex: 0.25,
    renderCell: (params: GridCellParams<GridRowModel>) => <Typography>{params.row.market}</Typography>,
  },
  {
    field: 'maxCapacity',
    headerName: 'Max. Capacity',
    flex: 0.25,
    renderCell: (params: GridCellParams<GridRowModel>) => <Typography>{params.row.maxCapacity}</Typography>,
  },
  {
    flex: 0.15,
    field: 'addVenueEvent',
    type: 'actions',
    getActions: (params) => {
      const { id, venueId } = params.row;
      if (id === undefined || id === null || venueId === undefined || venueId === null || !onVenueSelect) {
        return [<></>];
      }

      return [
        <GridActionsCellItem
          icon={<AddTextStyled>{venueSelectLabel}</AddTextStyled>}
          label={venueSelectLabel}
          onClick={() => {
            void onVenueSelect(params);
          }}
          data-testid="venue-grid-data-add-venue"
        />,
      ];
    },
  },
];

const mapVenue = (venue: Venue): VenueGridData[] => {
  const {
    name, id: venueId, city, spaces, stateCode, metroArea,
  } = venue;

  const market = `${city}${stateCode ? `, ${stateCode}` : ''}`;

  if (!spaces?.length) {
    return [
      {
        venueId,
        id: venue.id,
        venueName: name,
        market,
        maxCapacity: 'Not Available',
        configurations: [],
        metroArea: metroArea ?? undefined,
        spaceType: 'Not Available',
      },
    ];
  }

  return spaces.map((space) => ({
    venueId,
    id: space?.id,
    venueName: space?.name === name ? name : `${name} - ${space?.name ?? ''}`,
    market,
    maxCapacity: space?.maxCapacity?.toLocaleString() ?? 'Not Available',
    configurations: space?.configurations as SpaceConfiguration[],
    updatedDate: space?.updatedDate ?? undefined,
    metroArea: metroArea ?? undefined,
    spaceType: space?.type ?? 'Not Available',
  }));
};

export const processVenue = (venue: Venue | Venue[]): VenueGridData[] => {
  if (!venue) {
    return [];
  }

  if (Array.isArray(venue)) {
    return venue.flatMap(mapVenue);
  }

  return mapVenue(venue);
};

export const searchVenueOptions = [
  { value: VenueSearchOptions.name, label: 'Search by Venue Name' },
  { value: VenueSearchOptions.market, label: 'Search by Market' },
];
