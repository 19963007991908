/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { ParentTour } from '@gql/types/graphql';
import { TourFormTypes } from '@types';
import {
  AegDrawer,
  InputLabel,
  InputLabelContainer,
  PlaceholderContainer,
} from '../shared';
import { AddTourForm } from '../AddTourForm/AddTourForm';
import { SelectStyled, SubHeader } from './CreateTourDrawer.styled';
import { AddParentTourForm } from '../AddParentTourForm/AddParentTourForm';
import {
  CREATE_TOUR_TEXT,
  TourFormText,
  tourOptions,
} from './CreateTourDrawer.models';

interface CreateTourDefaults {
  tourType?: TourFormTypes;
  parentTour?: ParentTour | null;
}

export const CreateTourDrawer = ({
  isDrawerOpen,
  closeDrawer,
  defaults,
}: {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  defaults?: CreateTourDefaults;
}) => {
  const [whichFormIsOpen, setWhichFormIsOpen] = useState(
    defaults?.tourType || '',
  );
  const [formContainerText, setFormContainerText] = useState<TourFormText>(CREATE_TOUR_TEXT);

  const tourTypeSelect = (
    <>
      <InputLabelContainer>
        <InputLabel required={true} data-testid={'tour-type-label'}>
          Tour Type
        </InputLabel>{' '}
      </InputLabelContainer>
      <SelectStyled
        displayEmpty
        disabled={defaults !== undefined}
        data-testid="tour-type-dropdown"
        onChange={(e) => setWhichFormIsOpen(e.target.value as string)}
        value={whichFormIsOpen}
        renderValue={(selectedValue) => {
          if (selectedValue) {
            return tourOptions.find(
              (option) => option.value === whichFormIsOpen,
            )?.label;
          }
          return (
            <PlaceholderContainer data-testid={'test-dropdown-placeholder'}>
              Select Tour Type
            </PlaceholderContainer>
          );
        }}
      >
        {tourOptions.map((option, index) => (
          <MenuItem key={`dropdown-option-${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectStyled>
    </>
  );

  const renderForm = useMemo(() => {
    switch (whichFormIsOpen) {
      case TourFormTypes.tour:
        return (
          <AddTourForm
            onTourSaved={closeDrawer}
            setFormContainerText={setFormContainerText}
            parentTour={defaults?.parentTour || null}
          >
            {tourTypeSelect}
          </AddTourForm>
        );
      case TourFormTypes.multiLeg:
        return (
          <>
            <AddParentTourForm>{tourTypeSelect}</AddParentTourForm>
          </>
        );
      default:
        return <>{tourTypeSelect}</>;
    }
  }, [whichFormIsOpen, defaults]);

  const handleClose = () => {
    closeDrawer();
    // Reset the state on close
    setWhichFormIsOpen(defaults?.tourType || '');
    setFormContainerText(CREATE_TOUR_TEXT);
  };

  return (
    <AegDrawer
      anchorValue={'right'}
      openDrawer={isDrawerOpen}
      onClose={handleClose}
    >
      <Typography variant="h5" data-testid="create-tours-form-header-text">
        {formContainerText.title}
      </Typography>

      <SubHeader
        variant="body2"
        data-testid="create-tours-form-sub-header-text"
      >
        {formContainerText.subTitle}
      </SubHeader>

      {renderForm}
    </AegDrawer>
  );
};
