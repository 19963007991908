import * as React from 'react';
import {
  CreateBundleDrawer, ProtectedComponent, ListViewFilter, BundlesListView,
} from '@components';
import { AegResourceTypes } from '@types';
import { BreadcrumbDispatchContext } from '@providers';
import { CompanyPermission } from '@gql/types/graphql';

export function Bundles({ disableVirtualization = false }: { disableVirtualization?: boolean }) {
  const [listViewFilterModel, setListViewFilterModel] = React.useState<ListViewFilter>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);

  React.useEffect(() => {
    setBreadcrumbs([
      {
        text: 'Bundles',
        href: '/bundles/',
      },
    ]);
  }, []);

  React.useEffect(() => {
    document.title = 'Bundles - Modern Elvis';
  }, []);

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <ProtectedComponent
      checkPermission={{
        permission: CompanyPermission.BundleList,
        resourceType: AegResourceTypes.Company,
      }}
      navigateAway={'/unauthorized'}
    >
      <BundlesListView
        disableVirtualization={disableVirtualization}
        listViewFilterModel={listViewFilterModel}
        setListViewFilterModel={setListViewFilterModel}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <CreateBundleDrawer isDrawerOpen={isDrawerOpen} closeDrawer={onClose} />
    </ProtectedComponent>
  );
}
