import { graphql } from '@gql/types';

export const ONE_OFF_PAGE_FRAGMENT = graphql(`
  fragment OneOffPageFields on OneOff {
    id
    name
    eventDate
    showCount
    capacity
    primaryEventId
    venue {
      id
      name
      city
      stateCode
    }
    buyer {
      id
      name
    }
    events {
      id
      name
      date
      status
      sellableCapacity
      venue {
        id
        name
        city
        stateCode
      }
      primaryBuyer {
        id
        name
      }
      shows {
        id
        name
      }
    }
  }
`);

export const GET_ONE_OFF_EVENTS_BY_ARTISTS = graphql(`
  query GetOneOffEventsByArtists($page: Int, $limit: Int, $artistIds: [String!]!, $searchTerm: String) {
    getOneOffEventsByArtists(page: $page, limit: $limit, artistIds: $artistIds, searchTerm: $searchTerm) {
      ...OneOffPageFields
    }
  }
`);
