import * as React from 'react';
import { Modal, Typography } from '@mui/material';
import { CloseButtonContainer, ModalLayoutContainer, ModalLayoutTitle } from './ModalLayout.styled';
import { CloseIconGrey } from '../Icons/StyledMuiIcons';

export interface ModalLayoutProps {
  open: boolean;
  children: React.ReactNode;
  title?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fullWidth?: boolean;
  testId?: string;
}

export function ModalLayout({
  open, setOpen, children, title, fullWidth = false, testId,
}: ModalLayoutProps) {
  return (
    <Modal open={open} onClose={() => setOpen(false)} aria-labelledby='modal-layout' data-testid={testId}>
      <ModalLayoutContainer fullWidth={fullWidth}>
        <ModalLayoutTitle>
          <Typography data-testid='modal-layout-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <CloseButtonContainer fullWidth={fullWidth}>
            <CloseIconGrey data-testid='modal-layout-close' onClick={() => setOpen(false)} />
          </CloseButtonContainer>
        </ModalLayoutTitle>
        {children}
      </ModalLayoutContainer>
    </Modal>
  );
}
