import { graphql } from '@gql/types';

export const SYNC_NOS_SETTLEMENT = graphql(`
  mutation modifyNOSSettlement($nosSettlement: NosSettlementInput!) {
    modifyNOSSettlement(nosSettlement: $nosSettlement) {
      id
      updatedDate
    }
  }
`);

export const SET_PRIMARY_NOS_SETTLEMENT = graphql(`
  mutation SetPrimaryNOSSettlement($nosSettlementId: ID!) {
    setPrimaryNOSSettlement(nosSettlementId: $nosSettlementId) {
      id
      workbookItemId
      isPrimary
      version
    }
  }
`);

export const SYNC_TOUR_SETTLEMENT = graphql(`
  mutation modifyTourSettlement($tourSettlementId: ID!, $tourSettlement: TourSettlementInput!) {
    modifyTourSettlement(tourSettlementId: $tourSettlementId, tourSettlement: $tourSettlement) {
      id
    }
  }
`);

export const SUBMIT_TOUR_SETTLEMENT = graphql(`
  mutation submitTourSettlement($submitTourSettlement: SubmitTourSettlementInput!) {
    submitTourSettlement(submitTourSettlement: $submitTourSettlement) {
      id
    }
  }
`);
