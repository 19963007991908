import * as yup from 'yup';
import { User } from '@gql/types/graphql';
import {
  HallPassRole,
  HallPassRow,
  RosterRole,
  RosterRow,
  ManageTeamMembersFormFields,
} from './ManageTeamMembersForm.models';

const UserFieldSchema = yup.object<User>({
  id: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().required(),
}).noUnknown();

const HallPassRowSchema = yup.object<HallPassRow>({
  role: yup
    .mixed<HallPassRole>()
    .oneOf(Object.values(HallPassRole))
    .required('Select a role.'),
});

const RosterRowSchema = yup.object<RosterRow>({
  role: yup
    .mixed<RosterRole>()
    .oneOf(Object.values(RosterRole))
    .required('Select a role.'),
});

export const ManageTeamMembersFormSchema = yup.object<ManageTeamMembersFormFields>({
  primaryBuyer: UserFieldSchema.required('Please select a Primary Buyer.'),
  hallPasses: yup
    .array()
    .of(HallPassRowSchema),
  rosters: yup
    .array()
    .of(RosterRowSchema),
});
