import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const SignInContainer = styled(Container)<ContainerProps>({
  width: '432px',
  height: '439px',
  padding: '0px !important',
  backgroundColor: 'white',
  boxShadow: 'var(--secondary-box-shadow)',
  borderRadius: '8px',
  position: 'absolute',
  top: 'calc(50% - 219px)',
  left: 'calc(50% - 219px)',
}) as typeof Container;

export const AegLogoBox = styled(Box)({
  position: 'relative',
  '& img:nth-of-type(1)': {
    // AEG lettering
    marginLeft: '50px',
  },
  '& img:nth-of-type(2)': {
    // Elvis image
    position: 'absolute',
    left: '268px',
    top: '39px',
  },
}) as typeof Box;

export const ContentBox = styled(Box)({
  marginLeft: '40px',
}) as typeof Box;

export const SignInBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flexStart',
  gap: '16px',
  marginTop: '35px',
}) as typeof Box;

export const LoginButton = styled(Button)<ButtonProps>({
  textTransform: 'none',
  margin: '40px 0px 32px 0px',
}) as typeof Button;

export const LoginTroubleBox = styled(Box)(({ theme }) => ({
  '& a': {
    color: theme.palette.info.main,
    marginLeft: '7px',
  },
})) as typeof Box;
