import { StyledChip, StyledContainer } from './ShowsCountCell.styled';

interface ShowsCountCellProps {
  count: number;
  total: number;
}

export function ShowsCountCell({ count, total }: ShowsCountCellProps) {
  return (
    <StyledContainer>
      <StyledChip
        label={`${count}/${total}`}
        size='small'
        variant='filled'
      />
    </StyledContainer>
  );
}
