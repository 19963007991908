import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import { EnvConfig } from '@types';
import { FeatureFlagsProviderBuilder } from './providers/FlagConfig/FeatureFlagsProvider';
import { App } from './App';

// license is static across all environments, if we need to update
//  to be environment specfic we can move this to App.tsx and leverage
//  EnvConfig context
LicenseInfo.setLicenseKey(
  '90f907798295e720aa2e4674422f19feTz05MDEyNSxFPTE3NDY5ODUyNzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
const container = document.getElementById('root');
const root = createRoot(container!);

await (async () => {
  const response = await fetch('/config.json');
  const envConfig = await response.json() as EnvConfig;

  const FeatureFlagsProvider = FeatureFlagsProviderBuilder(envConfig.LAUNCH_DARKLY_CLIENT_ID);
  const { flags, flagOverrides = {} } = envConfig;
  try {
    root.render(
      <FeatureFlagsProvider flags={flags} flagOverrides={flagOverrides}>
        <App envConfig={envConfig} />
      </FeatureFlagsProvider>,
    );
  } catch (error) {
    root.render(
      <div>There was an error loading the environment configuration.</div>,
    );
  }
})();
