import { GET_OLD_ELVIS_NOS_SETTLEMENT_LINK } from '@gql/mutations/oldElvis';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { ListViewLayout } from '@layouts/ListViewLayout';
import { getBookingSupportUrl } from '@utils/stringHelpers';
import { ActionButton, ContentContainer, GenericMessage } from './OldElvisNosSettlementFile.styled';

export const OldElvisNosSettlementFile = ({ companyId, fileName }: { companyId: string, fileName: string }) => {
  const tourSasUriPayload = {
    variables: {
      blobData: {
        companyId,
        fileName,
      },
    },
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [tourSasUriMutation, { loading }] = useMutation(
    GET_OLD_ELVIS_NOS_SETTLEMENT_LINK,
    {
      onCompleted: (response) => {
        if (response.getOldElvisNosSettlementLink?.url) {
          openInNewTab(response.getOldElvisNosSettlementLink?.url);
        }
      },
    },
  );

  useEffect(() => {
    if (fileName) {
      void tourSasUriMutation(tourSasUriPayload);
    }
  }, [tourSasUriMutation]);

  if (loading) return <p>Loading...</p>;

  return (
    <ListViewLayout headerText="Old Elvis Nos Settlement">
      <ContentContainer data-testid="old-elvis-nos-settlements-container">
        <GenericMessage data-testid="download-generic-message">
          If you were unable to download, enable your pop up blocker, refresh this page and try downloading again.
        </GenericMessage>
        <GenericMessage data-testid="booking-support-generic-message">
          Contact Booking support for further assistance.
        </GenericMessage>
        <ActionButton
          href={getBookingSupportUrl(`Old Elvis NOS Settlements - ${fileName}`)}
          data-testid="request-support-button"
        >
          Contact Support
        </ActionButton>
      </ContentContainer>
    </ListViewLayout>
  );
};
