import React from 'react';
import { useMutation } from '@apollo/client';
import { RETRY_JDE_TOUR_SUBLEDGER } from '@gql/mutations/tours';
import { StatusIcon } from '@components';
import {
  SubledgerContainer,
  TypographyStyled,
  MetadataGridItem,
  MetadataTitle,
} from './JDETourSubledgerStatus.styled';

type JDETourSubledgerStatusProps = {
  tourId: string;
  subledgerId: string;
  subledgerStatus: string;
};

export function JDETourSubledgerStatus({ tourId, subledgerId, subledgerStatus }: JDETourSubledgerStatusProps) {
  const [retryJdeTourSubledger] = useMutation(RETRY_JDE_TOUR_SUBLEDGER, {
    errorPolicy: 'all',
  });

  const handleRetryJdeTourSubledger = React.useCallback(
    async () => {
      await retryJdeTourSubledger({
        variables: {
          tourId,
        },
      });
    },
    [retryJdeTourSubledger],
  );

  return (
    <>
    {subledgerId
      && <MetadataGridItem
          item
          xs={true}
          className={'auto-size'}
          data-testid="detail-metadata-item"
        >
          <MetadataTitle variant="subtitle2" data-testid="detail-metadata-title">
            Subledger
          </MetadataTitle>
          <SubledgerContainer data-testid="detail-metadata-value">
            <TypographyStyled variant="body2">{subledgerId}</TypographyStyled>
            <StatusIcon
              status={subledgerStatus}
              messages={{
                pendingTooltip: 'Subledger Pending',
                failedTooltip: 'Retry Subledger',
                errorToast: 'We’re experiencing an issue with JDE. Please try again later.',
              }}
              clickFn={handleRetryJdeTourSubledger}
            />
          </SubledgerContainer>
        </MetadataGridItem>
    }
  </>
  );
}
