import { ArtistInformation } from '@gql/types/graphql';

/**
 * takes in array of Headliners as GQL ArtistInformation type, sorts alphabetically by artist name
 */
export const sortHeadlinersByArtistName = (headliners: ArtistInformation[]) => {
  if (!headliners) return [];
  return [...headliners].sort((a, b) => {
    const nameA = a?.artist?.name?.toLowerCase() as string;
    const nameB = b?.artist?.name?.toLowerCase() as string;
    return nameA.localeCompare(nameB);
  });
};
