import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AegResourceTypes } from '@types';
import { CompanyPermission } from '@gql/types/graphql';
import { ProtectedComponent } from '@components';
import { OldElvisNosSettlementFile } from './OldElvisNosSettlementFile/OldElvisNosSettlementFile';

export const OldElvisNosSettlements = () => {
  const { companyId } = useParams();

  const [searchParams] = useSearchParams();

  const getFileName = () => searchParams.get('fileName');

  useEffect(() => {
    document.title = 'Old Elvis NOS Settlements - Modern Elvis';
  }, []);

  return (
    <ProtectedComponent
      checkPermission={{
        permission: CompanyPermission.HasCompanyAccess,
        resourceType: AegResourceTypes.Company,
        resourceId: companyId as string,
      }}
      navigateAway={'/unauthorized-file'}>
      <OldElvisNosSettlementFile companyId={companyId as string} fileName={getFileName() as string} />
    </ProtectedComponent>
  );
};
