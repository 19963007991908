import React from 'react';
import {
  GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';

import { CompanySelect } from '@components';

export const CompanyFilter = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const handleOnChange = (selectedCompany: string | null) => {
    if (!selectedCompany) {
      applyValue({ ...item, value: null });
    } else {
      applyValue({ ...item, value: selectedCompany });
    }
  };

  return (
      <CompanySelect
        selectedCompany={item.value as string}
        onChange={handleOnChange}
        testId='autocomplete-bundles-company'
      />
  );
};
