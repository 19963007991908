/* eslint-disable max-lines-per-function */
/* eslint-disable no-await-in-loop */
import { useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { EnvStatusContext } from '@providers';
import { useLoading } from '@hooks';
import {
  TOUR_SETTLEMENT_FAR_INITIALIZED_KEY,
  NAMED_RANGES,
  SHEET_NAMES,
  TABLE_NAMES,
} from '@utils/excel/tourSettlementFar/constants';
import {
  WORKBOOK_ORIGINAL_PARENT_DIR_PATH,
  WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER,
} from '@utils/excel/shared/constants';
import { toNumberOrUndefined } from '@utils/numberHelpers';
import { toStringOrUndefined } from '@utils/stringHelpers';

import { GET_TOUR_AND_OFFER_ONLY, GET_OFFER_EVENTS_TO_POPULATE_SHOW_DETAIL } from '@gql/queries/tours';
import { SYNC_TOUR_SETTLEMENT, SUBMIT_TOUR_SETTLEMENT } from '@gql/mutations/settlement';
import { InternalDealSummaryInput, Event } from '@gql/types/graphql';
import { generateShowDetailRowForFar } from '@utils/excel/tourSettlementFar/helpers';
import { useExcel, TableDataArray, ExcelCalcMode } from './useExcel';

export function useAegTourSettlementFar() {
  const { DEAL_SUMMARY } = NAMED_RANGES;
  const { SHOW_DETAIL } = SHEET_NAMES;
  const { SHOW_DETAIL_TABLES } = TABLE_NAMES;

  const { envStatus } = useContext(EnvStatusContext);
  const { displayAegLoadingDialog } = useLoading();

  const { tourId, offerId } = envStatus;

  const [getTourAndOfferOnly] = useLazyQuery(GET_TOUR_AND_OFFER_ONLY);
  const [getEventsToPopulate] = useLazyQuery(GET_OFFER_EVENTS_TO_POPULATE_SHOW_DETAIL);

  const [syncTourSettlementFar, { error: syncTourSettlementError }] = useMutation(SYNC_TOUR_SETTLEMENT);
  const [submitTourSettlementFar, { error: submitTourSettlementError }] = useMutation(SUBMIT_TOUR_SETTLEMENT, {
    refetchQueries: [GET_TOUR_AND_OFFER_ONLY], // Reload to display new status
  });

  const {

    applyValuesToNamedRanges,
    addActivationListenerToCheckForSharepoint,
    saveWorkbookOriginalFileLocation,
    checkIfWorkbookIsSyncedToSharepoint,
    loadExcelParentDirectory,
    getCustomProperties,
    setCustomProperty,
    getValuesFromNamedRanges,
    populateMultipleTables,
    setCalculationMode,
  } = useExcel();

  const generateFarShowDetailRows = async (events: Event[]) => {
    const validData: TableDataArray = [];

    // Avoid Promise.all in Excel ops
    // eslint-disable-next-line no-restricted-syntax
    for (const event of events) {
      validData.push(generateShowDetailRowForFar(event) as Array<boolean | string | number>);
    }

    const showDetailsTableData = {
      tableData: {
        name: SHOW_DETAIL_TABLES.SHOW_DETAIL_FAR,
        data: validData,
      },
      cleanInsert: true,
      batchInsert: true,
    };

    await populateMultipleTables(SHOW_DETAIL, [showDetailsTableData]);
  };

  const populateTourSettlementFarShowDetailSheet = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    const tourEventsData = await getEventsToPopulate({ variables: { tourId, offerId } });
    const events = tourEventsData?.data?.getOfferEvents;

    if (events && events.length) {
      await setCalculationMode(ExcelCalcMode.manual);
      await generateFarShowDetailRows(events as Event[]);
      await setCalculationMode(ExcelCalcMode.automatic);
    }
  };

  const initTourSettlementFARWorkbook = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }
    const workbookParentDir = await loadExcelParentDirectory();

    const workbookProperties = await getCustomProperties();
    if (TOUR_SETTLEMENT_FAR_INITIALIZED_KEY in workbookProperties) {
      // eslint-disable-next-line max-len
      if (!(WORKBOOK_ORIGINAL_PARENT_DIR_PATH in workbookProperties) || !(WORKBOOK_ORIGINAL_PARENT_DIR_TOUR_FOLDER in workbookProperties)) {
        await saveWorkbookOriginalFileLocation(workbookParentDir);
      }
      // check if file location changed when wb is first opened with Add-In
      await checkIfWorkbookIsSyncedToSharepoint();
      await addActivationListenerToCheckForSharepoint();

      return;
    }

    const hideAegLoadingDialog = await displayAegLoadingDialog();

    const tourLatest = await getTourAndOfferOnly({ variables: { tourId, offerId } });
    const tour = tourLatest.data?.tour;

    try {
      if (!tour) {
        throw new Error('Failed to load data for workbook init');
      }

      // Set Tour Name
      await applyValuesToNamedRanges({
        sheet: SHEET_NAMES.DEAL_SUMMARY,
        namedRangeAssignments: [
          {
            range: DEAL_SUMMARY.TOUR_NAME,
            value: tour?.name,
          },
        ],
      });

      await saveWorkbookOriginalFileLocation(workbookParentDir);

      // Populate Show Detail Sheet from database data
      await populateTourSettlementFarShowDetailSheet();

      // Populate a Custom Property to Signal that this file has been initialized before
      await setCustomProperty(TOUR_SETTLEMENT_FAR_INITIALIZED_KEY, new Date().toString());
    } finally {
      hideAegLoadingDialog();
    }
  };

  const generateInternalDealSummaryInput = async (): Promise<InternalDealSummaryInput> => {
    const {
      TOTAL_POOL_INCOME, TOTAL_ARTIST_EARNINGS, TOTAL_PROMOTER_EARNINGS, COMMENTS,
    } = DEAL_SUMMARY;

    const namedRangeLookups = [
      COMMENTS,
      ...Object.values(TOTAL_POOL_INCOME).filter((item) => typeof item !== 'object'),
      ...Object.values(TOTAL_ARTIST_EARNINGS).filter((item) => typeof item !== 'object'),
      ...Object.values(TOTAL_PROMOTER_EARNINGS).filter((item) => typeof item !== 'object'),
      ...Array(TOTAL_POOL_INCOME.MAX.MANUAL_ADJUSTMENTS)
        .fill(0)
        .map((_, i) => [
          `${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.NAME}${i + 1}`,
          `${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`,
          `${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`,
          `${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`,
        ]),
      ...Array(TOTAL_ARTIST_EARNINGS.MAX.MANUAL_ADJUSTMENTS)
        .fill(0)
        .map((_, i) => [
          `${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.NAME}${i + 1}`,
          `${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`,
          `${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`,
          `${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`,
        ]),
      ...Array(TOTAL_PROMOTER_EARNINGS.MAX.MANUAL_ADJUSTMENTS)
        .fill(0)
        .map((_, i) => [
          `${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.NAME}${i + 1}`,
          `${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`,
          `${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`,
          `${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`,
        ]),
      ...Object.values(TOTAL_PROMOTER_EARNINGS.DIRECT_OVERHEADS),
      ...Object.values(TOTAL_POOL_INCOME.PREMIUM_TICKETING_REVENUE),
    ].flat();

    const namedRangeValues = await getValuesFromNamedRanges({
      sheet: SHEET_NAMES.DEAL_SUMMARY,
      namedRanges: namedRangeLookups as string[],
    });

    const getString = (key: string) => toStringOrUndefined(namedRangeValues[key]);
    const getNum = (key: string) => toNumberOrUndefined(namedRangeValues[key]);

    const internalDealSummary: InternalDealSummaryInput = {
      comments: getString(COMMENTS),
      totalArtistEarnings: {
        manualAdjustments: Array(TOTAL_ARTIST_EARNINGS.MAX.MANUAL_ADJUSTMENTS)
          .fill(0)
          .map((_, i) => ({
            name: getString(`${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.NAME}${i + 1}`),
            settled: getNum(`${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`),
            nonSettled: getNum(`${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`),
            originalOffer: getNum(`${TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`),
          })),
        splitPoint: getNum(TOTAL_ARTIST_EARNINGS.SPLIT_POINT),
        splitPointOriginalOffer: getNum(TOTAL_ARTIST_EARNINGS.SPLIT_POINT_OFFER),
        totalGuaranteesOriginalOffer: getNum(TOTAL_ARTIST_EARNINGS.TOTAL_GUARANTEES_OFFER),
        totalSplitPoint: getNum(TOTAL_ARTIST_EARNINGS.TOTAL_SPLIT_POINT),
        totalSplitPointOriginalOffer: getNum(TOTAL_ARTIST_EARNINGS.TOTAL_SPLIT_POINT_OFFER),
      },

      totalPoolIncome: {
        manualAdjustments: Array(TOTAL_POOL_INCOME.MAX.MANUAL_ADJUSTMENTS)
          .fill(0)
          .map((_, i) => ({
            name: getString(`${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.NAME}${i + 1}`),
            settled: getNum(`${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`),
            nonSettled: getNum(`${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`),
            originalOffer: getNum(`${TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`),
          })),
        adjustmentsOriginalOffer: getNum(TOTAL_POOL_INCOME.ADJUSTMENTS_OFFER),
        grossReceiptsOriginalOffer: getNum(TOTAL_POOL_INCOME.GROSS_RECEIPTS_OFFER),
        netGrossReceiptsOriginalOffer: getNum(TOTAL_POOL_INCOME.NET_GROSS_RECEIPTS_OFFER),
        numShowsOriginalOffer: getNum(TOTAL_POOL_INCOME.NUM_SHOWS_OFFER),
        otherAdjustmentsOriginalOffer: getNum(TOTAL_POOL_INCOME.OTHER_ADJUSTMENTS_OFFER),
        premiumTicketingRevenue: {
          nonSettled: getNum(TOTAL_POOL_INCOME.PREMIUM_TICKETING_REVENUE.NOT_SETTLED),
          originalOffer: getNum(TOTAL_POOL_INCOME.PREMIUM_TICKETING_REVENUE.OFFER),
          settled: getNum(TOTAL_POOL_INCOME.PREMIUM_TICKETING_REVENUE.SETTLED),
        },
        showExpensesOriginalOffer: getNum(TOTAL_POOL_INCOME.SHOW_EXPENSES_OFFER),
        soldOriginalOffer: getNum(TOTAL_POOL_INCOME.SOLD_OFFER),
        availableCapacityOriginalOffer: getNum(TOTAL_POOL_INCOME.AVAILABLE_CAPACITY_OFFER),
      },

      totalPromoterEarnings: {
        manualAdjustments: Array(TOTAL_PROMOTER_EARNINGS.MAX.MANUAL_ADJUSTMENTS)
          .fill(0)
          .map((_, i) => ({
            name: getString(`${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.NAME}${i + 1}`),
            settled: getNum(`${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.SETTLED}${i + 1}`),
            nonSettled: getNum(`${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.NOT_SETTLED}${i + 1}`),
            originalOffer: getNum(`${TOTAL_PROMOTER_EARNINGS.MANUAL_ADJUSTMENTS.OFFER}${i + 1}`),
          })),
        ancillaryEarningsOriginalOffer: getNum(TOTAL_PROMOTER_EARNINGS.ANCILLARY_EARNINGS_OFFER),
        coProShareOriginalOffer: getNum(TOTAL_PROMOTER_EARNINGS.CO_PRO_SHARE_OFFER),
        directOverheads: {
          nonSettled: getNum(TOTAL_PROMOTER_EARNINGS.DIRECT_OVERHEADS.NOT_SETTLED),
          originalOffer: getNum(TOTAL_PROMOTER_EARNINGS.DIRECT_OVERHEADS.OFFER),
          settled: getNum(TOTAL_PROMOTER_EARNINGS.DIRECT_OVERHEADS.SETTLED),
        },
        expenseVarianceOriginalOffer: getNum(TOTAL_PROMOTER_EARNINGS.EXPENSE_VARIANCE_OFFER),
        revenueVarianceOriginalOffer: getNum(TOTAL_PROMOTER_EARNINGS.REVENUE_VARIANCE_OFFER),
      },
    };

    return internalDealSummary;
  };

  const syncTourSettlementFARWorkbook = async () => {
    if (!tourId || !offerId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    const internalDealSummary = await generateInternalDealSummaryInput();
    const tourSettlementFarPayload = {
      variables: {
        tourSettlementId: tourId,
        tourSettlement: {
          internalDealSummary,
        },
      },
    };
    await syncTourSettlementFar(tourSettlementFarPayload);
  };

  const submitTourSettlementFARWorkbook = async () => {
    if (!offerId || !tourId) {
      throw new Error('Missing required parameters');
    }

    await checkIfWorkbookIsSyncedToSharepoint();

    const submitTourSettlementInput = {
      variables: {
        submitTourSettlement: {
          offerId,
          tourId,
          tourSettlementId: tourId,
        },
      },
    };

    await submitTourSettlementFar(submitTourSettlementInput);
  };

  return {
    initTourSettlementFARWorkbook,
    syncTourSettlementFARWorkbook,
    populateTourSettlementFarShowDetailSheet,
    submitTourSettlementFARWorkbook,
  };
}
