import { BundleOfferQuery, EventOfferScenario } from '@gql/types/graphql';
import {
  ScenarioBuilderScenario,
  ProjectionType,
  ScenarioBuilderEventOffer,
  TotalActiveOffers,
} from '@types';

export const DEFAULT_PROJECTION_AMOUNT = 100;
export const DEFAULT_PROJECTION_TYPE = ProjectionType.Average;

export const calculateTotalActiveOffers = (
  activeOffers: ScenarioBuilderEventOffer[],
): TotalActiveOffers => {
  const numberOfScenarios = (activeOffers?.[0]?.scenarios?.length) ?? 0;

  return {
    id: 'summary',
    averageBreakeven: {
      projectionAmount: '53',
      projectionType: ProjectionType.Average,
    },
    sequentialBreakeven: {
      projectionAmount: '53',
      projectionType: ProjectionType.Sequential,
    },
    localOfferInternalForecastColumn: {
      projectionAmount: '78',
      projectionType: 'Varies',
    },
    scenarios: Array.from({ length: numberOfScenarios }, (_, scenarioIndex) => {
      const projectionAmounts: number[] = [];
      const projectionTypes: (ProjectionType | 'Varies')[] = [];
      activeOffers.forEach((eventOffer) => {
        const scenario = eventOffer.scenarios?.[scenarioIndex];
        projectionAmounts.push(Number(scenario?.projectionAmount));
        projectionTypes.push(scenario?.projectionType as ProjectionType);
      });

      return {
        projectionAmount: (
          projectionAmounts.reduce((a, b) => a + b, 0) / projectionAmounts.length
        ).toString(), // Calculate average safely, even if the array is empty
        projectionType: projectionTypes.reduce(
          (a, b) => (a !== b ? 'Varies' : b),
          projectionTypes[0] || 'Varies',
        ), // If all are not the same, return 'Varies'
      };
    }),
  };
};

export const getDefaultOffers = (
  data: BundleOfferQuery,
): [ScenarioBuilderEventOffer[], ScenarioBuilderEventOffer[]] => {
  const { bundleOffer } = data;
  const activeOffers: ScenarioBuilderEventOffer[] = [];
  const inactiveOffers: ScenarioBuilderEventOffer[] = [];
  bundleOffer?.offerEventRouting?.forEach((eventOffer) => {
    const defaultEventOffer = {
      ...eventOffer,
      id: eventOffer.event.id,
      scenarios: eventOffer.scenarios?.map((scenario) => ({
        ...scenario,
        projectionAmount: scenario?.projectionAmount === null || scenario?.projectionAmount === undefined
          ? DEFAULT_PROJECTION_AMOUNT
          : scenario.projectionAmount * 100,
        projectionType: scenario?.projectionType ?? DEFAULT_PROJECTION_TYPE,
      })),
      averageBreakeven: {
        projectionAmount: '53',
        projectionType: ProjectionType.Average,
      },
      sequentialBreakeven: {
        projectionAmount: '53',
        projectionType: ProjectionType.Sequential,
      },
      localOfferInternalForecastColumn: {
        projectionAmount: '78',
        projectionType: 'Varies',
      },
    } as ScenarioBuilderEventOffer;

    if (eventOffer.isActive) {
      activeOffers.push(defaultEventOffer);
    } else {
      inactiveOffers.push(defaultEventOffer);
    }
  });

  return [activeOffers, inactiveOffers];
};

export const updateOffers = (
  offers: ScenarioBuilderEventOffer[],
  eventOfferId: string,
  scenarioIndex: number,
  scenario: Partial<ScenarioBuilderScenario>,
) => {
  const updatedOffers = offers.map((eventOffer) => {
    if (eventOffer.id !== eventOfferId) return eventOffer;

    const newScenarios = [...(eventOffer?.scenarios ?? [])];

    newScenarios[scenarioIndex] = {
      ...(newScenarios[scenarioIndex] ?? {}),
      ...scenario,
    } as EventOfferScenario;

    return {
      ...eventOffer,
      scenarios: newScenarios,
    };
  });

  return updatedOffers;
};
