import { graphql } from '@gql/types';

export const GLOBAL_SEARCH = graphql(`
  query GlobalSearch($searchTerm: String!) {
    globalSearch(searchTerm: $searchTerm) {
        id
        type
        name
        status
        headliners {
            id
            name
        }
        supports {
            id
            name
        }
        events {
            id
            name
            status
            dateTime
            venue {
                id
                name
                city
                state
                country
                metroArea
            }
        }
        company {
            id
            name
        }
        team {
            id
            name
            role
        }
    }
  }
`);
