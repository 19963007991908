import { Link } from 'react-router-dom';
import { GenericMessage, ActionButton } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function AddInNotSyncedWarning() {
  return (
    <LayoutWarning header={'Not synced with Modern Elvis'}>
      <GenericMessage data-testid="generic-message">
            The connection between Excel and Modern Elvis is broken.
            Please launch your workbook from Modern Elvis to enable the Excel Add-in to work with the application.
      </GenericMessage>
      <ActionButton>
        <Link
          to={window.location.origin}
          target="_blank"
        >
          Launch from Modern Elvis
        </Link>
      </ActionButton>
    </LayoutWarning>
  );
}
