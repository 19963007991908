import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SvgImage } from './SvgImage';

export const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  justifyContent: 'center',
}));

export const LogoImg = styled(SvgImage)(({ theme }) => ({
  width: theme.spacing(4),
}));

export const ElvisImg = styled(SvgImage)(({ theme }) => ({
  width: theme.spacing(4.125),
  height: theme.spacing(5.625),
}));
