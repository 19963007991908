/* eslint-disable max-len */
import { OfferStatus } from '@types';
import { ArtistInformation, GetBundleQuery, User } from '@gql/types/graphql';
import { DetailsMetadataItem } from '@components';
import { kebabCase } from '@utils/stringHelpers';
import { sortHeadlinersByArtistName } from '@utils/artistHelpers';

export enum ConfirmModalSteps {
  confirm,
  unconfirm,
  notify,
}

export const CONFIRM_MODAL_TEXT = {
  bodyText: 'Confirming this Offer will change its status and send a notification to local buyers of this change.',
  submitText: 'Confirm',
  cancelText: 'Cancel',
};
export const UNCONFIRM_MODAL_TEXT = {
  bodyText: 'Unconfirming this Offer will change its status back to Draft and send a notification to local buyers of this change.',
  submitText: 'Unconfirm',
  cancelText: 'Cancel',
};
export const NOTIFY_MODAL_TEXT = {
  bodyText: 'Would you like to send an email notification to local buyers? This email will include the Offer Summary PDF as well as any additional comments you leave.',
  submitText: 'Create email',
  cancelText: 'Skip',
};
export const CONFIRMATION_EMAIL_MODAL_TEXT = {
  submitText: 'Send Email',
  cancelText: 'Discard',
};

export enum OfferActionsOptions {
  EditOffer,
  DuplicateOffer,
  ConfirmOffer,
  UnconfirmOffer,
  SendConfirmationEmail,
  SetAsPrimary,
  DeleteOffer,
}

const confirmStatusAction = (offerStatus: OfferStatus, eventsLength: number, allEventsInactive: boolean) => {
  if (offerStatus === OfferStatus.Confirmed) {
    return {
      label: 'Unconfirm Offer',
      value: OfferActionsOptions.UnconfirmOffer,
    };
  }
  return {
    label: 'Confirm Offer',
    value: OfferActionsOptions.ConfirmOffer,
    disabled: !eventsLength || allEventsInactive,
  };
};

export const getOfferActions = (offerStatus: OfferStatus, eventsLength: number, allEventsInactive: boolean) => [
  {
    label: 'Edit Offer',
    value: OfferActionsOptions.EditOffer,
  },
  {
    label: 'Duplicate Offer',
    value: OfferActionsOptions.DuplicateOffer,
  },
  confirmStatusAction(offerStatus, eventsLength, allEventsInactive),
  {
    label: 'Send Confirmation Email',
    value: OfferActionsOptions.SendConfirmationEmail,
    disabled: offerStatus !== OfferStatus.Confirmed,
  },
  {
    label: 'Set As Primary',
    value: OfferActionsOptions.SetAsPrimary,
    disabled: true, // TODO: update w/conditional
  },
  {
    label: 'Delete Offer',
    value: OfferActionsOptions.DeleteOffer,
  },
];

// eslint-disable-next-line max-lines-per-function
export const getBundleMetadata = (bundleDetails: GetBundleQuery) => {
  const mapName = ((user: User) => user.name);
  const filterNullValues = (name?: string | undefined | null) => name !== null && name !== undefined;

  const sortedHeadliners = sortHeadlinersByArtistName(bundleDetails?.bundle?.headliners as ArtistInformation[]);

  const headliners = sortedHeadliners
    ?.map((headliner) => headliner.artist?.name)
    ?.filter(filterNullValues)
    ?? [];

  // Show only one Agent per headliner; if no Agent, show blank space
  const agents = sortedHeadliners
    ?.flatMap((headliner) => {
      if (headliner.agents && headliner.agents.length > 0) {
        return headliner.agents[0]?.name ?? '';
      }
      return ' ';
    })
    ?.filter(filterNullValues)
    ?? [];

  const buyers = bundleDetails?.bundle?.team?.buyers
    ?.map(mapName)
    ?.filter(filterNullValues)
    ?? [];

  const operators = bundleDetails?.bundle?.team?.operators
    ?.map(mapName)
    ?.filter(filterNullValues)
    ?? [];

  return [
    {
      title: 'Headliner(s)',
      value: headliners,
      showAll: true,
    },
    {
      title: 'Agent(s)',
      value: agents,
      showAll: true,
    },
    {
      title: 'Company',
      value: bundleDetails?.bundle?.company?.name ?? '',
    },
    {
      title: 'Tour Type',
      value: 'Bundle',
    },
    {
      title: 'Buyer(s)',
      value: buyers,
      showAll: true,
    },
    {
      title: 'Operator(s)',
      value: operators,
      showAll: true,
    },
  ].map((metadataItem) => (
    <DetailsMetadataItem
      key={kebabCase(metadataItem.title)}
      metadata={metadataItem}
    />
  ));
};
