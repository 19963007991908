import { useMutation, useQuery } from '@apollo/client';
import { GET_EVENT } from '@gql/queries/events';
import { useContext, useMemo } from 'react';
import { EnvStatusContext } from '@providers';
import { useAegNOSSettlement, useAddInAction } from '@hooks';
import { EventStatus, SnackBarMessages } from '@types';
import { FINALIZE_NOS_SETTLEMENT } from '@gql/mutations/events';
import { ButtonStyled } from './NosFinalizeButton.styled';

export const NosFinalizeButton = () => {
  const { envStatus } = useContext(EnvStatusContext);
  const { handleAddInAction } = useAddInAction();
  const { syncNosSettlementWorkbook } = useAegNOSSettlement();

  const [finalizeSubmit] = useMutation(FINALIZE_NOS_SETTLEMENT, {
    refetchQueries: [GET_EVENT],
  });

  const { data } = useQuery(GET_EVENT, {
    variables: { eventId: envStatus.eventId ?? '' },
  });

  const event = useMemo(() => data?.event, [data?.event]);

  const finalizeNosSettlement = async () => {
    await syncNosSettlementWorkbook();

    const updateEventPayload = {
      variables: {
        accountingSettlementEvent: {
          // Fallback on Event ID if NOS ID does not exist
          nosId: envStatus.nosId ?? envStatus.eventId!,
        },
      },
    };

    await finalizeSubmit(updateEventPayload);
  };
  return (
    <ButtonStyled
    variant="contained"
    data-testid="finalize-nos-settlement-button"
    onClick={() => {
      void handleAddInAction(
        finalizeNosSettlement,
        event?.status !== EventStatus.AccountingSettled
          ? SnackBarMessages.NosSubmitComplete
          : SnackBarMessages.NosResubmitComplete,
        SnackBarMessages.SubmitError,
      );
    }}
    >
    {event?.status === EventStatus.AccountingSettled
      ? 'Resubmit Accounting Settlement'
      : 'Submit Accounting Settlement'}
    </ButtonStyled>
  );
};
