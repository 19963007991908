import {
  GridColDef,
  GridRenderCellParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';

import {
  VenueNameCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  ScenarioBuilderCell,
  EventOfferActionsCell,
} from '@components';
import {
  ScenarioBuilderScenario,
  ProjectionType,
  ScenarioBuilder,
  ScenarioBuilderEventOffer,
  ScenarioBuilderProjection,
} from '@types';
import { Venue } from '@gql/types/graphql';
import { leftPinnedColumn } from '@utils/datagridHelpers';

export type ScenarioBuilderRow = (
  ScenarioBuilder &
  {
    isTotalsRow?: boolean;
    isBottomRow?: boolean;
  } &
  ScenarioBuilderEventOffer &
  ScenarioBuilderProjection
);
type ScenarioBuilderColumns = GridColDef<ScenarioBuilderRow>[];
type RenderCellParams = GridRenderCellParams<ScenarioBuilderRow>;

type OnChangeScenario = (params: {
  scenario: Partial<ScenarioBuilderScenario>;
  eventOfferId: string;
  scenarioIndex: number;
  isActive?: boolean
}) => void;

const scenario1Column = (onChangeScenario: OnChangeScenario) => ({
  field: 'scenario1',
  headerName: 'Scenario 1',
  flex: 0.2,
  minWidth: 220,
  renderCell: ({ row }: RenderCellParams) => (
      <ScenarioBuilderCell
        name={row.name}
        ticketSales={row.scenarios?.[0]?.ticketSales}
        sellableCapacity={row.scenarios?.[0]?.sellableCapacity}
        netGrossPublic={row.scenarios?.[0]?.netGrossPublic}
        totalArtistEarnings={row.scenarios?.[0]?.totalArtistEarnings}
        clubTourPL={row.scenarios?.[0]?.clubTourPL}
        projectionAmount={
          row.totalActiveOffers?.scenarios?.[0]?.projectionAmount ?? row.scenarios?.[0]?.projectionAmount?.toString()
        }
        projectionType={
          row.totalActiveOffers?.scenarios?.[0]?.projectionType ?? row.scenarios?.[0]?.projectionType as ProjectionType
        }
        onChangeAmount={(value) => onChangeScenario({
          scenario: {
            projectionAmount: value,
          },
          eventOfferId: row.id,
          scenarioIndex: 0,
          isActive: !row.isBottomRow,
        })}
        onChangeType={(type) => onChangeScenario({
          scenario: {
            projectionType: type as ProjectionType,
          },
          eventOfferId: row.id,
          scenarioIndex: 0,
          isActive: !row.isBottomRow,
        })}
      />
  ),
});

const scenario2Column = (onChangeScenario: OnChangeScenario) => ({
  field: 'scenario2',
  headerName: 'Scenario 2',
  flex: 0.2,
  minWidth: 220,
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.scenarios?.[1]?.ticketSales}
      sellableCapacity={row.scenarios?.[1]?.sellableCapacity}
      netGrossPublic={row.scenarios?.[1]?.netGrossPublic}
      totalArtistEarnings={row.scenarios?.[1]?.totalArtistEarnings}
      clubTourPL={row.scenarios?.[1]?.clubTourPL}
      projectionAmount={
        row.totalActiveOffers?.scenarios?.[1]?.projectionAmount ?? row.scenarios?.[1]?.projectionAmount?.toString()
      }
      projectionType={
        row.totalActiveOffers?.scenarios?.[1]?.projectionType ?? row.scenarios?.[1]?.projectionType as ProjectionType
      }
      onChangeAmount={(value) => onChangeScenario({
        scenario: {
          projectionAmount: value,
        },
        eventOfferId: row.id,
        scenarioIndex: 1,
        isActive: !row.isBottomRow,
      })}
      onChangeType={(type) => onChangeScenario({
        scenario: {
          projectionType: type as ProjectionType,
        },
        eventOfferId: row.id,
        scenarioIndex: 1,
        isActive: !row.isBottomRow,
      })}
    />
  ),
});

const localOfferInternalForecastColumn = {
  field: 'localOfferInternalForecastColumn',
  headerName: 'Local Offer Internal Forecast',
  flex: 0.2,
  minWidth: 220,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.localOfferInternalForecastColumn?.ticketSales}
      sellableCapacity={row.localOfferInternalForecastColumn?.sellableCapacity}
      netGrossPublic={row.localOfferInternalForecastColumn?.netGrossPublic}
      totalArtistEarnings={row.localOfferInternalForecastColumn?.totalArtistEarnings}
      clubTourPL={row.localOfferInternalForecastColumn?.clubTourPL}
      projectionAmount={
        row.totalActiveOffers?.localOfferInternalForecastColumn?.projectionAmount
        ?? row.localOfferInternalForecastColumn?.projectionAmount
      }
      projectionType={row.totalActiveOffers?.localOfferInternalForecastColumn?.projectionType
        ?? row.localOfferInternalForecastColumn?.projectionType}
      disabled
    />
  ),
};

const averageBreakevenColumn = {
  field: 'averageBreakeven',
  headerName: 'Average Breakeven',
  flex: 0.2,
  minWidth: 220,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.averageBreakeven?.ticketSales}
      sellableCapacity={row.averageBreakeven?.sellableCapacity}
      netGrossPublic={row.averageBreakeven?.netGrossPublic}
      totalArtistEarnings={row.averageBreakeven?.totalArtistEarnings}
      clubTourPL={row.averageBreakeven?.clubTourPL}
      projectionAmount={
        row.totalActiveOffers?.averageBreakeven?.projectionAmount ?? row.averageBreakeven?.projectionAmount
      }
      projectionType={row.totalActiveOffers?.averageBreakeven?.projectionType ?? row.averageBreakeven?.projectionType}
      disabled
    />
  ),
};

const sequentialBreakevenColumn = {
  field: 'sequentialBreakeven',
  headerName: 'Sequential Breakeven',
  flex: 0.2,
  minWidth: 220,
  cellClassName: 'disabled-cell',
  renderCell: ({ row }: RenderCellParams) => (
    <ScenarioBuilderCell
      name={row.name}
      ticketSales={row.sequentialBreakeven?.ticketSales}
      sellableCapacity={row.sequentialBreakeven?.sellableCapacity}
      netGrossPublic={row.sequentialBreakeven?.netGrossPublic}
      totalArtistEarnings={row.sequentialBreakeven?.totalArtistEarnings}
      clubTourPL={row.sequentialBreakeven?.clubTourPL}
      projectionAmount={
        row.totalActiveOffers?.sequentialBreakeven?.projectionAmount ?? row.sequentialBreakeven?.projectionAmount
      }
      projectionType={
        row.totalActiveOffers?.sequentialBreakeven?.projectionType ?? row.sequentialBreakeven?.projectionType
      }
      disabled
    />
  ),
};

const renderVenueNameCell = ({ row }: RenderCellParams) => (
  <LeftPinnedCell>
    <VenueNameCell venue={row.event?.venue as Venue} />
    <EventOfferActionsCell
      // TODO: Update with real data
      updatedDate={'2024-10-03T16:25:36.715Z'}
      offerId={''}
    />
  </LeftPinnedCell>
);

export const scenarioBuilderColumns = (onChangeScenario: OnChangeScenario): ScenarioBuilderColumns => [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => !params.row.isTotalsRow && !params.row.isBottomRow && <ExpandButtonCell {...params} />,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    headerName: 'Active Offers',
    renderCell: (props) => {
      const { row } = props;
      if (row.isBottomRow) {
        return renderVenueNameCell(props);
      }

      return (
        <LeftPinnedCell>
          <TextWrapPrettyCell fontWeight={row.isTotalsRow ? '500' : 'normal'}>{row.name}</TextWrapPrettyCell>
        </LeftPinnedCell>
      );
    },
  },
  localOfferInternalForecastColumn,
  scenario1Column(onChangeScenario),
  scenario2Column(onChangeScenario),
  averageBreakevenColumn,
  sequentialBreakevenColumn,
];

export const scenarioBuilderEventOfferColumns = (onChangeScenario: OnChangeScenario): ScenarioBuilderColumns => [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: renderVenueNameCell,
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  localOfferInternalForecastColumn,
  scenario1Column(onChangeScenario),
  scenario2Column(onChangeScenario),
  averageBreakevenColumn,
  sequentialBreakevenColumn,
];
