import { useAddInAction } from '@hooks';
import { SnackBarMessages } from '@types';
import { useQuery } from '@apollo/client';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { EnvStatusContext } from '@providers';
import { useContext } from 'react';
import { Offer } from '@gql/types/graphql';
import { FarButtonStyled } from './FarSubmitButton.styled';

type FarSubmitButtonProps = {
  submitFn: () => Promise<void>;
};

export const FarSubmitButton = ({ submitFn }: FarSubmitButtonProps) => {
  const { envStatus } = useContext(EnvStatusContext);
  const { offerId, tourId } = envStatus;
  const {
    data,
  } = useQuery(GET_TOUR_AND_OFFER_ONLY, {
    variables: { tourId: tourId ?? '', offerId: offerId ?? '' },
  });

  const offer = data?.tour?.offers?.[0] as Offer;

  const { handleAddInAction } = useAddInAction();

  return (
  <FarButtonStyled
    data-testid="submit-final-settlement-button"
    color={'primary'}
    variant={'contained'}
    disabled={!offer?.canSubmitTourSettlement}
    onClick={() => {
      void handleAddInAction(
        submitFn,
        SnackBarMessages.SubmitSettlementSuccessful,
        SnackBarMessages.SubmitError,
      );
    }}
  >
    Submit Final Settlement
  </FarButtonStyled>
  );
};
