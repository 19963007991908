/* eslint-disable max-lines-per-function */

import React from 'react';
import {
  GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';
import { UserModel } from '@types';
import { UserSearch } from '@components';

import { AutocompleteContainer } from '../BundlesListView.styled';

export const PrimaryBuyerFilterInput = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  const handleOnChange = (selectedPrimaryBuyer: UserModel | null) => {
    if (!selectedPrimaryBuyer) {
      applyValue({ ...item, value: null });
    } else {
      applyValue({ ...item, value: selectedPrimaryBuyer });
    }
  };

  return (
    <AutocompleteContainer>
      <UserSearch
        selectedUser={item.value as unknown as UserModel}
        onChange={handleOnChange}
        testId='autocomplete-bundles-primary-buyer'
      />
    </AutocompleteContainer>
  );
};
