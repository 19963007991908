/* eslint-disable max-lines-per-function */
import { useMutation } from '@apollo/client';
import { MODIFY_TOUR_TEAM } from '@gql/mutations/tours';
import { ModifyTourTeamInput, Tour } from '@gql/types/graphql';
import React, { useContext, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { WebUserContext } from '@providers';
import { FormErrorComponent } from '@components';
import { useMaestro } from '@hooks';
import { ModifyTeamMembersFormUserRoles } from './ModifyTeamMembersForm.models';
import { SubHeader } from './ModifyTeamMembersForm.styled';
import { ModifyTeamMembersForm } from './ModifyTeamMembersForm';

interface ModifyTeamProps {
  closeDrawer: () => Promise<void>;
  tourDetails: Tour;
}

export function ModifyTourTeamMembers({ closeDrawer, tourDetails }: ModifyTeamProps) {
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const { handleShareWithMarketing } = useMaestro(tourDetails.id, tourDetails.primaryOfferId);
  const user = useContext(WebUserContext);

  const [
    modifyTeam,
    { error: mutationError },
  ] = useMutation(MODIFY_TOUR_TEAM, {
    errorPolicy: 'all',
    async onCompleted() {
      await closeDrawer();

      if (tourDetails.id && tourDetails.primaryOfferId) {
        await handleShareWithMarketing();
      }
    },
    async onError(err) {
      await closeDrawer();
      throw err;
    },
  });

  const handleModifyTeam = React.useCallback(
    async (tour: ModifyTourTeamInput) => {
      await modifyTeam({
        variables: {
          tour,
        },
      });
    },
    [modifyTeam],
  );

  const handleSubmitModifyTeam = async (tourMemberData: ModifyTourTeamInput) => {
    if (tourMemberData === null) {
      return;
    }

    const tourId = tourDetails?.id || '';
    const {
      additionalBuyerIds,
      additionalOperatorIds,
      primaryBuyerId,
      primaryOperatorId,
      viewOnlyIds,
      nosEmailDistributionIds = [],
    } = tourMemberData;

    const finalTourTeamInput: ModifyTourTeamInput = {
      additionalBuyerIds,
      additionalOperatorIds,
      id: tourId,
      primaryBuyerId,
      primaryOperatorId,
      viewOnlyIds,
      nosEmailDistributionIds,
    };

    await handleModifyTeam(finalTourTeamInput);
  };

  const defaultValues = useMemo(() => {
    const {
      primaryBuyer,
      primaryOperator,
      additionalOperators,
      additionalBuyers,
      viewOnlyMembers,
      nosEmailDistributionList,
    } = tourDetails;

    let userRole = ModifyTeamMembersFormUserRoles.operator;
    const userId = user?.id ?? '';
    if (userId === primaryBuyer?.id || additionalBuyers?.some((buyer) => buyer.id === userId)) {
      userRole = ModifyTeamMembersFormUserRoles.buyer;
    }

    return {
      userRole,
      operators: [
        ...(primaryOperator ? [primaryOperator] : []),
        ...(additionalOperators ?? []),
      ],
      primaryOperatorId: primaryOperator?.id as string,
      buyers: [
        ...(primaryBuyer ? [primaryBuyer] : []),
        ...(additionalBuyers ?? []),
      ],
      primaryBuyerId: primaryBuyer?.id as string,
      viewOnly: viewOnlyMembers ?? [],
      nosEmailDistribution: nosEmailDistributionList ?? [],
    };
  }, [tourDetails, user]);

  return (
    <>
      <FormErrorComponent
        isDismissable={true}
        error={mutationError}
        isOpen={isFormErrorOpen}
        setIsOpen={setIsFormErrorOpen}
      />
      <Typography variant="h5" data-testid="modify-tour-team-form-header-text">
        Manage Team
      </Typography>
      <SubHeader
        variant="body2"
        data-testid="modify-tour-team-form-sub-header-text"
      >
        Operators and Buyers will have immediate access and edit rights to this tour.
      </SubHeader>
      <ModifyTeamMembersForm
        isFormSaving={false}
        defaultValues={defaultValues}
        withPrimarySelection
        onFormSubmit={(data) => handleSubmitModifyTeam(data as ModifyTourTeamInput)}
      />
    </>
  );
}
