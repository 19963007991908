import aegLogoWhite from '@images/modern-elvis-logo.svg';
import aegLogoWhiteCompact from '@images/modern-elvis-logo-compact.svg';
import { LogoBox, LogoImg } from './AegLogoBox.styled';

export function AegLogoBox() {
  return (
    <LogoBox data-testid="aeg-logo">
      <LogoImg alt="aeg-modern-elvis-logo" src={aegLogoWhite} />
    </LogoBox>
  );
}

export function AegLogoBoxCompact() {
  return (
    <LogoBox data-testid="aeg-logo-compact">
      <LogoImg alt="AEG Modern Elvis logo compact" src={aegLogoWhiteCompact} />
    </LogoBox>
  );
}
