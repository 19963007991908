/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useState } from 'react';
import {
  ServerError, useQuery, useMutation,
} from '@apollo/client';
import { useFormContext, useFormState } from 'react-hook-form';
import {
  AddAgentInput, Agency, Agent, Artist, ModifyAgentInput,
} from '@gql/types/graphql';
import { SEARCH_ARTISTS_WITH_SUB_GENRES } from '@gql/queries/artists';
import { SEARCH_AGENCIES, SEARCH_AGENTS } from '@gql/queries/agencies';
import { ADD_AGENT, UPDATE_AGENT } from '@gql/mutations/agents';
import {
  AutocompleteOption,
  FieldsContainer,
  FormAutocompleteWrapper,
  FormErrorComponent,
  FormSearchAutocomplete,
  FormStylingMode,
  GenericModal,
  VerticalSpacer,
} from '@components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { removeTypenamesAndErrors } from '@utils/gqlHelpers';
import { AegAggregator } from '@types';
import {
  AutocompleteContainer, CloseIconStyle, CreateNewAgentBtn, HeadlinerContainer, PrimaryText,
} from './HeadlinerFields.styled';

import {
  AgentFormFields,
  AgentFormMode,
  AGENT_FORM_DEFAULTS,
  CREATE_AGENT_TEXT,
  EDIT_AGENT_TEXT,
} from '../AgentForm/AgentForm.models';
import { AgentForm, AgentFormError } from '../AgentForm/AgentForm';
import { AgentChip } from './AgentChip/AgentChip';

type HeadlinerFieldsProps = {
  onSelectHeadliner: (headliner: Artist) => void;
  // Corresponds to the field that holds the data for the headliners
  //   so for use with fieldArray pass in `${fieldName}[${idx}]`
  fieldName: string;
  // If the field is removable, render a close icon
  removable?: boolean;
  onRemove?: () => void;
  // Test id
  testId?: string;
  contextClientName?: string;
};

// eslint-disable-next-line max-lines-per-function
export function HeadlinerFields({
  onSelectHeadliner,
  fieldName,
  removable = false,
  onRemove,
  testId = '0',
  contextClientName = AegAggregator.GTAGG,
}: HeadlinerFieldsProps) {
  const appInsights = useAppInsightsContext();
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [agentFormError, setAgentFormError] = useState<AgentFormError | null>(null);
  const { setValue, getValues, trigger } = useFormContext();
  const { isSubmitted } = useFormState();
  const [agentFormMode, setAgentFormMode] = useState<AgentFormMode>(AgentFormMode.CREATE);
  const [agentFormData, setAgentFormData] = useState<AgentFormFields>(AGENT_FORM_DEFAULTS);

  const createNewAgent = { name: 'Create New Agent', id: 'create-agent' };
  const duplicateAgentError: AgentFormError = {
    field: 'email',
    message: 'An agent already exists with this email address.',
  };

  const { loading: agentsLoading, data: agentsData } = useQuery(SEARCH_AGENTS, {
    variables: { searchTerm: agentSearchTerm },
    errorPolicy: 'all',
    skip: agentSearchTerm.length === 0,
    context: { clientName: contextClientName },
  });

  const [
    createAgent,
    { loading: createMutationSaving, error: createMutationError },
  ] = useMutation(ADD_AGENT, {
    context: { clientName: contextClientName },
    errorPolicy: 'all',
    onCompleted(data) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currentAgents = (getValues(`${fieldName}.agents`) ?? []) as Agent[];
      const updatedAgents = currentAgents.concat([data.addAgent as Agent]);
      setValue(`${fieldName}.agents`, updatedAgents);
      setGenericModalOpen(false);
    },
    onError(e) {
      logError(appInsights, 'CreateAgentForm Error.', e);
      if ((e?.networkError as ServerError)?.statusCode === 409) {
        setAgentFormError(duplicateAgentError);
      } else {
        setIsFormErrorOpen(true);
      }
    },
  });

  const [
    modifyAgent,
    { loading: modifyMutationSaving, error: modifyMutationError },
  ] = useMutation(UPDATE_AGENT, {
    context: { clientName: contextClientName },
    errorPolicy: 'all',
    onCompleted(data) {
      const newAgent = data.modifyAgent as Agent;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const currentAgents = (getValues(`${fieldName}.agents`) ?? []) as Agent[];
      const newAgents = currentAgents.filter((agent) => agent.id !== newAgent.id).concat([newAgent]);
      setValue(`${fieldName}.agents`, newAgents);
      setGenericModalOpen(false);
    },
    onError(e) {
      logError(appInsights, 'ModifyAgentForm Error.', e);
      if ((e?.networkError as ServerError).statusCode === 409) {
        setAgentFormError(duplicateAgentError);
      } else {
        setIsFormErrorOpen(true);
      }
    },
  });

  // handles deleted phone numbers
  const cleanAgent = (agent: AddAgentInput | ModifyAgentInput) => ({
    ...agent,
    phone: agent?.phone?.length ? agent.phone : undefined,
  });

  const handleAddAgent = useCallback(
    async (agent: AddAgentInput) => {
      setAgentFormError(null);
      await createAgent({
        variables: {
          agent: cleanAgent(agent),
        },
      });
    },
    [createAgent],
  );

  const handleModifyAgent = useCallback(
    async (agent: ModifyAgentInput) => {
      setAgentFormError(null);
      await modifyAgent({
        variables: {
          agent: {
            ...cleanAgent(agent),
            id: agent.id, // to keep TypeScript happy
          },
        },
      });
    },
    [modifyAgent],
  );

  const isCreateMode = agentFormMode === AgentFormMode.CREATE;

  const closeAgentForm = () => {
    setGenericModalOpen(false);
  };

  const clickEditAgent = (agent: Agent) => {
    setAgentFormMode(AgentFormMode.EDIT);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAgentFormData(removeTypenamesAndErrors(agent));
    setAgentFormError(null);
    setGenericModalOpen(true);
  };

  const renderAgentOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
  ) => {
    if (option.id === 'create-agent') {
      return (
        <li key={option.name}>
          <CreateNewAgentBtn
            data-testid='create-new-agent-btn'
            onClick={() => {
              setAgentFormMode(AgentFormMode.CREATE);
              setAgentFormData(AGENT_FORM_DEFAULTS);
              setGenericModalOpen(true);
            }}
          >
            {CREATE_AGENT_TEXT.title}
          </CreateNewAgentBtn>
        </li>
      );
    }

    return (
      <li {...props} key={option.name}>
        <PrimaryText>{option.name}</PrimaryText>
      </li>
    );
  };

  const reValidateAgencyField = () => {
    // This ensures re-validation runs after a form has been submitted at least once
    if (isSubmitted) {
      void trigger();
    }
  };

  const onHeadlinerChange = (headliner: Artist) => {
    onSelectHeadliner(headliner);
    // This ensures re-validation runs after a form has been submitted at least once
    if (isSubmitted) {
      void trigger();
    }
  };

  return (
    <>
      <HeadlinerContainer>
        <AutocompleteContainer>
          <FormSearchAutocomplete
            fieldName={`${fieldName}.headliner`}
            query={SEARCH_ARTISTS_WITH_SUB_GENRES}
            getOptions={(data) => data.artists as Artist[] ?? []}
            getOptionLabel={(artist: Artist) => artist?.name ?? ''}
            placeholder={'Enter Headliner or Artist Name'}
            label='Headliner'
            isRequired
            formStylingMode={FormStylingMode.COMPACT}
            clearOnBlur
            onSelect={onHeadlinerChange}
            testId={`headliner-autocomplete-${testId}`}
            inputTestId={`headliner-autocomplete-input-${testId}`}
          />
        </AutocompleteContainer>
{removable && (
  <div>
    <CloseIconStyle
      onClick={() => typeof onRemove === 'function' && onRemove()}
      data-testid={`close-autocomplete-headliner-form-${testId}`}
    />
  </div>
)}
      </HeadlinerContainer>
      <FieldsContainer data-testid={`bundles-fields-container-${testId}`} formStylingMode={FormStylingMode.COMPACT}>
        <VerticalSpacer />
          <FormSearchAutocomplete
            fieldName={`${fieldName}.agency`}
            query={SEARCH_AGENCIES}
            getOptions={(data) => data.agencies as Agency[] ?? []}
            getOptionLabel={(agency) => (agency as Agency)?.name ?? ''}
            placeholder={'Enter Agency Name'}
            label='Agency'
            isRequired
            formStylingMode={FormStylingMode.COMPACT}
            clearOnBlur
            testId={`agency-search-autocomplete-${testId}`}
            inputTestId={`agency-autocomplete-input-${testId}`}
            onSelect={reValidateAgencyField}
          />
          <FormAutocompleteWrapper
            fieldName={`${fieldName}.agents`}
            options={!agentsLoading && agentsData?.agents ? agentsData.agents : []}
            label='Agent(s)'
            onInputChange={setAgentSearchTerm}
            testId={`agents-search-autocomplete-${testId}`}
            getOptionLabel={(agent: Agent) => agent.name ?? ''}
            placeholder='Type in Agent’s name or email address'
            renderTags={(tagValue, getTagProps) => (tagValue as Agent[]).map((option, index) => (
              <AgentChip
                {...getTagProps({ index })}
                name={option.name as string}
                onClick={() => clickEditAgent(option)}
              />
            ))}
            renderOption={renderAgentOption}
            persistentOption={createNewAgent}
            dataLoading={agentsLoading}
          />
      </FieldsContainer>
      {genericModalOpen && (
        <GenericModal
          open
          setOpen={setGenericModalOpen}
          title={isCreateMode ? CREATE_AGENT_TEXT.title : EDIT_AGENT_TEXT.title}
          mode={FormStylingMode.COMPACT}
          errorComponent={
            <FormErrorComponent
              isDismissable={true}
              error={isCreateMode ? createMutationError : modifyMutationError}
              isOpen={isFormErrorOpen}
              setIsOpen={setIsFormErrorOpen}
            />
          }
        >
          <AgentForm
            onFormSubmit={isCreateMode ? handleAddAgent : handleModifyAgent}
            isFormSaving={isCreateMode ? createMutationSaving : modifyMutationSaving}
            handleClose={closeAgentForm}
            onFormSubmitNetworkError={agentFormError}
            setIsFormErrorOpen={setIsFormErrorOpen}
            agentFormMode={agentFormMode}
            defaultValues={agentFormData}
          />
        </GenericModal>
      )}
    </>
  );
}
