/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import {
  Divider, Grid, Stack, Tabs,
} from '@mui/material';
import { TabPanel, TabStyled } from '@components';
import { kebabCase } from '@utils/stringHelpers';
import {
  HeaderDetailRow,
  PageDetailsContentBody,
  PaperDetailsContentContainer,
  DivDetailsContentContainer,
  PageDetailsContentHeader,
  GridStyled,
  TabContainer,
} from './PageDetailLayout.styled';

export enum WrapperType {
  Paper,
  Div,
}

interface Tab {
  name: string;
  pageContentHeader?: React.ReactNode;
  pageContent: React.ReactNode;
  pageContentStyle?: React.CSSProperties;
  idPrefix: string;
  wrapperType?: WrapperType;
}

type PageDetailLayoutProps = {
  pageTitle: React.ReactNode;
  metadata?: React.ReactNode;
  subledger?: React.ReactNode;
  actions?: React.ReactNode;
  row2?: React.ReactNode;
  pageContentHeader?: React.ReactNode;
  pageContent?: React.ReactNode;
  pageContentStyle?: React.CSSProperties;
  wrapperType?: WrapperType;
  pageTitleColSize?: number;
  metaColSize?: number;
  actionsColSize?: number;
  tabs?: Tab[];
};

interface PageDetailContentProps {
  pageContent: React.ReactNode;
  pageContentHeader?: React.ReactNode;
  pageContentStyle?: React.CSSProperties;
  wrapperType?: WrapperType;
}

const PaperDetailContent: React.FC<PageDetailContentProps> = ({ pageContent, pageContentHeader, pageContentStyle }) => (
  <PaperDetailsContentContainer pageContentStyle={pageContentStyle}>
    {pageContentHeader && (
      <PageDetailsContentHeader>
        {pageContentHeader}
      </PageDetailsContentHeader>
    )}
    <PageDetailsContentBody pageContentStyle={pageContentStyle}>{pageContent}</PageDetailsContentBody>
  </PaperDetailsContentContainer>
);

const DivDetailContent: React.FC<PageDetailContentProps> = ({ pageContent, pageContentHeader, pageContentStyle }) => (
  <DivDetailsContentContainer pageContentStyle={pageContentStyle}>
    {pageContentHeader && (
      <PageDetailsContentHeader>
        {pageContentHeader}
      </PageDetailsContentHeader>
    )}
    <PageDetailsContentBody pageContentStyle={pageContentStyle}>{pageContent}</PageDetailsContentBody>
  </DivDetailsContentContainer>
);

const PageDetailContent: React.FC<PageDetailContentProps> = ({
  pageContent, pageContentHeader, pageContentStyle, wrapperType,
}) => {
  const DetailContent = wrapperType === WrapperType.Div ? DivDetailContent : PaperDetailContent;
  return <DetailContent pageContent={pageContent} pageContentHeader={pageContentHeader} pageContentStyle={pageContentStyle} />;
};

export const PageDetailLayout = (props: PageDetailLayoutProps) => {
  const [tabValue, setTabValue] = React.useState(0);

  const onTabSelect = (
    _: React.SyntheticEvent<Element, Event>,
    newVal: number,
  ) => {
    setTabValue(newVal);
  };

  const {
    pageTitle,
    metadata,
    subledger,
    actions,
    row2,
    pageContentHeader,
    pageContent,
    pageContentStyle,
    wrapperType = WrapperType.Paper,
    pageTitleColSize = 3,
    metaColSize = 7,
    actionsColSize = 2,
    tabs = [],
  } = props;

  return (
    <>
      <HeaderDetailRow container>
        <Grid item xs={pageTitleColSize}>
          {pageTitle}
        </Grid>
        {metaColSize > 0 && (
          <GridStyled
            spacing={5}
            item
            container
            xs={metaColSize}
          >
            {metadata}
            {subledger}
          </GridStyled>
        )}
        <Grid item xs={actionsColSize}>
          <Stack direction="row" justifyContent="end">
            {actions}
          </Stack>
        </Grid>
      </HeaderDetailRow>
      {row2 && (
        <>
          <Divider />
          <HeaderDetailRow container>{row2}</HeaderDetailRow>
        </>
      )}
      {tabs?.length ? (
        <TabContainer>
          <Tabs value={tabValue} onChange={onTabSelect} data-testid={`${tabs[0].idPrefix}-tabs`}>
            {tabs.map((tab) => (
              <TabStyled
                data-testid={`${kebabCase(tab.name)}-tab`}
                key={`${tab.name}-tab`}
                label={tab.name}
              />
            ))}
          </Tabs>
          {tabs.map((tab, index) => (
            <TabPanel
              value={tabValue}
              key={`${tab.name}-tab-panel`}
              index={index}
              idPrefix={tab.idPrefix}
              data-testid={`${tab.idPrefix}-tab-${kebabCase(tab.name)}`}
            >
              <PageDetailContent
                pageContent={tab.pageContent}
                pageContentHeader={tab.pageContentHeader}
                wrapperType={tab.wrapperType ?? WrapperType.Paper}
                pageContentStyle={tab.pageContentStyle}
              />
            </TabPanel>
          ))}
        </TabContainer>
      ) : (
        <PageDetailContent
          pageContent={pageContent}
          pageContentHeader={pageContentHeader}
          pageContentStyle={pageContentStyle}
          wrapperType={wrapperType}
        />
      )}
    </>
  );
};
