/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
  FC, useContext, useCallback, useMemo, useState,
  useEffect,
} from 'react';
import {
  Event, Show, Space,
  Venue,
} from '@gql/types/graphql';
import {
  DataGridProProps,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridActionsCellItem,
  GridRowId,
  GridRowParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { Add, InfoOutlined } from '@mui/icons-material';
import { Switch, Tooltip, Typography } from '@mui/material';
import { expandColumn, manageExpandedRows } from '@utils/datagridHelpers';
import { useAegModeling } from '@hooks';
import { DialogDispatchContext, NotificationDispatchContext } from '@providers';
import { EventStatus, SnackBarMessages } from '@types';
import { eventMarketName, getTimezoneDate } from '@utils/stringHelpers';
import { sortEventsByDateActive } from '@utils/eventHelpers';
import featureFlags from '@utils/featureFlags';
import { SnackbarType } from '@components';
import {
  AltRowColorGrid,
  CANCEL_EVENT_DIALOG,
  REMOVE_EVENT_DIALOG,
  FeatureGate,
  GenericModal,
  ShowTable,
  StatusChip,
  CONFIRM_UPDATE_VENUE_DIALOG,
  UPDATE_VENUE_MODAL,
} from '../../shared';
import { ShowsGridData } from '../../shared/ShowTable/ShowTable.types';
import {
  SwitchContainer,
  AddEventText,
  AddEventTab,
  ButtonStyled,
  VenueSwapStyled,
  SwapHorizStyled,
  MarketLink,
} from './RoutingTab.styled';
import { ModelingViewMode } from '..';
import { VenueSearch } from '../VenueSearch/VenueSearch';
import { VenueRowHandler } from '../VenueSearch/VenueSearch.types';
import { MarketSearch } from '../../SearchMarkets/SearchMarkets.models';

interface RoutingTabProps {
  handleTabChange: () => void,
  mode?: ModelingViewMode;
  events?: Event[];
  selectedEvents: GridRowId[]
  onSelectedEventsChange: (eventIds: GridRowId[]) => void;
  disableVirtualization?: boolean; // for testing
  gridApiRef: React.MutableRefObject<GridApiPro>,
}

type DetailPanel = NonNullable<DataGridProProps['getDetailPanelContent']>;

// eslint-disable-next-line max-lines-per-function
export const RoutingTab: FC<RoutingTabProps> = ({
  handleTabChange,
  mode = ModelingViewMode.Default,
  events,
  selectedEvents,
  onSelectedEventsChange,
  disableVirtualization = false,
  gridApiRef,
}) => {
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  const {
    activateEvent,
    selectEventWorksheet,
    cancelEventFromOffer,
    removeEventFromOffer,
    updateEventVenue,
  } = useAegModeling();
  const setDialog = useContext(DialogDispatchContext);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [eventVenueUpdateInfo, setEventVenueUpdateInfo] = useState<{ eventId: string; currentVenueName: string }>();
  const setNotification = useContext(NotificationDispatchContext);

  const [venueSearch, setVenueSearch] = useState<Venue | null>(null);
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const [marketSearch, setMarketSearch] = useState<MarketSearch | null>(null);

  const expandRowClick = (gridRowId: GridRowId) => {
    manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  };

  const handleRemoveEvent = (eventId: string, isActive: boolean) => {
    void removeEventFromOffer(eventId, isActive);
    setDialog(null);
  };

  const handleCancelEvent = (eventId: string) => {
    void cancelEventFromOffer(eventId);
    setDialog(null);
  };

  const handleCancelDialog = () => setDialog(null);

  const getDetailPanelContent = useCallback<DetailPanel>(({ row }) => (
    <ShowTable row={row} enableEditShow />
  ), []);

  useEffect(() => {
    if (!genericModalOpen) {
      setVenueSearch(null);
      setSelectedVenues([]);
      setMarketSearch(null);
    }
  }, [genericModalOpen]);

  /**
   * Determines if there is a validation message to display to the user for a given event.
   * If string is returned, event is invalid and should display an info tooltip, instead
   * of a checkbox when the user is selecting events.
   */
  const eventTooltipMessage = (event: Event): false | string => {
    if (mode === ModelingViewMode.SelectConfirmedEvents) {
      if (event.canConfirm?.result) {
        return false;
      }
      return event.canConfirm?.requirements?.join('\n') || '';
    }
    if (mode === ModelingViewMode.SelectRoutingConfirmedEvents) {
      if (event.canShareWithMarketing?.result) {
        return false;
      }
      return event.canShareWithMarketing?.requirements?.join('\n') || '';
    }
    return false;
  };

  const rows = useMemo((): ShowsGridData[] => {
    if (!events) {
      return [];
    }

    const sortedEvents = sortEventsByDateActive(events);

    return sortedEvents.map((event, i) => ({
      key: i,
      id: event?.id ?? '',
      active: event?.isActive ?? true,
      firstEventDate: event?.date ? getTimezoneDate(event?.date, event?.venue?.timezone)[0] : null,
      market: event ? eventMarketName(event) : '',
      shows: event?.shows as Show[] ?? [],
      space: event?.space as Space ?? null,
      status: event?.status as EventStatus ?? '',
      timezone: event?.venue?.timezone ?? null,
      venueName: event?.space?.name ?? '',
      invalidConfirmationMessage: eventTooltipMessage(event),
      canUpdateVenue: event?.canUpdateVenue ?? false,
    }));
  }, [mode, events]);

  // eslint-disable-next-line max-lines-per-function
  const generateActions = (params: ShowsGridData) => {
    const {
      id, status, active, canUpdateVenue, venueName,
    } = params;
    const actions = [];

    if (status === EventStatus.Confirmed) {
      actions.push(
        <GridActionsCellItem
          key={id}
          showInMenu
          label='Cancel Event'
          data-testid='cancel-event-button'
          onClick={() => {
            setDialog({
              titles: CANCEL_EVENT_DIALOG.TITLES,
              submit: {
                text: CANCEL_EVENT_DIALOG.SUBMIT,
                action: () => handleCancelEvent(id),
              },
              cancel: {
                text: CANCEL_EVENT_DIALOG.CANCEL,
                action: handleCancelDialog,
              },
            });
          }}
        />,
      );
    }

    if (status === EventStatus.Draft) {
      actions.push(
        <GridActionsCellItem
          key={id}
          showInMenu
          label='Remove Event'
          data-testid='remove-event-button'
          onClick={() => {
            setDialog({
              titles: REMOVE_EVENT_DIALOG.TITLES,
              submit: {
                text: REMOVE_EVENT_DIALOG.SUBMIT,
                action: () => handleRemoveEvent(id, active),
              },
              cancel: {
                text: REMOVE_EVENT_DIALOG.CANCEL,
                action: handleCancelDialog,
              },
            });
          }}
        />,
      );
      if (canUpdateVenue) {
        actions.push(
          FeatureGate({
            configFlag: featureFlags.CAN_UPDATE_VENUE,
            children: (
              <GridActionsCellItem
              key={id}
              showInMenu
              label='Update Venue'
              data-testid='update-venue-button'
              onClick={() => {
                setEventVenueUpdateInfo({ eventId: id, currentVenueName: venueName });
                setGenericModalOpen(true);
              }}
            />
            ),
          }) as React.ReactElement,
        );
      }
    }

    return actions;
  };

  const firstColumnConditionalCheckbox: GridColDef<ShowsGridData>[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        if (params.row.invalidConfirmationMessage) {
          return (
            <Tooltip title={params.row.invalidConfirmationMessage} placement='top-start'>
              <InfoOutlined data-testid="info_icon_invalid_event"/>
            </Tooltip>
          );
        }

        return GRID_CHECKBOX_SELECTION_COL_DEF.renderCell?.(params);
      },
    },
  ];

  const columns: GridColDef<ShowsGridData>[] = [
    ...expandColumn(expandedRows, expandRowClick),
    ...((mode === ModelingViewMode.SelectConfirmedEvents
      || mode === ModelingViewMode.SelectRoutingConfirmedEvents) ? firstColumnConditionalCheckbox : []),
    {
      field: 'venueName',
      headerName: 'Venue',
      width: 160,
      renderCell: (params) => (
        <Typography>{params.row.venueName}</Typography>
      ),
    },
    {
      field: 'market',
      headerName: 'Market',
      renderCell: (params) => (
        <MarketLink onClick={() => {
          void selectEventWorksheet(params.row.id);
        }}>
          {params.row.market}
        </MarketLink>
      ),
    },
    {
      field: 'shows',
      headerName: 'Show(s)',
      renderCell: (params) => (
        <Typography>{params.row.shows.length}</Typography>
      ),
    },
    {
      field: 'firstEventDate',
      headerName: 'First Event Date',
      renderCell: (params) => (
        <Typography>
          {params.row.firstEventDate}
        </Typography>
      ),
    },
    {
      field: 'active',
      headerName: 'Active',
      renderCell: (params) => (
        <SwitchContainer>
          <Switch
            checked={params.row.active}
            onChange={(e) => {
              void activateEvent(params.row.id, e.target.checked);
            }}
            disabled={params.row.status !== EventStatus.Draft
              && params.row.status !== EventStatus.RoutingConfirmed
              && params.row.status !== EventStatus.Canceled
              && params.row.status !== EventStatus.CanceledSettled}
          />
        </SwitchContainer>
      ),
    },
    {
      field: 'status',
      headerName: 'Event Status',
      renderCell: (params) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        params.row.status.length ? <StatusChip status={params.row.status as string} />
          : <Typography>Not Available</Typography>
      ),
    },
    {
      field: 'moreActions',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: (params) => generateActions(params.row),
    },
  ];

  /**
   * Determines if a row is enabled/disabled based on the current view mode and
   * event properties like status and whether it's active. Does not affect
   * whether a row is preselected (i.e. pre-checked).
   */
  const isRowSelectable = useCallback((params: GridRowParams<ShowsGridData>) => {
    // if there is an invalid confirmation message or the event is inactive, the event is not selectable
    if (params.row.invalidConfirmationMessage || !params.row.active) {
      return false;
    }

    if (mode === ModelingViewMode.SelectRoutingConfirmedEvents) {
      // Only events in Draft status are enabled
      if (params.row.status !== EventStatus.Draft) {
        return false;
      }
    }

    if (mode === ModelingViewMode.SelectConfirmedEvents) {
      // Only events in Draft and RoutingConfirmed status are enabled
      if (params.row.status !== EventStatus.Draft && params.row.status !== EventStatus.RoutingConfirmed) {
        return false;
      }
    }

    return true;
  }, [mode]);

  const handleUpdateEventVenue: VenueRowHandler = ({ row }) => {
    if (!eventVenueUpdateInfo) return;

    const {
      TITLES, DESCRIPTION, SUBMIT, CANCEL,
    } = CONFIRM_UPDATE_VENUE_DIALOG;

    const newVenue = {
      venueId: row.venueId as string,
      spaceId: row.id as string,
      name: row.market,
    };

    setDialog({
      titles: TITLES,
      description: (
        <>
          <VenueSwapStyled>{row.venueName} <SwapHorizStyled /> {eventVenueUpdateInfo.currentVenueName}</VenueSwapStyled>
          {DESCRIPTION}
        </>
      ),
      submit: {
        text: SUBMIT,
        action: () => {
          void (async () => {
            try {
              await updateEventVenue(eventVenueUpdateInfo.eventId, newVenue);

              setDialog(null);
              setGenericModalOpen(false);
              setNotification({
                type: SnackbarType.SUCCESS,
                text: SnackBarMessages.VenueUpdateSaved,
                duration: 6000,
              });
            } catch (e) {
              setDialog(null);
              setNotification({
                type: SnackbarType.ERROR,
                text: SnackBarMessages.VenueUpdateFailed,
                duration: 6000,
              });
            }
          })();
        },
      },
      cancel: {
        text: CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  return events?.length
    ? (
      <>
        <AltRowColorGrid
          apiRef={gridApiRef}
          onRowSelectionModelChange={onSelectedEventsChange}
          rowSelectionModel={selectedEvents}
          hideFooter
          columns={columns}
          rows={rows}
          disableVirtualization={disableVirtualization}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          disableRowSelectionOnClick
          data-testid='routing-tab-grid'
          isRowSelectable={isRowSelectable}
          checkboxSelection={(
            mode === ModelingViewMode.SelectConfirmedEvents
            || mode === ModelingViewMode.SelectRoutingConfirmedEvents
          )}
          initialState={{
            pinnedColumns: {
              right: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'moreActions'],
            },
          }}
        />
      {genericModalOpen ? (
        <GenericModal
          open
          setOpen={setGenericModalOpen}
          title={UPDATE_VENUE_MODAL.TITLE}
          description={UPDATE_VENUE_MODAL.DESCRIPTION}
        >
          <VenueSearch
            onVenueSelect={handleUpdateEventVenue}
            venueSelectLabel='Select'
            disableMarketSearch
            venueSearchData={{
              selectedVenues,
              setSelectedVenues,
              venueSearch,
              setVenueSearch,
            }}
          />
        </GenericModal>
      ) : null}
      </>
    ) : (
      <AddEventTab>
        <AddEventText>
          Add events to create routing for this offer
        </AddEventText>
        <ButtonStyled
          data-testid="add-event-button"
          variant="contained"
          startIcon={<Add />}
          onClick={handleTabChange}
        >
          Add Event
        </ButtonStyled>
      </AddEventTab>
    );
};
