/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import { AegAutocomplete, DisplayFields, InputContainer } from '@components';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { useQuery } from '@apollo/client';
import { Venue } from '@gql/types/graphql';
import { flattenVenues } from '@utils/venueHelpers';
import { TypenameResponse } from '@types';
import { SearchContainer } from './SearchBar.styled';
import { SearchBarProps } from './SearchBar.models';
import { handleSecondaryField } from './SearchBar.utils';

export function SearchBar<T extends TypenameResponse, K>({ search }: SearchBarProps<T, K>) {
  const {
    query,
    displayFields,
    placeholder,
    onSelect,
    resultKey,
    minimumCharacters = 0,
    autoSuggestHighlight = false,
    shouldFlattenVenues = false,
    freeSolo = true,
    defaultValue: initialDefaultValue,
  } = search;

  const defaultSearchTerm = () => {
    if (!initialDefaultValue) {
      return '';
    }
    const primaryText = initialDefaultValue[displayFields.primary] as string;
    if (displayFields.secondary) {
      if (typeof displayFields.secondary === 'function') {
        const props = displayFields.secondary(initialDefaultValue);
        const secondaryText = handleSecondaryField(initialDefaultValue, props);
        return `${primaryText} - ${secondaryText}`;
      }
      const secondaryText = handleSecondaryField(initialDefaultValue, displayFields.secondary);
      return `${primaryText} - ${secondaryText}`;
    }
    return `${primaryText}`;
  };

  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm());

  const { data: searchResponse, loading: searchLoading } = useQuery(query, {
    variables: { searchTerm },
    errorPolicy: 'all',
    skip: searchTerm.length < minimumCharacters,
  });

  let options: Venue[] | T[keyof T] = [];
  if (searchResponse) {
    options = resultKey === 'venues' && shouldFlattenVenues
      ? flattenVenues(searchResponse[resultKey] as Venue[])
      : searchResponse[resultKey];
  }

  useEffect(() => {
    if (initialDefaultValue && onSelect) {
      onSelect(initialDefaultValue);
    }
  }, []);

  return (
    <SearchContainer>
      <AegAutocomplete
        testId='search-autocomplete'
        options={options as K[]}
        areOptionsLoading={searchLoading}
        dataDisplayField={displayFields as DisplayFields}
        onInputChange={setSearchTerm}
        placeholderText={placeholder}
        minimumCharacters={minimumCharacters}
        autoSuggestHighlight={autoSuggestHighlight}
        InputComponent={InputContainer}
        defaultValue={initialDefaultValue}
        value={searchTerm}
        freeSolo={freeSolo}
        onSelect={(...params) => {
          if (onSelect) {
            onSelect(...params, options);
          }
        }}
        inputAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </SearchContainer>
  );
}
