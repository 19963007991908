import { styled } from '@mui/material/styles';

export const SelectContainer = styled('div')({
  width: '100%',
});

export const SelectLabelContainer = styled('label')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SelectControlContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  'div.alternate': {
    paddingLeft: theme.spacing(0),
  },
}));

export const SelectItemIconContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  verticalAlign: '-0.4em',
  marginRight: theme.spacing(1),
  color: theme.palette.warning.light,
}));

export const SelectItemContainer = styled('div')(({ theme }) => ({
  '&.alternate': {
    paddingLeft: theme.spacing(4),
  },
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
}));
