import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const SubmitButtonStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'none',
  marginLeft: 'auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
