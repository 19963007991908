import { graphql } from '@gql/types';

export const ADD_EVENT_BOX_OFFICE = graphql(`
  mutation AddBoxOffice($boxOffice: AddBoxOfficeInput!) {
    addBoxOffice(boxOffice: $boxOffice) {
      id
      phases {
        id
        items {
          projectedSales
          projectedSalesPercent
          name
          capacity
          scalePercentage
          price
          kills
          comps
          sellableCapacity
          grossPotential
          totalPreTaxFees
          totalPostTaxFees
          totalTax
          netPrice
          netGrossPotential
          fees {
            profitLossCategoryId
            amount
            formula
            notes
          }
          deadwood
        }
        fees {
          profitLossCategoryId
          amount
          formula
          notes
        }
        percentSold
      }
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const ADD_SHOW_TO_EVENT = graphql(`
  mutation AddShowToEvent($eventId: ID!, $show: ShowInput!) {
    addShowToEvent(eventId: $eventId, show: $show) {
      id
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const MODIFY_SHOW = graphql(`
  mutation ModifyShow($eventId: ID!, $show: ModifyShowInput!) {
    modifyShow(eventId: $eventId, show: $show) {
      id
      name
      showDateTime
    }
  }
`);

export const CLONE_EVENT = graphql(`
  mutation CloneEvent($tourId: ID!, $offerId: ID!, $eventId: ID!) {
    cloneEvent(tourId: $tourId, offerId: $offerId, eventId: $eventId) {
      id
      name
      status
      sellableCapacity
      exchangeRate
      baseCurrency
      date
      compBreakdown
      merchandiseDeal
      notesAndComments
      preparedBy
      preparedOn
      dealLanguage
      sellableCapacityFromConfig
      stagehandsPerTruckFromConfig
      breakEvenCalculations{
        breakEvenAvgOther
        breakEvenSeqOther
      }
      expense {
        id
        phases(id: "modeling") {
          id
          fixedCategoryItems {
            categoryId
            items {
              id
              notes
              budget
            }
          }
          variableCategoryItems {
            categoryId
            items {
              id
              rate
              minimum
              maximum
            }
          }
          ancillaryCategoryItems {
            categoryId
            items {
              id
              calcType
              notes
              coPro1
              coPro2
              amount
              projected
              grossPotential
            }
          }
          sellOffCalculations {
            guarantee
            versusPercent
            incomePoolPercent
          }
          coProCalculations {
            name
            percentage {
              potential
            }
          }
        }
      }
      defaultBoxOffice {
        id
        phases {
          id
          percentSold
          fees {
            profitLossCategoryId
            amount
            formula
            notes
          }
          items {
            name
            capacity
            price
            kills
            comps
          }
        }
      }
      venue {
        id
        name
        city
        stateCode
        country
        localCurrencyCode
      }
      space {
        id
        name
      }
      shows {
        id
        name
      }
      headliner {
        id
        name
      }
      artistOffer {
        artistAdjustment
        artistAdjustmentNotes
        guarantee
        percentNetPoolEarnings
      }
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const UPDATE_EVENT = graphql(`
  mutation ModifyEvent($event: ModifyEventInput!) {
    modifyEvent(event: $event) {
      id
      isActive
    }
  }
`);

export const UPDATE_EVENT_VENUE = graphql(`
  mutation UpdateEventVenue($eventId: ID!, $venue: UpdateEventVenueInput!) {
    updateEventVenue(eventId: $eventId, venue: $venue) {
      id
      name
      space {
        id
        name
        description
      }
      venue {
        id
        name
        city
        cityCode
        state
        stateCode
        country
        timezone
        localCurrencyCode
        metroArea
      }
    }
  }
`);

export const MODIFY_BOX_OFFICE_PHASE = graphql(`
  mutation ModifyBoxOfficePhase(
    $boxOfficeId: ID!
    $boxOfficePhaseId: ID!
    $boxOfficePhase: ModifyBoxOfficePhaseInput!
  ) {
    addOrUpdateBoxOfficePhase(
      boxOfficeId: $boxOfficeId
      boxOfficePhaseId: $boxOfficePhaseId
      boxOfficePhase: $boxOfficePhase
    ) {
      id
      items {
        projectedSales
        projectedSalesPercent
        name
        capacity
        scalePercentage
        price
        kills
        comps
        sellableCapacity
        gross
        grossPotential
        totalPreTaxFees
        totalPostTaxFees
        totalTax
        netPrice
        netGrossPotential
        fees {
          profitLossCategoryId
          amount
          formula
          notes
        }
        deadwood
      }
      fees {
        profitLossCategoryId
        amount
        formula
        notes
      }
      percentSold
    }
  }
`);

// IMPORTANT: All code locations that use this mutation must
// use useMaestro hook and call the handleShareWithMaestro
// method.
export const REMOVE_SHOW_FROM_EVENT = graphql(`
  mutation RemoveShowFromEvent($eventId: ID!, $showId: ID!) {
    removeShowFromEvent(eventId: $eventId, showId: $showId) {
      id
      name
      shows {
        id
        name
      }
    }
  }
`);

export const RETRY_JDE_EVENT_SUBLEDGER = graphql(`
  mutation RetryJdeEventSubledger($eventId: ID!) {
    retryJdeEventSubledger(eventId: $eventId) {
      id
      baseCurrency
      cancelDate
      cancelReason
      canceledByUser
      compBreakdown
      configuration
      copromoters
      date
      dealLanguage
      exchangeRate
      isActive
      localCurrency
      merchandiseDeal
      name
      notesAndComments
      preparedBy
      preparedOn
      sellableCapacity
      status
      subledgerId
      subledgerPayload
      subledgerStatus
      type
    }
  }
`);

export const SUBMIT_NOS_SETTLEMENT = graphql(`
  mutation SettleNosSettlementEvent($nosSettlementEvent: SettleNosSettlementInput!) {
    settleNosSettlementEvent(settleNosSettlementEvent: $nosSettlementEvent) {
      id
    }
  }
`);

export const FINALIZE_NOS_SETTLEMENT = graphql(`
  mutation SettleAccountingSettlementEvent($accountingSettlementEvent: SettleAccountingSettlementInput!) {
    settleAccountingSettlementEvent(settleAccountingSettlementEvent: $accountingSettlementEvent) {
      id
    }
  }
`);
