import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const SubHeader = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
})) as typeof Typography;

export const Form = styled('form')({});

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});
