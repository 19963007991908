import {
  Dispatch, PropsWithChildren, SetStateAction, createContext, useMemo,
} from 'react';

type SideNavFlyoutState = {
  sideNavOpenFlyoutId: string | null;
  setSideNavOpenFlyoutId: Dispatch<SetStateAction<string | null>>;
};

export const SideNavFlyoutDispatchContext = createContext<SideNavFlyoutState>({
  sideNavOpenFlyoutId: null,
  setSideNavOpenFlyoutId: () => {},
});

export const SideNavFlyoutProvider = ({
  children,
  sideNavOpenFlyoutId,
  setSideNavOpenFlyoutId,
}: PropsWithChildren<SideNavFlyoutState>) => {
  const flyoutState = useMemo<SideNavFlyoutState>(() => ({
    sideNavOpenFlyoutId,
    setSideNavOpenFlyoutId,
  }), [sideNavOpenFlyoutId, setSideNavOpenFlyoutId]);

  return (
    <SideNavFlyoutDispatchContext.Provider value={flyoutState}>
      {children}
    </SideNavFlyoutDispatchContext.Provider>
  );
};
