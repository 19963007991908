import { string, object } from 'yup';

import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

export const editParentTourFormValidationSchema = object({
  name: string()
    .trim()
    .required('Please enter a tour name.')
    .matches(strWithoutSpecialCharsRegex, 'Some special characters are not allowed'),
});
