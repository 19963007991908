export const UPDATE_VENUE_MODAL = {
  TITLE: 'Update Event',
  DESCRIPTION: 'Search and select a venue to update your workbook.',
  SUBMIT: 'Yes, update',
  CANCEL: 'Back to Search',
};

export const CONFIRM_UPDATE_VENUE_DIALOG = {
  TITLES: {
    dialogTitle: 'Are you sure you want to update your event?',
    alertTitle: 'Other venue data (e.g., Configurations, Taxes, Fees, Rent) will need to be manually updated.',
  },
  DESCRIPTION: 'Selecting a new venue will update Venue, Market, and Local Currency data in the market sheet.',
  SUBMIT: 'Yes, update',
  CANCEL: 'Back to Search',
};
