export enum OfferStatus {
  // shared with EventStatus
  Draft = 'Draft',
  PendingApproval = 'Pending Approval',
  Approved = 'Approved',
  RoutingConfirmed = 'Routing Confirmed',
  OfferAccepted = 'Offer Accepted',
  SentToAgent = 'Sent to Agent',
  Confirmed = 'Confirmed',
  AccountingSettled = 'Accounting Settled',
  Canceled = 'Canceled',
  // unique to Offer
  Dead = 'Dead',
  OnTour = 'On Tour',
  SettlementInProgress = 'Settlement in Progress',
}

export enum OfferStatusFilters {
  Draft = 'Draft',
  RoutingConfirmed = 'Routing Confirmed',
  Confirmed = 'Confirmed',
  AccountingSettled = 'Accounting Settled',
  Canceled = 'Canceled',
  OnTour = 'On Tour',
  SettlementInProgress = 'Settlement in Progress',
}
