import {
  BoxOfficeItem,
  FixedCost,
  TierScaling,
  VariableCost,
  AncillaryCost,
  TourFinancialSummary,
} from '@gql/types/graphql';
import { NamedRangeAssignment, TableDataArray } from '@hooks';
import { CalcType } from '@types';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { logWarning } from '@services/telemetry-service';
import {
  ANCILLARY_EARNINGS,
  ANCILLARY_EARNINGS_PROJECTED,
  BOX_OFFICE_SUMMARY,
  FIXED_COSTS_TBL_FORMULAS,
  MINIMUM_BOX_OFFICE_ROWS,
  NAMED_RANGES,
  SAFE_SHEET_NAME_KEY,
  VARIABLE_COSTS_TBL_FORMULAS,
  SHOW_DETAIL_SHEET_NAME,
  SUMMARY_SHEET_NAME,
  SUMMARY_UNCROSSED_SHEET_NAME,
  OVERHEAD_COSTS_SHEET_NAME,
  QUICK_SUM_SHEET_NAME,
  LATERAL_SUM_SHEET_NAME,
  APPROVAL_EMAIL_SHEET_NAME,
  APPROVAL_REVISED_SHEET_NAME,
  OVERHEAD_COSTS_SHEET_TABLES,
  createShowDetailRowFormulas,
} from './constants';
import { AncillarySheetValue, formatCurrencyCell, getSafeMarketName } from '../shared/helpers';
import { FixedCostParsedTableData, VariableCostRow } from './tableShapes';
import { EventSheet } from '../shared/types';

const { SUMMARY } = NAMED_RANGES;

/**
 * Shape ancillary items data to fit excel table population format
 * @param ancillaryItems event's expense ancillary items
 * @param ancillaryItemsIdsMap mapper for ancillary item ids to names
 * @returns Excel ready table data array
 */
export const generateAncillaryItemsTableData = (
  ancillaryItems: AncillaryCost[],
  ancillaryItemsIdsMap: { id: string; name: string }[],
  numeratedEventMarketName: string,
  templateAncillaryValues: AncillarySheetValue[],
) => {
  const tableData: TableDataArray = [];
  const safeMarketName = getSafeMarketName(numeratedEventMarketName);
  const projectedFormula = ANCILLARY_EARNINGS_PROJECTED(safeMarketName);

  ancillaryItemsIdsMap.forEach((profitLossItem) => {
    const profitLossId = profitLossItem?.id;
    if (profitLossId) {
      const templateAncillaryValue = templateAncillaryValues?.find(
        (item) => item['Ancillary Earnings'] === profitLossItem.name,
      );

      const ancillaryItem = ancillaryItems.find(
        (item) => item.id === profitLossId,
      ) || {};

      const name = profitLossItem.name || '';

      let calcType = ancillaryItem?.calcType ?? '';
      if (!calcType && templateAncillaryValue) {
        calcType = templateAncillaryValue['Calc Type'] as CalcType;
      }

      const returnItem = [
        name,
        calcType,
        ancillaryItem?.notes || '',
        ANCILLARY_EARNINGS.CO_PRO_ONE,
        ANCILLARY_EARNINGS.CO_PRO_TWO,
        ancillaryItem?.amount || '',
        projectedFormula,
        ANCILLARY_EARNINGS.GROSS_POTENTIAL,
        ANCILLARY_EARNINGS.PER_TICKET_TO_BASE,
        ANCILLARY_EARNINGS.PROJ_TO_BASE,
        ANCILLARY_EARNINGS.POT_TO_BASE,
      ];
      tableData.push(returnItem);
    }
  });

  return tableData;
};

export const generateBoxOfficeSummaryTableData = (
  boxOfficeItems: Array<BoxOfficeItem | TierScaling>,
): TableDataArray => {
  const mappedBoxOfficeItems = boxOfficeItems.map((boxOfficeItem) => [
    boxOfficeItem.name || '',
    boxOfficeItem.capacity ?? '',
    'kills' in boxOfficeItem ? boxOfficeItem.kills ?? '' : '',
    BOX_OFFICE_SUMMARY.AVAILABLE_CAPACITY,
    'comps' in boxOfficeItem ? boxOfficeItem.comps ?? '' : '',
    BOX_OFFICE_SUMMARY.SELLABLE,
    'price' in boxOfficeItem ? boxOfficeItem.price ?? '' : '',
    BOX_OFFICE_SUMMARY.PRICE_TO_BASE,
    BOX_OFFICE_SUMMARY.GROSS,
    BOX_OFFICE_SUMMARY.GROSS_TO_BASE,
  ]);

  while (mappedBoxOfficeItems.length < MINIMUM_BOX_OFFICE_ROWS) {
    mappedBoxOfficeItems.push([
      '', // boxOfficeItem.name
      '', // boxOfficeItem.capacity
      '', // boxOfficeItem.kills
      BOX_OFFICE_SUMMARY.AVAILABLE_CAPACITY,
      '', // boxOfficeItem.comps
      BOX_OFFICE_SUMMARY.SELLABLE,
      '', // boxOfficeItem.price
      BOX_OFFICE_SUMMARY.PRICE_TO_BASE,
      BOX_OFFICE_SUMMARY.GROSS,
      BOX_OFFICE_SUMMARY.GROSS_TO_BASE,
    ]);
  }

  return mappedBoxOfficeItems;
};

type VariableCostWorkbookSheetValues = Pick<VariableCost, 'id' | 'minimum' | 'maximum' | 'notes' | 'rate'>;
interface MappedVariableCostValues extends Pick<VariableCost, 'id' | 'minimum' | 'maximum' | 'notes'> {
  name: string;
  rate: number | string;
}
/**
 * Convert Variable costs to include the name and rate from reference data.
 */
export const mapVariableCostNamesAndRates = (
  variableCosts: VariableCostWorkbookSheetValues[],
  variableCostIdsMap: { id: string; name: string }[],
): MappedVariableCostValues[] => variableCosts.reduce<MappedVariableCostValues[]>((result, cost) => {
  const itemId = cost?.id;
  if (!itemId) {
    return result;
  }
  const nameIdMapForItem = variableCostIdsMap?.find((mapElement) => mapElement.id === itemId);
  const name = nameIdMapForItem?.name;
  if (!name) {
    return result;
  }
  // use item rate if it exists, otherwise use the formula. 0 is a valid value.
  const rate = cost?.rate ?? VARIABLE_COSTS_TBL_FORMULAS.RATE;
  result.push({
    ...cost,
    name,
    rate,
  });
  return result;
}, []);

/**
 * Map variable cost data to excel table population format
 * @param item variable cost item in variable state shapes
 * @returns Excel ready table data array
 */
const variableCostTableDataMapper = (
  item: VariableCostRow | MappedVariableCostValues,
  isDefaultRow?: boolean,
) => {
  // rate should not be empty.
  const itemRate = item.rate === '' ? VARIABLE_COSTS_TBL_FORMULAS.RATE : item.rate;
  // default rows should always have formula unless a rate is defined.
  const rate = isDefaultRow ? VARIABLE_COSTS_TBL_FORMULAS.RATE : (itemRate ?? VARIABLE_COSTS_TBL_FORMULAS.RATE);

  return [
    item.name || '',
    VARIABLE_COSTS_TBL_FORMULAS.CALCULATED_COST,
    // rates of 0 are allowed.
    rate,
    item.minimum?.toString() || '',
    item.maximum?.toString() || '',
    item.notes || '',
    VARIABLE_COSTS_TBL_FORMULAS.COST_TO_BASE,
    VARIABLE_COSTS_TBL_FORMULAS.BLANK_COLUMN_0,
    VARIABLE_COSTS_TBL_FORMULAS.BLANK_COLUMN_1,
    VARIABLE_COSTS_TBL_FORMULAS.BLANK_COLUMN_2,
  ];
};

/**
 * Shape variable cost data to fit excel table population format
 * @param variableCosts event's expense variable costs
 * @param variableCostIdsMap mapper for variable cost ids to names
 * @param ratesIdsMap mapper for default rates if item lacks a rate
 * @returns Excel ready table data array
 */
// eslint-disable-next-line max-lines-per-function
export const generateVariableCostTableData = (
  variableCosts: VariableCost[],
  variableCostIdsMap: { id: string; name: string }[],
  currentTableData: VariableCostRow[],
) => {
  const tableData: TableDataArray = [];

  const variableCostsWithNamesAndRates = mapVariableCostNamesAndRates(variableCosts, variableCostIdsMap);

  currentTableData.forEach((item, idx) => {
    let variableItemIndex: number | undefined;
    const matchingVariableCost = variableCostsWithNamesAndRates.find((variableCost, index) => {
      if (variableCost.name === item.name) {
        // capture index if we have a match.
        variableItemIndex = index;
        return true;
      }
      return false;
    });
    // with a match we use the variable cost data.
    if (matchingVariableCost) {
      if (variableItemIndex !== undefined) {
        // remove matched item from list.
        variableCostsWithNamesAndRates.splice(variableItemIndex, 1);
      }
      tableData.push(variableCostTableDataMapper(matchingVariableCost));
      return;
    }
    // if we don't have a matching variable cost return the default item.
    // first default table row should use parsed value from table.
    // if first row has formula for rate column, Excel auto populates all rows with formula.
    tableData.push(variableCostTableDataMapper(item, idx !== 0));
  });

  // Matches have been removed, but if we still have costs, add them to the list.
  variableCostsWithNamesAndRates.forEach((cost) => tableData.push(variableCostTableDataMapper(cost)));

  return tableData;
};

type FixedCostWorkbookSheetValues = Pick<FixedCost, 'budget' | 'id' | 'notes'>;
interface MappedFixedCostValues extends FixedCostWorkbookSheetValues {
  name: string
}

/**
 * Convert the fixed cost IDs to stringed names from reference data.
 */
export const mapFixedCostName = (
  fixedCosts: FixedCostWorkbookSheetValues[],
  fixedCostIdMap: { id: string; name: string }[],
): MappedFixedCostValues[] => fixedCosts.map((fixedCost) => {
  const nameIdMapForItem = fixedCostIdMap.find(
    (mapElement) => mapElement.id === fixedCost.id,
  );
  const expenseName = nameIdMapForItem?.name || '';
  return {
    ...fixedCost,
    name: expenseName,
  };
  // remove any fixed costs that don't have a matching ref name.
  // if GTAGG resolved fixed cost names, this can be removed.
}).filter((fixedCost) => fixedCost.name);

/**
 * Shape variable cost data to fit excel table population format
 * @param fixedCosts event's expense fixed costs
 * @param fixedCostIdsMap mapper for variable cost ids to names
 * @returns Excel ready table data array
 */
export const generateFixedCostTableData = (
  fixedCosts: FixedCostWorkbookSheetValues[],
  fixedCostIdsMap: { id: string; name: string }[],
  currentData: FixedCostParsedTableData[],
) => {
  const tableData: TableDataArray = [];
  const fixedCostsWithNames = mapFixedCostName(fixedCosts, fixedCostIdsMap);

  // start from existing data
  currentData.forEach((item) => {
    let fixedItemIndex: number | undefined;
    const matchingFixedCost = fixedCostsWithNames.find((fixedCost, index) => {
      if (fixedCost.name === item.name) {
        // capture index if we have a match.
        fixedItemIndex = index;
        return true;
      }
      return false;
    });
    // with a match we use the fixed cost data.
    if (matchingFixedCost) {
      if (fixedItemIndex !== undefined) {
        // remove matched item from list.
        fixedCostsWithNames.splice(fixedItemIndex, 1);
      }
      const returnItem = [
        matchingFixedCost.name,
        matchingFixedCost.budget || '',
        matchingFixedCost.notes || '',
        FIXED_COSTS_TBL_FORMULAS.BUDGET_TO_BASE,
      ];
      tableData.push(returnItem);
      return;
    }
    // if we don't have a matching fixed cost return the default.
    const defaultReturnItem = [
      item.name,
      item.budget || '',
      item.notes || '',
      FIXED_COSTS_TBL_FORMULAS.BUDGET_TO_BASE,
    ];
    tableData.push(defaultReturnItem);
  });

  // Matches have been removed, but if we still have costs, add them to the list.
  if (fixedCostsWithNames.length > 0) {
    fixedCostsWithNames.forEach((fixedCost) => {
      const returnItem = [
        fixedCost.name,
        fixedCost.budget || '',
        fixedCost.notes || '',
        FIXED_COSTS_TBL_FORMULAS.BUDGET_TO_BASE,
      ];
      tableData.push(returnItem);
    });
  }

  return tableData;
};

/**
 * Utility function to replace keys in a call with a corresponding value
 * @param row - an array of strings representing a row in the excel sheet
 * @param keyValuePairs - the key value pairs to replace in the row
 * @returns
 */
export const generateShowDetailRowForSheet = (eventSheet: EventSheet, headers: string[], appInsights?: ReactPlugin) => {
  const safeSheetName = getSafeMarketName(eventSheet.numeratedEventMarketName);
  const formulas = createShowDetailRowFormulas(eventSheet.sheetName, safeSheetName);

  return headers.map((header) => {
    const name = header as keyof typeof formulas;
    if (formulas[name] !== undefined) {
      return formulas[name];
    }

    const message = `Couldn't find formula for unknown Show Detail column name "${name}", returning empty`;
    // eslint-disable-next-line no-console
    console.warn(message);
    if (appInsights) {
      logWarning(appInsights, message);
    }
    return '';
  });
};

export const generateTourFinancialSummaryData = (financialSummary: TourFinancialSummary): NamedRangeAssignment[] => {
  const financialSummaryNamedRanges: NamedRangeAssignment[] = [
    {
      range: SUMMARY.TOTAL_POOL_INCOME.TICKETING_REVENUE_AVG,
      value: financialSummary?.totalPoolIncomeTicketingRevenueAverage?.toString(),
    },
    {
      range: SUMMARY.TOTAL_ARTIST_EARNINGS.SPLIT_POINT_PERCENT,
      value: financialSummary?.artistEarningsTotalSplitPointFigure?.toString(),
    },
    {
      range: SUMMARY.TOTAL_ARTIST_EARNINGS.PERCENT_OVER_SPLIT_POINT,
      value: financialSummary?.artistEarningsOverSplitPoint?.toString(),
    },
    {
      range: SUMMARY.TOTAL_POOL_INCOME.SPONSORSHIP_REVENUE_AVG,
      value: financialSummary?.totalPoolIncomeSponsorshipRevenueAverage?.toString(),
    },
  ];

  // TOTAL POOL INCOME MANUAL ADJUSTMENTS
  financialSummary?.totalPoolIncomeManualAdjustments?.forEach((manualAdjustment, index: number) => {
    if (manualAdjustment?.name && manualAdjustment?.avgTicketPriceTotal) {
      const namedRangeForName = {
        range: `${SUMMARY.TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.NAME}${index + 1}`,
        value: manualAdjustment.name || '',
      };
      const namedRangeForAvg = {
        range: `${SUMMARY.TOTAL_POOL_INCOME.MANUAL_ADJUSTMENTS.AVG}${index + 1}`,
        value: manualAdjustment.avgTicketPriceTotal.toString(),
      };
      financialSummaryNamedRanges.push(namedRangeForName, namedRangeForAvg);
    }
  });
  // TOTAL ARTIST EARNINGS MANUAL ADJUSTMENTS
  // eslint-disable-next-line max-len
  financialSummary?.totalArtistEarningsManualAdjustments?.forEach((manualAdjustment, index: number) => {
    if (manualAdjustment?.name && manualAdjustment?.avgTicketPriceTotal) {
      const namedRangeForName = {
        range: `${SUMMARY.TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.NAME}${index + 1}`,
        value: manualAdjustment.name || '',
      };
      const namedRangeForAvg = {
        range: `${SUMMARY.TOTAL_ARTIST_EARNINGS.MANUAL_ADJUSTMENTS.AVG}${index + 1}`,
        value: manualAdjustment.avgTicketPriceTotal.toString(),
      };
      financialSummaryNamedRanges.push(namedRangeForName, namedRangeForAvg);
    }
  });

  // NET GROSS PROMOTER INCOME MANUAL ADJUSTMENTS
  // eslint-disable-next-line max-len
  financialSummary?.netGrossPromoterIncomeManualAdjustments?.forEach((manualAdjustment, index: number) => {
    if (manualAdjustment?.name && manualAdjustment?.avgTicketPriceTotal) {
      const namedRangeForName = {
        range: `${SUMMARY.NET_GROSS_PROMOTER_INCOME.MANUAL_ADJUSTMENTS.NAME}${index + 1}`,
        value: manualAdjustment.name || '',
      };
      const namedRangeForAvg = {
        range: `${SUMMARY.NET_GROSS_PROMOTER_INCOME.MANUAL_ADJUSTMENTS.AVG}${index + 1}`,
        value: manualAdjustment.avgTicketPriceTotal.toString(),
      };
      financialSummaryNamedRanges.push(namedRangeForName, namedRangeForAvg);
    }
  });

  return financialSummaryNamedRanges;
};

export const replaceCellWithSheetName = (sheetName: string, excelFormula: string): string => {
  const safeSheetName = getSafeMarketName(sheetName);
  return excelFormula.replaceAll(SAFE_SHEET_NAME_KEY, safeSheetName);
};

/**
 * Parse through the current excel sheet names to generate a unique name for a new sheet
 * @param name desired sheet name
 * @param existingSheetNames lost of current sheet names
 * @returns numerated sheet name
 */
export const numerateMarketSheetName = (
  name: string,
  existingSheetNames: string[],
): string => {
  const eventMarketRegex = new RegExp(`^${name}(.\\([0-9]+\\))?$`, 'g');

  // filter all the sheets down to matches with market name
  const sheetsMatchingMarketName = existingSheetNames.filter((sheetName) => {
    const sheetNameMatches = sheetName.match(eventMarketRegex);
    return sheetNameMatches && sheetNameMatches.length > 0;
  });

  const numerationArray: number[] = [];

  // extract numeration out of the matching names
  sheetsMatchingMarketName.forEach((sheetName) => {
    const stringNumeration = sheetName.match(/\([0-9]+\)/g);
    if (stringNumeration && stringNumeration.length > 0) {
      const numeration = stringNumeration[0].replace(/\(|\)/g, '');
      const sheetNumber = Number(numeration) || 0;
      numerationArray.push(sheetNumber);
    }
  });

  // sort numeration, highest to lowest
  const highestNumeration = numerationArray.length > 0 && numerationArray.sort((a, b) => b - a);

  if (highestNumeration && highestNumeration[0]) {
    return `${name} (${highestNumeration[0] + 1})`;
  }
  if (sheetsMatchingMarketName.length > 0) {
    return `${name} (${sheetsMatchingMarketName.length + 1})`;
  }

  return name;
};

// eslint-disable-next-line max-lines-per-function
export const generateWorkbookInitCurrencyRanges = (currencySymbol: string) => {
  const currencyRoundedFormat = formatCurrencyCell(currencySymbol, true, false);
  const currencyDecimalFormat = formatCurrencyCell(currencySymbol, true, true);

  const allNamedRangeAssignments = [
    {
      sheet: APPROVAL_EMAIL_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.APPROVAL_EMAIL.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.APPROVAL_EMAIL.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: APPROVAL_REVISED_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.APPROVAL_REVISED.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.APPROVAL_REVISED.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: LATERAL_SUM_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.LATERAL_SUM_POTENTIAL.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.LATERAL_SUM_POTENTIAL.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: OVERHEAD_COSTS_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.OVERHEAD_COSTS.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
      ],
    },
    {
      sheet: QUICK_SUM_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.QUICK_SUM.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.QUICK_SUM.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: SUMMARY_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.SUMMARY.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.SUMMARY.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: SUMMARY_UNCROSSED_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.SUMMARY_UNCROSSED.BASE_CURRENCY_ROUNDED,
          format: currencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.SUMMARY_UNCROSSED.BASE_CURRENCY_DECIMAL,
          format: currencyDecimalFormat,
        },
      ],
    },
    {
      sheet: SHOW_DETAIL_SHEET_NAME,
      namedRangeAssignments: [
        ...NAMED_RANGES.SHOW_DETAIL.BASE_CURRENCY_ROUNDED.map((range) => ({ range, format: currencyRoundedFormat })),
        ...NAMED_RANGES.SHOW_DETAIL.BASE_CURRENCY_DECIMAL.map((range) => ({ range, format: currencyDecimalFormat })),
      ],
    },
  ];

  return allNamedRangeAssignments;
};

// Helper function to map item fields based on the table name
export const mapOverheadCostsFields = (name: string, item: Record<string, unknown>) => {
  switch (name) {
    case OVERHEAD_COSTS_SHEET_TABLES.SUMMARY:
      return {
        name: item.name,
        total: item.total,
      };
    case OVERHEAD_COSTS_SHEET_TABLES.OFFICE_STAFF:
      return {
        name: item.name,
        total: item.total,
      };
    case OVERHEAD_COSTS_SHEET_TABLES.ROAD_STAFF:
      return {
        name: item.name,
        rate: item.rate,
        unit: item.unit,
        quantity: item.quantity,
      };
    case OVERHEAD_COSTS_SHEET_TABLES.TOUR_SPECIFIC_STAFF:
      return {
        name: item.name,
        rate: item.rate,
        unit: item.unit,
        people: item.numberOfPeople,
        quantity: item.quantity,
      };
    case OVERHEAD_COSTS_SHEET_TABLES.PRE_TOUR_STAFF:
      return {
        name: item.name,
        total: item.total,
      };
    default:
      return undefined;
  }
};
