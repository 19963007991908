import { styled } from '@mui/material/styles';

export const TabHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderBottomColor: theme.palette.divider,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
}));
