/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useMutation, useQuery } from '@apollo/client';
import { GET_VENUE_AND_QD_DATA } from '@gql/queries/venues';
import {
  FeeCategories, GetVenueAndQdDataQuery, Maybe, Space, SpaceConfiguration, TierScaling,
} from '@gql/types/graphql';
import { GridRowId, GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, {
  MouseEventHandler, useContext, useEffect, useMemo, useRef,
} from 'react';
import { useMatch } from 'react-router-dom';
import { MODIFY_SPACE } from '@gql/mutations/venues';
import { Dialog, DialogDispatchContext, NotificationDispatchContext } from '@providers';
import { SnackbarType, SAVE_VENUE_CHANGES_DIALOG, CANCEL_VENUE_CHANGES_DIALOG } from '@components';
import { FeeItemRow, FeeItemWithErrors, SnackBarMessages } from '@types';
import { removeNullProperties, removeTypenamesAndErrors } from '@utils/gqlHelpers';
import { useNavigateOnError, useYupValidationResolver } from '@hooks';
import { handleCommaSeparatedNumber, toValueOrUndefined } from '@utils/numberHelpers';
import { GET_PL_CATEGORIES } from '@gql/queries/profitLossCategories';
import { removeTempIds, rowDataHasChanged } from '@utils/datagridHelpers';
import {
  DEFAULT_ALL_FEES,
  DEFAULT_TIERS,
  sumTierCapacity,
} from '@utils/venueHelpers';
import { addOrReplaceItemById } from '@utils/arrayHelpers';
import { camelCase } from '@utils/stringHelpers';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { configurationSchema, feeItemSchema, priceTierSchema } from './VenueManagementProvider.schema';
import { validateConfig, validateFeeItem, validatePriceTier } from './VenueManagementProvider.validators';

type ProcessRowUpdate<T extends GridValidRowModel = GridValidRowModel> = (
  newRow: GridRowModel<T>,
  oldRow: GridRowModel<T>,
) => GridRowModel<T> | Promise<GridRowModel<T>>;

export type IVenueManagementContext = {
  data?: GetVenueAndQdDataQuery['venueAndSpace'];
  isDirty: boolean;
  expandedRows: GridRowId[];
  gridErrors: {
    configurations: number;
    priceTiers: number;
    fixedCosts: number,
    variableCosts: number,
    taxes: number,
    ticketFees: number,
  };
  setExpandedRows: React.Dispatch<React.SetStateAction<GridRowId[]>>;
  setVenueNote: React.ChangeEventHandler<HTMLTextAreaElement>;
  setFeeItem: ProcessRowUpdate<FeeItemRow>;
  setConfiguration: ProcessRowUpdate;
  setPriceTier: ProcessRowUpdate;
  addConfiguration: MouseEventHandler<HTMLButtonElement>;
  removeConfiguration: (arg: string) => void;
  saveData: () => void;
  cancelData: () => void;
};

export const DEFAULT_VENUE_CONTEXT: IVenueManagementContext = {
  data: undefined,
  isDirty: false,
  expandedRows: [],
  gridErrors: {
    configurations: 0,
    priceTiers: 0,
    fixedCosts: 0,
    variableCosts: 0,
    taxes: 0,
    ticketFees: 0,
  },
  setExpandedRows: () => ({}),
  setConfiguration: () => ({}),
  addConfiguration: () => ({}),
  saveData: () => {},
  cancelData: () => {},
  setVenueNote: () => {},
  removeConfiguration: () => {},
  setPriceTier: () => ({}),
  setFeeItem: () => ({} as FeeItemRow),
};

const VenueManagementContext = React.createContext<IVenueManagementContext>(DEFAULT_VENUE_CONTEXT);
const VenueManagementDispatch = React.createContext<
React.Dispatch<React.SetStateAction<GetVenueAndQdDataQuery['venueAndSpace'] | undefined>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};

interface VenuePayload {
  configurations: SpaceConfiguration[];
  fees: FeeCategories[];
}

function VenueManagementProvider({ children }: ProviderProps) {
  const appInsights = useAppInsightsContext();
  const { isDirty: isDirtyDefault, gridErrors: gridErrorsDefault } = DEFAULT_VENUE_CONTEXT;
  const [venueData, setVenueData] = React.useState<GetVenueAndQdDataQuery['venueAndSpace']>();
  const [isDirty, setIsDirty] = React.useState(isDirtyDefault);
  const [gridErrors, setGridErrors] = React.useState(gridErrorsDefault);
  const setDialog = useContext(DialogDispatchContext);
  const setNotification = useContext(NotificationDispatchContext);
  const [expandedRows, setExpandedRows] = React.useState<GridRowId[]>([]);
  const addedConfigurations = React.useRef<number>(0);
  const match = useMatch('/venues/:venueId/spaces/:spaceId');
  // TODO: Use this ref to build a payload with the grid methods below
  // Use a ref instead of state because updates to the payload should not trigger a re-render
  const venuePayload = useRef<VenuePayload>({
    configurations: [],
    fees: [],
  });
  const configurationValidator = useYupValidationResolver(configurationSchema, true);
  const feeItemValidator = useYupValidationResolver(feeItemSchema, true);
  const priceTierValidator = useYupValidationResolver(priceTierSchema, true);

  const venueId = match?.params.venueId;
  const spaceId = match?.params?.spaceId;
  const {
    data: queryResponse,
    loading,
    refetch,
    networkStatus, // helps to notify if refetch is triggered
    error,
  } = useQuery(GET_VENUE_AND_QD_DATA, {
    variables: { venueId: venueId || '', spaceId: spaceId || '' },
    skip: !venueId || !spaceId,
    notifyOnNetworkStatusChange: true,
  });

  const navigateOnError = useNavigateOnError();
  useEffect(() => {
    if (error) {
      navigateOnError(error);
    }
  }, [error]);

  const [modifySpace] = useMutation(MODIFY_SPACE, {
    refetchQueries: [GET_VENUE_AND_QD_DATA],
  });

  useEffect(() => {
    if (queryResponse?.venueAndSpace?.space) {
      const { venue, space } = queryResponse.venueAndSpace;
      /**
       * Set the venue data in a dedicated state so it is managed as user makes edits.
       * Price tiers need to be mapped to display default tiers when a config has no tier data.
       */
      setVenueData({
        venue,
        space: {
          ...space,
          configurations: space.configurations?.map((config) => ({
            ...config,
            name: config?.name || '',
            tierScaling: config?.tierScaling?.length ? config.tierScaling : DEFAULT_TIERS,
          })),
        },
      });
    }
  }, [queryResponse, networkStatus]);

  const { data: plCatResponse } = useQuery(GET_PL_CATEGORIES);
  const profitLossCategories = useMemo(() => plCatResponse?.profitLossCategories || [], [plCatResponse]);

  const setFeeItem: ProcessRowUpdate<FeeItemRow> = async (newRow, oldRow) => {
    if (!rowDataHasChanged(newRow, oldRow) || !venueData?.space) {
      return newRow;
    }

    setIsDirty(true);
    let { profitLossCategoryId } = newRow;
    const {
      subCategory, note, type, max, min, value, feeName, formula, category, categoryParent,
    } = newRow;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const oldRowErrors = oldRow.errors?.length || 0;

    // Find the profitLossCategoryId for the subCategory
    if (!profitLossCategoryId) {
      const plCategory = profitLossCategories?.find((item) => item?.subCategory === subCategory);

      if (!plCategory?.id) return newRow;
      profitLossCategoryId = plCategory.id;
    }

    // Create a new fee item with the updated values
    const updatedFeeItem: FeeItemWithErrors = {
      profitLossCategoryId,
      type,
      note,
      subCategory,
      formula,
      category,
      categoryParent,
      value: handleCommaSeparatedNumber(toValueOrUndefined(value), type) as number,
      min: handleCommaSeparatedNumber(toValueOrUndefined(min)) as number,
      max: handleCommaSeparatedNumber(toValueOrUndefined(max)) as number,
    };

    // Find the index of the fee category
    const fees = venueData.space.fees || [];
    const feeIndex = venueData.space.fees?.findIndex((fee) => fee.name === feeName);

    const { errors: validationErrors } = await feeItemValidator(updatedFeeItem);
    const validatedFee = validateFeeItem(updatedFeeItem, validationErrors);

    const emptyItem = (validatedFee.value === undefined || validatedFee.value === null)
      && !validatedFee.min && !validatedFee.max && !validatedFee.note;

    // Initialize or update the category
    let updatedFees: FeeCategories[];
    let existingFeeItemIndex: number | undefined;
    if (feeIndex !== -1) {
      // Fee exists, so update it
      updatedFees = fees.map((fee, index) => {
        if (index === feeIndex) {
          existingFeeItemIndex = fee.items?.findIndex((item) => item?.profitLossCategoryId === profitLossCategoryId);

          if (String(value).trim() === ''
            && existingFeeItemIndex !== undefined
            && existingFeeItemIndex > -1
            && fee.items?.[existingFeeItemIndex]?.category === undefined
            && emptyItem
          ) {
            const updatedItems = fee.items?.filter((_, idx) => idx !== existingFeeItemIndex);
            return { ...fee, items: updatedItems };
          }

          let updatedItems;

          if (emptyItem) { // no values in row, remove from state/payload
            updatedItems = fee.items?.filter((item) => item?.profitLossCategoryId !== profitLossCategoryId);
          } else {
            updatedItems = existingFeeItemIndex !== undefined && existingFeeItemIndex > -1
              ? fee.items?.map((item, idx) => ( // item exists
                idx === existingFeeItemIndex
                  ? { ...item, ...validatedFee } // item being updated, update as validated fee
                  : item))
              : [...(fee.items || []), validatedFee]; // Add new item if it doesn't exist
          }

          return { ...fee, items: updatedItems } as FeeCategories;
        }
        return fee;
      });
    } else {
      // Fee doesn't exist, so add it
      updatedFees = [...fees, { name: feeName, items: [validatedFee] }];
    }
    const categoryName = DEFAULT_ALL_FEES.find(
      (cat) => cat?.items?.some((item) => item?.subCategory === subCategory),
    )?.name;

    const derivedCatName: string = camelCase(categoryName as string);
    setGridErrors((prevState) => {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-argument
      const newFeeItemErrors = prevState[derivedCatName] + (Object.keys(validationErrors).length - oldRowErrors);
      return ({
        ...prevState,
        [derivedCatName]: newFeeItemErrors,
      });
    });

    setVenueData((prevState) => ({
      ...prevState,
      space: {
        ...prevState?.space,
        name: prevState?.space?.name || '',
        fees: updatedFees,
      },
    }));

    if (updatedFees) { // will exist when any fee has a value
      venuePayload.current.fees = updatedFees;
    }

    return { ...newRow, ...validatedFee };
  };

  const setConfiguration: ProcessRowUpdate = async (newRow, oldRow) => {
    if (!rowDataHasChanged(newRow, oldRow) || !venueData?.space) {
      return newRow;
    }

    setIsDirty(true);
    const {
      id, name, configType, description,
    } = newRow;
    const { name: oldRowName } = oldRow;

    const originalConfig = venueData?.space?.configurations?.filter(
      (config) => config?.id === id,
    )[0];

    const updatedConfig = {
      ...originalConfig,
      id,
      name: String(name).trim(),
      type: configType,
    };

    if (description) {
      updatedConfig.description = String(description).trim();
    } else if (oldRow.description && !description) {
      updatedConfig.description = '';
    }

    const updatedConfigurations: SpaceConfiguration[] = addOrReplaceItemById(
      venueData?.space?.configurations as SpaceConfiguration[] || [],
      updatedConfig,
    );

    const { errors: validationErrors } = await configurationValidator(updatedConfigurations);

    if (validationErrors) {
      setGridErrors((prevState) => ({
        ...prevState,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        configurations: Object.keys(validationErrors).length,
      }));
    }

    const {
      validatedConfig,
      configurations,
    } = validateConfig(updatedConfig, updatedConfigurations, validationErrors, false, oldRowName as string);

    venuePayload.current.configurations = configurations;

    setVenueData({
      ...venueData,
      space: {
        ...venueData?.space as Space,
        configurations,
      },
    });

    return validatedConfig;
  };

  const addConfiguration = async () => {
    setIsDirty(true);

    const newConfig: SpaceConfiguration = {
      id: `new-config-${addedConfigurations.current + 1}`,
      name: `New Configuration ${addedConfigurations.current + 1}`,
      type: '', // instead of null to prevent MUI out-of-range value error
      description: null,
      tierScaling: DEFAULT_TIERS.map((tier, idx) => ({
        ...tier,
        id: `new-price-tier-${(addedConfigurations.current + 1) * idx}`,
      })),
    };
    addedConfigurations.current += 1;

    const updatedConfigurations: SpaceConfiguration[] = addOrReplaceItemById(
      venueData?.space?.configurations as SpaceConfiguration[] || [],
      newConfig,
    );

    const { errors: validationErrors } = await configurationValidator(updatedConfigurations);

    const {
      validatedConfig,
      configurations,
    } = validateConfig(newConfig, updatedConfigurations, validationErrors, true);

    if (validationErrors) {
      setGridErrors((prevState) => ({
        ...prevState,
        configurations: validatedConfig.errors.length,
      }));
    }

    setVenueData({
      ...venueData,
      space: {
        ...venueData?.space as Space,
        configurations,
      },
    });
    venuePayload.current.configurations.push(validatedConfig);
    return validatedConfig;
  };

  const removeConfiguration = async (rowId: string) => {
    setIsDirty(true);

    const configurations = venueData?.space?.configurations as SpaceConfiguration[] || [];
    const updatedConfigurations = configurations.filter((config) => config.id !== rowId);

    const { errors: validationErrors } = await configurationValidator(updatedConfigurations);

    if (validationErrors) {
      setGridErrors((prevState) => ({
        ...prevState,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        configurations: Object.keys(validationErrors).length,
      }));
    }

    setVenueData({
      ...venueData,
      space: {
        ...venueData?.space as Space,
        configurations: [
          ...updatedConfigurations,
        ],
      },
    });

    venuePayload.current.configurations = venuePayload.current.configurations.filter(
      (config) => config.id !== rowId,
    );
  };

  const setVenueNote: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setVenueData((prevState) => ({
      ...(prevState || {}),
      space: {
        ...(prevState?.space || {}),
        name: prevState?.space?.name || '',
        description: event.target.value,
      },
    }));
    setIsDirty(true);
  };

  const saveData = () => {
    const existingConfigIds = queryResponse?.venueAndSpace?.space?.configurations?.map((config) => config?.id);
    const modifiedConfigIds = venuePayload.current.configurations.map((config) => config.id);

    const unchangedConfigs = venueData?.space?.configurations?.filter(
      (config) => !modifiedConfigIds.includes(config?.id),
    ) as SpaceConfiguration[];

    const modifiedConfigsRemoveTempIds = venuePayload.current.configurations
      .map((c) => (existingConfigIds?.includes(c.id) ? c : removeTempIds(c))) as SpaceConfiguration[];

    const mergedConfigurations = [
      ...unchangedConfigs,
      ...modifiedConfigsRemoveTempIds,
    ];

    const configurations = mergedConfigurations
      .sort((a, b) => a.name.localeCompare(b.name)); // send configs in alphabetical order by name

    // remove resolved fields from unchanged fees being passed back with space
    const transformedFees = venueData?.space?.fees?.map((fee) => ({
      ...fee,
      items: fee.items?.map((item) => {
        const {
          category, categoryParent, subCategory, ...rest
        } = item;
        return rest;
      }),
    }));

    const { id, updatedDate, ...existingSpaceData } = venueData?.space as Space;

    const modifySpacePayload = {
      variables: {
        venueId,
        space: {
          ...existingSpaceData,
          id: spaceId,
          configurations,
          fees: transformedFees, // TODO: Update for modified fees
        },
      },
    };

    const formatPayload = removeTypenamesAndErrors(modifySpacePayload);
    const cleanPayload = removeNullProperties(formatPayload);

    setDialog({
      titles: SAVE_VENUE_CHANGES_DIALOG.TITLES,
      submit: {
        text: SAVE_VENUE_CHANGES_DIALOG.SUBMIT,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        action: async () => {
          try {
            setDialog((prevDialog: Dialog) => ({
              ...(prevDialog ?? {}),
              isLoading: true,
            }));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            await modifySpace(cleanPayload);
            setDialog(null);
            setIsDirty(false);
            addedConfigurations.current = 0;
            venuePayload.current = {
              configurations: [],
              fees: [],
            };
            setNotification({
              type: SnackbarType.SUCCESS,
              text: SnackBarMessages.VenueChangesSaved,
              duration: 6000,
            });
          } catch (e) {
            logError(appInsights, 'venueManagement save venue changes error', e);
            setDialog(null);
            setNotification({
              type: SnackbarType.ERROR,
              text: SnackBarMessages.VenueChangesFailed,
              duration: 6000,
            });
          }
        },
      },
      cancel: {
        text: SAVE_VENUE_CHANGES_DIALOG.CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  const cancelData = () => {
    setDialog({
      titles: CANCEL_VENUE_CHANGES_DIALOG.TITLES,
      submit: {
        text: CANCEL_VENUE_CHANGES_DIALOG.SUBMIT,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        action: async () => {
          addedConfigurations.current = 0;
          setDialog((prevDialog) => ({
            ...(prevDialog ?? {}),
            isLoading: true,
          }));
          await refetch();
          setIsDirty(false);
          setDialog(null);
        },
      },
      cancel: {
        text: CANCEL_VENUE_CHANGES_DIALOG.CANCEL,
        action: () => setDialog(null),
      },
    });
  };

  const setPriceTier: ProcessRowUpdate = async (newRow) => {
    setIsDirty(true);
    if (!venueData?.space) return newRow;

    const {
      configurationId,
      capacity,
      id,
      name,
    } = newRow;
    const existingConfig = venueData.space?.configurations?.find((c) => c?.id === configurationId);
    const updatedCapacity = toValueOrUndefined(capacity);

    const updatedTier: TierScaling = {
      name,
      id,
    };

    if (updatedCapacity !== null && updatedCapacity !== undefined) {
      updatedTier.capacity = handleCommaSeparatedNumber(updatedCapacity) as number;
    }

    const updatedTiers: Maybe<TierScaling>[] = (existingConfig?.tierScaling || []).map((tier) => {
      if (tier?.id === id) {
        return updatedTier;
      }
      return tier;
    }) || [];

    const { errors: validationErrors } = await priceTierValidator(updatedTier);

    if (validationErrors) {
      setGridErrors((prevState) => ({
        ...prevState,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        priceTiers: Object.keys(validationErrors).length,
      }));
    }

    const { validatedTier, validatedTiers } = validatePriceTier(updatedTier, updatedTiers, validationErrors);

    const updatedConfiguration: SpaceConfiguration = {
      ...existingConfig,
      tierScaling: validatedTiers,
      name: existingConfig?.name || '',
      totalCapacity: sumTierCapacity(validatedTiers),
    };
    const updatedConfigurations = venueData.space?.configurations?.map((c) => (
      c?.id === configurationId ? updatedConfiguration : c
    ));

    const updatedVenueData = {
      ...venueData,
      space: {
        ...venueData.space,
        configurations: updatedConfigurations,
      },
    };

    setVenueData(updatedVenueData);

    const existingConfigIdx = venuePayload
      .current.configurations?.findIndex((config: SpaceConfiguration) => config.id === updatedConfiguration.id);

    if (existingConfigIdx !== -1) {
      venuePayload.current.configurations[existingConfigIdx] = updatedConfiguration;
    } else {
      venuePayload.current.configurations.push(updatedConfiguration);
    }

    return { ...newRow, ...validatedTier };
  };

  const value = {
    data: venueData,
    expandedRows,
    gridErrors,
    isDirty,
    setExpandedRows,
    setFeeItem,
    setConfiguration,
    addConfiguration,
    saveData,
    cancelData,
    setVenueNote,
    removeConfiguration,
    setPriceTier,
  };

  if (!venueData || loading) return null;

  return (
    <VenueManagementContext.Provider value={value}>
      <VenueManagementDispatch.Provider value={setVenueData}>{children}</VenueManagementDispatch.Provider>
    </VenueManagementContext.Provider>
  );
}

export { VenueManagementProvider, VenueManagementContext, VenueManagementDispatch };
