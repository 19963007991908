import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { FormStylingMode } from '../Form';

interface SearchContainerProps {
  formStylingMode?: FormStylingMode;
}

export const SearchContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'formStylingMode',
})<SearchContainerProps>(({ theme, formStylingMode }) => ({
  width: '100%',
  padding: theme.spacing(0),
  margin: formStylingMode === FormStylingMode.COMPACT ? theme.spacing(0) : theme.spacing(2),
  maxWidth: theme.spacing(80),
}));

export const OptionContainer = styled('li')(({ theme }) => ({
  fontSize: theme.spacing(2),
}));
