/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import { Headliner, Agent } from '../../../../views/OneOffDetails/mockOneOffDetails';
import { AltRowColorGrid, StyledTypography } from './HeadlinerContent.styled';

interface HeadlinerRow {
  id: string;
  name: string;
  agency: string;
  agent: string;
  email: string;
  phone: string;
}

interface HeadlinerContentProps {
  headliners: Headliner[];
}

export function HeadlinerContent({ headliners }: HeadlinerContentProps) {
  const [rows, setRows] = useState<HeadlinerRow[]>([]);
  const actions = [<GridActionsCellItem showInMenu label='Test' onClick={() => {}} />];
  const columns: GridColDef<HeadlinerRow>[] = [
    {
      field: 'headliners',
      headerName: 'Headliners',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const StyledCell = params.row.name ? StyledTypography : Typography;
        return <StyledCell>{params.row.name}</StyledCell>;
      },
    },
    {
      field: 'agency',
      headerName: 'Agency',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const StyledCell = params.row.name ? StyledTypography : Typography;
        return <StyledCell>{params.row.agency}</StyledCell>;
      },
    },
    {
      field: 'agent',
      headerName: 'Agent',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const StyledCell = params.row.name ? StyledTypography : Typography;
        return <StyledCell>{params.row.agent}</StyledCell>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const StyledCell = params.row.name ? StyledTypography : Typography;
        return <StyledCell>{params.row.email || '—'}</StyledCell>;
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const StyledCell = params.row.name ? StyledTypography : Typography;
        return <StyledCell>{params.row.phone || '—'}</StyledCell>;
      },
    },
    {
      field: 'moreActions',
      type: 'actions',
      resizable: false,
      width: 10,
      getActions: (params) => {
        if (params.row.name) {
          return actions;
        }
        return [];
      },
    },
  ];

  const mapHeadlinersToRows = (headlinerArr: Headliner[]) => {
    const mappedRows: HeadlinerRow[] = [];
    headlinerArr.forEach((headliner: Headliner) => {
      const firstRow = {
        id: headliner.id as string,
        name: headliner.name as string,
        agency: headliner?.agency?.name || '',
        agent: headliner?.agent ? headliner?.agent[0].name : '',
        email: headliner?.agent ? headliner?.agent[0].email : '',
        phone: headliner?.agent && headliner?.agent[0].phone ? headliner?.agent[0].phone : '',
      };
      mappedRows.push(firstRow);
      if (headliner?.agent && headliner?.agent?.length > 1) {
        headliner?.agent?.forEach((agent: Agent, index: number) => {
          if (index > 0) {
            const row = {
              id: `${headliner.id || ''}-${index}`,
              name: '',
              agency: '',
              agent: agent?.name || '',
              email: agent?.email || '',
              phone: agent?.phone || '',
            };
            mappedRows.push(row);
          }
        });
      }
    });
    setRows(mappedRows);
  };

  useEffect(() => {
    if (headliners) {
      mapHeadlinersToRows(headliners);
    }
  }, [headliners]);

  return (
    <div data-testid="headliners-container">
      <AltRowColorGrid
        hideFooter
        rows={rows}
        columns={columns as GridColDef[]}
        data-testid="headliner-data-grid"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        getRowId={(row) => row.id as string}
        initialState={{
          pinnedColumns: {
            right: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'moreActions'],
          },
        }}
      />
    </div>
  );
}
