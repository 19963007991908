/* eslint-disable max-lines-per-function */
import { useMutation } from '@apollo/client';
import { ADD_TOUR } from '@gql/mutations/tours';
import { AddTourInput, ParentTour } from '@gql/types/graphql';
import React, { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WebUserContext } from '@providers';
import { GET_TOURS_PAGE } from '@gql/queries/tours';
import { ModifyTeamMembersFormInput, ModifyTeamMembersForm, ModifyTeamMembersFormUserRoles } from '@components';
import { TourForm, TourFormSubmit } from './TourForm/TourForm';
import { CREATE_TOUR_TEXT, TourFormFields, CREATE_TOUR_DEFAULTS } from './TourForm/TourForm.models';
import { MEMBER_FORM_TEXT, TourFormText } from '../CreateTourDrawer/CreateTourDrawer.models';

enum AddTourFormSteps {
  one,
  two,
}

interface AddTourFormProps {
  onTourSaved: () => void;
  setFormContainerText: React.Dispatch<React.SetStateAction<TourFormText>>;
  parentTour?: ParentTour | null;
  children: ReactNode;
}

export const AddTourForm: React.FC<AddTourFormProps> = ({
  onTourSaved,
  children,
  setFormContainerText,
  parentTour,
}) => {
  const navigate = useNavigate();

  const user = useContext(WebUserContext);
  const [formStep, setFormStep] = React.useState<AddTourFormSteps>(AddTourFormSteps.one);
  const [addTourInput, setAddTourInput] = React.useState<AddTourInput | null>(null);
  const [tourFormData, setTourFormData] = React.useState<TourFormFields | null>(
    {
      ...CREATE_TOUR_DEFAULTS,
      parentTour: parentTour || null,
      isMultiLeg: parentTour !== null,
    },
  );

  const [
    createTour,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(ADD_TOUR, {
    errorPolicy: 'all',
    onCompleted(data) {
      navigate(`/tours/${data?.addTour?.id ?? ''}`);
    },
    onError(err) {
      throw err;
    },
    refetchQueries: [GET_TOURS_PAGE],
  });

  const handleCreateTour = React.useCallback(
    async (tour: AddTourInput) => {
      await createTour({
        variables: {
          tour,
        },
      });
    },
    [createTour],
  );

  const handleStepOne = (tourData: TourFormSubmit) => {
    const { formData, tour } = tourData;
    setAddTourInput(tour);
    setTourFormData(formData);
    setFormStep(AddTourFormSteps.two);
    setFormContainerText(MEMBER_FORM_TEXT);
  };

  const handleStepTwo = async (tourMemberData: ModifyTeamMembersFormInput) => {
    const tourData: AddTourInput | null = addTourInput;

    if (mutationLoading) {
      return;
    }

    if (tourData === null) {
      setFormStep(AddTourFormSteps.one);
      return;
    }

    const finalTourInput: AddTourInput = {
      ...tourData,
      ...tourMemberData,
    };

    await handleCreateTour(finalTourInput);
    onTourSaved();
  };

  const defaultValues = {
    userRole: ModifyTeamMembersFormUserRoles.operator,
    operators: [
      {
        id: user?.id || '',
        email: user?.email || '',
        name: user?.name || '',
      },
    ],
    primaryOperatorId: user?.id || '',
    buyers: [],
    primaryBuyerId: '',
    viewOnly: [],
    nosEmailDistribution: [],
  };
  return (
    <>
      {formStep === AddTourFormSteps.one && (
        <TourForm
          tourFormData={tourFormData || undefined}
          onFormSubmit={handleStepOne}
          isFormSaving={mutationLoading}
          mutationError={mutationError}
        >
          {children}
        </TourForm>
      )}
      {formStep === AddTourFormSteps.two && (
        <ModifyTeamMembersForm
          defaultValues={defaultValues}
          onPrevious={() => {
            setFormContainerText(CREATE_TOUR_TEXT);
            setFormStep(AddTourFormSteps.one);
          }}
          onFormSubmit={handleStepTwo}
          isFormSaving={mutationLoading}
          isAddMemberFlow
          withPrimarySelection
        />
      )}
    </>
  );
};
