import { styled } from '@mui/material/styles';
import { CheckCircle } from '@mui/icons-material';
import { Typography } from '@mui/material';

export const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.spacing(1.75),
}));

export const DisabledText = styled(Typography)(({ theme }) => ({
  opacity: '56%',
  fontSize: theme.spacing(1.75),
}));

export const GreenCheck = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.light,
}));
