import React, { useCallback } from 'react';
import { DataGridProProps, GridRowClassNameParams, GridValidRowModel } from '@mui/x-data-grid-pro';

import { ContainerStyled, DataGridWithTotalsStyled } from './DataGridWithTotals.styled';

interface DataGridWithTotalsProps extends DataGridProProps<GridValidRowModel> {
  rowsGroup?: string;
  totalRows?: GridValidRowModel[];
  bottomRows?: GridValidRowModel[];
  testId: string;
  scrollable?: boolean;
}

export function DataGridWithTotals({
  rowsGroup,
  totalRows = [],
  testId,
  rows,
  scrollable,
  bottomRows = [],
  ...props
}: DataGridWithTotalsProps) {
  const getRowClassName = useCallback(({ row }: GridRowClassNameParams<GridValidRowModel>) => {
    if (totalRows.some((totalRow) => totalRow.name === row.name)) {
      return 'totals-row';
    }

    if (bottomRows.some((bottomRow) => bottomRow.name === row.name)) {
      return 'bottom-row';
    }

    if (row.isGroupRow) {
      return 'group-row';
    }
    return '';
  }, [totalRows]);

  const modifiedRows = React.useMemo(() => {
    if (!rowsGroup) return rows;

    const result: (GridValidRowModel)[] = [];

    // Add category to rows
    rows.forEach((row) => {
      const groupName = row[rowsGroup] as string;
      if (!result.some((res) => res.id === groupName)) {
        result.push({ id: groupName, name: groupName, isGroupRow: true });
      }
      result.push(row);
    });

    return result;
  }, [rows, rowsGroup]);

  return (
    <ContainerStyled>
      <DataGridWithTotalsStyled
        data-testid={`${testId}-data-grid`}
        hideFooter
        scrollable={scrollable}
        getRowClassName={getRowClassName}
        rows={totalRows ? [
          ...modifiedRows,
          ...totalRows.map((totalRow) => ({ ...totalRow, isTotalsRow: true })),
          ...bottomRows.map((bottomRow) => ({ ...bottomRow, isBottomRow: true })),
        ] : modifiedRows}
        {...props}
      />
    </ContainerStyled>
  );
}
