import * as yup from 'yup';

const NO_RECIPIENT_ERROR_MESSAGE = 'Enter email recipients to continue sending the email.';

const RecipientFieldSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  email: yup.string(),
}).noUnknown();

export const SendEmailFormSchema = {
  toUsers: yup.array().of(RecipientFieldSchema).required().min(1, NO_RECIPIENT_ERROR_MESSAGE),
  ccUsers: yup.array().of(RecipientFieldSchema).notRequired(),
  comments: yup.string().optional(),
};

export const createSendEmailFormValidationSchema = () => yup.object().shape(SendEmailFormSchema);
