import { ReactNode } from 'react';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import LayersIcon from '@mui/icons-material/Layers';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import { AegResourceTypes, EnvConfig, UserPermissions } from '@types';
import { CompanyPermission } from '@gql/types/graphql';
import featureFlags from '@utils/featureFlags';
import { getBookingSupportUrl } from '@utils/stringHelpers';
import { GlobalSearch } from '../GlobalSearch/GlobalSearch';
import { BundlesIcon } from '../shared';

interface ProtectedLinkItem {
  permissionCheck: UserPermissions | CompanyPermission;
  resourceType: AegResourceTypes;
}

interface IconWithLabel {
  label: string;
  icon: ReactNode;
  path?: string;
  flyoutComponent?: ReactNode;
  featureFlag?: keyof EnvConfig['flags'];
  protected?: ProtectedLinkItem;
}

interface PathMenuItem extends IconWithLabel {
  path: string;
}

interface FlyoutMenuItem extends IconWithLabel {
  flyoutComponent: ReactNode;
}

type MenuItem = PathMenuItem | FlyoutMenuItem;

export const menuMap: MenuItem[] = [
  {
    label: 'Search',
    icon: <SearchIcon />,
    featureFlag: featureFlags.GLOBAL_SEARCH,
    flyoutComponent: <GlobalSearch />,
  },
  {
    label: 'Tours',
    icon: <ShareLocationIcon />,
    path: '/tours',
  },
  {
    label: 'One-Offs',
    icon: <LayersIcon />,
    path: '/one-offs',
  },
  {
    label: 'Bundles',
    icon: <BundlesIcon />,
    path: '/bundles',
    featureFlag: featureFlags.BUNDLES,
    protected: {
      permissionCheck: CompanyPermission.BundleList,
      resourceType: AegResourceTypes.Company,
    },
  },
  {
    label: 'Booking Support',
    icon: <ContactSupportIcon />,
    path: getBookingSupportUrl('Booking Support'),
  },
  {
    label: 'Admin Portal',
    icon: <AdminPanelSettingsIcon />,
    path: '/admin',
    protected: {
      permissionCheck: UserPermissions.ManageSystem,
      resourceType: AegResourceTypes.System,
    },
  },
  {
    label: 'Venue Management',
    icon: <LocationOnIcon />,
    path: '/venues',
    protected: {
      permissionCheck: UserPermissions.ManageVenueQD,
      resourceType: AegResourceTypes.System,
    },
  },
];
