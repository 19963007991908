/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable max-lines-per-function */
import { useCallback, useContext, useMemo } from 'react';
import {
  GridColDef, GridCellParams, GridRowModel,
} from '@mui/x-data-grid-pro';
import {
  ShowFieldsFragment, ShowInput, Space, ModifyShowInput,
} from '@gql/types/graphql';
import { Button, Typography } from '@mui/material';
import {
  AddIcon, CloseIconGrey, CondensedGrid,
} from '@components';
import { useAegModeling } from '@hooks';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DialogDispatchContext } from '@providers';
import { EventStatus } from '@types';
import { getTimezoneDate } from '@utils/stringHelpers';
import { ShowsGridData } from './ShowTable.types';
import { AddShowContainer, DateCellContainer, ShowTableContainer } from './ShowTable.styled';
import { REMOVE_SHOW_DIALOG } from '../AegDialog/AegDialogConstants';
import { BorderlessDateTimePicker } from '../DatePickers/BorderlessDateTimePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

const SHOW_DATE_AND_TIME_PLACEHOLDER = 'Show Date and Time';
const SHOW_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

export const ShowTable = ({
  row: event,
  enableEditShow = false,
  disableVirtualization = false,
}: {
  row: ShowsGridData;
  enableEditShow?: boolean;
  disableVirtualization?: boolean;
}) => {
  const setDialog = useContext(DialogDispatchContext);

  const { addShowToEvent, removeShowFromEvent, modifyShowOnEvent } = useAegModeling();

  const modifyShowRestricted = [EventStatus.AccountingSettled, EventStatus.Canceled, EventStatus.NOSSettled];

  const handleAddShowToEvent = async (space?: Space) => {
    if (!space?.id) {
      return;
    }
    const addShowInput: ShowInput = {
      spaceId: space.id,
    };

    await addShowToEvent(event.id, addShowInput);
  };

  const handleShowDateChange = async (value: Dayjs | null, showId: string) => {
    const modifyShowPayload: ModifyShowInput = {
      id: showId,
      showDateTime: value ? value.toISOString() : '',
    };
    await modifyShowOnEvent(event.id, modifyShowPayload);
  };

  const eventHasOtherValidShowDates = (showRow: ShowFieldsFragment): boolean => {
    const allShows = event.shows;
    if (allShows?.length <= 1) {
      return false;
    }
    // Events on Draft status don't sync to M and can have Shows with empty date and time.
    if (event.status === EventStatus.Draft) {
      return true;
    }
    const anyValidShowDate = allShows
      ?.filter((s: ShowFieldsFragment) => s.id && s.id !== showRow.id)
      .some((s: ShowFieldsFragment) => (!!s.showDateTime));
    if (anyValidShowDate) {
      return true;
    }
    return false;
  };

  const showDateCell = (showRow: ShowFieldsFragment) => {
    const showDateTime = getTimezoneDate(
      showRow.showDateTime as string,
      event.timezone,
      SHOW_DATE_FORMAT,
    )[1];

    const onAccept = useCallback((value: Dayjs | null) => {
      if ((!value && event.status === EventStatus.Draft) || (value && value.isValid())) {
        void handleShowDateChange(value, showRow.id as string);
      }
    }, []);

    return (
      <DateCellContainer data-testid={`show-date-${showRow.id as string}`}>
        <BorderlessDateTimePicker
          onAccept={onAccept}
          timezone={event.timezone ?? undefined}
          format={SHOW_DATE_FORMAT}
          value={showDateTime}
          label={!showRow.showDateTime ? SHOW_DATE_AND_TIME_PLACEHOLDER : undefined}
          disabled={modifyShowRestricted.includes(event.status)}
          isClearable={event.status === EventStatus.Draft}
        />
      </DateCellContainer>
    );
  };

  const columns: GridColDef<ShowFieldsFragment>[] = [
    {
      field: 'name',
      headerName: 'Show',
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'showDateTime',
      headerName: 'Show Dates',
      sortable: false,
      flex: 1.5,
      renderCell: (params: GridCellParams<GridRowModel>) => {
        if (enableEditShow) {
          return showDateCell(params.row);
        }
        return (
          <Typography>
            {params.row.showDateTime
              ? getTimezoneDate(params.row.showDateTime as string, event.timezone, 'L LT')[0]
              : 'TBD'}
          </Typography>
        );
      },
    },
  ];

  if (enableEditShow) {
    columns.push({
      field: 'removeShow',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params: GridCellParams<GridRowModel>) => (
        !modifyShowRestricted.includes(event.status)
        && event.status !== EventStatus.Canceled
        && eventHasOtherValidShowDates(params.row)
          ? <Button
            data-testid={`remove-show-${params.row.id as string}`}
            onClick={() => {
              setDialog({
                titles: REMOVE_SHOW_DIALOG.TITLES,
                submit: {
                  text: REMOVE_SHOW_DIALOG.SUBMIT,
                  action: async () => {
                    setDialog(null);
                    void await removeShowFromEvent(
                      params.row.eventId as string,
                      params.row.id as string,
                      params.row.index as number,
                    );
                  },
                },
                cancel: {
                  text: REMOVE_SHOW_DIALOG.CANCEL,
                  action: () => {
                    setDialog(null);
                  },
                },
              });
            }}
          >
            <CloseIconGrey />
           </Button> : <></>
      ),
    });
  }

  const rows = useMemo(() => event.shows.map((show, index) => ({
    key: show?.id,
    id: show?.id,
    eventId: event.id,
    name: `Show ${index + 1}`,
    showDateTime: show?.showDateTime,
    index: index + 1,
  })), [event]);

  const showGridFooter = useMemo(() => () => (
    enableEditShow && event.status !== EventStatus.Canceled ? (
      <AddShowContainer
        onClick={() => handleAddShowToEvent(event?.space)}
        data-testid={`add-show-${event.id}`}
      >
          <AddIcon />
          <Typography color='info.main'>
            Add New Show
          </Typography>
      </AddShowContainer>
    ) : null
  ), [event.status, event.space?.id, enableEditShow]);

  return (
    <ShowTableContainer>
      <CondensedGrid
        columns={columns}
        rows={rows}
        rowHeight={40}
        hideFooter={modifyShowRestricted.includes(event.status)}
        columnHeaderHeight={40}
        disableVirtualization={disableVirtualization}
        disableRowSelectionOnClick
        data-testid={`event-show-grid-${event.id}`}
        slots={{
          footer: showGridFooter,
        }}
      />
    </ShowTableContainer>
  );
};
