import * as yup from 'yup';
import { string } from 'yup';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const createAgentValidationSchema = yup.object({
  name: string().required("Please enter the agent's name"),
  email: string()
    .required("Please enter the agent's email")
    .test('is-valid-email', 'Invalid email format', (value) => value === '' || emailRegex.test(value)),
});
