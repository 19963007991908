import { useContext, useEffect, useState } from 'react';
import { BreadcrumbDispatchContext } from '@providers';
import { ListViewFilter, OneOffsEventsListView } from '@components';

export function OneOffs({ disableVirtualization = false }: { disableVirtualization?: boolean }) {
  const [listViewFilterModel, setListViewFilterModel] = useState<ListViewFilter>();

  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);

  useEffect(() => {
    document.title = 'One-Offs - Modern Elvis';
    setBreadcrumbs([
      {
        text: 'One-Offs',
        href: '/one-offs/',
      },
    ]);
  }, []);

  return (
    <OneOffsEventsListView
      disableVirtualization={disableVirtualization}
      listViewFilterModel={listViewFilterModel}
      setListViewFilterModel={setListViewFilterModel}
    />
  );
}
