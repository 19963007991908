import { graphql } from '@gql/types';

export const UPSERT_USER = graphql(`
  mutation UpsertUser($userInput: UserInput!) {
    upsertUser(user: $userInput) {
      id
      name
      email
      availableCompanies {
        id
        name
      }
      intercomUserHash
    }
  }
`);

export const GRANT_USER_ACCESS = graphql(`
  mutation GrantAccessToUser($userAccess: UserAccessInput!) {
    grantAccessToUser(userAccess: $userAccess) {
      id
      name
      email
      availableCompanies {
        id
        name
      }
    }
  }
`);

export const GRANT_ADMIN_ACCESS = graphql(`
  mutation GrantAdminAccess($userId: ID!) {
    grantAdminAccess(userId: $userId) {
      id
      name
      email
      availableCompanies {
        id
        name
      }
    }
  }
`);

export const REMOVE_USER_ACCESS = graphql(`
  mutation RemoveUserAccess($userAccess: RemoveUserAccessInput!) {
    removeUserAccess(userAccess: $userAccess) {
      id
      name
      email
    }
  }
`);
