/* eslint-disable max-len */
import { GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function CloneFailWarning() {
  return (
    <LayoutWarning header={'Offer Duplication Error'}>
          <GenericMessage data-testid="generic-message">
            An error occurred when the offer was duplicated. Please ensure that the Offer workbook you are trying to duplicate has been Synced to Modern Elvis and then try duplication again.
          </GenericMessage>
    </LayoutWarning>
  );
}
