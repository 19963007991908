import { GraphQLErrors } from '@apollo/client/errors';
import { useNavigate } from 'react-router-dom';

export const useNavigateOnError = () => {
  const navigate = useNavigate();
  return (error?: { message: string; name?: string; graphQLErrors?: GraphQLErrors }) => {
    if (!error) {
      return;
    }

    let gqlError;
    if (error?.graphQLErrors) {
      [gqlError] = error.graphQLErrors;
    }

    // code 1 indicates a 403 error from the GQL server
    if (gqlError && gqlError.extensions.code === 1) {
      navigate('/unauthorized');
    } else if (error) {
      navigate('/error', {
        state: {
          status: error.message,
          message: `Error - ${error.name || ''}`,
        },
      });
    }
  };
};
