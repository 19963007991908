import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  updateOffers,
  getDefaultOffers,
  DEFAULT_PROJECTION_AMOUNT,
  calculateTotalActiveOffers,
} from '@utils/scenarioBuilder';
import { GET_BUNDLE_OFFER } from '@gql/queries/bundles';
import { MODIFY_OFFER_SCENARIO } from '@gql/mutations/bundles';
import { EventOfferScenarioInput, BundleOfferQuery } from '@gql/types/graphql';
import { ScenarioBuilderScenario, ScenarioBuilderEventOffer } from '@types';

// eslint-disable-next-line max-lines-per-function
export const useScenarioBuilder = ({ bundleId, bundleOfferId }: {
  bundleId: string;
  bundleOfferId: string;
}) => {
  const [draftMode, setDraftMode] = useState<boolean>(false);
  const [activeOffers, setActiveOffers] = useState<ScenarioBuilderEventOffer[]>([]);
  const [inactiveOffers, setInactiveOffers] = useState<ScenarioBuilderEventOffer[]>([]);
  const totalActiveOffers = useMemo(() => calculateTotalActiveOffers(activeOffers), [activeOffers]);

  const [modifyOfferScenario, { loading }] = useMutation(MODIFY_OFFER_SCENARIO, {
    onCompleted: () => {
      setDraftMode(false);
    },
  });

  const initializeOffers = (data: BundleOfferQuery) => {
    const [defaultActiveOffers, defaultInactiveOffers] = getDefaultOffers(data);

    setActiveOffers(defaultActiveOffers);
    setInactiveOffers(defaultInactiveOffers);
    setDraftMode(false);
  };

  const { data: bundleOfferData } = useQuery(GET_BUNDLE_OFFER, {
    variables: {
      bundleId,
      bundleOfferId,
    },
    onCompleted: initializeOffers,
  });

  const onChangeScenario = ({
    scenario,
    eventOfferId,
    scenarioIndex,
    isActive,
  }: {
    scenario: Partial<ScenarioBuilderScenario>;
    eventOfferId: string;
    scenarioIndex: number;
    isActive?: boolean
  }) => {
    if (isActive) {
      setActiveOffers((prevActiveOffers) => {
        const updatedActiveOffers = updateOffers(prevActiveOffers, eventOfferId, scenarioIndex, scenario);
        setDraftMode(true);
        return updatedActiveOffers;
      });
    } else {
      setInactiveOffers((prevInactiveOffers) => {
        const updatedInactiveOffers = updateOffers(prevInactiveOffers, eventOfferId, scenarioIndex, scenario);
        setDraftMode(true);
        return updatedInactiveOffers;
      });
    }
  };

  const applyEdits = async () => {
    const offerScenariosGrouped: Record<string, EventOfferScenarioInput[]> = {};

    [...activeOffers, ...inactiveOffers].forEach((eventOffer) => {
      eventOffer.scenarios?.forEach((scenario) => {
        const scenarioId = scenario?.scenarioId as string;
        offerScenariosGrouped[scenarioId] ??= [];
        const roundedProjectionAmount = (
          scenario?.projectionAmount === null
            || scenario?.projectionAmount === undefined
            || scenario?.projectionAmount.toString() === ''
            ? DEFAULT_PROJECTION_AMOUNT
            : Number(Number(scenario?.projectionAmount).toFixed(0)));
        offerScenariosGrouped[scenarioId].push({
          eventId: scenario?.eventId as string,
          projectionAmount: roundedProjectionAmount / 100,
          projectionType: scenario?.projectionType,
        });
      });
    });

    await Promise.all(Object.keys(offerScenariosGrouped).map(async (scenarioId) => {
      const offerScenarios = Object.values(offerScenariosGrouped[scenarioId]);
      await modifyOfferScenario({
        variables: {
          bundleId,
          bundleOfferId,
          scenarioId,
          scenarios: {
            eventOfferScenarios: offerScenarios,
          },
        },
      });
    }));
  };

  const discardEdits = () => initializeOffers(bundleOfferData as BundleOfferQuery);

  return {
    activeOffers,
    inactiveOffers,
    totalActiveOffers,
    onChangeScenario,
    applyEdits,
    discardEdits,
    draftMode,
    loading,
  };
};
