import { ErrorOption } from 'react-hook-form';

import { RadioOptions } from '../shared';

export interface BundleOfferFormFields {
  name: string;
  crossed: boolean;
}

export type BundleOfferFormErrors = {
  name: ErrorOption[];
  crossed: ErrorOption[];
};

export const crossedOptions: RadioOptions[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
