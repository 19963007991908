import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridFilterInputDateProps } from '@mui/x-data-grid-pro';
import { Dayjs } from 'dayjs';
import { BorderlessDatePicker } from '@components';
import { DateRangeContainer, DateRangeTypography } from '../Tours.styled';

export const StartDateFilterInput = ({ item, applyValue }: GridFilterInputDateProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>((item?.value as Dayjs[])?.[0] || null);
  const [endDate, setEndDate] = useState<Dayjs | null>((item?.value as Dayjs[])?.[1] || null);

  useEffect(() => {
    if (startDate && endDate) {
      applyValue({ ...item, value: [startDate, endDate] });
    }
  }, [startDate, endDate]);

  return (
  <DateRangeContainer>
    <Box>
      <DateRangeTypography>From</DateRangeTypography>
      <BorderlessDatePicker
        value={startDate}
        onChange={setStartDate}
      />
    </Box>
    <Box>
      <DateRangeTypography>To</DateRangeTypography>
      <BorderlessDatePicker
        value={endDate}
        onChange={setEndDate}
      />
    </Box>
  </DateRangeContainer>
  );
};
