// lint exceptions in place because errors and row params are always of an 'any' type
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  GridCellParams, GridColDef, GridRowModel,
} from '@mui/x-data-grid-pro';
import {
  ConfigGridFields, FeeItemRow, FeeItemTypes, RenderCellType, ValidationErrorInfo,
} from '@types';
import { decimalPercentageGetter, decimalPercentageSetter, toValueOrUndefined } from '@utils/numberHelpers';
import { ConfigTypeEditCell } from './ConfigurationGrid/ConfigTypeEditCell';
import { RenderCell } from './RenderCell';

export type VenueGridProps = {
  disableVirtualization?: boolean;
};

const nonFilterableFields = {
  filterable: false,
  sortable: true,
};

const editableCellFields = {
  editable: true,
  ...nonFilterableFields,
};

export const handleConfigCellClassName = (params: GridCellParams) => {
  const fieldErrors: ValidationErrorInfo[] = params.row.errors?.filter(
    (e: ValidationErrorInfo) => e.field === params.field,
  );
  if (fieldErrors?.length && fieldErrors?.flatMap((e) => e.ids).includes(params.row.id)) {
    return 'validation-error';
  }
  return '';
};

export const handleFeeCellClassName = (params: GridCellParams) => {
  const fieldErrors: ValidationErrorInfo[] = params.row.errors?.filter(
    (e: ValidationErrorInfo) => e.field === params.field,
  );
  if (fieldErrors?.length && fieldErrors.map((e) => e.field).includes(params.field)) {
    return 'validation-error';
  }
  return '';
};

export const configurationColumns: GridColDef[] = [
  {
    field: ConfigGridFields.NAME,
    headerName: 'Configuration Name',
    minWidth: 220,
    cellClassName: handleConfigCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.TEXT, 'Enter Text', 30),
    ...editableCellFields,
  },
  {
    field: ConfigGridFields.CONFIG_TYPE,
    headerName: 'Configuration Type',
    flex: 0.2,
    cellClassName: handleConfigCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.CONFIG_TYPE),
    renderEditCell: (params: GridCellParams<GridRowModel>) => <ConfigTypeEditCell {...params} />,
    ...editableCellFields,
  },
  {
    field: ConfigGridFields.TIERS,
    headerName: 'Tiers',
    flex: 0.1,
    ...nonFilterableFields,
  },
  {
    field: ConfigGridFields.CAPACITY,
    headerName: 'Capacity',
    minWidth: 0.12,
    renderCell: (params) => RenderCell(params, RenderCellType.NUMBER),
    ...nonFilterableFields,
  },
  {
    field: ConfigGridFields.DESCRIPTION,
    headerName: 'Description',
    flex: 0.8,
    renderCell: (params) => RenderCell(params, RenderCellType.TEXT, 'Enter Text', 120),
    ...editableCellFields,
  },
];

export const fixedCostsColumns: GridColDef<FeeItemRow>[] = [
  {
    field: 'name',
    headerName: 'Category',
    editable: false,
    flex: 0.2,
    sortable: true,
  },
  {
    field: 'value',
    headerName: 'Total',
    flex: 0.35,
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.CURRENCY, '---'),
    ...editableCellFields,
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 0.45,
    renderCell: (params) => RenderCell(params, RenderCellType.TEXT, 'Enter Text', 120),
    ...editableCellFields,
  },
];

export const taxesColumns: GridColDef<FeeItemRow>[] = [
  {
    field: 'subCategory',
    headerName: 'Category',
    ...nonFilterableFields,
    flex: 0.2,
  },
  {
    field: 'value',
    headerName: 'Rate',
    flex: 0.116,
    ...editableCellFields,
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => RenderCell(params, 'percent', '---'),
    // This should be updated to render whatever value is passed to the row
    // GQL or BE should be resolving the value to the correct format
    valueGetter: decimalPercentageGetter,
    valueSetter: decimalPercentageSetter,
  },
  {
    field: 'formula',
    headerName: 'Type',
    ...nonFilterableFields,
    flex: 0.233,
  },
  {
    field: 'note',
    headerName: 'Note',
    ...editableCellFields,
    flex: 0.45,
    renderCell: (params) => RenderCell(params, 'text', 'Enter Text', 120),
  },
];

export const ticketFeesColumns: GridColDef[] = [
  {
    field: 'subCategory',
    flex: 0.2,
    headerName: 'Category',
    ...nonFilterableFields,
  },
  {
    field: 'value',
    flex: 0.116,
    headerName: 'Rate',
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.CURRENCY, '---'),
    ...editableCellFields,
  },
  {
    field: 'formula',
    flex: 0.233,
    headerName: 'Formula',
    ...nonFilterableFields,
  },
  {
    field: 'note',
    flex: 0.45,
    headerName: 'Note',
    renderCell: (params) => RenderCell(params, RenderCellType.TEXT, 'Enter Text', 120),
    ...editableCellFields,
  },
];

export const variableCostsColumns: GridColDef<FeeItemRow>[] = [
  {
    field: 'subCategory',
    headerName: 'Category',
    flex: 0.2,
    sortable: true,
  },
  {
    field: 'value',
    headerName: 'Rate',
    flex: 0.116,
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => {
      const renderCellType = params.row.type === FeeItemTypes.PERCENTAGE
        ? RenderCellType.PERCENT
        : RenderCellType.CURRENCY;
      return RenderCell(params, renderCellType, '---');
    },
    valueGetter: (params) => {
      if (params.row.type === FeeItemTypes.PERCENTAGE) {
        return decimalPercentageGetter(params);
      }
      return toValueOrUndefined(params.value);
    },
    valueSetter: (params) => {
      if (params.row.type === FeeItemTypes.PERCENTAGE) {
        return decimalPercentageSetter(params);
      }
      return {
        ...params.row, value: toValueOrUndefined(params.value),
      };
    },
    ...editableCellFields,
  },
  {
    field: 'min',
    headerName: 'Min.',
    flex: 0.116,
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.CURRENCY, '---'),
    ...editableCellFields,
  },
  {
    field: 'max',
    headerName: 'Max.',
    flex: 0.116,
    cellClassName: handleFeeCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.CURRENCY, '---'),
    ...editableCellFields,
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 0.45,
    renderCell: (params) => RenderCell(params, RenderCellType.TEXT, 'Enter Text', 60),
    ...editableCellFields,
  },
];

export const priceTierColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Price Tier',
    flex: 1,
  },
  {
    field: 'capacity',
    headerName: 'Capacity',
    flex: 1,
    ...editableCellFields,
    cellClassName: handleConfigCellClassName,
    renderCell: (params) => RenderCell(params, RenderCellType.NUMBER, 'Enter Value'),
  },
];
