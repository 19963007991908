import { RadioOptions } from '../shared';

export const configurationOptions = [
  { value: 'Other', label: 'Other' },
  { value: '180', label: '180' },
  { value: '220', label: '220' },
  { value: '240', label: '240' },
  { value: '270', label: '270' },
  { value: '360 (End)', label: '360 (End)' },
  { value: '360 (Round)', label: '360 (Round)' },
];

export const crossedOptions: RadioOptions[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const CREATE_OFFER_TEXT = {
  title: 'Create New Offer',
  subTitle: 'Please provide the details below to create a new offer.',
  submitText: 'Create',
};

export const EDIT_OFFER_TEXT = {
  title: 'Edit Offer',
  subTitle: null,
  submitText: 'Save',
};

export const DUPLICATE_OFFER_TEXT = {
  title: 'Duplicate Offer',
  subTitle: null,
  submitText: 'Duplicate',
};
