/* eslint-disable max-len */
import { Event } from '@gql/types/graphql';
import dayjs from 'dayjs';

const sortByMarket = (eventA: Event, eventB: Event) => {
  const marketA = eventA.venue?.city as string;
  const marketB = eventB.venue?.city as string;
  const venueA = eventA.venue?.name as string;
  const venueB = eventB.venue?.name as string;

  // if market is the same, sort by venue
  if (marketA === marketB) {
    return venueA.localeCompare(venueB);
  }
  // sort by market
  return marketA.localeCompare(marketB);
};

const sortEventsWithoutDates = (events: Event[]) => events.sort((eventA, eventB) => sortByMarket(eventA, eventB));

const sortEventsWithDates = (events: Event[]) => events.sort(
  (eventA, eventB) => {
    const dateA = dayjs(eventA.shows?.[0]?.showDateTime as string);
    const dateB = dayjs(eventB.shows?.[0]?.showDateTime as string);

    // if date and time are the same, sort by market
    if (dateA.isSame(dateB, 'minute')) {
      return sortByMarket(eventA, eventB);
    }
    // sort by date
    return (dateA).isBefore(dateB)
      ? -1
      : 1;
  },
);

export const sortEventsByDateActive = (events?: Event[]): Event[] => {
  const activeEventsWithDate = events?.filter((event) => event.shows?.[0]?.showDateTime && event.isActive) || [];
  const inactiveEventsWithDate = events?.filter((event) => event.shows?.[0]?.showDateTime && !event.isActive) || [];
  const activeEventsWithoutDate = events?.filter((event) => !event.shows?.[0]?.showDateTime && event.isActive) || [];
  const inactiveEventsWithoutDate = events?.filter((event) => !event.shows?.[0]?.showDateTime && !event.isActive) || [];

  return [
    ...sortEventsWithDates(activeEventsWithDate), // Event that has an EVENT DATE + ACTIVE - Sorted by date, then by market, then by venue
    ...sortEventsWithDates(inactiveEventsWithDate), // Event that has an EVENT DATE + INACTIVE - Sorted by date, then by market, then by venue
    ...sortEventsWithoutDates(activeEventsWithoutDate), // Event that is TBD + ACTIVE - Sorted by market, then by venue
    ...sortEventsWithoutDates(inactiveEventsWithoutDate), // Event that is TBD + INACTIVE - Sorted by market, then by venue
  ];
};

export const sortEventsByDate = (events?: Event[]): Event[] => {
  const activeEvents = events?.filter((event) => event.shows?.[0]?.showDateTime) || [];
  const inactiveEvents = events?.filter((event) => !event.shows?.[0]?.showDateTime) || [];

  return [
    ...sortEventsWithDates(activeEvents), // Event that has an EVENT DATE - Sorted by date, then by market, then by venue
    ...sortEventsWithDates(inactiveEvents), // Event that has an EVENT DATE - Sorted by date, then by market, then by venue
  ];
};
