import { getVersionIDFromNOSId } from './stringHelpers';

export const createVersionedId = (id: string, version?: number) => {
  if (!version || version === 1) {
    return id;
  }
  return `${id}_v${version}`;
};
/**
 * Finds the version number from the NOS settlement, by calling getVersionIDFromNOSId (v_##)
 * and extracting the number from it (##)
 */
export const getVersionIDNumberFromNOSId = (nosId: string): number | undefined => {
  const nosVersionStr = getVersionIDFromNOSId(nosId);
  return nosVersionStr ? parseInt(nosVersionStr.substring(1), 10) : undefined;
};
