import { Venue } from '@gql/types/graphql';
import { ProtectedComponent, SearchBar } from '@components';
import { GET_VENUES } from '@gql/queries/venues';
import { Typography } from '@mui/material';
import { ListViewLayout } from '@layouts/ListViewLayout';
import { BreadcrumbDispatchContext } from '@providers';
import { venueRegionDisplayFieldGetter } from '@utils/venueHelpers';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AegResourceTypes, UserPermissions } from '@types';
import {
  FlexContainer, MailIcon, NewVenueButton, SearchContainer, TextContainer,
} from './Venues.styled';

export function Venues() {
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);

  useEffect(() => {
    setBreadcrumbs([{ href: '/venues', text: 'Venue Management' }]);
  });

  useEffect(() => {
    document.title = 'Venue Management - Modern Elvis';
  }, []);

  const navigate = useNavigate();

  const recipient = encodeURIComponent('bookingsupport@aegpresents.com');
  const subject = encodeURIComponent('New venue request for Elvis');
  const mailtoUrl = `mailto:${recipient}?subject=${subject}`;

  return (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.ManageVenueQD,
        resourceType: AegResourceTypes.System,
      }}
      navigateAway={'/unauthorized'}
    >
      <FlexContainer>
        <ListViewLayout headerText="Search Venues">
          <SearchContainer>
            <SearchBar
              search={{
                query: GET_VENUES,
                displayFields: {
                  primary: 'name',
                  secondary: venueRegionDisplayFieldGetter,
                },
                resultKey: 'venues',
                shouldFlattenVenues: true,
                placeholder: 'Search Venues',
                minimumCharacters: 2,
                onSelect: (venue: Venue) => {
                  if (venue) {
                    navigate(`${venue?.id}/spaces/${venue?.spaces?.[0]?.id || ''}`);
                  }
                },
              }}
            />
            <NewVenueButton variant="contained" data-testid="request-venue-button" href={mailtoUrl}>
              <MailIcon />
              Request a New Venue
            </NewVenueButton>
          </SearchContainer>
          <TextContainer>
            <Typography data-testid="page-description">Search venues to view their configurations</Typography>
          </TextContainer>
        </ListViewLayout>
      </FlexContainer>
    </ProtectedComponent>
  );
}
