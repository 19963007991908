import { MenuItem, SelectChangeEvent } from '@mui/material';
import { GridApi, GridRowId } from '@mui/x-data-grid-pro';
import { configurationTypeOptions } from '@utils/configurationTypeHelpers';
import { ConfigGridFields } from '@types';
import { ConfigTypeSelect } from './ConfigurationGrid.styled';

interface EditCellProps {
  id: GridRowId;
  value?: string | unknown;
  api?: GridApi;
}

const dropdownOptions = configurationTypeOptions.map((option, index: number) => (
  <MenuItem
    key={`dropdown-option-${index}`}
    value={option.value}
    data-testid={`config-type-dropdown-option-${index}`}
  >
    {option.label}
  </MenuItem>
));

export const ConfigTypeEditCell: React.FC<EditCellProps> = (props) => {
  const { id, value, api } = props;
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const handleChange: (event: SelectChangeEvent<string | unknown>) => void = async (
    event: SelectChangeEvent<string | unknown>,
  ) => {
    await api?.setEditCellValue({ id, field: ConfigGridFields.CONFIG_TYPE, value: event.target.value });
  };

  return (
    <ConfigTypeSelect
      value={value}
      onChange={handleChange}
    >
      {dropdownOptions}
    </ConfigTypeSelect>
  );
};
