import { EventOfferScenario, BundleEvent } from '@gql/types/graphql';

export interface ScenarioBuilder {
  id: string;
  name: string;
  activeOffers: ScenarioBuilderEventOffer[];
  totalActiveOffers: TotalActiveOffers;
}

export interface ScenarioBuilderEventOffer extends Partial<BundleEvent>, CalculatedColumns {}
export interface TotalActiveOffers extends CalculatedColumns {
  scenarios: CalculatedScenario[];
}
export interface ScenarioBuilderProjection extends ScenarioProjectionCalculatedColumns {
  scenarios: ScenarioProjection[];
}

export enum ProjectionType {
  Average = 'average',
  Sequential = 'sequential',
  Manual = 'manual',
}

export interface ScenarioBuilderScenario extends Partial<Omit<EventOfferScenario, 'projectionAmount'>> {
  projectionAmount?: string; // String to be able to handle user input decimals. i.e '100.', '100%'
}

export interface CalculatedScenario {
  // If all the scenarios are not the same, use 'Varies'
  projectionType?: ScenarioBuilderScenario['projectionType'] | 'Varies';
  projectionAmount?: ScenarioBuilderScenario['projectionAmount'];
}

/* ---- Calculated data ---- */
export interface ScenarioProjection {
  ticketSales: number;
  sellableCapacity: number;
  netGrossPublic: number;
  totalArtistEarnings: number;
  clubTourPL: number;
}

interface CalculatedColumns {
  id: string;
  averageBreakeven: CalculatedScenario;
  sequentialBreakeven: CalculatedScenario;
  localOfferInternalForecastColumn: CalculatedScenario;
}

interface ScenarioProjectionCalculatedColumns {
  averageBreakeven: ScenarioProjection;
  sequentialBreakeven: ScenarioProjection;
  localOfferInternalForecastColumn: ScenarioProjection;
}
/* ------------------------- */
