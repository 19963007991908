import { Controller, useFormContext } from 'react-hook-form';
import { kebabCase } from '@utils/stringHelpers';
import { SearchAutocomplete } from '@components';
import { QueryBySearchTerm, QueryResponse, TypenameResponse } from '@types';
import { FormStylingMode, InputLabel, InputLabelContainer } from './Form.styled';

type FormSearchAutocompleteProps<
  Query extends TypenameResponse = any,
  Entity = any,
> = {
  fieldName: string;
  label?: string;
  isRequired?: boolean;
  query: QueryBySearchTerm<Query>;
  formStylingMode?: FormStylingMode;
  testId?: string;
  inputTestId?: string;
  getOptions: (data: QueryResponse<Query>) => (Entity | null)[];
  getOptionLabel: (entity: Entity) => string;
  onSubmit?: (currentValue: string) => void;
  onSelect?: (selectedOption: Entity) => void;
  placeholder?: string;
  clearOnBlur?: boolean;
};

export const FormSearchAutocomplete = ({
  fieldName,
  placeholder,
  label,
  isRequired,
  query,
  formStylingMode,
  clearOnBlur,
  testId,
  inputTestId,
  getOptions,
  getOptionLabel,
  onSubmit,
  onSelect,
}: FormSearchAutocompleteProps) => {
  const { control } = useFormContext();
  return (
    <>
      {label && (
        <InputLabelContainer formStylingMode={formStylingMode}>
          <InputLabel
            required={isRequired}
            data-testid={`${kebabCase(label)}-label`}
          >
            {label}
          </InputLabel>{' '}
        </InputLabelContainer>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SearchAutocomplete
            query={query}
            getOptions={getOptions}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            onSubmit={onSubmit}
            onSelect={(selectedOption) => {
              onChange(selectedOption);
              onSelect?.(selectedOption);
            }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={value}
            defaultValue={''}
            error={error !== undefined}
            helperText={error?.message?.toString()}
            formStylingMode={formStylingMode}
            clearOnBlur={clearOnBlur}
            testId={testId}
            inputTestId={inputTestId}
          />
        )}
      />
    </>
  );
};
