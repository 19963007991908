import * as yup from 'yup';
import {
  ModifyTeamMembersFormFields,
  ModifyTeamMembersFormUserRoles,
} from './ModifyTeamMembersForm.models';

const MemberFieldSchema = yup.object({
  id: yup.string().required(''),
  name: yup.string().required(''),
  email: yup.string().required(''),
}).noUnknown();

type ModifyTeamMemberSchema = yup.ObjectSchema<ModifyTeamMembersFormFields>;

export const ModifyTeamMemberFormSchema: ModifyTeamMemberSchema = yup.object({
  userRole: yup.mixed<ModifyTeamMembersFormUserRoles>().required(),
  operators: yup
    .array()
    .of(MemberFieldSchema.required('Enter at least one operator.'))
    .min(1, 'Enter at least one operator.')
    .required(),
  buyers: yup
    .array()
    .of(MemberFieldSchema.required('Enter at least one buyer.'))
    .min(1, 'Enter at least one buyer.')
    .required(),
  primaryBuyerId: yup.string(),
  primaryOperatorId: yup.string(),
  viewOnly: yup
    .array()
    .of(MemberFieldSchema)
    .required(),
  nosEmailDistribution: yup
    .array()
    .of(MemberFieldSchema),
});

export const ModifyTeamMemberWithPrimaryFormSchema: ModifyTeamMemberSchema = ModifyTeamMemberFormSchema.shape({
  primaryBuyerId: yup.string().required('Select a primary buyer.'),
  primaryOperatorId: yup.string().required('Select a primary operator.'),
});
