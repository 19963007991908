import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';

export function ExpandButtonCell(params: GridRenderCellParams<GridValidRowModel> & { onClick?: () => void }) {
  return (
    <GridActionsCellItem
      key={params.id}
      icon={params.value
        ? <ExpandLess data-testid="expand-less-icon" />
        : <ExpandMore data-testid="expand-more-icon" />
      }
      aria-label={params.value ? 'Close' : 'Open'}
      label="expand"
      onClick={params.onClick}
    />
  );
}
