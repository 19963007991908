import { BoxOfficeItem, CategoryBreakdown, SettlementPaymentInput } from '@gql/types/graphql';

export type ExpenseRow = Partial<CategoryBreakdown> & {
  name: string;
  rate: number;
  internalNotes?: string;
};

// does not include currency or exchangeRate from type CategoryBreakdown
export const EXPENSE_TABLE_ROW: ExpenseRow = {
  aegCash: 0,
  aegOffice: 0,
  aegRoad: 0,
  artist: 0,
  name: '',
  notes: '',
  internalNotes: '',
  rate: 0,
  partner: 0,
  total: 0,
  totalToBase: 0,
  venue: 0,
};

export const NOS_BOX_OFFICE_TABLE_ROW: BoxOfficeItem = {
  name: '',
  price: 0,
  kills: 0,
  comps: 0,
  deadwood: 0,
  sellableCapacity: 0,
  grossPotential: 0,
  capacity: 0,
};

export const SETTLEMENT_PAYMENT_TABLE_ROW: SettlementPaymentInput = {
  name: '',
  notes: '',
  localCurrency: '',
  exchangeRate: 0,
  total: 0,
  totalToBase: 0,
};

export type FixedCostRow = {
  Expenses: string;
  Notes: string;
  Budget: number;
  Actual: number;
  Difference: number;
  'Converted Total': number;
};

export const FIXED_COST_TABLE_ROW: FixedCostRow = {
  Expenses: '',
  Notes: '',
  Budget: 0,
  Actual: 0,
  Difference: 0,
  'Converted Total': 0,
};

export type VariableCostRow = FixedCostRow;
export const VARIABLE_COST_TABLE_ROW: VariableCostRow = FIXED_COST_TABLE_ROW;
