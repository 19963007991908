import { CustomTheme, styled } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export const BorderlessDateTimeInput = styled(DateTimePicker)(({ theme }: { theme?: CustomTheme }) => ({
  '& .MuiInputBase-input': {
    paddingLeft: '5px',
    paddingRight: '0',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .Mui-error .MuiInputBase-input': {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    border: `solid 1px ${theme?.palette.error.dark} !important`,
    paddingTop: '5px',
    paddingBottom: '5px',
  },

  '& .MuiInputLabel-root.Mui-error': {
    display: 'none',
  },
})) as typeof DateTimePicker<Dayjs>;
