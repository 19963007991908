import {
  Box, Typography, styled, Button,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const AddButtonWithText = styled(Button)({});

export const VenueDataGridContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  position: 'relative',
}));

export const VenueGridTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

export const PriceTierContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, 2),
}));

export const PlaceholderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem', // default datagrid size
}));

export const ValidationError = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const ValidationTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme?.palette.error.dark,
  },
}));
