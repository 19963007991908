import { Box, BoxProps } from '@mui/material';
import React, { SVGAttributes } from 'react';

type ImgProps = {
  alt: string;
  src: React.FunctionComponent<SVGAttributes<SVGElement>>;
  // add more HTML img attributes you need
};

export function SvgImage(props: BoxProps & ImgProps) {
  return <Box component="img" {...props} />;
}
