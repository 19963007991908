import { useMemo } from 'react';
import {
  GridColDef, GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { roundNumber } from '@utils/numberHelpers';
import { CondensedGrid } from '../../shared';
import { BoxOfficeTableContainer } from './QdTab.styled';
import { QdTabGridData, TierScalingGridData } from './QdTab.models';

export const BoxOfficeTable = ({
  row: space,
  disableVirtualization = false,
}: {
  row: QdTabGridData;
  disableVirtualization?: boolean;
}) => {
  const columns: GridColDef<TierScalingGridData>[] = [
    {
      field: 'name',
      headerName: 'Price Tier',
      minWidth: 180,
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      minWidth: 140,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()}`;
      },
    },
    {
      field: 'scale',
      headerName: 'Scale',
      minWidth: 140,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (typeof params.value === 'number' && Number.isFinite(params.value)) {
          const roundedNumber = roundNumber(params.value * 100, 1);
          return `${roundedNumber}%`;
        }
        if (params.value == null) {
          return '';
        }
        return params.value;
      },
    },
  ];

  const rows = useMemo(() => {
    if (space.tierScaling?.length) {
      return space.tierScaling.map((tier, idx) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key: `${tier?.name} {idx}`,
        id: idx,
        name: tier?.name as string,
        capacity: tier?.capacity as number,
        scale: space.totalCapacity && tier?.capacity
          ? (tier.capacity / space.totalCapacity) : 'Not Available',
      }));
    }

    return [];
  }, [space]);

  return (
    <BoxOfficeTableContainer>
      <CondensedGrid
        columns={columns}
        rows={rows}
        rowHeight={40}
        hideFooter={true}
        columnHeaderHeight={40}
        disableVirtualization={disableVirtualization}
        disableRowSelectionOnClick
        data-testid="box-office-grid"
      />
    </BoxOfficeTableContainer>
  );
};
