import { AegLogoBox } from '@components';
import lockedPage from '@images/aeg-access-prohibited-img.svg';

import {
  PageContainer,
  Header,
  ContentContainer,
  ErrorMessage,
  ActionButton,
  GenericMessage,
  ErrorImage,
} from '../ErrorPage.styled';

export function UnauthorizedFile() {
  const handleEmailClick = () => {
    const recipient = encodeURIComponent('bookingsupport@aegpresents.com');
    const subject = encodeURIComponent(
      'Please Review - Requesting Access to Modern Elvis',
    );
    const mailtoUrl = `mailto:${recipient}?subject=${subject}`;
    window.location.href = mailtoUrl;
  };

  return (
    <>
      <PageContainer data-testid="unauthorized-page-container">
        <Header>
          <AegLogoBox />
        </Header>
        <ContentContainer>
          <ErrorImage src={lockedPage} alt="unauthorized-page-image" />
          <ErrorMessage data-testid="unauthorized-message">
            You don't have permission to download this file.
          </ErrorMessage>
          <GenericMessage data-testid="unauthorized-generic-message">
            Hit request access button and we’ll find someone who can give you
            access.
          </GenericMessage>
          <ActionButton
            onClick={() => handleEmailClick()}
            data-testid="request-access-button"
          >
            Request File Access
          </ActionButton>
        </ContentContainer>
      </PageContainer>
    </>
  );
}
