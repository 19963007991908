import {
  styled,
  Select,
  TextField,
  Typography,
  CustomTheme,
} from '@mui/material';

export const TextWrapPrettyCell = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  textWrap: 'pretty',
  lineHeight: 'normal',
}));

export const LeftPinnedCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  width: '100%',
  height: '100%',
}));

export const TextFieldCell = styled(TextField)(({ theme }: { theme?: CustomTheme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: theme?.spacing(0.5),
    height: theme?.spacing(3.5),
    fontSize: theme?.spacing(1.75),
    alignContent: 'center',
  },
  '& fieldset': {
    borderColor: `${theme?.palette?.input?.disabled?.border ?? ''} !important`,
  },
}));

export const SelectCell = styled(Select)(({ theme }: { theme?: CustomTheme }) => ({
  width: '100%',
  fontSize: theme?.spacing(1.5),
  '&:before': {
    display: 'none',
  },
  '& .MuiInputBase-input': {
    height: `${theme?.spacing(3.5) ?? ''} !important`,
    padding: theme?.spacing(1),
    textAlign: 'right',
    fontSize: theme?.spacing(1.75),
    alignContent: 'center',
    verticalAlign: 'middle',
  },
  '&:disabled, & .Mui-disabled': {
    '& .MuiSelect-select': {
      color: theme?.palette?.input?.disabled?.color,
    },
  },
  '& fieldset': {
    borderColor: `${theme?.palette?.input?.disabled?.border ?? ''} !important`,
  },
}));
