import React from 'react';
import { capitalize } from '@mui/material';

import { CalculatedScenario, ProjectionType, ScenarioProjection } from '@types';
import { formatNumberToCurrency, formatNumberToPercentage } from '@utils/numberHelpers';

import { DivisionCell } from '../DivisionCell/DivisionCell';
import { ValueTypeCell } from '../ValueTypeCell/ValueTypeCell';
import { TotalsCellContainer } from './ScenarioBuilderCell.styled';

interface ScenarioBuilderCellProps extends ScenarioProjection, CalculatedScenario {
  name: string,
  disabled?: boolean
  onChangeAmount?: (value?: string) => void;
  onChangeType?: (type: string) => void;
}

export function ScenarioBuilderCell({
  name,
  ticketSales,
  sellableCapacity,
  netGrossPublic,
  totalArtistEarnings,
  clubTourPL,
  projectionType,
  projectionAmount,
  disabled,
  onChangeAmount,
  onChangeType,
}: Partial<ScenarioBuilderCellProps>) {
  switch (name) {
    case 'Ticket Sales / Sellable Capacity':
      return (
        <TotalsCellContainer>
          <DivisionCell
            dividend={ticketSales?.toLocaleString() ?? ''}
            divisor={sellableCapacity?.toLocaleString() ?? ''}
          />
        </TotalsCellContainer>
      );
    case 'Net Gross (Public)':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(netGrossPublic)}
        </TotalsCellContainer>
      );
    case 'Total Artist Earnings':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(totalArtistEarnings)}
        </TotalsCellContainer>
      );
    case 'Club Tour P&L':
      return (
        <TotalsCellContainer>
          {formatNumberToCurrency(clubTourPL)}
        </TotalsCellContainer>
      );
    case 'Inactive Offers':
      return <></>;
    default:
      return (
        <ValueTypeCell
          value={projectionAmount?.toString() ?? ''}
          formatValue={(value) => {
            if (value === null || value === undefined || value === '') {
              return '';
            }
            return formatNumberToPercentage(Number(value), 0, false);
          }}
          onChangeValue={onChangeAmount}
          type={projectionType?.toString() ?? ''}
          onSelectType={onChangeType}
          options={[
            {
              label: capitalize(ProjectionType.Average),
              value: ProjectionType.Average,
            },
            {
              label: capitalize(ProjectionType.Sequential),
              value: ProjectionType.Sequential,
            },
          ]}
          disabled={disabled}
        />
      );
  }
}
