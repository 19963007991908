import { graphql } from '@ooagg-gql/types';

export const GET_COMPANIES = graphql(`
  query GetCompanies {
    companies {
      id
      name
    }
  }
`);
