import { KeyOrKeyArray } from './SearchBar.models';

export const handleSecondaryField = <K>(data: K, props: KeyOrKeyArray<K>) => {
  if (Array.isArray(props)) {
    const secondaryText = props.map((prop) => data[prop]).join(' ');
    return `${secondaryText}`;
  }
  const secondaryText = data[props];
  return `${secondaryText as string}`;
};
