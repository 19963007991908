import { BoxOfficeItem, FixedCostInput, VariableCostInput } from '@gql/types/graphql';

export const BOX_OFFICE_TABLE_ROW: BoxOfficeItem = {
  name: '',
  price: 0,
  kills: 0,
  comps: 0,
  sellableCapacity: 0,
  grossPotential: 0,
  capacity: 0,
};

export type VariableCostRow = VariableCostInput & {
  name: string;
};

export const VARIABLE_COST_TABLE_ROW: VariableCostRow = {
  name: '',
  calculatedCost: 0,
  rate: 0,
  minimum: 0,
  maximum: 0,
  notes: '',
};

export type FixedCostRow = FixedCostInput & {
  name: string;
};

export const FIXED_COST_TABLE_ROW: FixedCostRow = {
  name: '',
  budget: 0,
  notes: '',
};

export type FixedCostParsedTableData = {
  name: string;
  budget: string;
  notes: string;
  convertedTotal: number;
};

export type PreTaxRow = {
  amount: number;
  formula: string;
  category: string;
  total: number;
  convertedTotal: string;
};

export type TaxRow = PreTaxRow;

export type PostTaxRow = Omit<PreTaxRow, 'formula'> & {
  notes: string;
};

const commonFields = {
  amount: 0,
  category: '',
  total: 0,
  convertedTotal: '',
};

export const PRE_TAX_TABLE_ROW: PreTaxRow = {
  ...commonFields,
  formula: '',
};

export const TAX_TABLE_ROW: TaxRow = PRE_TAX_TABLE_ROW;

export const POST_TAX_TABLE_ROW: PostTaxRow = {
  ...commonFields,
  notes: '',
};
