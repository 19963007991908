import { styled } from '@mui/material/styles';

export const FormErrorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const AlertContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));
