import { GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function TemplateUpdatedWarning() {
  return (
    <LayoutWarning header={'Template File Updated'}>
      <GenericMessage data-testid="generic-message">
        The template file has been updated to now auto open the Modern Elvis Add-In. Please close the file now.
      </GenericMessage>
    </LayoutWarning>
  );
}
