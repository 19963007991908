import { CircularProgress } from '@mui/material';
import {
  ButtonStyled,
} from '../shared/Form';
import {
  ConfirmModalSteps,
  CONFIRM_MODAL_TEXT,
  UNCONFIRM_MODAL_TEXT,
  NOTIFY_MODAL_TEXT,
} from '../../views/BundleDetails/BundleDetails.models';
import { ConfirmBundleContainer, ButtonContainer } from './ConfirmBundle.styled';

interface ConfirmBundleProps {
  mutationLoading: boolean;
  handleClose: () => void;
  modalStep: ConfirmModalSteps;
  submitAction: () => void;
}

export function ConfirmBundle({
  mutationLoading,
  handleClose,
  modalStep,
  submitAction,
}: Readonly<ConfirmBundleProps>) {
  const modalTexts = {
    [ConfirmModalSteps.confirm]: CONFIRM_MODAL_TEXT,
    [ConfirmModalSteps.unconfirm]: UNCONFIRM_MODAL_TEXT,
    [ConfirmModalSteps.notify]: NOTIFY_MODAL_TEXT,
  };

  const { submitText, cancelText } = modalTexts[modalStep];

  return (
    <ConfirmBundleContainer data-testid="confirm-bundle-modal">
      <ButtonContainer>
        <ButtonStyled
          data-testid="cancel-button"
          onClick={handleClose}
          type="button"
          variant="outlined"
        >
          {cancelText}
        </ButtonStyled>
        <ButtonStyled
          variant="contained"
          data-testid="confirm-bundle-btn"
          type="submit"
          disabled={mutationLoading}
          onClick={submitAction}
          startIcon={mutationLoading && <CircularProgress size={24} color="inherit" data-testid="progress-icon" />}
        >
          {!mutationLoading && submitText}
        </ButtonStyled>
      </ButtonContainer>
    </ConfirmBundleContainer>
  );
}
