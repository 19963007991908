import { CircularProgress } from '@mui/material';
import React from 'react';
import { StyledLoading } from './Loading.styled';

export function Loading() {
  return (
    <StyledLoading>
      <CircularProgress data-testid="circular-loading-icon" />
    </StyledLoading>
  );
}
