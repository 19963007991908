import * as yup from 'yup';
import { object, ObjectSchema } from 'yup';

import { HeadlinerModel } from '@components';
import { EventType, GenreModel } from '@types';
import { Agency, Agent } from '@gql/types/graphql';
import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

const headlinerGroupsSchema = object({
  headliner: yup.object<HeadlinerModel>().nullable().required('Please select a headliner.'),
  agency: yup.object<Agency>().nullable().required('Please select an agency.'),
  agents: yup.array().of(yup.object<Agent>().nullable()),
}).typeError('No results found.');

const genreSchema: ObjectSchema<GenreModel> = object({
  id: yup.string().required(),
  name: yup.string().required(),
}).typeError('No results found.');

export const createEventValidationSchema = yup.object({
  headliners: yup.array().of(headlinerGroupsSchema).required('Please select a headliner.'),
  name: yup
    .string()
    .required('Please enter an event name.')
    .matches(
      strWithoutSpecialCharsRegex,
      'Some special characters are not allowed',
    ),
  type: yup.mixed<EventType>().oneOf(Object.values(EventType)).required('Please select an event type.'),
  companyId: yup.string().required('Please select a company.'),
  genres: yup.array().of(genreSchema).min(1, 'Please select a genre.'),
});
