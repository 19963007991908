import { useMsal } from '@azure/msal-react';

export function useActiveAccount() {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const signOut = () => {
    void instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  return {
    signOut,
    account,
    instance,
  };
}
