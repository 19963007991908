/* eslint-disable max-lines-per-function */
// TODO: Resolve type issues in this view

import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import { ListViewLayout } from '@layouts/ListViewLayout';
import {
  CreateTourDrawer,
  FilteringListView,
  ListViewFilter,
  ListViewFilterColumn,
  ProtectedComponent,
  SearchAutocomplete,
  TabPanel,
} from '@components';
import { AegResourceTypes } from '@types';
import { useQuery } from '@apollo/client';
import { GET_ARTISTS_BY_ID } from '@gql/queries/artists';
import { GET_TOURS_PAGE, SEARCH_TOURS } from '@gql/queries/tours';
import { GET_USERS_BY_ID } from '@gql/queries/users';
import AddIcon from '@mui/icons-material/Add';
import { BreadcrumbDispatchContext } from '@providers';
import {
  CompanyPermission, ToursPageFieldsFragment, Tour,
} from '@gql/types/graphql';
import { useOnError } from '@hooks';
import { getSharedTourColumns } from '@utils/datagridHelpers';
import dayjs, { Dayjs } from 'dayjs';
import { CreateButton } from './Tours.styled';
import {
  PrimaryBuyerFilterInput, HeadlinerFilter, StartDateFilterInput, CompanyFilter,
} from './Filters';

const columns: GridColDef<ToursPageFieldsFragment>[] = [
  ...getSharedTourColumns<ToursPageFieldsFragment>(),
  {
    field: 'parentTour',
    headerName: 'Multi-Leg Tour',
    flex: 0.16,
    renderCell: (params) => (
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      <Link to={`/multi-leg-tour/${params?.row?.parentTour?.id || ''}`}>
        <Typography variant="body2" color="info.main">
          {params.row?.parentTour?.name}
        </Typography>
      </Link>
    ),
  },
];

export function Tours({ disableVirtualization = false }: { disableVirtualization?: boolean }) {
  const [searchParams] = useSearchParams();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [listViewFilterModel, setListViewFilterModel] = React.useState<ListViewFilter>();
  const navigate = useNavigate();
  const errorLog = useOnError();
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);

  React.useEffect(() => {
    document.title = 'Tours - Modern Elvis';
    setBreadcrumbs([
      {
        text: 'Tours',
        href: '/tours/',
      },
    ]);
  }, []);

  const artistIds = searchParams.getAll('headliner').filter((id) => !!id);
  const { loading: loadingArtists, data: artistData } = useQuery(GET_ARTISTS_BY_ID, {
    errorPolicy: 'all',
    variables: { artistIds },
    skip: !artistIds.length,
  });

  const userIds = searchParams.getAll('buyer').filter((id) => !!id);
  const { loading: loadingUsers, data: userData } = useQuery(GET_USERS_BY_ID, {
    errorPolicy: 'all',
    variables: { userIds },
    skip: !userIds.length,
  });

  const updateNameFilter = (searchTerm: string) => {
    const gridFilter = listViewFilterModel?.gridFilter ? [...listViewFilterModel.gridFilter] : [];
    const existingFilterIndex = gridFilter.findIndex((item) => item.field === 'name');

    if (searchTerm.length > 0) {
      if (existingFilterIndex >= 0) {
        gridFilter[existingFilterIndex].value = searchTerm;
      } else {
        gridFilter.push({ field: 'name', value: searchTerm, operator: 'contains' });
      }
    } else if (existingFilterIndex >= 0) {
      gridFilter.splice(existingFilterIndex, 1);
    }

    setListViewFilterModel({
      ...listViewFilterModel,
      gridFilter,
    });
  };

  const mapSearchParamsToFilter = (newSearchParams: URLSearchParams): GridFilterItem[] =>
    Array.from(newSearchParams.entries())
      .map(([field, value], index) => {
        // These default values work for most of the items
        const filterItem: GridFilterItem = {
          id: `${field}-${index}`,
          field,
          value: value ?? '',
          operator: 'is',
        };
        switch (field) {
          case 'name':
            filterItem.operator = 'contains';
            break;
          case 'headliner':
            filterItem.field = 'headlinerIds';
            if (value) {
              filterItem.value = artistData?.artists?.find(((a) => a?.id === value)) ?? { id: value };
            }
            break;
          case 'startDate':
            filterItem.value = value.split(',').map((date) => dayjs.utc(date));
            filterItem.operator = 'range';
            break;
          case 'buyer':
            filterItem.field = 'buyerIds';
            if (value) {
              filterItem.value = userData?.users?.find(((u) => u?.id === value)) ?? { id: value };
            }
            break;
          case 'company':
            filterItem.field = 'companyIdsFiltered';
            break;
          case 'status':
          default:
              // no-op
        }
        return filterItem;
      });

  const mapFilterToSearchParams = (gridFilter: GridFilterItem[]) : URLSearchParams => {
    const newSearchParams = new URLSearchParams();

    gridFilter
      .forEach((filterItem) => {
        let { field, value } = filterItem as { field: string; value: unknown };

        let fromDate: Dayjs | undefined;
        let toDate: Dayjs | undefined;
        let from: string;
        let to: string;
        switch (filterItem.field) {
          case 'headlinerIds':
            field = 'headliner';
            value = (value as { id: string })?.id;
            break;
          case 'startDate':
            fromDate = (value as Dayjs[])?.at(0);
            toDate = (value as Dayjs[])?.at(1);
            from = fromDate?.isValid() ? fromDate.format('YYYY-MM-DD') : '';
            to = toDate?.isValid() ? toDate.format('YYYY-MM-DD') : '';
            value = from.length && to.length ? `${from},${to}` : '';
            break;
          case 'buyerIds':
            field = 'buyer';
            value = (value as { id: string })?.id;
            break;
          case 'companyIdsFiltered':
            field = 'company';
            break;
          case 'name':
          case 'status':
          default:
            // no-op
        }
        newSearchParams.append(field, (value ?? '') as string);
      });

    return newSearchParams;
  };

  const {
    loading: loadingTours,
    fetchMore,
    data: toursData,
  } = useQuery(GET_TOURS_PAGE, {
    // Note: If limit less than container height (~15), would need to
    // implement 'load more' link/functionality as onRowsScrollEnd will not fire
    variables: {
      page: 1,
      limit: 25,
      tourSearch: {
        filters: listViewFilterModel?.queryFilter,
        sort: listViewFilterModel?.sort,
      },
    },
    errorPolicy: 'all',
    onError: (res) => errorLog(res),
  });

  const fetchPage = (page: number) => {
    void fetchMore({
      variables: {
        page,
      },
    });
  };

  const memoCols = React.useMemo<ListViewFilterColumn[]>(
    () =>
      columns.map((col) => {
        switch (col.field) {
          case 'name':
            return {
              ...col,
              filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
              singleFilterAllowed: true,
            };
          case 'buyerIds':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: PrimaryBuyerFilterInput,
                },
              ],
            };
          case 'startDate':
            return {
              ...col,
              sortable: true,
              singleFilterAllowed: true,
              filterOperators: [{
                label: 'range',
                value: 'range',
                getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                InputComponent: StartDateFilterInput,
              }],
            };
          case 'headlinerIds':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: HeadlinerFilter,
                },
              ],
            };
          case 'status':
            return {
              ...col,
              sortable: false,
              singleFilterAllowed: true,
              filterOperators: getGridSingleSelectOperators()
                .filter((operator) => operator.value === 'is')
                .map((operator) => ({
                  ...operator,
                  // This keeps an empty status filter in the filter window when the user re-opens it
                  requiresFilterValue: false,
                })),
            };
          case 'companyIdsFiltered':
            return {
              ...col,
              sortable: false,
              filterOperators: [
                {
                  label: 'is',
                  value: 'is',
                  getApplyFilterFn: () => (): boolean => true, // prevents client-side filtering
                  InputComponent: CompanyFilter,
                },
              ],
            };
          default:
            return ({
              ...col,
              sortable: false,
              filterable: false,
            });
        }
      }),
    [],
  );

  const nameSearchTerm = listViewFilterModel?.gridFilter?.find((item) => item.field === 'name')?.value as string | null;

  return (
    <ListViewLayout headerText="Tours">
      <TabPanel
        value={0}
        index={0}
        idPrefix="tours"
        data-testid="tours-tab-all-tours"
      >
        {/* All Tours */}
        <FilteringListView
          filterModel={listViewFilterModel}
          columns={memoCols}
          disableVirtualization={disableVirtualization}
          fetchPage={fetchPage}
          isLoadingData={loadingTours}
          isLoadingFilters={loadingArtists || loadingUsers}
          onFilterChange={setListViewFilterModel}
          mapFilterToSearchParams={mapFilterToSearchParams}
          mapSearchParamsToFilter={mapSearchParamsToFilter}
          rows={toursData?.tours ?? []}
          searchComponent={
            <SearchAutocomplete
              defaultValue={nameSearchTerm ?? ''}
              query={SEARCH_TOURS}
              getOptions={(data) => data.searchTours ?? []}
              getOptionLabel={(tour) => tour?.name ?? ''}
              placeholder={'Search Tour Name'}
              onSubmit={updateNameFilter}
              onSelect={(selectedTour: Tour) => {
                if (selectedTour?.id) {
                  navigate(`/tours/${selectedTour.id}`);
                }
              }}
              testId={'tours-search-autocomplete'}
              inputTestId={'search-autocomplete-input'}
            />
          }
          actions={
            <ProtectedComponent
              checkPermission={{
                permission: CompanyPermission.CreateTours,
                resourceType: AegResourceTypes.Company,
              }}
            >
              <CreateButton
                data-testid="create-button"
                variant="contained"
                onClick={() => setIsDrawerOpen(true)}
                startIcon={<AddIcon />}
              >
                Create
              </CreateButton>
            </ProtectedComponent>
          }
          />
      </TabPanel>
      <CreateTourDrawer
        isDrawerOpen={isDrawerOpen}
        closeDrawer={() => setIsDrawerOpen(false)}
      />
    </ListViewLayout>
  );
}
