import React from 'react';
import { useQuery } from '@apollo/client';

import { UserModel } from '@types';
import { AegAutocomplete, SearchInputComponent } from '@components';
import { SEARCH_USERS } from '@gql/queries/users';

type UserSearchProps = {
  selectedUser: UserModel | null;
  testId: string;
  onChange: (selectedPrimaryBuyer: UserModel | null) => void;
};

export const UserSearch = ({
  selectedUser,
  testId,
  onChange,
}: UserSearchProps) => {
  const [userSearchVal, setUserSearchVal] = React.useState('');
  const { loading: loadingUsers, data: userOptions } = useQuery(SEARCH_USERS, {
    errorPolicy: 'all',
    variables: { searchTerm: userSearchVal },
    skip: !userSearchVal,
  });

  return (
    <AegAutocomplete
    areOptionsLoading={loadingUsers}
    testId={testId}
    options={!loadingUsers && userOptions?.users ? userOptions.users : []}
    dataDisplayField={{ primary: 'name', secondary: 'email' }}
    dataFilterField={'id'}
    onInputChange={setUserSearchVal}
    placeholderText="User's Name or Email Address"
    value={selectedUser}
    onChange={(user) => {
      onChange(user as unknown as UserModel);
    }}
    InputComponent={SearchInputComponent}
  />
  );
};
