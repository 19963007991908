import { useEffect, useState } from 'react';

type PrerequisitesComponentProps = {
  prerequisites: Array<() => Promise<boolean>>;
  render: (prerequisitesMet: boolean | undefined, loading: boolean) => JSX.Element;
};

export const PrerequisitesComponent = ({ prerequisites, render }: PrerequisitesComponentProps) => {
  const [prerequisitesMet, setPrerequisitesMet] = useState<boolean | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    void Promise.allSettled(prerequisites.map(async (prerequisite) => {
      const result = await prerequisite();
      return result;
    })).then((results) => {
      const met = results.every((result) => result.status === 'fulfilled' && result.value);

      setPrerequisitesMet(met);

      setLoading(false);
    });
  }, []);

  return render(prerequisitesMet, loading);
};
