/* eslint-disable max-lines-per-function */
import { useAegNOSSettlement } from '@hooks';
import { UserPermissions, AegResourceTypes, EventStatus } from '@types';
import {
  SyncButton, NosFinalizeButton, ProtectedComponent, NosSubmitButton,
} from '@components';
import { useQuery } from '@apollo/client';
import { GET_EVENT } from '@gql/queries/events';
import { useMemo } from 'react';
import { ButtonContainer, SubButtonContainer } from './NosButtons.styled';

interface NosButtonProps {
  tourId: string;
  eventId: string;
}

export const NosButtons = ({ tourId, eventId }: NosButtonProps) => {
  const { syncNosSettlementWorkbook } = useAegNOSSettlement();

  const {
    data,
  } = useQuery(GET_EVENT, {
    variables: { eventId: eventId ?? '' },
  });

  const event = useMemo(() => data?.event, [data?.event]);

  return (
    <ButtonContainer>
      <SubButtonContainer>
        <SyncButton syncFn={syncNosSettlementWorkbook} outlined={true} />
        <ProtectedComponent
          checkPermission={{
            permission: UserPermissions.NosSettle,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}>
          <NosSubmitButton />
        </ProtectedComponent>
      </SubButtonContainer>
      <SubButtonContainer>
      {(event?.status === EventStatus.NOSSettled || event?.status === EventStatus.AccountingSettled)
      && <ProtectedComponent
        checkPermission={{
          permission: UserPermissions.FinalizeNosSettle,
          resourceType: AegResourceTypes.Tour,
          resourceId: tourId,
        }}>
          <NosFinalizeButton />
        </ProtectedComponent>}
      </SubButtonContainer>
    </ButtonContainer>
  );
};
