import React, { useContext } from 'react';
import { WebUserContext } from '@providers';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedLayout = () => {
  const user = useContext(WebUserContext);
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  const isInProgress = inProgress !== 'none'; // inProgress returns 'none'

  if (isInProgress || (isAuthenticated && !user)) {
    return <>Loading</>;
  }

  if (isAuthenticated) {
    if (user && user.availableCompanies?.length > 0) {
      return <Outlet />;
    }
    return <Navigate to={'unauthorized'} />;
  }
  return (
    <Navigate to={`/auth/signin?returnUrl=${location.pathname}`} replace />
  );
};
