/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActualBoxOfficeOverview = {
  __typename?: 'ActualBoxOfficeOverview';
  totalAvailableCapacity?: Maybe<Scalars['Int']>;
  totalGross?: Maybe<Scalars['Float']>;
  totalGrossToBase?: Maybe<Scalars['Float']>;
  totalSellableCapacity?: Maybe<Scalars['Int']>;
};

export type AddAgentInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type AddOneOffEventInput = {
  coPromoterDeals?: InputMaybe<Array<InputMaybe<CoPromoterDealInput>>>;
  commitment?: InputMaybe<Scalars['Float']>;
  includeInForecast?: InputMaybe<Scalars['Boolean']>;
  isSettledInNewElvis?: InputMaybe<Scalars['Boolean']>;
  offerNotes?: InputMaybe<Scalars['String']>;
  pdfConfig?: InputMaybe<PdfConfigInput>;
  projectedPoolTaxAdjustmentsTotalToBase?: InputMaybe<Scalars['Float']>;
  projectedPreTaxAdjustmentsTotalToBase?: InputMaybe<Scalars['Float']>;
  radiusRestrictionNotes?: InputMaybe<Scalars['String']>;
};

export type AddOneOffInput = {
  companyId: Scalars['String'];
  genreIds: Array<InputMaybe<Scalars['String']>>;
  hallPassUsers?: InputMaybe<Array<HallPassUserInput>>;
  headliners: Array<HeadlinerInformationInput>;
  name: Scalars['String'];
  notesAndComments?: InputMaybe<Scalars['String']>;
  oneOffType?: InputMaybe<Scalars['String']>;
  primaryBuyerId: Scalars['ID'];
  rosterUsers?: InputMaybe<Array<RosterUserInput>>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AdjustmentsSummary = {
  __typename?: 'AdjustmentsSummary';
  netShowReceipts?: Maybe<Scalars['Float']>;
  netShowReceiptsToBase?: Maybe<Scalars['Float']>;
};

export type Agency = {
  __typename?: 'Agency';
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Agent = {
  __typename?: 'Agent';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AncillaryCategoryItem = {
  __typename?: 'AncillaryCategoryItem';
  categoryId?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  items?: Maybe<Array<AncillaryCost>>;
  total?: Maybe<Scalars['Float']>;
  totalGrossPotential?: Maybe<Scalars['Float']>;
  totalProjected?: Maybe<Scalars['Float']>;
  totalProjectedToBase?: Maybe<Scalars['Float']>;
};

export type AncillaryCost = {
  __typename?: 'AncillaryCost';
  amount?: Maybe<Scalars['Float']>;
  calcType?: Maybe<Scalars['String']>;
  coPro1?: Maybe<Scalars['Float']>;
  coPro2?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  grossPotential?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  localCurrency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  projected?: Maybe<Scalars['Float']>;
  totalToBase?: Maybe<Scalars['Float']>;
};

export type Artist = {
  __typename?: 'Artist';
  genres?: Maybe<Array<Maybe<Genre>>>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  subGenres?: Maybe<Array<Maybe<Genre>>>;
};

export type ArtistAegSettlement = {
  __typename?: 'ArtistAEGSettlement';
  artistEarningsTotal?: Maybe<Scalars['Float']>;
  artistEarningsTotalToBase?: Maybe<Scalars['Float']>;
  coPromoterShareTotal?: Maybe<Scalars['Float']>;
  coPromoterShareTotalToBase?: Maybe<Scalars['Float']>;
  expenseAdjustmentTotal?: Maybe<Scalars['Float']>;
  expenseAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  netProfitOrLossToAegTotal?: Maybe<Scalars['Float']>;
  netProfitOrLossToAegTotalToBase?: Maybe<Scalars['Float']>;
  netProfitOrLossTotal?: Maybe<Scalars['Float']>;
  netProfitOrLossTotalToBase?: Maybe<Scalars['Float']>;
  netShowReceiptsTotal?: Maybe<Scalars['Float']>;
  netShowReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  otherAdjustmentTotal?: Maybe<Scalars['Float']>;
  otherAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  pctCoPromoterShare?: Maybe<Scalars['Float']>;
};

export type ArtistBonusConfiguration = {
  __typename?: 'ArtistBonusConfiguration';
  bonusAmount?: Maybe<Scalars['Float']>;
  bonusFormula?: Maybe<BonusFormula>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  triggerMax?: Maybe<Scalars['Float']>;
  triggerMin?: Maybe<Scalars['Float']>;
  triggerType?: Maybe<TriggerType>;
};

export type ArtistNosSettlementDetail = {
  __typename?: 'ArtistNosSettlementDetail';
  actualBoxOfficeOverview?: Maybe<ActualBoxOfficeOverview>;
  actualPostTaxAdjustmentsTotal?: Maybe<Scalars['Float']>;
  actualPostTaxAdjustmentsTotalToBase?: Maybe<Scalars['Float']>;
  aegSettlement?: Maybe<ArtistAegSettlement>;
  ancillaryEarnings?: Maybe<Array<Maybe<SettlementPayment>>>;
  artistPaymentDue?: Maybe<Array<Maybe<SettlementPayment>>>;
  artistRep?: Maybe<Scalars['String']>;
  artistSettlement?: Maybe<ArtistSettlement>;
  baseCurrency?: Maybe<Scalars['String']>;
  compTicketSummary?: Maybe<CompTicketSummary>;
  contact?: Maybe<Scalars['String']>;
  dropCountSummary?: Maybe<DropCountSummary>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localCurrency?: Maybe<Scalars['String']>;
  netShowReceipts?: Maybe<NetShowReceipts>;
  notesAndComments?: Maybe<Scalars['String']>;
  preparedBy?: Maybe<Scalars['String']>;
  preparedOn?: Maybe<Scalars['String']>;
  venueSettlement?: Maybe<Array<Maybe<SettlementPayment>>>;
};

export type ArtistOffer = {
  __typename?: 'ArtistOffer';
  artistAdjustmentNotes?: Maybe<Scalars['String']>;
  bonusConfigurations?: Maybe<Array<Maybe<ArtistBonusConfiguration>>>;
  dealType?: Maybe<ArtistOfferDealType>;
  earningType?: Maybe<EarningType>;
  guarantee?: Maybe<Scalars['Float']>;
  percentNetPoolEarnings?: Maybe<Scalars['Float']>;
};

export enum ArtistOfferDealType {
  Flat = 'Flat',
  Gross = 'Gross',
  Net = 'Net',
  Plus = 'Plus'
}

export type ArtistSettlement = {
  __typename?: 'ArtistSettlement';
  artistAdjustmentNote?: Maybe<Scalars['String']>;
  artistAdjustmentTotal?: Maybe<Scalars['Float']>;
  artistAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  artistNosSettlementTotal?: Maybe<Scalars['Float']>;
  artistNosSettlementTotalToBase?: Maybe<Scalars['Float']>;
  guarantee?: Maybe<Scalars['Float']>;
  guaranteeToBase?: Maybe<Scalars['Float']>;
  netPoolEarningsTotal?: Maybe<Scalars['Float']>;
  netPoolEarningsTotalToBase?: Maybe<Scalars['Float']>;
  pctOfNetPoolEarnings?: Maybe<Scalars['Float']>;
};

export type ArtistSettlementSummary = {
  __typename?: 'ArtistSettlementSummary';
  artistAdjustment?: Maybe<Scalars['Float']>;
  artistAdjustmentToBase?: Maybe<Scalars['Float']>;
  artistEarnings?: Maybe<Scalars['Float']>;
  artistEarningsToBase?: Maybe<Scalars['Float']>;
  artistTickets?: Maybe<Scalars['Float']>;
  artistTicketsToBase?: Maybe<Scalars['Float']>;
  bandReimbursements?: Maybe<Scalars['Float']>;
  bandReimbursementsToBase?: Maybe<Scalars['Float']>;
  cashAdvances?: Maybe<Scalars['Float']>;
  cashAdvancesToBase?: Maybe<Scalars['Float']>;
  deposits?: Maybe<Scalars['Float']>;
  depositsToBase?: Maybe<Scalars['Float']>;
  guarantee?: Maybe<Scalars['Float']>;
  guaranteeToBase?: Maybe<Scalars['Float']>;
  netPoolEarnings?: Maybe<Scalars['Float']>;
  netPoolEarningsToBase?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
  otherToBase?: Maybe<Scalars['Float']>;
  pctOfNetPoolEarnings?: Maybe<Scalars['Float']>;
  totalDueArtist?: Maybe<Scalars['Float']>;
  totalDueArtistToBase?: Maybe<Scalars['Float']>;
};

export type AvailableCompanies = {
  __typename?: 'AvailableCompanies';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum BonusFormula {
  DealPercent = 'DealPercent',
  Flat = 'Flat',
  PerHead = 'PerHead',
  PerPaid = 'PerPaid'
}

export type BoxOffice = {
  __typename?: 'BoxOffice';
  currency?: Maybe<Scalars['String']>;
  dropCount?: Maybe<Scalars['Int']>;
  gross?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  net?: Maybe<Scalars['Float']>;
  phases?: Maybe<Array<BoxOfficePhase>>;
  sellableCapacity?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['String']>;
  ticketingDeal?: Maybe<Scalars['Int']>;
};


export type BoxOfficePhasesArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoxOfficeFee = {
  __typename?: 'BoxOfficeFee';
  amount?: Maybe<Scalars['Float']>;
  formula?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  profitLossCategoryId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BoxOfficeItem = {
  __typename?: 'BoxOfficeItem';
  capacity?: Maybe<Scalars['Int']>;
  comps?: Maybe<Scalars['Int']>;
  fees?: Maybe<Array<Maybe<BoxOfficeFee>>>;
  gross?: Maybe<Scalars['Float']>;
  grossPotential?: Maybe<Scalars['Float']>;
  kills?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  netGrossPotential?: Maybe<Scalars['Float']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sellableCapacity?: Maybe<Scalars['Int']>;
  totalPostTaxFees?: Maybe<Scalars['Float']>;
  totalPreTaxFees?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
};

export type BoxOfficePhase = {
  __typename?: 'BoxOfficePhase';
  dropCountPercentage?: Maybe<Scalars['Float']>;
  dropCountValue?: Maybe<Scalars['Int']>;
  fees?: Maybe<Array<Maybe<BoxOfficeFee>>>;
  id: Scalars['String'];
  items?: Maybe<Array<Maybe<BoxOfficeItem>>>;
  percentSold?: Maybe<Scalars['Float']>;
  percentSoldType?: Maybe<PercentSoldType>;
};

export type CategoryBreakdown = {
  __typename?: 'CategoryBreakdown';
  aegCash?: Maybe<Scalars['Float']>;
  aegOffice?: Maybe<Scalars['Float']>;
  aegRoad?: Maybe<Scalars['Float']>;
  artist?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  totalToBase?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['Float']>;
};

export type CoProCalculation = {
  __typename?: 'CoProCalculation';
  ancillaryRevenue?: Maybe<ProjectionPercentage>;
  artistPercent?: Maybe<ProjectionPercentage>;
  guarantee?: Maybe<Projection>;
  name?: Maybe<Scalars['String']>;
  partnerShare?: Maybe<Projection>;
  percentage?: Maybe<ProjectionPercentage>;
};

export type CoProCategoryItem = {
  __typename?: 'CoProCategoryItem';
  categoryId?: Maybe<Scalars['String']>;
  payments?: Maybe<CoProCategoryItemPayment>;
};

export type CoProCategoryItemPayment = {
  __typename?: 'CoProCategoryItemPayment';
  aeg?: Maybe<Scalars['Float']>;
  partner1?: Maybe<Scalars['Float']>;
  partner2?: Maybe<Scalars['Float']>;
  partner3?: Maybe<Scalars['Float']>;
  partner4?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['Float']>;
};

export type CoPromoterDeal = {
  __typename?: 'CoPromoterDeal';
  amount?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  copromoterId?: Maybe<Scalars['String']>;
  dealType?: Maybe<Scalars['String']>;
  forecast?: Maybe<Scalars['Float']>;
  includeAncillaries?: Maybe<Scalars['Boolean']>;
  includeNameInPdf?: Maybe<Scalars['Boolean']>;
  venue?: Maybe<Venue>;
};

export type CoPromoterDealInput = {
  amount?: InputMaybe<Scalars['Float']>;
  coPromoterId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['String']>;
  dealType?: InputMaybe<Scalars['String']>;
  includeAncillaries?: InputMaybe<Scalars['Boolean']>;
  includeNameInPdf?: InputMaybe<Scalars['Boolean']>;
  venueId?: InputMaybe<Scalars['String']>;
};

export type CompTicketSummary = {
  __typename?: 'CompTicketSummary';
  artistAndTour?: Maybe<Scalars['Int']>;
  promoter?: Maybe<Scalars['Int']>;
  promotion?: Maybe<Scalars['Int']>;
  relocateAndTech?: Maybe<Scalars['Int']>;
  sponsor?: Maybe<Scalars['Int']>;
  venue?: Maybe<Scalars['Int']>;
};

export type Company = {
  __typename?: 'Company';
  folderId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  jdeCompanyNumber: Scalars['Int'];
  lobType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  subledgerAutomationCode: Scalars['String'];
};

export type ConfirmationInfo = {
  __typename?: 'ConfirmationInfo';
  requirements?: Maybe<Array<Scalars['String']>>;
  result: Scalars['Boolean'];
};

export type Country = {
  __typename?: 'Country';
  cities?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  states?: Maybe<Array<State>>;
};

export type DropCountSummary = {
  __typename?: 'DropCountSummary';
  other?: Maybe<Scalars['Int']>;
  paidAndComp?: Maybe<Scalars['Int']>;
  suite?: Maybe<Scalars['Int']>;
  unredeemed?: Maybe<Scalars['Int']>;
};

export enum EarningType {
  Merged = 'Merged',
  Unmerged = 'Unmerged'
}

export type EarningsSummary = {
  __typename?: 'EarningsSummary';
  gross?: Maybe<Scalars['Float']>;
  grossToBase?: Maybe<Scalars['Float']>;
  netGross?: Maybe<Scalars['Float']>;
  netGrossToBase?: Maybe<Scalars['Float']>;
  postTaxAdjustment?: Maybe<Scalars['Float']>;
  postTaxAdjustmentToBase?: Maybe<Scalars['Float']>;
  preTaxAdjustment?: Maybe<Scalars['Float']>;
  preTaxAdjustmentToBase?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  taxesToBase?: Maybe<Scalars['Float']>;
};

export type Event = {
  __typename?: 'Event';
  artistOffer?: Maybe<ArtistOffer>;
  baseCurrency?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  canceledByUser?: Maybe<Scalars['String']>;
  compBreakdown?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  configuration?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  dealLanguage?: Maybe<Scalars['String']>;
  defaultBoxOffice?: Maybe<BoxOffice>;
  exchangeRate?: Maybe<Scalars['Float']>;
  expense?: Maybe<Expense>;
  genre?: Maybe<Genre>;
  headliners?: Maybe<Array<Maybe<Artist>>>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  localCurrency?: Maybe<Scalars['String']>;
  merchandiseDeal?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nosSettlement?: Maybe<NosSettlement>;
  nosSettlementVersions?: Maybe<Array<Maybe<NosSettlement>>>;
  notesAndComments?: Maybe<Scalars['String']>;
  preparedBy?: Maybe<Scalars['String']>;
  preparedOn?: Maybe<Scalars['String']>;
  primaryBooker?: Maybe<User>;
  projectedAegEarnings?: Maybe<ProjectedAegEarnings>;
  projectedArtistOffer?: Maybe<ProjectedArtistOffer>;
  projectedBoxOfficeOverview?: Maybe<ProjectedBoxOfficeOverview>;
  projectedNetShowReceipts?: Maybe<ProjectedNetShowReceipts>;
  projectedPostTaxAdjustmentsTotalToBase?: Maybe<Scalars['Float']>;
  projectedSales?: Maybe<Scalars['Float']>;
  projectedSalesToBase?: Maybe<Scalars['Float']>;
  projectedSequentialTotalShows?: Maybe<Scalars['Float']>;
  projectedShowIncome?: Maybe<ProjectedShowIncome>;
  sellableCapacity?: Maybe<Scalars['Int']>;
  shows?: Maybe<Array<Maybe<Show>>>;
  space?: Maybe<Space>;
  status?: Maybe<Scalars['String']>;
  supports?: Maybe<Array<Maybe<Artist>>>;
  type?: Maybe<Scalars['String']>;
  venue?: Maybe<Venue>;
  venueStagehandsCost?: Maybe<Scalars['Float']>;
};


export type EventNosSettlementArgs = {
  version?: InputMaybe<Scalars['Int']>;
};

export type EventFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  returnTBD?: InputMaybe<Scalars['Boolean']>;
  spaceId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  top?: InputMaybe<Scalars['Int']>;
  venueId?: InputMaybe<Scalars['String']>;
};

export type Expense = {
  __typename?: 'Expense';
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phases?: Maybe<Array<ExpensePhase>>;
};


export type ExpensePhasesArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type ExpensePhase = {
  __typename?: 'ExpensePhase';
  ancillaryCategoryItems?: Maybe<Array<AncillaryCategoryItem>>;
  artistRep?: Maybe<Scalars['String']>;
  coProAsAdjustments?: Maybe<Scalars['Float']>;
  coProCalculations?: Maybe<Array<CoProCalculation>>;
  coProCategoryItems?: Maybe<Array<CoProCategoryItem>>;
  contact?: Maybe<Scalars['String']>;
  fixedCategoryItems?: Maybe<Array<FixedCategoryItem>>;
  id: Scalars['String'];
  internalNotes?: Maybe<Scalars['String']>;
  itemizedArtistCategoryItems?: Maybe<Array<ItemizedArtistCategoryItem>>;
  sellOffCalculations?: Maybe<SellOffCalculations>;
  seqVariableCosts?: Maybe<Scalars['Float']>;
  seqVariableCostsToBase?: Maybe<Scalars['Float']>;
  totalAncillaryRevenue?: Maybe<Scalars['Float']>;
  totalBudgetVariableFixedCosts?: Maybe<Scalars['Float']>;
  totalVariableCosts?: Maybe<Scalars['Float']>;
  totalVariableCostsToBase?: Maybe<Scalars['Float']>;
  totalVariableFixedCosts?: Maybe<Scalars['Float']>;
  variableCategoryItems?: Maybe<Array<VariableCategoryItem>>;
};

export type FeeCategories = {
  __typename?: 'FeeCategories';
  items?: Maybe<Array<Maybe<FeeItem>>>;
  name?: Maybe<Scalars['String']>;
};

export type FeeItem = {
  __typename?: 'FeeItem';
  category?: Maybe<Scalars['String']>;
  categoryParent?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  profitLossCategoryId: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type FixedCategoryItem = {
  __typename?: 'FixedCategoryItem';
  breakdown?: Maybe<CategoryBreakdown>;
  categoryId?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  items?: Maybe<Array<FixedCost>>;
  totalBudget?: Maybe<Scalars['Float']>;
  totalBudgetToBase?: Maybe<Scalars['Float']>;
};

export type FixedCost = {
  __typename?: 'FixedCost';
  breakdown?: Maybe<CategoryBreakdown>;
  budget?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  localCurrency?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type Genre = {
  __typename?: 'Genre';
  id: Scalars['String'];
  mainGenre?: Maybe<Genre>;
  name: Scalars['String'];
};

export type HallPassUser = {
  __typename?: 'HallPassUser';
  role: Scalars['String'];
  user: User;
};

export type HallPassUserInput = {
  role: Scalars['String'];
  userId: Scalars['ID'];
};

export type HeadlinerInformation = {
  __typename?: 'HeadlinerInformation';
  agency: Agency;
  agents: Array<Agent>;
  artist: Artist;
};

export type HeadlinerInformationInput = {
  agencyId: Scalars['String'];
  agentIds?: InputMaybe<Array<Scalars['String']>>;
  artistId: Scalars['String'];
};

export type Health = {
  __typename?: 'Health';
  authZedResult?: Maybe<HealthResult>;
  microserviceResults?: Maybe<Array<Maybe<HealthResponse>>>;
};

export type HealthConfigResults = {
  __typename?: 'HealthConfigResults';
  APPINSIGHTS_INSTRUMENTATIONKEY?: Maybe<Scalars['Boolean']>;
  AzureWebJobsStorage?: Maybe<Scalars['Boolean']>;
  COSMOSDB_CONNECTION_STR?: Maybe<Scalars['Boolean']>;
  COSMOSDB_DATABASE_NAME?: Maybe<Scalars['Boolean']>;
};

export type HealthContainerResults = {
  __typename?: 'HealthContainerResults';
  isSuccess?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type HealthResponse = {
  __typename?: 'HealthResponse';
  configResults?: Maybe<HealthConfigResults>;
  containerResults?: Maybe<Array<Maybe<HealthContainerResults>>>;
};

export type HealthResult = {
  __typename?: 'HealthResult';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type InternalAegSettlement = {
  __typename?: 'InternalAEGSettlement';
  ancillaryIncomeTotalToBase?: Maybe<Scalars['Float']>;
  artistEarningsTotal?: Maybe<Scalars['Float']>;
  artistEarningsTotalToBase?: Maybe<Scalars['Float']>;
  coPromoterShareTotal?: Maybe<Scalars['Float']>;
  coPromoterShareTotalToBase?: Maybe<Scalars['Float']>;
  expenseAdjustmentTotal?: Maybe<Scalars['Float']>;
  expenseAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  netProfitOrLossToAegTotal?: Maybe<Scalars['Float']>;
  netProfitOrLossToAegTotalToBase?: Maybe<Scalars['Float']>;
  netProfitOrLossTotal?: Maybe<Scalars['Float']>;
  netProfitOrLossTotalToBase?: Maybe<Scalars['Float']>;
  netShowReceiptsTotal?: Maybe<Scalars['Float']>;
  netShowReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  otherAdjustmentTotal?: Maybe<Scalars['Float']>;
  otherAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  pctCoPromoterShare?: Maybe<Scalars['Float']>;
};

export type InternalNosSettlementDetail = {
  __typename?: 'InternalNosSettlementDetail';
  actualBoxOfficeOverview?: Maybe<ActualBoxOfficeOverview>;
  actualPostTaxAdjustmentsTotal?: Maybe<Scalars['Float']>;
  actualPostTaxAdjustmentsTotalToBase?: Maybe<Scalars['Float']>;
  aegSettlement?: Maybe<InternalAegSettlement>;
  ancillaryEarnings?: Maybe<Array<Maybe<SettlementPayment>>>;
  artistPaymentDue?: Maybe<Array<Maybe<SettlementPayment>>>;
  artistRep?: Maybe<Scalars['String']>;
  artistSettlement?: Maybe<ArtistSettlement>;
  baseCurrency?: Maybe<Scalars['String']>;
  compTicketSummary?: Maybe<CompTicketSummary>;
  contact?: Maybe<Scalars['String']>;
  dropCountSummary?: Maybe<DropCountSummary>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localCurrency?: Maybe<Scalars['String']>;
  netShowReceipts?: Maybe<NetShowReceipts>;
  netToPool?: Maybe<NetToPool>;
  notesAndComments?: Maybe<Scalars['String']>;
  preparedBy?: Maybe<Scalars['String']>;
  preparedOn?: Maybe<Scalars['String']>;
  venueSettlement?: Maybe<Array<Maybe<SettlementPayment>>>;
};

export type ItemizedArtistCategoryItem = {
  __typename?: 'ItemizedArtistCategoryItem';
  breakdown?: Maybe<CategoryBreakdown>;
  categoryId?: Maybe<Scalars['String']>;
};

export type MessageToQueueResponse = {
  __typename?: 'MessageToQueueResponse';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAgent?: Maybe<Agent>;
  addOneOff?: Maybe<OneOff>;
  addOneOffEvent?: Maybe<OneOffEvent>;
  generateOfferPdf?: Maybe<MessageToQueueResponse>;
  updateAgent?: Maybe<Agent>;
};


export type MutationAddAgentArgs = {
  agent: AddAgentInput;
};


export type MutationAddOneOffArgs = {
  oneOff: AddOneOffInput;
};


export type MutationAddOneOffEventArgs = {
  oneOffEvent: AddOneOffEventInput;
  oneOffId: Scalars['ID'];
};


export type MutationGenerateOfferPdfArgs = {
  input: OneOffOfferPdfInput;
};


export type MutationUpdateAgentArgs = {
  agent: UpdateAgentInput;
};

export type NetShowReceipts = {
  __typename?: 'NetShowReceipts';
  netGrossReceiptsTotal?: Maybe<Scalars['Float']>;
  netGrossReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  netShowExpensesTotal?: Maybe<Scalars['Float']>;
  netShowExpensesTotalToBase?: Maybe<Scalars['Float']>;
  netShowReceiptsTotal?: Maybe<Scalars['Float']>;
  netShowReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  showAdjustments?: Maybe<Array<Maybe<ShowAdjustment>>>;
};

export type NetToPool = {
  __typename?: 'NetToPool';
  actual?: Maybe<Scalars['Float']>;
  projected?: Maybe<Scalars['Float']>;
  variance?: Maybe<Scalars['Float']>;
};

export type NosSettlement = {
  __typename?: 'NosSettlement';
  artistSettlement?: Maybe<ArtistNosSettlementDetail>;
  event?: Maybe<Event>;
  id: Scalars['String'];
  internalSettlement?: Maybe<InternalNosSettlementDetail>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  populatedExpensePhase?: Maybe<ExpensePhase>;
  populatedShows?: Maybe<Array<Maybe<Show>>>;
  submittedBy?: Maybe<User>;
  submittedOn?: Maybe<Scalars['String']>;
  summary?: Maybe<SummaryDetail>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  workbookItemId?: Maybe<Scalars['String']>;
};

export type OneOff = {
  __typename?: 'OneOff';
  baseCurrency?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['String']>;
  cancelReason?: Maybe<Scalars['String']>;
  canceledByUserId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  events?: Maybe<Array<OneOffEvent>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  genres?: Maybe<Array<Genre>>;
  hallPassUsers?: Maybe<Array<HallPassUser>>;
  hasBeenConfirmed?: Maybe<Scalars['Boolean']>;
  headliners?: Maybe<Array<HeadlinerInformation>>;
  humanReadableId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  jdeBusinessUnitsId?: Maybe<Scalars['String']>;
  killDate?: Maybe<Scalars['String']>;
  killReason?: Maybe<Scalars['String']>;
  localCurrency?: Maybe<Scalars['String']>;
  merchandiseDeal?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notesAndComments?: Maybe<Scalars['String']>;
  oneOffType?: Maybe<Scalars['String']>;
  preparedBy?: Maybe<Scalars['String']>;
  preparedOn?: Maybe<Scalars['String']>;
  primaryBuyer?: Maybe<User>;
  primaryEvent?: Maybe<OneOffEvent>;
  rosterUsers?: Maybe<Array<RosterUser>>;
  status?: Maybe<Scalars['String']>;
  subledgerId?: Maybe<Scalars['String']>;
  subledgerPayload?: Maybe<Scalars['String']>;
  subledgerStatus?: Maybe<Scalars['String']>;
};

export type OneOffEvent = {
  __typename?: 'OneOffEvent';
  baseEvent?: Maybe<Event>;
  coPromoterDeals?: Maybe<Array<Maybe<CoPromoterDeal>>>;
  commitment?: Maybe<Scalars['Float']>;
  createdDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  includeInForecast?: Maybe<Scalars['Boolean']>;
  isSettledInNewElvis?: Maybe<Scalars['Boolean']>;
  offerNotes?: Maybe<Scalars['String']>;
  pdfConfig?: Maybe<PdfConfig>;
  projectedPoolTaxAdjustmentsTotalToBase?: Maybe<Scalars['Float']>;
  projectedPreTaxAdjustmentsTotalToBase?: Maybe<Scalars['Float']>;
  radiusRestrictionNotes?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type OneOffOfferPdfInput = {
  eventId: Scalars['ID'];
  includeArtistDeal?: InputMaybe<Scalars['Boolean']>;
  includeBreakeven?: InputMaybe<Scalars['Boolean']>;
  includeExpense?: InputMaybe<Scalars['Boolean']>;
  includePromoterEarnings?: InputMaybe<Scalars['Boolean']>;
  includeTotalExpenses?: InputMaybe<Scalars['Boolean']>;
  oneOffId: Scalars['ID'];
};

export type PdfConfig = {
  __typename?: 'PdfConfig';
  includeAttendance: Scalars['Boolean'];
  includeBreakEven: Scalars['Boolean'];
  includeDealBreakdown: Scalars['Boolean'];
  includeExpenseDetail: Scalars['Boolean'];
  includePromoterEarnings: Scalars['Boolean'];
  includeTotalExpensesAndNetShowReceipts: Scalars['Boolean'];
};

export type PdfConfigInput = {
  includeAttendance: Scalars['Boolean'];
  includeBreakEven: Scalars['Boolean'];
  includeDealBreakdown: Scalars['Boolean'];
  includeExpenseDetail: Scalars['Boolean'];
  includePromoterEarnings: Scalars['Boolean'];
  includeTotalExpensesAndNetShowReceipts: Scalars['Boolean'];
};

export enum PercentSoldType {
  Average = 'Average',
  Manual = 'Manual',
  Sequential = 'Sequential'
}

export type ProfitLossCategory = {
  __typename?: 'ProfitLossCategory';
  category?: Maybe<Scalars['String']>;
  categoryParent?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDisplayByDefaultModel?: Maybe<Scalars['Boolean']>;
  isDisplayByDefaultSettlement?: Maybe<Scalars['Boolean']>;
  isNegativeByDefault?: Maybe<Scalars['Boolean']>;
  objectAccount?: Maybe<Scalars['Int']>;
  subCategory?: Maybe<Scalars['String']>;
  subsidiaryAccount?: Maybe<Scalars['Int']>;
};

export type ProjectedAegEarnings = {
  __typename?: 'ProjectedAegEarnings';
  aegNetShowEarningsTotal?: Maybe<Scalars['Float']>;
  aegNetShowEarningsTotalToBase?: Maybe<Scalars['Float']>;
  coPromoterShareTotal?: Maybe<Scalars['Float']>;
  coPromoterShareTotalToBase?: Maybe<Scalars['Float']>;
};

export type ProjectedArtistOffer = {
  __typename?: 'ProjectedArtistOffer';
  artistAdjustmentNotes?: Maybe<Scalars['String']>;
  artistAdjustmentTotal?: Maybe<Scalars['Float']>;
  artistAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  artistEarningSeqRoundedToBase?: Maybe<Scalars['Float']>;
  artistEarnings?: Maybe<Scalars['Float']>;
  artistEarningsToBase?: Maybe<Scalars['Float']>;
  guarantee?: Maybe<Scalars['Float']>;
  guaranteeToBase?: Maybe<Scalars['Float']>;
  pctOfNetPoolEarnings?: Maybe<Scalars['Float']>;
  pctOfNetPoolEarningsAmount?: Maybe<Scalars['Float']>;
  pctOfNetPoolEarningsAmountToBase?: Maybe<Scalars['Float']>;
};

export type ProjectedBoxOfficeOverview = {
  __typename?: 'ProjectedBoxOfficeOverview';
  totalAvailableCapacity?: Maybe<Scalars['Int']>;
  totalCapacity?: Maybe<Scalars['Int']>;
  totalGross?: Maybe<Scalars['Float']>;
  totalGrossToBase?: Maybe<Scalars['Float']>;
  totalSellableCapacity?: Maybe<Scalars['Int']>;
};

export type ProjectedNetShowReceipts = {
  __typename?: 'ProjectedNetShowReceipts';
  budgetedShowExpensesToBase?: Maybe<Scalars['Float']>;
  netGrossReceiptsTotal?: Maybe<Scalars['Float']>;
  netGrossReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  netSellOffAdjustmentTotal?: Maybe<Scalars['Float']>;
  netSellOffAdjustmentTotalToBase?: Maybe<Scalars['Float']>;
  netShowExpensesTotal?: Maybe<Scalars['Float']>;
  netShowExpensesTotalToBase?: Maybe<Scalars['Float']>;
  netShowReceiptsTotal?: Maybe<Scalars['Float']>;
  netShowReceiptsTotalToBase?: Maybe<Scalars['Float']>;
  sequentialNetToPoolToBase?: Maybe<Scalars['Float']>;
};

export type ProjectedShowIncome = {
  __typename?: 'ProjectedShowIncome';
  grossShowReceipts?: Maybe<Scalars['Float']>;
  grossShowReceiptsToBase?: Maybe<Scalars['Float']>;
  netGrossReceipts?: Maybe<Scalars['Float']>;
  netGrossReceiptsToBase?: Maybe<Scalars['Float']>;
};

export type Projection = {
  __typename?: 'Projection';
  potential?: Maybe<Scalars['Float']>;
  projectedAverage?: Maybe<Scalars['Float']>;
  projectedSequential?: Maybe<Scalars['Float']>;
};

export type ProjectionPercentage = {
  __typename?: 'ProjectionPercentage';
  potential?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  agencies?: Maybe<Array<Maybe<Agency>>>;
  agency?: Maybe<Agency>;
  agent?: Maybe<Agent>;
  agents?: Maybe<Array<Maybe<Agent>>>;
  artist?: Maybe<Artist>;
  artists?: Maybe<Array<Maybe<Artist>>>;
  boxOffice?: Maybe<BoxOffice>;
  boxOfficePhase?: Maybe<BoxOfficePhase>;
  companies?: Maybe<Array<Maybe<Company>>>;
  company?: Maybe<Company>;
  countries?: Maybe<Array<Maybe<Country>>>;
  country?: Maybe<Country>;
  event?: Maybe<Event>;
  events?: Maybe<Array<Maybe<Event>>>;
  expense?: Maybe<Expense>;
  genre?: Maybe<Genre>;
  genres?: Maybe<Array<Maybe<Genre>>>;
  getBookersByCompanyId?: Maybe<Array<User>>;
  health?: Maybe<Health>;
  nosSettlement?: Maybe<NosSettlement>;
  nosSettlementVersions?: Maybe<Array<Maybe<NosSettlement>>>;
  nosSettlements?: Maybe<Array<Maybe<NosSettlement>>>;
  oneOff?: Maybe<OneOff>;
  oneOffs?: Maybe<Array<Maybe<OneOff>>>;
  profitLossCategories?: Maybe<Array<Maybe<ProfitLossCategory>>>;
  profitLossCategory?: Maybe<ProfitLossCategory>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  venue?: Maybe<Venue>;
  venueAndSpace?: Maybe<VenueAndSpace>;
  venues?: Maybe<Array<Maybe<Venue>>>;
};


export type QueryAgenciesArgs = {
  agencyIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryAgencyArgs = {
  agencyId: Scalars['String'];
};


export type QueryAgentArgs = {
  agentId: Scalars['String'];
};


export type QueryAgentsArgs = {
  agentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryArtistArgs = {
  artistId: Scalars['String'];
};


export type QueryArtistsArgs = {
  artistIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryBoxOfficeArgs = {
  boxOfficeId: Scalars['ID'];
};


export type QueryBoxOfficePhaseArgs = {
  boxOfficeId: Scalars['ID'];
  boxOfficePhaseId: Scalars['ID'];
};


export type QueryCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryCountryArgs = {
  countryCode: Scalars['String'];
};


export type QueryEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryExpenseArgs = {
  expenseId: Scalars['ID'];
};


export type QueryGenreArgs = {
  genreId: Scalars['String'];
};


export type QueryGenresArgs = {
  genreIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryGetBookersByCompanyIdArgs = {
  companyId: Scalars['String'];
};


export type QueryNosSettlementArgs = {
  nosSettlementId: Scalars['ID'];
};


export type QueryNosSettlementVersionsArgs = {
  eventId: Scalars['ID'];
};


export type QueryOneOffArgs = {
  oneOffId: Scalars['ID'];
};


export type QueryProfitLossCategoryArgs = {
  profitLossCategoryId: Scalars['ID'];
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  searchTerm?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryVenueArgs = {
  venueId: Scalars['String'];
};


export type QueryVenueAndSpaceArgs = {
  spaceId: Scalars['String'];
  venueId: Scalars['String'];
};


export type QueryVenuesArgs = {
  filter?: InputMaybe<VenueFilter>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type RosterUser = {
  __typename?: 'RosterUser';
  role: Scalars['String'];
  user: User;
};

export type RosterUserInput = {
  role: Scalars['String'];
  userId: Scalars['ID'];
};

export type SellOffCalculations = {
  __typename?: 'SellOffCalculations';
  adjustment?: Maybe<Scalars['Float']>;
  guarantee?: Maybe<Scalars['Float']>;
  incomePoolPercent?: Maybe<Scalars['Float']>;
  versusPercent?: Maybe<Scalars['Float']>;
};

export type SettlementPayment = {
  __typename?: 'SettlementPayment';
  exchangeRate?: Maybe<Scalars['Float']>;
  localCurrency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  totalToBase?: Maybe<Scalars['Float']>;
};

export type Show = {
  __typename?: 'Show';
  ageRequirement?: Maybe<Scalars['String']>;
  announcementDateTime?: Maybe<Scalars['String']>;
  boxOffice?: Maybe<BoxOffice>;
  curfewTime?: Maybe<Scalars['String']>;
  doorTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  onsaleDateTime?: Maybe<Scalars['String']>;
  showDateTime?: Maybe<Scalars['String']>;
  space?: Maybe<Space>;
};

export type ShowAdjustment = {
  __typename?: 'ShowAdjustment';
  adjustmentName?: Maybe<Scalars['String']>;
  adjustmentRate?: Maybe<Scalars['String']>;
  adjustmentTotal?: Maybe<Scalars['Float']>;
  adjustmentTotalToBase?: Maybe<Scalars['Float']>;
};

export type ShowExpensesSummary = {
  __typename?: 'ShowExpensesSummary';
  advertising?: Maybe<Scalars['Float']>;
  advertisingToBase?: Maybe<Scalars['Float']>;
  artistProrates?: Maybe<Scalars['Float']>;
  artistProratesToBase?: Maybe<Scalars['Float']>;
  catering?: Maybe<Scalars['Float']>;
  cateringToBase?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
  otherToBase?: Maybe<Scalars['Float']>;
  production?: Maybe<Scalars['Float']>;
  productionProrates?: Maybe<Scalars['Float']>;
  productionProratesToBase?: Maybe<Scalars['Float']>;
  productionToBase?: Maybe<Scalars['Float']>;
  rent?: Maybe<Scalars['Float']>;
  rentToBase?: Maybe<Scalars['Float']>;
  staffing?: Maybe<Scalars['Float']>;
  staffingToBase?: Maybe<Scalars['Float']>;
  stagehands?: Maybe<Scalars['Float']>;
  stagehandsToBase?: Maybe<Scalars['Float']>;
  support?: Maybe<Scalars['Float']>;
  supportToBase?: Maybe<Scalars['Float']>;
  totalExpenses?: Maybe<Scalars['Float']>;
  totalExpensesToBase?: Maybe<Scalars['Float']>;
  transport?: Maybe<Scalars['Float']>;
  transportToBase?: Maybe<Scalars['Float']>;
  variable?: Maybe<Scalars['Float']>;
  variableToBase?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['Float']>;
  venueToBase?: Maybe<Scalars['Float']>;
};

export type Space = {
  __typename?: 'Space';
  configurations?: Maybe<Array<Maybe<SpaceConfiguration>>>;
  description?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<FeeCategories>>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  maxCapacity?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type SpaceConfiguration = {
  __typename?: 'SpaceConfiguration';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tierScaling?: Maybe<Array<Maybe<TierScaling>>>;
  totalCapacity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

export type SummaryDetail = {
  __typename?: 'SummaryDetail';
  adjustments?: Maybe<AdjustmentsSummary>;
  artistSettlement?: Maybe<ArtistSettlementSummary>;
  earningsSummary?: Maybe<EarningsSummary>;
  showExpenses?: Maybe<ShowExpensesSummary>;
};

export type TierScaling = {
  __typename?: 'TierScaling';
  capacity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum TriggerType {
  NetGross = 'NetGross',
  NoTrigger = 'NoTrigger',
  TicketSales = 'TicketSales'
}

export type UpdateAgentInput = {
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  availableCompanies?: Maybe<Array<Maybe<AvailableCompanies>>>;
  email: Scalars['String'];
  id: Scalars['ID'];
  intercomUserHash?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type VariableCategoryItem = {
  __typename?: 'VariableCategoryItem';
  breakdown?: Maybe<CategoryBreakdown>;
  categoryId?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  items?: Maybe<Array<VariableCost>>;
};

export type VariableCost = {
  __typename?: 'VariableCost';
  breakdown?: Maybe<CategoryBreakdown>;
  calculatedCost?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  localCurrency?: Maybe<Scalars['String']>;
  maximum?: Maybe<Scalars['Float']>;
  minimum?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type Venue = {
  __typename?: 'Venue';
  city: Scalars['String'];
  cityCode: Scalars['String'];
  country: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<FeeCategories>>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  localCurrencyCode?: Maybe<Scalars['String']>;
  metroArea?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownership: Scalars['String'];
  spaces?: Maybe<Array<Maybe<Space>>>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
};

export type VenueAndSpace = {
  __typename?: 'VenueAndSpace';
  space?: Maybe<Space>;
  venue?: Maybe<Venue>;
};

export type VenueFilter = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type AddOneOffMutationVariables = Exact<{
  oneOff: AddOneOffInput;
}>;


export type AddOneOffMutation = { __typename?: 'Mutation', addOneOff?: { __typename?: 'OneOff', id: string } | null };

export type GetGenresQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type GetGenresQuery = { __typename?: 'Query', genres?: Array<{ __typename?: 'Genre', id: string, name: string, mainGenre?: { __typename?: 'Genre', id: string, name: string } | null } | null> | null };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'Company', id: string, name: string } | null> | null };

export type SearchUsersQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type SearchUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, name: string, email: string } | null> | null };

export type GetBookersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type GetBookersByCompanyIdQuery = { __typename?: 'Query', getBookersByCompanyId?: Array<{ __typename?: 'User', id: string, name: string, email: string }> | null };


export const AddOneOffDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddOneOff"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"oneOff"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddOneOffInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addOneOff"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"oneOff"},"value":{"kind":"Variable","name":{"kind":"Name","value":"oneOff"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddOneOffMutation, AddOneOffMutationVariables>;
export const GetGenresDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGenres"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"genres"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"searchTerm"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"mainGenre"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetGenresQuery, GetGenresQueryVariables>;
export const GetCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const SearchUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"searchTerm"},"value":{"kind":"Variable","name":{"kind":"Name","value":"searchTerm"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetBookersByCompanyIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetBookersByCompanyId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getBookersByCompanyId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<GetBookersByCompanyIdQuery, GetBookersByCompanyIdQueryVariables>;