import { useContext } from 'react';
import { NosButtons, NosSettlementContainer, TaskPaneHeader } from '@components';
import { useQuery } from '@apollo/client';
import { GET_EVENT_TOUR_INFORMATION } from '@gql/queries/events';
import { EnvStatusContext } from '@providers';
import { TaskPanePaperContainer } from './TaskPane.styled';

export const NosSettlementView = () => {
  const { envStatus } = useContext(EnvStatusContext);
  const { eventId, tourId } = envStatus;

  const { data, loading } = useQuery(GET_EVENT_TOUR_INFORMATION, {
    variables: { eventId: eventId as string },
  });

  const { tourInformation } = data?.event || {};
  const { tourName = '', offerName = '', offerStatus = '' } = tourInformation || {};

  return loading ? null : (
    <>
      <TaskPaneHeader
        loading={loading}
        tourName={tourName as string}
        offerName={offerName as string}
        offerStatus={offerStatus as string}
        actions={
          <NosButtons
            tourId={tourId ?? ''}
            eventId={eventId ?? ''}
          />
        }
      >
      </TaskPaneHeader>
      <TaskPanePaperContainer>
        <NosSettlementContainer/>
      </TaskPanePaperContainer>
    </>
  );
};
