import { graphql } from '@ooagg-gql/types';

export const GET_GENRES = graphql(`
  query GetGenres($searchTerm: String!) {
    genres(searchTerm: $searchTerm) {
      id
      name
      mainGenre {
        id
        name
      }
    }
  }
`);
