import { Country } from '@gql/types/graphql';

export const orderCountries = (countries: Country[]) => {
  if (!countries || !countries.length) {
    return [];
  }
  let unitedStates: Country = {};
  let canada: Country = {};
  const sortedCountries = [...countries].sort((a, b) => (a?.name || '').localeCompare(b?.name || ''));
  const filteredCountries: Country[] = sortedCountries.reduce((acc, country) => {
    if (country.name === 'United States') {
      unitedStates = country;
    } else if (country.name === 'Canada') {
      canada = country;
    } else {
      acc.push(country);
    }
    return acc;
  }, [] as Country[]);

  return [unitedStates, canada, ...filteredCountries];
};
