import { GridColDef } from '@mui/x-data-grid-pro';
import { getMarketDisplayName } from '@utils/venueHelpers';
import { Venue, NosVersionTableFragment } from '@gql/types/graphql';
import { getUserTimezoneDate } from '@utils/stringHelpers';
import { NOSVersionRow } from './NOSVersionsTable.models';
import { NOSVersionTableActions, SendNosEmailProps } from './NOSVersionTableActions';
import { NOSVersionsPrimaryButton } from './NOSVersionsPrimaryButton';

export interface NOSVersionTable extends NosVersionTableFragment {
  market: string;
}

const DEFAULT_VALUE = '---';

export const NOSVersionColumns = (sendNOSEmail: (data: SendNosEmailProps) => void): GridColDef<NOSVersionRow>[] => ([
  {
    headerName: '',
    flex: 0.05,
    maxWidth: 50,
    field: 'isPrimary',
    resizable: false,
    renderCell: (params) => <NOSVersionsPrimaryButton row={params.row} />,
  },
  {
    headerName: 'NOS Version',
    flex: 0.25,
    field: 'version',
    valueGetter: (params) => {
      const market = getMarketDisplayName(params.row?.venue as Venue);
      return (`${market} - Settlement_V${params.row?.version || 1}`);
    },
  },
  {
    headerName: 'Submitted by',
    flex: 0.175,
    field: 'submittedBy',
    valueGetter: (params: { value: { name: string } }) => params.value?.name ?? DEFAULT_VALUE,
  },
  {
    headerName: 'Submitted Date',
    flex: 0.175,
    field: 'submittedOn',
    valueFormatter: ({ value }: { value: string }) => getUserTimezoneDate(value)[0] || DEFAULT_VALUE,
  },
  {
    headerName: 'Last Updated',
    flex: 0.175,
    field: 'updatedDate',
    valueFormatter: ({ value }: { value: string }) => getUserTimezoneDate(value)[0] || DEFAULT_VALUE,
  },
  {
    headerName: 'Updated by',
    flex: 0.175,
    field: 'updatedBy',
    valueGetter: (params: { value: { name: string } }) => params.value?.name ?? DEFAULT_VALUE,
  },
  {
    field: 'moreActions',
    type: 'actions',
    resizable: false,
    width: 10,
    getActions: (params) => NOSVersionTableActions(params, sendNOSEmail),
  },
]);
