/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { kebabCase } from '@utils/stringHelpers';
import {
  PrimaryText,
  PrimaryTextBold,
  SecondaryText,
  SpaceHyphen,
} from './AegAutocomplete.styled';
import { AvatarChip, ChipOption } from '../AvatarChip/AvatarChip';

export type DisplayFields = {
  primary: string;
  secondary?: string | string[] | ((item: any) => string | string[]);
};

export type AutocompleteOption = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: unknown;
};

export interface AegAutocompleteParams {
  /** @required the source data as an array */
  options: any[];
  InputComponent: React.FC<TextFieldProps>;
  /** @required property displayed in the Input component */
  dataDisplayField: DisplayFields;
  areOptionsLoading?: boolean;
  /** @optional property to filter out based on existing selections */
  dataFilterField?: string;
  /** @optional an array of selections to filter out of options */
  filterOut?: string[];
  /** @optional method to fire on confirmed selection */
  onChange?: (value: string | AutocompleteOption | null | (string | AutocompleteOption)[]) => void;
  /** @optional callback to update state in parent component */
  onInputChange?: React.Dispatch<React.SetStateAction<string>>;
  /** @optional normally used to update the selected value in parent component */
  onSelect?: (
    selectedOption: AutocompleteOption | string | (AutocompleteOption | string)[] | null,
    event?: React.SyntheticEvent<Element, Event>,
  ) => void;
  testId: string;
  placeholderText?: string;
  error?: boolean;
  helperText?: string;
  defaultValue?: any;
  value?: any;
  /** @optional the min length of input text to open the autocomplete component */
  minimumCharacters?: number;
  inputAdornment?: JSX.Element;
  disabled?: boolean;
  autoSuggestHighlight?: boolean;
  freeSolo?: boolean;
  forcePopupIcon?: boolean;
  getOptionLabel?: (option: any) => string;
  disableClearable?: boolean;
  removeClearIcon?: boolean;
  /** @optional this turns the Input field into a Chip Option Selector */
  chipOption?: ChipOption;
}

export function AegAutocomplete({
  options,
  areOptionsLoading,
  InputComponent,
  dataDisplayField,
  dataFilterField,
  filterOut,
  onChange,
  onInputChange,
  onSelect,
  testId,
  placeholderText,
  error,
  helperText,
  defaultValue,
  value,
  minimumCharacters = 1,
  inputAdornment,
  disabled,
  autoSuggestHighlight = false,
  freeSolo = false,
  forcePopupIcon = false,
  getOptionLabel,
  disableClearable = false,
  removeClearIcon = false,
  chipOption,
}: AegAutocompleteParams) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOptionLabel = (option: AutocompleteOption | string): string => {
    if (typeof option === 'string') {
      return option;
    }

    const { primary, secondary } = dataDisplayField;

    if (!option[primary]) {
      return '';
    }

    let secondaryField = secondary;
    if (secondaryField) {
      if (secondaryField instanceof Function) {
        secondaryField = secondaryField(option);
      }
    }

    return `${option[primary] as string}${secondaryField ? ` - ${Array.isArray(secondaryField)
      ? secondaryField.map((field) => option[field] as string).join(', ') : option[secondaryField] as string}` : ''}`;
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
  ) => {
    const { primary, secondary } = dataDisplayField;
    const primaryText = typeof option === 'string' ? option : option[primary];

    // Array of secondary fields provided for venue search should *not* result in primary field in bold
    let primaryTextElement = (secondary && !(secondary instanceof Function) && !Array.isArray(secondary)) ? (
      <PrimaryTextBold>{option[primary]}</PrimaryTextBold>
    ) : (
      <PrimaryText>{primaryText}</PrimaryText>
    );

    if (autoSuggestHighlight && !(secondary)) {
      const matches = match(primaryText, value, { insideWords: true });
      const parts = parse(primaryText, matches);

      primaryTextElement = (
        <PrimaryText>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </PrimaryText>
      );
    }

    const secondaryTextElement = () => {
      let secondaryField = secondary;
      if (secondaryField) {
        if (secondaryField instanceof Function) {
          secondaryField = secondaryField(option);
        }

        // Array of secondary fields are currently only provided for Venue search in form of [city, stateCode]
        // Those fields should be comma-separated in provided order and then appended to primary field
        if (Array.isArray(secondaryField)) {
          return (
            <>
              <SpaceHyphen>-</SpaceHyphen>
              <SecondaryText>{secondaryField.map((field) => option[field]).join(', ')}</SecondaryText>
            </>
          );
        }
        return (<>
          <SpaceHyphen>-</SpaceHyphen>
          <SecondaryText>{option[secondaryField]}</SecondaryText>
        </>);
      }
      return <></>;
    };

    return (
      <li {...props} key={option[primary] || kebabCase(primary)}>
        {primaryTextElement}
        {secondaryTextElement()}
      </li>
    );
  };

  return (
    <Autocomplete
      disabled={disabled}
      disablePortal
      filterSelectedOptions
      disableClearable={disableClearable}
      loading={areOptionsLoading}
      loadingText="Loading"
      multiple={!!chipOption}
      onBlur={() => setIsOpen(false)}
      isOptionEqualToValue={(option, equalValue) => {
        if (option.id && equalValue.id) {
          return option.id === equalValue.id;
        }

        if (option.name && equalValue.name) {
          return option.name === equalValue.name;
        }

        return option === equalValue;
      }}
      open={isOpen}
      onOpen={() => {
        if (!isOpen && disableClearable) {
          setIsOpen(true);
        }
      }}
      onInputChange={(
        event: React.SyntheticEvent<Element, Event>,
        changedValue: string,
      ) => {
        if (!event) {
          return;
        }

        if (changedValue.length < minimumCharacters) {
          if (onInputChange) {
            onInputChange('');
          }
          if (isOpen) {
            setIsOpen(false);
          }
        } else {
          if (onInputChange) {
            onInputChange(changedValue.trim());
          }
          if (!isOpen) {
            setIsOpen(true);
          }
        }
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, changeValue) => {
        if (event) {
          if (onSelect) {
            onSelect(changeValue, event);
          }

          if (onChange) {
            onChange(changeValue);
          }
          setIsOpen(false);
        }
      }}
      data-testid={testId}
      options={options}
      getOptionLabel={getOptionLabel || handleOptionLabel}
      renderOption={renderOption}
      defaultValue={defaultValue as AutocompleteOption}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value={value || null}
      forcePopupIcon={forcePopupIcon}
      freeSolo={freeSolo}
      autoHighlight={!freeSolo}
      filterOptions={
        filterOut && dataFilterField
          ? (_options) => _options.filter(
            (option) => !filterOut.includes(option[dataFilterField]),
          )
          : undefined
      }
      renderInput={(params) => (
        chipOption
          ? <TextField
            {...params}
            variant="outlined"
            error={error}
            helperText={helperText}
            placeholder={placeholderText}
            data-testid={`${testId}-input`}
            />
          : <InputComponent
          {...params}
          sx={{ color: '#052E54' }}
          error={error}
          helperText={helperText}
          inputProps={{
            'data-testid': `${testId}-input`,
            ...params.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: inputAdornment,
            endAdornment: removeClearIcon ? null : params.InputProps.endAdornment,
          }}
          placeholder={placeholderText}
        />
      )}
      renderTags={(tags, getTagProps) => {
        if (chipOption) {
          return tags.map((tag, index) => (
            <AvatarChip
              data-testid='autocomplete-input-chip'
              tag={tag} chipOption={chipOption}
              {...getTagProps({ index })}
            />
          ));
        }
        return [];
      }}
    />
  );
}
