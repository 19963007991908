import { useEffect } from 'react';

export function useCallbackOnWindowFocus<T>(callback: () => T) {
  const handleChange = () => {
    void callback();
  };

  useEffect(() => {
    window.addEventListener('focus', handleChange);
    return () => {
      window.removeEventListener('focus', handleChange);
    };
  }, []);
}
