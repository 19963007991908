import * as yup from 'yup';
import { ValidationError } from 'yup';
import { ConfigGridFields } from '@types';

interface ErrorAccumulator {
  [key: string]: { ids: string[]; indices: number[] }
}

export const configurationSchema = yup.array().of(
  yup.object().shape({
    id: yup.string(),
    name: yup.string().required('Configuration name is required'),
    type: yup.string().required('Configuration type is required'),
  }),
).test('unique-name', 'Names must be unique', (array) => {
  if (array?.length) {
    const nameMap: ErrorAccumulator = array.reduce((acc: ErrorAccumulator, el, i) => {
      if (!acc[el.name]) {
        acc[el.name] = { ids: [el.id as string], indices: [i] };
      } else {
        acc[el.name].ids.push(el.id as string);
        acc[el.name].indices.push(i);
      }
      return acc;
    }, {});

    const duplicateIds = Object.values(nameMap)
      .filter(({ indices }) => indices.length > 1)
      .flatMap(({ ids }) => ids);

    if (duplicateIds.length > 0) {
      throw new ValidationError('Configuration names must be unique', duplicateIds, ConfigGridFields.NAME);
    }
  }
  return true;
});

export const priceTierSchema = yup.object().shape({
  capacity: yup
    .number()
    .min(0, 'Please enter a number greater than or equal to 0')
    .typeError('Please enter a valid number')
    .test('is-integer', 'Capacity must be a whole number', (value) => {
      if (value === undefined || value === null) {
        return true;
      }
      return Number.isInteger(value);
    }),
});

export const feeItemSchema = yup.object().shape({
  value: yup
    .number()
    .min(0, 'Please enter a number greater than or equal to 0')
    .typeError('Please enter a valid number'),
  min: yup
    .number()
    .min(0, 'Please enter a number greater than or equal to 0')
    .typeError('Please enter a valid number'),
  max: yup
    .number()
    .min(0, 'Please enter a number greater than or equal to 0')
    .typeError('Please enter a valid number'),
  note: yup.string().nullable(),
})
  .test('value-blank', 'Value cannot be blank if other fields contain values', (object) => {
    if (
      (object.min || object.max || object.note)
        && (object.value === undefined || object.value === null)
    ) {
      throw new ValidationError('Cell cannot be blank if other fields in this row contain values', null, 'value');
    }
    return true;
  })
  .test('min-max', 'Min must be less than max', (object) => {
    if (object && object.min && object.max && object.min >= object.max) {
      throw new ValidationError('Min must be less than max', null, 'min');
    }
    return true;
  });
