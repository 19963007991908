import {
  FeeItem, Maybe, TierScaling, Venue,
} from '@gql/types/graphql';
import { FeeItemTypes } from '@types';

export const flattenVenues = (venues: Venue[]) => {
  const flattenedVenues: Venue[] = [];

  venues.forEach((venue) => {
    if (venue.spaces && venue.spaces.length > 1) {
      venue.spaces.forEach((space) => {
        if (space) {
          flattenedVenues.push({
            ...venue,
            name: venue.name === space.name ? venue.name : `${venue.name} - ${space.name}`,
            spaces: [space],
          });
        }
      });
    } else {
      const space = venue.spaces?.[0];
      if (space) {
        flattenedVenues.push({
          ...venue,
          name: venue.name === space.name ? venue.name : `${venue.name} - ${space.name}`,
          spaces: [space],
        });
      }
    }
  });

  return flattenedVenues;
};

export const venueRegionDisplayFieldGetter = (venue: Venue): (keyof Venue)[] => {
  if (venue.stateCode || venue.state) {
    const stateKey = venue.stateCode ? 'stateCode' : 'state';
    return ['city', stateKey];
  }
  return ['city', 'country'];
};

export const TAXES = 'Taxes';
export const FIXED_COSTS = 'Fixed Costs';
export const VARIABLE_COSTS = 'Variable Costs';
export const PRE_TAX_FEES = 'Pre Tax Fees';
export const TICKET_FEES = 'Ticket Fees';

export const DEFAULT_TAXES: FeeItem[] = [
  {
    subCategory: 'Tax 1', formula: 'Divisor', type: FeeItemTypes.PERCENTAGE, profitLossCategoryId: '',
  },
  {
    subCategory: 'Tax 2', formula: 'Multiplier', type: FeeItemTypes.PERCENTAGE, profitLossCategoryId: '',
  },
];

export const DEFAULT_FIXED_COSTS = [
  {
    subCategory: 'Rent', name: 'Rent', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
  {
    subCategory: 'House Flat', name: 'House Flat', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
  {
    subCategory: 'Staffing', name: 'Staffing', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
  {
    subCategory: 'Stagehands',
    name: 'Stagehands (per truck)',
    type: FeeItemTypes.FIXED_AMOUNT,
    profitLossCategoryId: '',
  },
  {
    subCategory: 'Venue', name: 'Venue Expenses', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
];

export const DEFAULT_VARIABLE_COSTS = [
  { subCategory: 'Rent (% of Net Gross)', type: FeeItemTypes.PERCENTAGE, profitLossCategoryId: '' },
  { subCategory: 'Rent (% of Gross)', type: FeeItemTypes.PERCENTAGE, profitLossCategoryId: '' },
  { subCategory: 'Rent (per ticket)', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '' },
];

export const DEFAULT_TICKET_FEES = [
  {
    subCategory: 'FMF', formula: 'Per Ticket', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
  {
    subCategory: 'Parking', formula: 'Per Ticket', type: FeeItemTypes.FIXED_AMOUNT, profitLossCategoryId: '',
  },
];

export const DEFAULT_TIERS = [
  { name: 'P1 (Floor)', id: 'new-tier-1' },
  { name: 'P2 (Lower Bowl Side)', id: 'new-tier-2' },
  { name: 'P3 (Lower Bowl Rear)', id: 'new-tier-3' },
  { name: 'P4 (Upper Bowl Side)', id: 'new-tier-4' },
  { name: 'P5 (Upper Bowl Rear)', id: 'new-tier-5' },
];

export const DEFAULT_ALL_FEES = [
  { name: FIXED_COSTS, items: DEFAULT_FIXED_COSTS },
  { name: VARIABLE_COSTS, items: DEFAULT_VARIABLE_COSTS },
  { name: TAXES, items: DEFAULT_TAXES },
  { name: TICKET_FEES, items: DEFAULT_TICKET_FEES },
];

export const sumTierCapacity = (tiers?: Maybe<Maybe<TierScaling>[]>) => tiers?.reduce((acc, tier) => {
  if (tier?.capacity && Number(tier.capacity)) {
    return acc + Number(tier.capacity);
  }

  return acc;
}, 0);

export const getMarketDisplayName = (venue?: Venue | null) => (
  `${venue?.city || ''}${venue?.stateCode ? `, ${venue.stateCode}` : ''}`
);
