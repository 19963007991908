import {
  Box, CustomTheme, Tabs, styled,
} from '@mui/material';
import { DropdownButton } from '../../shared/DropdownButton/DropdownButton';
import { ModelingActionOptions } from './ModelingContainer.options';

export const ContainerStyled = styled(Box)(
  ({ theme }: { theme?: CustomTheme }) => ({
    background: theme?.palette.primary.contrast,
    borderRadius: theme?.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxShadow: theme?.shadows[3],
    maxWidth: '100%',
  }),
);

export const LineElement = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    border: 0,
    height: 0,
    borderTop: '1px solid',
    borderTopColor: theme?.palette.primary.light[100],
  }),
);

export const TabsContainer = styled(Tabs)({
  '&.hidden': {
    visibility: 'hidden',
    opacity: 0,
    pointerEvents: 'none',
  },
});

export const FlexBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ActionContainer = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    marginLeft: 'auto',
    paddingRight: theme?.spacing(1),
    '& button + button': {
      marginLeft: theme?.spacing(1),
    },
  }),
);

export const DropdownActions = styled(DropdownButton<ModelingActionOptions>)(
  ({ theme }: { theme?: CustomTheme }) => ({
    backgroundColor: theme?.palette.primary.contrast,
  }),
);
