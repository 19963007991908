/* eslint-disable max-lines-per-function */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ModifyTourInput, ParentTour } from '@gql/types/graphql';
import { useYupValidationResolver } from '@hooks';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { MAX_CHARS_TOUR_NAME } from '@utils/excel/shared/constants';
import { ButtonContainer } from './EditParentTourForm.styled';
import {
  FormTextInput,
  FormErrorComponent,
} from '../shared';
import { editParentTourFormValidationSchema } from './EditParentTourForm.schema';
import {
  AutocompleteInput,
  ButtonStyled,
} from '../shared/Form';

export interface EditParentTourFormFields {
  id: string;
  name: string;
  tourType: string;
}

interface EditParentTourFormProps {
  onFormSubmit: SubmitHandler<ModifyTourInput>;
  isFormSaving: boolean; // Push saving state into form
  tour: ParentTour;
}

export function EditParentTourForm({
  onFormSubmit,
  isFormSaving,
  tour,
}: Readonly<EditParentTourFormProps>) {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const resolver = useYupValidationResolver(editParentTourFormValidationSchema);

  const defaultValues = useMemo(() => {
    const { id, name } = tour;

    const fields = {
      id,
      name: name.trim(),
      tourType: 'Multi-Leg Tour',
    };

    return fields as EditParentTourFormFields;
  }, [tour]);

  const {
    formState: { isDirty },
    control,
    handleSubmit,
  } = useForm<EditParentTourFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit = async ({ id, name }: EditParentTourFormFields) => {
    setIsFormErrorOpen(false);

    const editParentTour: ModifyTourInput = {
      id,
      name: name?.trim(),
    };

    try {
      await onFormSubmit(editParentTour);
    } catch (error) {
      logError(appInsights, 'EditParentTourForm submit error', error);
      setIsFormErrorOpen(true);
    }
  };

  return (
    <>
      <Typography variant="h5" data-testid="edit-tours-form-header-text">
        Edit a Multi-Leg Tour
      </Typography>

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormErrorComponent
          isDismissable={true}
          isOpen={isFormErrorOpen}
          setIsOpen={setIsFormErrorOpen}
        />

        <AutocompleteInput
          fieldName="tourType"
          control={control}
          label="Tour Type"
          options={[]}
          dataDisplayField={{ primary: 'name' }}
          isRequired
          forcePopupIcon
          disableClearable
          disabled
        />

        <FormTextInput
          control={control}
          fieldName="name"
          label="Multi-Leg Tour Name"
          isRequired={true}
          placeholderText="Multi-Leg Tour Name"
          maxLength={MAX_CHARS_TOUR_NAME}
        />

        <ButtonContainer>
          <ButtonStyled
            variant="contained"
            data-testid="create-button-form"
            type="submit"
            disabled={isFormSaving || (tour && !isDirty)}
          >
            Save and Update
          </ButtonStyled>
        </ButtonContainer>
      </form>
    </>
  );
}
