import * as yup from 'yup';
import { ObjectSchema, string, object } from 'yup';

import { SupportModel } from '@components';
import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

const supportSchema: ObjectSchema<SupportModel> = object({
  id: string(),
  name: string(),
}).typeError('No results found.');

const sharedFields = {
  name: yup
    .string()
    .required('Please enter an offer name.')
    .matches(
      strWithoutSpecialCharsRegex,
      'Some special characters are not allowed',
    ),
  support: supportSchema.notRequired().nullable(),
};

export const createOfferValidationSchema = () => yup.object().shape(sharedFields);
