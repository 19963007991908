/* eslint-disable max-lines-per-function */
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useYupValidationResolver } from '@hooks';
import {
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import { ServerError, useMutation } from '@apollo/client';
import { ADD_PARENT_TOUR } from '@gql/mutations/parentTours';
import { AddParentTourInput } from '@gql/types/graphql';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MAX_CHARS_TOUR_NAME } from '@utils/excel/shared/constants';
import { FormError } from '@types';
import { ButtonContainer } from './AddParentTourForm.styled';
import { parentTourSchema } from './AddParentTourForm.schema';
import { ButtonStyled, FormErrorComponent, FormTextInput } from '../shared';

const CREATE_PARENT_TOUR_DEFAULTS = {
  name: '',
};

export type CreateParentTourFormErrors = {
  name: FormError[];
};

interface AddParentTourFormProps {
  children?: ReactNode;
}

export const AddParentTourForm: React.FC<AddParentTourFormProps> = ({
  children,
}) => {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const resolver = useYupValidationResolver(parentTourSchema);

  const defaultValues = useMemo(() => CREATE_PARENT_TOUR_DEFAULTS, []);
  const { control, handleSubmit, setError } = useForm<AddParentTourInput>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const navigate = useNavigate();

  const [
    createParentTour,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(ADD_PARENT_TOUR, {
    errorPolicy: 'all',
    onCompleted(data) {
      navigate(`/multi-leg-tour/${data?.addParentTour?.id ?? ''}`);
    },
    onError(e) {
      logError(appInsights, 'AddParentTourForm Error.', e);
      if ((e?.networkError as ServerError)?.statusCode === 409) {
        setError(
          'name',
          {
            type: 'validate',
            message:
              'Multi-Leg Tour already exists with the same name. Please try using another name.',
          },
          { shouldFocus: true },
        );
      } else {
        setIsFormErrorOpen(true);
      }
    },
  });

  const handleCreateParentTour = useCallback(
    async (parentTour: AddParentTourInput) => {
      await createParentTour({
        variables: {
          parentTour,
        },
      });
    },
    [createParentTour],
  );

  const handleInvalidSubmit: SubmitErrorHandler<CreateParentTourFormErrors> = (
    error,
  ) => {
    if (isFormErrorOpen) {
      setIsFormErrorOpen(false);
    }
  };

  const handleValidSubmit = async (data: AddParentTourInput) => {
    if (mutationLoading) {
      return;
    }

    await handleCreateParentTour(data);
  };

  return (
    <>
      <FormErrorComponent
        isDismissable={true}
        error={mutationError}
        isOpen={isFormErrorOpen}
        setIsOpen={setIsFormErrorOpen}
      />
      {children}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <FormTextInput
          control={control}
          fieldName="name"
          label="Multi-leg Tour Name"
          isRequired={true}
          key={'multi-leg-tour-name-input'}
          maxLength={MAX_CHARS_TOUR_NAME}
          placeholderText="Multi-leg tour name"
        />

        <ButtonContainer>
          <ButtonStyled
            data-testid="create-tour-button"
            variant="contained"
            type="submit"
          >
            Create
          </ButtonStyled>
        </ButtonContainer>
      </form>
    </>
  );
};
