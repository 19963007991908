import { Button, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  zIndex: 1,
}));
