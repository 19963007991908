/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CustomTheme, styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';

export const ConfirmationEmailFormContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: `${theme?.spacing(0)} ${theme?.spacing(3)}`,
}));

export const MetadataContainer = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(1.25, 3, 3, 3),
  backgroundColor: '#F5F7FB',
}));

export const ButtonContainer = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme?.spacing(1.5),
  gap: theme?.spacing(2),
  '& .MuiButtonBase-root': {
    marginTop: theme?.spacing(1),
  },
}));

export const BundleDetailsContainer = styled(Container)(({ theme }: { theme?: CustomTheme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const PrimaryText = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
}));
