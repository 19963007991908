/* eslint-disable max-lines-per-function */
import React, {
  useState, Dispatch, SetStateAction,
  useRef,
} from 'react';
import { useQuery } from '@apollo/client';
import { Button, InputAdornment, LinearProgress } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridRowId } from '@mui/x-data-grid-pro';
import SearchIcon from '@mui/icons-material/Search';

import { ListViewLayout } from '@layouts/ListViewLayout';
import { OneOff } from '@gql/types/graphql';
import { GET_ONE_OFF_EVENTS_BY_ARTISTS } from '@gql/queries/oneOffs';
import { useOnError } from '@hooks';
import { SubmitHandler } from 'react-hook-form';
import { OfferEvent } from '@types';
import { getOneOffsColumns, getEventsColumns } from './OneOffEventsModal.datagrid';

import { ListView, ModalLayout } from '../shared';
import {
  GridFilterPanelStyled, SaveAndCloseButtonContainer, SearchTextField, StyledDataGrid,
} from './OneOffEventsModal.styled';
import {
  newSelectedEvents, mapOneOffsDefaults, OneOffs, RowType,
} from './OneOffEventsModal.models';

interface OneOffEventsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  currentEvents: OfferEvent[];
  artistIds: string[];
  onSaveOneOffEvents: SubmitHandler<OfferEvent[]>;
}

export const OneOffEventsModal = ({
  open,
  setOpen,
  currentEvents,
  artistIds,
  onSaveOneOffEvents,
}: Readonly<OneOffEventsModalProps>) => {
  const errorLog = useOnError();
  const [currentSelection, setCurrentSelection] = useState<OfferEvent[]>(currentEvents);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [oneOffs, setOneOffs] = useState<OneOffs[]>([]);

  const [nextPage, setNextPage] = useState(1);
  const [rows, setRows] = useState<OneOff[]>([]);
  const hasMoreData = useRef(true);

  // Can't use useQuery's loading because we only load through fetchMore
  const [isLoading, setIsLoading] = useState(false);

  const { fetchMore } = useQuery(GET_ONE_OFF_EVENTS_BY_ARTISTS, {
    variables: {
      page: 1,
      limit: 25,
      artistIds,
    },
    onError: errorLog,
    skip: true,
  });

  const fetchPage = async (
    page: number,
    search: string,
    selection: OfferEvent[],
  ): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await fetchMore({
        variables: {
          page,
          searchTerm: search.length > 0 ? search : undefined,
        },
      });

      const newOneOffs = data.getOneOffEventsByArtists as (OneOff[] | null);
      if (!newOneOffs?.length) {
        hasMoreData.current = false;
        return;
      }

      setNextPage(page + 1);

      setRows((current) => current.concat(newOneOffs));
      setOneOffs((current) => current.concat(mapOneOffsDefaults(newOneOffs, selection)));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPage = () => {
    if (isLoading || !hasMoreData.current) return;

    void fetchPage(nextPage, searchTerm, currentSelection);
  };

  const updateSearchTerm = async (newSearchTerm: string) => {
    if (searchTerm === newSearchTerm) return;

    setSearchTerm(newSearchTerm);

    // Save the current event selection
    const newSelectedEventsList = newSelectedEvents(oneOffs, currentSelection);
    setCurrentSelection(newSelectedEventsList);

    // Reset pagination
    setNextPage(1);
    setRows([]);
    setOneOffs([]);
    hasMoreData.current = true;

    await fetchPage(1, newSearchTerm, newSelectedEventsList);
  };

  const checkIsPrimaryEventSelected = (id: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === id);
    return selectedOneOff?.events.some((o) => o.primary && o.selected) ?? false;
  };

  const checkEventsSelected = (id: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === id);
    return selectedOneOff?.events.filter((o) => o.selected).length ?? 0;
  };

  const checkIsActive = (id: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === id);
    return selectedOneOff?.isActive ?? true;
  };

  const toggleIsActive = (id: string, isActive: boolean) => {
    setOneOffs((prevOneOffs) => prevOneOffs.map((oneOff) => {
      if (oneOff.id !== id) return oneOff;

      return {
        ...oneOff,
        isActive,
        events: oneOff.events.map((event) => ({
          ...event,
          isActive,
        })),
      };
    }));
  };

  const oneOffsColumns = getOneOffsColumns({
    checkIsPrimaryEventSelected,
    checkEventsSelected,
    checkIsActive,
    toggleIsActive,
  });

  const checkIsPrimary = (oneOffId: string, eventId: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === oneOffId);
    return selectedOneOff?.events.find((o) => o.id === eventId)?.primary ?? false;
  };

  const checkIsActiveEvent = (oneOffId: string, eventId: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === oneOffId);
    return selectedOneOff?.events.find((o) => o.id === eventId)?.isActive ?? true;
  };

  const toggleIsActiveEvent = (oneOffId: string, eventId: string, isActive: boolean) => {
    setOneOffs((prevOneOffs) => prevOneOffs.map((oneOff) => {
      if (oneOff.id !== oneOffId) return oneOff;

      return {
        ...oneOff,
        isActive,
        events: oneOff.events.map((event) => {
          if (event.id !== eventId) return event;

          return {
            ...event,
            isActive,
          };
        }),
      };
    }));
  };

  const selectedOneOffs = oneOffs
    .filter((oneOff) => oneOff.selected)
    .map((oneOff) => oneOff.id);

  const getSelectedEvents = (selectedOneOffId: string) => {
    const selectedOneOff = oneOffs.find((e) => e.id === selectedOneOffId);

    const selectedEvents: string[] = selectedOneOff?.events
      ?.filter((event) => event.selected)
      ?.map((event) => event.id)
      ?? [];

    return selectedEvents;
  };

  const handleSelectionChange = (type: RowType, selectedIds: GridRowId[], oneOffId?: string) => {
    setOneOffs((prevOneOffs) => prevOneOffs.map((oneOff) => {
      switch (type) {
        case RowType.oneOff: {
          const selected = selectedIds.includes(oneOff.id);
          if (oneOff.selected === selected) return oneOff;

          const events = oneOff.events.map((event) => ({
            ...event,
            selected: event.primary && selected,
          }));

          return {
            ...oneOff,
            selected,
            events,
          };
        }

        case RowType.event: {
          if (oneOff.id !== oneOffId) return oneOff;

          const events = oneOff.events.map((event) => ({
            ...event,
            selected: selectedIds.includes(event.id),
          }));

          const selected = events.some((event) => event.selected);

          return {
            ...oneOff,
            selected,
            events,
          };
        }

        default:
          return oneOff;
      }
    }));
  };

  const handleSearchKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const text = target?.value?.trim() ?? '';
      void updateSearchTerm(text);
    }
  };

  const handleSaveAndClose = () => {
    const newSelectedEventsList = newSelectedEvents(oneOffs, currentSelection);
    onSaveOneOffEvents(newSelectedEventsList);
  };

  return (
    <ModalLayout open={open} setOpen={setOpen} fullWidth testId='one-off-events-modal'>
      <ListViewLayout headerText='Bundle Events' fullWidth={false}>
        <ListView
          datagrid={{
            rows,
            columns: oneOffsColumns,
            isPaginationLoading: isLoading,
            gridInfoText: 'No results found.',
            onRowsScrollEnd: fetchNextPage,
            slots: {
              loadingOverlay: LinearProgress,
              filterPanel: (props) => (
                <GridFilterPanelStyled
                  {...props}
                  logicOperators={[]}
                />
              ),
            },
            filterMode: 'server',
            sortingMode: 'server',
            disableVirtualization: true,
            checkboxSelection: true,
            heightSubtract: 330,
            hideFooter: true,
            disableRowSelectionOnClick: true,
            rowSelectionModel: selectedOneOffs,
            onRowSelectionModelChange: (oneOffIds: GridRowId[]) => handleSelectionChange(RowType.oneOff, oneOffIds),
            initialState: {
              pinnedColumns: {
                right: [GRID_DETAIL_PANEL_TOGGLE_FIELD],
              },
            },
            getDetailPanelContent: ({ row }: { row: OneOff; }) => (
              <StyledDataGrid
                rows={row.events ?? []}
                columns={getEventsColumns({
                  checkIsPrimary: (eventId: string) => checkIsPrimary(row.id, eventId),
                  checkIsActive: (eventId: string) => checkIsActiveEvent(row.id, eventId),
                  toggleIsActive: (eventId: string, isActive: boolean) =>
                    toggleIsActiveEvent(row.id, eventId, isActive),
                })}
                data-testid="offers-datagrid"
                hideFooter
                slots={{
                  loadingOverlay: LinearProgress,
                  columnHeaders: () => null,
                }}
                rowSelectionModel={getSelectedEvents(row.id)}
                onRowSelectionModelChange={
                  (eventIds: GridRowId[]) => handleSelectionChange(RowType.event, eventIds, row.id)
                }
                disableVirtualization
                checkboxSelection
                disableRowSelectionOnClick
              />
            ),
            getDetailPanelHeight: () => 'auto',
          }}
          searchComponent={
            <SearchTextField
              data-testid='search-event-input'
              onKeyUp={handleSearchKeyUp}
              placeholder='Search Event'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        <SaveAndCloseButtonContainer>
          <Button variant="contained" onClick={handleSaveAndClose} data-testid="save-events-to-bundle">
            Save & Close
          </Button>
        </SaveAndCloseButtonContainer>
      </ListViewLayout>
    </ModalLayout>
  );
};
