import { graphql } from '@gql/types';

export const SEARCH_ARTISTS = graphql(`
  query SearchArtists($searchTerm: String!) {
    artists(searchTerm: $searchTerm) {
      id
      name
    }
  }
`);

export const SEARCH_ARTISTS_WITH_SUB_GENRES = graphql(`
  query SearchArtistsWithSubGenres($searchTerm: String!) {
    artists(searchTerm: $searchTerm) {
      id
      name
      genres {
        id
        mainGenre {
          id
          name
        }
        name
      }
      subGenres {
        id
        mainGenre {
          id
          name
        }
        name
      }
    }
  }
`);

export const GET_GENRES = graphql(`
  query GetGenres {
    genres {
      id
      name
    }
  }
`);

export const GET_ARTISTS_BY_ID = graphql(`
  query GetArtistsById($artistIds: [String]) {
    artists(artistIds: $artistIds) {
      id
      name
    }
  }
`);
