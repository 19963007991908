import React from 'react';
import {
  withLDProvider, useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';
import { FlagConfigs } from '@types';

type FeatureFlagContextValues = Record<string, boolean>;
const FeatureFlagsContext = React.createContext<FeatureFlagContextValues>({});

type ProviderProps = {
  children: React.ReactNode;
  flags: FlagConfigs;
  flagOverrides: FlagConfigs;
};
function FeatureFlagsProvider({
  children,
  flags: flagsFromConfigJson,
  flagOverrides: flagOverridesFromConfigJson = {},
}: ProviderProps) {
  const flagsFromLD = useLDFlags();

  const flags = {
    ...flagsFromConfigJson, // flags from the public/config.json file -> 'flags'
    ...flagsFromLD, // flags from Launch Darkly
    ...flagOverridesFromConfigJson, // flags from the public/config.json file -> 'flagOverrides'
  };

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

const FeatureFlagsProviderBuilder = (ldKey: string) => withLDProvider<ProviderProps & React.Attributes>({
  clientSideID: ldKey,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(FeatureFlagsProvider);

export {
  FeatureFlagsProviderBuilder,
  FeatureFlagsProvider,
  FeatureFlagsContext,
};
