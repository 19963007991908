import * as yup from 'yup';
import { ObjectSchema, string, object } from 'yup';

import { HeadlinerModel } from '@components';
import { AgencyModel, AgentModel, GenreModel } from '@types';
import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

const headlinerSchema: ObjectSchema<HeadlinerModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

const genreSchema: ObjectSchema<GenreModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

const agencySchema: ObjectSchema<AgencyModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

const agentSchema: ObjectSchema<AgentModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

export const createBundleValidationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Please enter a bundle name.')
    .matches(
      strWithoutSpecialCharsRegex,
      'Some special characters are not allowed',
    ),
  headliners: yup.array().of(object({
    headliner: headlinerSchema.required('Please select a headliner.'),
    agency: agencySchema.required('Please select an agency.'),
    agents: yup.array().of(agentSchema.nullable()),
  })).required('Please select a headliner.'),
  companyId: yup.string().required('Please select a company.'),
  genres: yup.array().of(genreSchema).required('Please select a genre.'),
});
