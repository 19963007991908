/* eslint-disable no-param-reassign */
import { ApplicationInsights, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const trackTrace = (
  appInsights: ReactPlugin,
  message: string,
  importedProperties?: any,
  severityLevel: SeverityLevel = SeverityLevel.Information,
) => {
  let errorMessage: string | undefined;
  if (importedProperties instanceof Error) {
    errorMessage = importedProperties.message;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const properties = {
    ServiceName: 'web',
    ...importedProperties,
    errorMessage,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  appInsights.trackTrace({ message, properties, severityLevel });
};

const initializeAppInsights = (roleName: string, appInsightsInstrumentationKey: string) => {
  if (!appInsightsInstrumentationKey) {
    throw new Error('APP_INSIGHTS_INSTRUMENTATION_KEY is not set');
  }
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: `InstrumentationKey=${appInsightsInstrumentationKey}`,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      /**
      * turning enableCorsCorrelation to true adds
      * traceparent header to all requests and
      * causes LaunchDarkly client to fail due to foreign header
      */
      enableCorsCorrelation: false,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags = env.tags || [];
    env.tags['ai.cloud.role'] = roleName;
  });

  return appInsights;
};

const logInfo = (appInsights: ReactPlugin, message: string, importedProperties?: any) => {
  trackTrace(appInsights, message, importedProperties);
};

const logWarning = (appInsights: ReactPlugin, message: string, importedProperties?: any) => {
  trackTrace(appInsights, message, importedProperties, SeverityLevel.Warning);
};

const logError = (appInsights: ReactPlugin, message: string, importedProperties?: any) => {
  trackTrace(appInsights, message, importedProperties, SeverityLevel.Error);
};

export {
  reactPlugin, initializeAppInsights, logInfo, logWarning, logError,
};
