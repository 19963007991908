import { FormError } from '@types';

export interface AgentFormFields {
  id?: string;
  name: string;
  email: string;
  phone?: string;
}

export const CREATE_AGENT_TEXT = {
  title: 'Create New Agent',
  submitText: 'Create and Add to Event',
};

export const EDIT_AGENT_TEXT = {
  title: 'Edit Agent',
  submitText: 'Update and Add to Event',
};

export type CreateAgentFormErrors = {
  name: FormError[];
  email: FormError[];
  phone: FormError[];
};

export enum AgentFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export const AGENT_FORM_DEFAULTS: AgentFormFields = {
  name: '',
  email: '',
  phone: '',
};
