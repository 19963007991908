import * as React from 'react';
import {
  CheckBox,
  IndeterminateCheckBox,
} from '@mui/icons-material';
import {
  GridColDef,
  GridValidRowModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Checkbox, Switch, Typography } from '@mui/material';
import { getUserTimezoneDate } from '@utils/stringHelpers';
import {
  YellowStar,
  OfferNameContainer,
  EventNameContainer,
  StyledEventCountChip,
  SwitchContainer,
} from './OneOffEventsModal.styled';
import { OneOffEvent } from './OneOffEventsModal.models';
import { ExpandButtonCell } from '../shared';

interface GetOneOffsColumnsParams {
  checkIsPrimaryEventSelected?: (id: string) => boolean;
  checkEventsSelected?: (id: string) => number;
  checkIsActive?: (id: string) => boolean;
  toggleIsActive?: (id: string, isActive: boolean) => void;
}

interface GetEventsColumnsParams {
  checkIsPrimary?: (id: string) => boolean;
  checkIsActive?: (id: string) => boolean;
  toggleIsActive?: (id: string, isActive: boolean) => void;
}

type OneOffEventsModalGridRow = GridValidRowModel & OneOffEvent;
type OneOffEventsModalGridCol = GridColDef<OneOffEventsModalGridRow>;

const venueColumn: OneOffEventsModalGridCol = {
  field: 'venue',
  headerName: 'Venue',
  minWidth: 120,
  valueGetter: (params) => params.row.venue?.name ?? '',
};

const marketColumn: OneOffEventsModalGridCol = {
  field: 'market',
  headerName: 'Market',
  minWidth: 120,
  valueGetter: (params) => [params.row.venue?.city, params.row.venue?.stateCode].filter((item) => item).join(', '),
};

const offerDateColumn: OneOffEventsModalGridCol = {
  field: 'eventDate',
  headerName: 'Event Date',
  minWidth: 120,
  valueGetter: (params) => {
    const { date, eventDate } = params.row;

    if (date === 'TBD' || eventDate === 'TBD') {
      return 'TBD';
    }

    const dateToFormat = eventDate || date;
    const [formattedDate] = getUserTimezoneDate(dateToFormat, 'L');
    return formattedDate;
  },
};

const showsColumn: OneOffEventsModalGridCol = {
  field: 'shows',
  headerName: 'Shows',
  valueGetter: (params) => {
    const { showCount } = params.row;
    const showsLength = params.row.shows?.length;

    return showCount ?? showsLength ?? 0;
  },
};

const buyerColumn: OneOffEventsModalGridCol = {
  field: 'buyer',
  headerName: 'Buyer',
  valueGetter: (params) => {
    const primaryBuyerName = params.row.primaryBuyer?.name;
    const buyerName = params.row.buyer?.name;

    return primaryBuyerName ?? buyerName ?? '';
  },
};

// todo: add created date and updated date
// const renderDateTime = (date: string | null) => {
//   if (date === 'TBD') {
//     return 'TBD';
//   }

//   const [formattedDate] = getUserTimezoneDate(
//     date,
//     'L',
//   );
//   const [formattedTime] = getUserTimezoneDate(
//     date,
//     'LT',
//   );

//   return (
//     <DateContainer>
//       <Typography fontSize={14}>{formattedDate}</Typography>
//       <Typography variant='caption-small'>{formattedTime}</Typography>
//     </DateContainer>
//   );
// };

// todo: add created date
// const createdColumn: OneOffEventsModalGridCol = {
//   field: 'created',
//   headerName: 'Created',
//   renderCell: (params) => renderDateTime(params.row.createdDate),
// };

// todo: add updated date
// const updatedColumn: OneOffEventsModalGridCol = {
//   field: 'updated',
//   headerName: 'Updated',
//   renderCell: (params) => renderDateTime(params.row.updatedDate),
// };

const capacityColumn: OneOffEventsModalGridCol = {
  field: 'sellableCapacity',
  headerName: 'Capacity',
  valueGetter: (params) => {
    const { capacity } = params.row;
    const { sellableCapacity } = params.row;

    return capacity ?? sellableCapacity;
  },
};

const isPrimaryEventColumn = (
  checkIsPrimary: GetEventsColumnsParams['checkIsPrimary'],
): OneOffEventsModalGridCol => ({
  field: 'offer',
  headerName: 'Offer',
  renderCell: (params) => (checkIsPrimary?.(params.row.id as string) ? <YellowStar /> : <></>),
});

const offerNameColumn: OneOffEventsModalGridCol = {
  field: 'name',
  headerName: 'Event',
  minWidth: 158,
  renderCell: (params) => (
    <Link to={'/'}>
      <OfferNameContainer>
        <Typography data-testid="child-offer-link" variant="body2" color="info.main">
          {params.row.name}
        </Typography>
        <OpenInNewIcon fontSize="small" color='info' />
      </OfferNameContainer>
    </Link>
  ),
};

const checkboxColumn = (
  checkIsPrimary?: GetEventsColumnsParams['checkIsPrimary'],
): OneOffEventsModalGridCol => ({
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderCell: (params) => {
    const { row, api } = params;
    const id = row.id as string;
    return (
      <Checkbox
        checked={api.isRowSelected(id)}
        onChange={(event) => {
          api.selectRow(id, event.target.checked);
        }}
        checkedIcon={checkIsPrimary?.(id) ? <CheckBox /> : <IndeterminateCheckBox />}
      />
    );
  },
});

const expandColumn: OneOffEventsModalGridCol = {
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  renderCell: ExpandButtonCell, // TODO: Only show expand icon if there are more than one offer
};

const eventCountColumn = (
  checkEventsSelected: GetOneOffsColumnsParams['checkEventsSelected'],
): OneOffEventsModalGridCol => ({
  field: 'eventCount',
  headerName: '',
  sortable: false,
  renderCell: ({ row }) => {
    const eventsSelected = checkEventsSelected?.(row.id as string) ?? 0;
    return (
      <StyledEventCountChip
        label={`${eventsSelected}/${row.events?.length ?? 0}`}
        selectedEventCount={eventsSelected}
        size='small'
        variant='filled'
      />
    );
  },
});

const eventNameColumn = (
  checkIsPrimaryEventSelected: GetOneOffsColumnsParams['checkIsPrimaryEventSelected'],
): OneOffEventsModalGridCol => ({
  field: 'name',
  headerName: 'Event',
  minWidth: 200,
  renderCell: (params) => (
    <EventNameContainer>
      <Typography data-testid="event-name" variant="body2">
        {params.row.name}
      </Typography>
      {checkIsPrimaryEventSelected?.(params.row.id as string) && <YellowStar />}
    </EventNameContainer>
  ),
});

const activeColumn = (
  checkIsActive: GetOneOffsColumnsParams['checkIsActive'],
  toggleIsActive: GetOneOffsColumnsParams['toggleIsActive'],
): OneOffEventsModalGridCol => ({
  field: 'active',
  headerName: 'Active',
  width: 82,
  renderCell: ({ row }) => {
    if (row.events && row.events.length > 1) return null;
    const id = row.id as string;

    return (
      <SwitchContainer>
        <Switch
          data-testid={`toggle-active-event-${id}`}
          checked={checkIsActive?.(id)}
          onChange={toggleIsActive ? (e) => toggleIsActive(id, e.target.checked) : undefined}
        />
      </SwitchContainer>
    );
  },
});

export const getOneOffsColumns = ({
  checkIsPrimaryEventSelected,
  checkEventsSelected,
  checkIsActive,
  toggleIsActive,
}: GetOneOffsColumnsParams): GridColDef<OneOffEventsModalGridRow>[] => [
  eventNameColumn(checkIsPrimaryEventSelected),
  eventCountColumn(checkEventsSelected),
  venueColumn,
  marketColumn,
  offerDateColumn,
  showsColumn,
  buyerColumn,
  // Add created and updated columns
  // createdColumn,
  // updatedColumn,
  capacityColumn,
  activeColumn(checkIsActive, toggleIsActive),
  expandColumn,
];

export const getEventsColumns = ({
  checkIsPrimary,
  checkIsActive,
  toggleIsActive,
}: GetEventsColumnsParams): GridColDef<OneOffEventsModalGridRow>[] => [
  checkboxColumn(checkIsPrimary),
  offerNameColumn,
  isPrimaryEventColumn(checkIsPrimary),
  venueColumn,
  marketColumn,
  offerDateColumn,
  showsColumn,
  buyerColumn,
  // Add created and updated columns
  // createdColumn,
  // updatedColumn,
  capacityColumn,
  activeColumn(checkIsActive, toggleIsActive),
];
