import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { DateTimePickerProps } from '@mui/x-date-pickers';
import DateRangeFilledIcon from '@mui/icons-material/DateRange';
import { debounce } from '@mui/material/utils';
import { BorderlessDateTimeInput } from './DateTimePicker.styled';

const DEBOUNCE_WAIT_TIME = 2000;

const CalenderFilledIcon = () => <DateRangeFilledIcon fontSize="medium" color="info" />;

/**
 * Styled MUI/Dayjs DateTimePicker input with no borders and onAccept debouncing function
 * @param props - props passed will override defaults defined in the component
 */
export const BorderlessDateTimePicker = (
  props: DateTimePickerProps<Dayjs> & { isClearable?: boolean } & { onAccept: (value: Dayjs | null) => void },
) => {
  const {
    isClearable, onAccept, onChange, ...other
  } = props;

  // Debounced function needs to be memoized to keep the same timeout between each render.
  // For the same reason, the `onAccept` needs to be wrapped in useCallback.
  const debouncedOnAccept = useMemo(() => debounce(onAccept, DEBOUNCE_WAIT_TIME), [onAccept]);

  return (
    <BorderlessDateTimeInput
      slots={{
        openPickerIcon: CalenderFilledIcon,
      }}
      slotProps={{
        field: { clearable: isClearable },
      }}
      defaultValue={null}
      onChange={(newValue, context) => {
        debouncedOnAccept(newValue);
        onChange?.(newValue, context);
      }}
      {...other}
    />
  );
};
