import { Genre } from '@gql/types/graphql';
import { ErrorOption } from 'react-hook-form';
import { ArtistInformation, EventType } from '@types';

export const CREATE_EVENT_TEXT = {
  headlinerLabel: 'Headliner',
  headlinerPlaceholder: 'Enter Headliner or Artist name',
  agencyLabel: 'Agency',
  agencyPlaceholder: 'Enter Agency name',
  agentLabel: 'Agent(s)',
  agentPlaceholder: 'Type in Agent\'s name or email address',
  agentAddButtonLabel: 'Add Additional Agent',
  agentOptional: '(optional)',
  addHeadlinerButton: 'Add Headliner',
  eventNameLabel: 'Event Name',
  eventNamePlaceholder: 'Enter Event Name',
  eventTypeLabel: 'Event Type',
  eventTypePlaceholder: 'Select Event Type',
  companyLabel: 'Company',
  companyPlaceholder: 'Select Company',
  genreLabel: 'Genre',
  genrePlaceholder: 'Enter Genres',
  eventNotesLabel: 'Event Notes',
  eventNotesPlaceholder: 'Type in Notes',
  submitButton: 'Next',
};

export interface EventFormFields {
  companyId: string;
  name: string;
  type: EventType;
  headliners: ArtistInformation[];
  genres: Genre[];
  notes: string;
}

export const CREATE_EVENT_DEFAULTS: EventFormFields = {
  headliners: [
    {
      headliner: null,
      agency: null,
      agents: [],
    },
  ],
  name: '',
  type: EventType.Promoted,
  companyId: '',
  genres: [],
  notes: '',
};

export type CreateEventFormErrors = {
  headliners: {
    headliner: ErrorOption[];
    agency: ErrorOption[];
    agents: ErrorOption[];
  }[];
  name: ErrorOption[];
  type: ErrorOption[];
  companyId: ErrorOption[];
  genres: ErrorOption[];
  notes: ErrorOption[];
};
