import React from 'react';

export type Loading = {
  loading: boolean;
};

const LoadingContext = React.createContext<Loading>({
  loading: false,
});
const LoadingDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<Loading>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};

function LoadingProvider({ children }: ProviderProps) {
  const [loading, setLoading] = React.useState<Loading>({ loading: false });

  return (
    <LoadingContext.Provider value={loading}>
      <LoadingDispatchContext.Provider value={setLoading}>
        {children}
      </LoadingDispatchContext.Provider>
    </LoadingContext.Provider>
  );
}

export {
  LoadingProvider,
  LoadingContext,
  LoadingDispatchContext,
};
