import {
  ObjectSchema, string, object, boolean,
} from 'yup';

import { HeadlinerModel } from '@components';
import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

const headlinerSchema: ObjectSchema<HeadlinerModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

const coheadlinerSchema: ObjectSchema<HeadlinerModel> = object({
  id: string().uuid(),
  name: string(),
}).typeError('No results found.');

const parentTourSchema = object({
  id: string().required(),
  name: string().required(),
});

export const editTourFormValidationSchema = () =>
  object({
    type: string().when('id', {
      is: (val: string) => !val,
      then: (schema) => schema.required(),
    }),
    name: string()
      .trim()
      .required('Please enter a tour name.')
      .matches(strWithoutSpecialCharsRegex, 'Some special characters are not allowed'),
    headliner: headlinerSchema.required('Please select a headliner.'),
    // coHeadliners: array().of(coheadlinerSchema.required()),
    companyId: string().required('Please select a company name.'),
    isMultiLeg: boolean().required(),
    parentTour: parentTourSchema
      .when('isMultiLeg', {
        is: true,
        then: (schema) => schema.required('Please enter a Multi Leg Tour'),
      })
      .nullable(),
  });
