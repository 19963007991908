import { graphql } from '@gql/types';

export const GET_PDF_URL = graphql(`
query PdfUrl($messageId: String!) {
    pdfUrl(messageId: $messageId) {
        id
        failReason
        url
    }
}
`);
