/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useMutation, useQuery } from '@apollo/client';
import { GET_NOS_DETAILS } from '@gql/queries/nosSettlements';
import { useContext, useMemo } from 'react';
import { EnvStatusContext } from '@providers';
import { useAegNOSSettlement, useAddInAction } from '@hooks';
import { EventStatus, SnackBarMessages } from '@types';
import { SUBMIT_NOS_SETTLEMENT } from '@gql/mutations/events';
import { ButtonStyled } from './NosSubmitButton.styled';

export const NosSubmitButton = () => {
  const { envStatus } = useContext(EnvStatusContext);
  const { handleAddInAction } = useAddInAction();
  const { syncNosSettlementWorkbook } = useAegNOSSettlement();
  const [submitNOS] = useMutation(SUBMIT_NOS_SETTLEMENT, {
    refetchQueries: [GET_NOS_DETAILS],
  });

  const { data } = useQuery(GET_NOS_DETAILS, {
    variables: { nosId: envStatus.nosId ?? envStatus.eventId ?? '' },
  });

  const event = useMemo(() => data?.nosSettlement?.event, [data?.nosSettlement?.event]);

  const submitNosSettlement = async () => {
    await syncNosSettlementWorkbook();

    const updateEventPayload = {
      variables: {
        nosSettlementEvent: {
          // Fallback on Event ID if NOS ID does not exist
          nosId: envStatus.nosId ?? envStatus.eventId ?? '',
        },
      },
    };

    await submitNOS(updateEventPayload);
  };
  return (
    <ButtonStyled
    variant="contained"
    data-testid="submit-nos-settlement-button"
    onClick={() => {
      void handleAddInAction(
        submitNosSettlement,
        event?.status !== EventStatus.NOSSettled
          ? SnackBarMessages.NosSubmitComplete
          : SnackBarMessages.NosResubmitComplete,
        SnackBarMessages.SubmitError,
      );
    }}
    >
      {event?.status === EventStatus.NOSSettled || event?.status === EventStatus.AccountingSettled
        ? 'Resubmit'
        : 'Submit'}
    </ButtonStyled>
  );
};
