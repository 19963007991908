import * as yup from 'yup';
import { ObjectSchema, string, object } from 'yup';

import { HeadlinerModel } from '@components';
import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';

const headlinerSchema: ObjectSchema<HeadlinerModel> = object({
  id: string().required(),
  name: string().required(),
}).typeError('No results found.');

const parentTourSchema = object({
  id: string().required(),
  name: string().required(),
});

const sharedFields = {
  isMultiLeg: yup.boolean().required(),
  parentTour: parentTourSchema
    .when('isMultiLeg', {
      is: true,
      then: (schema) => schema.required('Please enter a Multi Leg Tour'),
    })
    .nullable(),
};

export const createTourValidationSchema = yup.object({
  ...sharedFields,
  name: yup
    .string()
    .required('Please enter a tour name.')
    .matches(
      strWithoutSpecialCharsRegex,
      'Some special characters are not allowed',
    ),
  headliner: headlinerSchema.required('Please select a headliner.'),
  companyId: yup.string().required('Please select a company name.'),
  currency: yup.string().required('Please select a base currency.'),
});

export const editTourValidationSchema = createTourValidationSchema.shape({
  ...sharedFields,
  numberOfTrucks: yup
    .number()
    .required('Please enter the number of trucks.')
    .typeError('Please enter a valid number.'),
});
