import {
  Box,
  styled,
} from '@mui/material';

export const TaskPaneContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light[100],
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

export const TaskPanePaperContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}));
