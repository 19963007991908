import { AegLogoBox } from '@components';
import { useLocation, useNavigate } from 'react-router-dom';
import deadComputer from '@images/aeg-error-page-img.svg';

import {
  PageContainer,
  Header,
  ContentContainer,
  ErrorMessage,
  ActionButton,
  GenericMessage,
  ErrorImage,
} from './ErrorPage.styled';

interface LocationState {
  state: {
    status: string;
    message: string;
  } | null;
}

export function ErrorPage() {
  const navigate = useNavigate();
  const location: LocationState = useLocation();
  const { state } = location;
  return (
    <>
      <PageContainer data-testid="error-page-container">
        <Header>
          <AegLogoBox />
        </Header>
        <ContentContainer>
          <ErrorImage src={deadComputer} alt="error-page-image" />
          <ErrorMessage data-testid="error-message">
            {state
              ? `${state.status} ${state.message}`
              : '404 Error - Page not found'}
          </ErrorMessage>
          <GenericMessage data-testid="generic-message">
            We’re sorry, the link you clicked may be broken or the page may have
            been removed or renamed. Please go back to the home page.
          </GenericMessage>
          <ActionButton
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </ActionButton>
        </ContentContainer>
      </PageContainer>
    </>
  );
}
