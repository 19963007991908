import React, { useMemo, useState } from 'react';
import { SpaceConfiguration, TierScaling } from '@gql/types/graphql';
import {
  DataGridProProps,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridEventListener,
  GridRowId,
  GridValueFormatterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AltRowColorGrid } from '@components';
import { addColumn, expandColumn, manageExpandedRows } from '@utils/datagridHelpers';
import { BoxOfficeTable } from './BoxOfficeTable';
import { QdTabGridData } from './QdTab.models';

// eslint-disable-next-line max-lines-per-function
export const QdTab = ({
  configurations,
  lastUpdatedDate,
  disableVirtualization = false,
  handleAddEvent,
}: {
  configurations: SpaceConfiguration[];
  lastUpdatedDate?: string;
  disableVirtualization?: boolean;
  handleAddEvent: (
    spaceConfigurationId: string,
  ) => Promise<void>;
}) => {
  const getDetailPanelContent = React.useCallback<
  NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <BoxOfficeTable row={row as QdTabGridData} />, []);

  const apiRef = useGridApiRef();
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);

  const expandRowClick = (gridRowId: GridRowId) => {
    manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  };

  const columns: GridColDef<QdTabGridData>[] = [
    ...addColumn<QdTabGridData>(async (row) => handleAddEvent(row.id || '')),
    ...expandColumn(expandedRows, expandRowClick),
    {
      field: 'configuration',
      headerName: 'Configuration',
      minWidth: 180,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 90,
    },
    {
      field: 'tierScaling',
      headerName: 'Tiers',
      minWidth: 90,
      valueFormatter: (params: GridValueFormatterParams<TierScaling[]>) => params.value?.length ?? '',
    },
    {
      field: 'totalCapacity',
      headerName: 'Sellable Capacity',
      minWidth: 180,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()}`;
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 250,
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Updated',
      minWidth: 140,
    },
  ];

  const rows = useMemo(() => {
    if (configurations) {
      return configurations.map((config, i) => {
        const {
          id,
          name,
          totalCapacity,
          tierScaling,
          type,
          description,
        } = config;
        return {
          key: id,
          id,
          configuration: name,
          lastUpdatedDate,
          totalCapacity,
          tierScaling,
          type,
          description,
        };
      });
    }

    return [];
  }, [configurations]);

  const onRowClick = React.useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      expandRowClick(params.id);
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef, expandedRows],
  );

  return (
    configurations && (
      <AltRowColorGrid
        hideFooter
        columns={columns as GridColDef[]}
        rows={rows}
        disableVirtualization={disableVirtualization}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        apiRef={apiRef}
        onRowClick={onRowClick}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: [GRID_DETAIL_PANEL_TOGGLE_FIELD],
            right: ['addEvent'],
          },
        }}
        data-testid="qd-tab-grid"
      />
    )
  );
};
