import { useContext } from 'react';

import { EnvStatusContext, LoadingDispatchContext } from '@providers';

export function useLoading() {
  const setLoading = useContext(LoadingDispatchContext);

  const { envStatus } = useContext(EnvStatusContext);
  const { isAutomationEnabled } = envStatus;

  const displayLoadingSpinner = () => {
    setLoading({ loading: true });
  };
  const hideLoadingSpinner = () => {
    setLoading({ loading: false });
  };

  const displayAegLoadingDialog: () => Promise<() => void> = async () =>
    new Promise((resolve) => {
      setLoading({ loading: true });

      Office.context.ui.displayDialogAsync(
        `https://${window.location.host}/warning`,
        { width: 60, height: 40 },
        (result) => {
          const dialog: Office.Dialog = result.value;

          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
            if (args.message === 'DialogLoaded') {
              resolve(() => {
                if (dialog) {
                  dialog.close();
                }
                setLoading({ loading: false });
              });
            }
          });
        },
      );
    });

  if (isAutomationEnabled) {
    return {
      displayAegLoadingDialog: () => Promise.resolve(() => {}),
      displayLoadingSpinner: () => (() => {}),
      hideLoadingSpinner: () => (() => {}),
    };
  }

  return {
    displayAegLoadingDialog,
    displayLoadingSpinner,
    hideLoadingSpinner,
  };
}
