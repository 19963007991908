// TODO uncomment after UAT testing

export enum ModelingActionOptions {
  // PendingApproval,
  // Approved,
  // SentToAgent,
  // OfferAccepted,
  RoutingConfirmed,
  Confirmed,
  // Dead,
}

interface ActionDropdownOptions {
  canConfirm?: boolean
}

export const actionOptions = (options?: ActionDropdownOptions) => ([
  // {
  //   value: ModelingActionOptions.PendingApproval,
  //   label: 'Set Offer as out for Internal Approval',
  // },
  // { value: ModelingActionOptions.Approved, label: 'Set Offer to Approved' },
  // { value: ModelingActionOptions.SentToAgent, label: 'Set Offer as out for Agent Approval' },
  // { value: ModelingActionOptions.OfferAccepted, label: 'Set Offer to Accepted' },
  { value: ModelingActionOptions.RoutingConfirmed, label: 'Share with Marketing' },
  ...(options && options.canConfirm ? [{ value: ModelingActionOptions.Confirmed, label: 'Confirm Offer' }] : []),
  // { value: ModelingActionOptions.Dead, label: 'Kill Offer' },
]);
