import {
  Box,
  List,
  ListItem as ListItemBase,
  ListItemText as ListItemTextBase,
  ListItemButton as ListItemButtonBase,
} from '@mui/material';
import { Link as LinkBase } from 'react-router-dom';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const SIDE_NAV_WIDTH = '88px';

export const SideNavDrawer = styled(Drawer)<DrawerProps>(({ theme }) => ({
  width: SIDE_NAV_WIDTH,
  zIndex: 10,
  '& .MuiDrawer-paper': {
    width: SIDE_NAV_WIDTH,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
  },
}));

export const Header = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
}));

export const LinkList = styled(List)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(13.25),
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0),
  '& .MuiBadge-badge': {
    minWidth: theme.spacing(1.625),
    maxWidth: theme.spacing(1.625),
    maxHeight: theme.spacing(1.625),
    left: theme.spacing(0.625),
    top: theme.spacing(0.75),
    fontSize: theme.spacing(1),
  },
}));

export const BottomLinkList = styled(LinkList)(({ theme }) => ({
  top: 'initial',
  bottom: theme.spacing(0),
}));

export const ListItem = styled(ListItemBase)(() => ({
  justifyContent: 'center',
}));

// Adding a string type to the active prop to remove non-boolean error in terminal
export const ListItemText = styled(ListItemTextBase)<{ active?: 'true' | 'false' }>(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0),
  '& .MuiListItemText-primary': {
    width: theme.spacing(3),
  },
  '& .MuiListItemText-secondary, & .MuiListItemText-primary': {
    color: active === 'true' ? theme.palette.primary.contrastText : theme.palette.primary.light[250],
    fontSize: theme.spacing(1.25),
    fontWeight: 600,
    whiteSpace: 'normal',
    textAlign: 'center',
  },
}));

export const Link = styled(LinkBase)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
  width: SIDE_NAV_WIDTH,
  ':hover': {
    backgroundColor: theme.palette.primary.light[400],
  },
}));

export const ListItemButton = styled(ListItemButtonBase)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
  width: SIDE_NAV_WIDTH,
  ':hover': {
    backgroundColor: theme.palette.primary.light[400],
  },
}));
