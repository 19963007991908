import { User } from '@gql/types/graphql';

export enum HallPassRole {
  BookingModify = 'Booking Modify',
  SettleModify = 'Settle Modify',
  ViewOnly = 'View Only',
  Accountant = 'Accountant',
}

export type HallPassRow = {
  user: User | null;
  role: HallPassRole;
};

export enum RosterRole {
  NonPrimaryBuyer = 'Buyer (non-primary)',
  General = 'General Manager',
  Assistant = 'Assistant',
  Marketer = 'Marketer',
  Ticketer = 'Ticketer',
  Settler = 'Settler',
  Accountant = 'Accountant',
  Production = 'Production',
}

export type RosterRow = {
  user: User | null;
  role: RosterRole;
};

export interface ManageTeamMembersFormFields {
  primaryBuyer: User | null;
  hallPasses?: HallPassRow[];
  rosters?: RosterRow[];
}

export const MANAGE_TEAM_MEMBERS_DEFAULTS: ManageTeamMembersFormFields = {
  primaryBuyer: null,
  hallPasses: [{ user: null, role: HallPassRole.ViewOnly }],
  rosters: [{ user: null, role: RosterRole.Assistant }],
};
