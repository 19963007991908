import { GenericMessage } from '../ErrorPage.styled';
import { LayoutWarning } from './LayoutWarning';

export function AddInCompatibilityWarning() {
  return (
     <LayoutWarning header={'Compatibility Error'}>
          <GenericMessage data-testid="generic-message">
            The file you're attempting to open is not
            supported by the Modern Elvis Add-in.
          </GenericMessage>
    </LayoutWarning>
  );
}
