import {
  Alert,
  Box, Button, ButtonProps, Typography, styled,
} from '@mui/material';

export const ButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isExcelMode',
})<{ isExcelMode?: boolean }>(({ theme, isExcelMode }) => ({
  display: 'flex',
  flexDirection: isExcelMode ? 'row-reverse' : 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '&:last-child button': {
    marginRight: isExcelMode ? 0 : theme.spacing(3),
    marginLeft: isExcelMode ? theme.spacing(3) : 0,
  },
}));

export const FormAndSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const EmailFormBoxStyled = styled(Box)(({ theme }) => ({
  flex: '1',
  marginRight: theme.spacing(4),
  paddingBottom: theme.spacing(0.5),
  [theme.breakpoints.down('mdAddIn')]: {
    flex: '1 1 auto',
    width: '100%',
    marginRight: 0,
  },
}));

export const ChildrenBoxStyled = styled(Box)(({ theme }) => ({
  flex: '0 1 auto',
  minWidth: theme.spacing(37),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('mdAddIn')]: {
    flex: '1 1 auto',
    width: '100%',
  },
}));

export const AlertStyled = styled(Alert)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('mdAddIn')]: {
    marginTop: theme.spacing(2),
  },
}));

export const ButtonStyled = styled(Button)<ButtonProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'none',
}));

export const HeaderFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme?.spacing(1),
  [theme.breakpoints.down('xsAddIn')]: {
    display: 'block',
  },
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(1.5, 0, 1.4, 2),
}));
