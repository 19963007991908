import { graphql } from '@gql/types';

export const SEARCH_AGENCIES = graphql(`
  query SearchAgencies($searchTerm: String!) {
    agencies(searchTerm: $searchTerm) {
      id
      name
    }
  }
`);

export const SEARCH_AGENTS = graphql(`
  query SearchAgents($searchTerm: String!) {
    agents(searchTerm: $searchTerm) {
      id
      name
      email
    }
  }
`);
