import { OneOff } from '@gql/types/graphql';
import { OfferEvent } from '@types';

export type OneOffEvent = {
  id?: string | null;
  name?: string | null;
  showCount?: number | null;
  capacity?: number | null;
  eventDate?: string | null;
  primaryEventId?: string | null;
  isActive?: boolean | null;
  buyer?: { name: string | null } | null;
  events?: {
    name?: string | null;
    shows?: Show[] | null;
  }[] | null;
  venue?: {
    id?: string | null;
    name: string | null;
    city: string | null;
    stateCode?: string | null
  } | null;
  date?: string | null;
  sellableCapacity?: number | null;
  primaryBuyer?: { name: string | null } | null;
  shows?: Show[] | null;
};

export type Show = {
  id?: string | null;
} | null;

export enum RowType {
  oneOff = 'oneOff',
  event = 'event',
}

export interface Event {
  id: string,
  primary: boolean,
  selected: boolean,
  isActive: boolean,
}

export interface OneOffs {
  id: string;
  selected: boolean;
  isActive: boolean,
  events: Event[];
}

export function mapOneOffsDefaults(oneOffs: OneOff[], currentEvents: OfferEvent[]): OneOffs[] {
  if (!oneOffs) return [];

  return oneOffs.map((oneOff) => {
    const events = oneOff.events?.map((event) => {
      const currentEvent = currentEvents.find((e) => e.id === event.id);

      return {
        id: event.id as string,
        primary: oneOff.primaryEventId === event.id,
        selected: currentEvent?.id !== undefined,
        isActive: currentEvent?.isActive ?? true,
      };
    }) ?? [];

    const isOneOffSelected = events.some((event) => event.selected);

    const isActive = events.length === 1 && events[0].selected
      ? events[0].isActive
      : true;

    return {
      id: oneOff.id,
      selected: isOneOffSelected,
      isActive,
      events,
    };
  });
}

export function newSelectedEvents(
  oneOffs: OneOffs[],
  currentEvents: OfferEvent[],
): OfferEvent[] {
  const loadedEvents = new Map<string, boolean>();
  const selectedEvents = new Map<string, boolean>();

  oneOffs.forEach((oneOff) => {
    oneOff.events.forEach((event) => {
      loadedEvents.set(event.id, event.isActive);
      if (event.selected) {
        selectedEvents.set(event.id, event.isActive);
      }
    });
  });

  const unloadedSelectedEvents = currentEvents
    .filter((event) => !loadedEvents.has(event.id))
    .map((event) => ({ id: event.id, isActive: event.isActive }));

  const loadedSelectedEvents = Array.from(loadedEvents.entries())
    .filter(([id]) => selectedEvents.has(id))
    .map(([id, isActive]) => ({ id, isActive }));

  return unloadedSelectedEvents.concat(loadedSelectedEvents);
}
