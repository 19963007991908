export enum EventStatus {
  // Shared with OfferStatus
  Draft = 'Draft',
  PendingApproval = 'Pending Approval',
  Approved = 'Approved',
  RoutingConfirmed = 'Routing Confirmed',
  OfferAccepted = 'Offer Accepted',
  SentToAgent = 'Sent to Agent',
  Confirmed = 'Confirmed',
  AccountingSettled = 'Accounting Settled',
  Canceled = 'Canceled',
  // unique to Event
  Inactive = 'Inactive',
  NOSSettled = 'NOS Settled',
  CanceledSettled = 'Canceled Settled',
}
