import { styled } from '@mui/material/styles';

export const StyledTotals = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTooltip = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
