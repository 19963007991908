import {
  GridColDef,
  GridRowIdGetter,
  GridValidRowModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import React, { useState } from 'react';

import {
  Accordion,
  NestedDataGrid,
  ConfirmButtons,
  DataGridWithTotals,
} from '@components';
import {
  BonusAllocationEventOffer,
  ScenarioBuilderEventOffer,
} from '@types';
import { useScenarioBuilder } from '@hooks';
import { kebabCase } from '@utils/stringHelpers';
import { disableColumnInteractions } from '@utils/datagridHelpers';

import {
  ScenarioBuilderRow,
  scenarioBuilderColumns,
  scenarioBuilderEventOfferColumns,
} from './columns/ScenarioBuilder.columns';
import {
  BonusAllocationRow,
  bonusAllocationColumns,
  bonusAllocationEventOfferColumns,
} from './columns/BonusAllocation.columns';
import { StyledHeaderBottomComponent } from './BundleProjectionTab.styled';
import { projectionColumns, ProjectionRow } from './columns/Projection.columns';
import { SelectProjectionType } from './SelectProjectionType/SelectProjectionType';

// TODO: Update with real data
import {
  mockProjections,
  mockProjectionScenarios,
  mockBonusAllocationData,
  mockScenarioBuilderProjectionData,
} from '../../mocks/mockProjectionsData';

type BundleFinancesRow = ProjectionRow | ScenarioBuilderRow | BonusAllocationRow;

interface BundleProjectionsAccordionProps {
  header: string;
  rows: GridValidRowModel[];
  bottomRows?: GridValidRowModel[];
  columns: GridColDef[];
  getRowId: GridRowIdGetter<BundleFinancesRow>;
  totalRows?: GridValidRowModel[];
  headerBottomComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  getNestedDataGrid?: (row: GridValidRowModel) => {
    rows: GridValidRowModel[];
    columns: GridColDef[];
  };
}

interface BundleProjectionTabProps {
  bundleId: string;
  bundleOfferId: string;
}

// eslint-disable-next-line max-lines-per-function
export function BundleProjectionTab({
  bundleId,
  bundleOfferId,
}: BundleProjectionTabProps) {
  const {
    activeOffers,
    inactiveOffers,
    totalActiveOffers,
    onChangeScenario,
    applyEdits,
    discardEdits,
    draftMode,
    loading,
  } = useScenarioBuilder({ bundleId, bundleOfferId });
  const [projectionType, setProjectionType] = useState<string>(
    mockProjectionScenarios[1].value,
  );

  const handleProjectionTypeChange = (newProjectionType: string) => setProjectionType(newProjectionType);

  const accordionItems: BundleProjectionsAccordionProps[] = [
    {
      header: 'Projection',
      rows: mockProjections.eventProjections,
      columns: projectionColumns.map(disableColumnInteractions),
      headerBottomComponent: (
        <SelectProjectionType
          value={projectionType}
          items={mockProjectionScenarios}
          onChange={handleProjectionTypeChange}
        />
      ),
      getRowId: (row) => row.id,
      totalRows: [
        {
          id: 'total-active',
          name: 'Total Active',
          ...mockProjections.totalActiveProjection,
        },
        {
          id: 'per-show',
          name: 'Per Show',
          ...mockProjections.perShowProjection,
        },
      ],
    },
    {
      header: 'Scenario Builder',
      rows: [
        {
          id: 'sales-percent-and-forecast-method',
          name: 'Sales % and Forecast Method',
          activeOffers,
          totalActiveOffers,
        },
      ],
      bottomRows: inactiveOffers,
      columns: scenarioBuilderColumns(
        (params) => {
          if (params.isActive) {
            activeOffers.forEach((eventOffer) => {
              onChangeScenario({
                ...params,
                eventOfferId: eventOffer.id,
              });
            });
          } else {
            onChangeScenario(params);
          }
        },
      ).map(disableColumnInteractions),
      getRowId: (row) => row.id ?? '',
      footerComponent: (
        <ConfirmButtons
          confirm='Apply Edits'
          onConfirm={() => {
            void applyEdits();
          }}
          discard='Discard'
          onDiscard={discardEdits}
          disabled={!draftMode || loading}
        />
      ),
      getNestedDataGrid: (row) => ({
        rows: row.activeOffers as ScenarioBuilderEventOffer[],
        columns: scenarioBuilderEventOfferColumns(onChangeScenario) as GridColDef<ScenarioBuilderEventOffer>[],
      }),
      totalRows: [
        'Ticket Sales / Sellable Capacity',
        'Net Gross (Public)',
        'Total Artist Earnings',
        'Club Tour P&L',
        'Inactive Offers',
      ].map((rowName) => ({
        id: kebabCase(rowName),
        name: rowName,
        ...mockScenarioBuilderProjectionData,
      })),
    },
    {
      header: 'Bonus Allocation',
      rows: mockBonusAllocationData,
      columns: bonusAllocationColumns.map(disableColumnInteractions),
      getRowId: (row) => row.name ?? '',
      getNestedDataGrid: (row) => ({
        rows: row.activeOffers as BonusAllocationEventOffer[],
        columns:
          bonusAllocationEventOfferColumns as GridColDef<BonusAllocationEventOffer>[],
      }),
      footerComponent: (
        <ConfirmButtons discard='Discard' confirm='Apply Edits' />
      ),
    },
  ];

  return accordionItems.map((datagrids) => (
    <Accordion
      header={datagrids.header}
      key={`bundle-projections-section-${kebabCase(datagrids.header)}`}
      showDivider
      headerBottomComponent={
        <StyledHeaderBottomComponent>
          {datagrids.headerBottomComponent}
        </StyledHeaderBottomComponent>
      }
    >
      <DataGridWithTotals
        rows={datagrids.rows}
        bottomRows={datagrids.bottomRows}
        columns={datagrids.columns}
        getRowId={datagrids.getRowId as GridRowIdGetter<GridValidRowModel>}
        testId={kebabCase(datagrids.header)}
        isRowSelectable={() => false}
        // If there is no nested data grid it should be scrolleable
        // Scroll does not work well with nested data grids
        scrollable={!datagrids.getNestedDataGrid}
        getDetailPanelContent={({ row }) => {
          const nestedDataGrid = datagrids.getNestedDataGrid?.(row);

          if (!nestedDataGrid?.rows) return undefined;

          return (
            <NestedDataGrid
              rows={nestedDataGrid.rows}
              columns={nestedDataGrid.columns}
              hideFooter
              slots={{
                columnHeaders: () => null,
              }}
              data-testid={`${kebabCase(
                datagrids.header,
              )}-active-offers-data-grid`}
              isRowSelectable={() => false}
              getRowId={(nestedDataGridRow) =>
                (nestedDataGridRow as { id: string }).id
              }
              initialState={{
                pinnedColumns: {
                  left: ['venueName', 'whiteSpace'],
                },
              }}
            />
          );
        }}
        getDetailPanelHeight={() => 'auto'}
        initialState={{
          pinnedColumns: {
            left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name', 'venueName'],
          },
        }}
        totalRows={datagrids.totalRows}
      />
      {datagrids.footerComponent}
    </Accordion>
  ));
}
