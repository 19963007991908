/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO remove these linter disables
import React from 'react';

export const useYupValidationResolver = (validationSchema: any, returnValue?: boolean) => React.useCallback(
  async (data: any) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors: any) {
      return {
        values: {},
        errors: errors.inner.reduce(
          (allErrors: any, currentError: { path: any; type: any; message: any; value: any; params: any; }) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
              value: returnValue ? currentError.value : undefined,
              params: currentError.params ?? undefined, // can be used to include customizable error details
            },
          }),
          {},
        ),
      };
    }
  },
  [validationSchema],
);
