import { styled, TableCell, TableRow } from '@mui/material';

export const ColoredTableRow = styled(TableRow)({
  backgroundColor: '#f5f7f8',
});

export const InputCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '0',
}));
