/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { Accordion } from '@components';
import {
  HeadlinerContent,
  OffersContent,
  SettlementContent,
  ShowsContent,
  TicketCountContent,
} from './AccordionContentComponents';
import {
  ButtonStyled,
} from './OneOffSummary.styled';
import { OneOff } from '../../views/OneOffDetails/mockOneOffDetails';

interface OneOffSummaryEventDataProps {
  oneOffDetails: OneOff;
}

interface AccordionButtonProps {
  handleClick: () => void;
  label: string;
}

interface AccordionItem {
  header: string;
  detailComponent: React.ReactNode;
  buttonComponent?: React.ReactNode;
  isExpanded?: boolean;
  isDisabled?: boolean;
}

const AccordionButton: React.FC<AccordionButtonProps> = ({ label, handleClick }) => (
  <ButtonStyled
    data-testid="accordion-button"
    variant={'outlined'}
    onClick={handleClick}
  >
    {label}
  </ButtonStyled>
);

export function OneOffSummary({ oneOffDetails }: OneOffSummaryEventDataProps) {
  const accordionItems: AccordionItem[] = [
    { header: 'Headliners', detailComponent: <HeadlinerContent headliners={oneOffDetails.headliners} />, isExpanded: true },
    { header: 'Shows', detailComponent: <ShowsContent />, isDisabled: true },
    { header: 'Offers', detailComponent: <OffersContent />, buttonComponent: <AccordionButton label={'Create Offer'} handleClick={() => {}} /> },
    {
      header: 'Settlement', detailComponent: <SettlementContent />, buttonComponent: <AccordionButton label={'Create Settlement'} handleClick={() => {}} />, isDisabled: true,
    },
    { header: 'Ticket Count', detailComponent: <TicketCountContent />, isDisabled: true },
  ];

  return (
    <div data-testid="accordion-container">
        {accordionItems.map((item: AccordionItem, idx: number) => (
          <Accordion key={`one-off-accordion-${idx}`} {...item} >
            {item.detailComponent}
          </Accordion>
        ))}
    </div>
  );
}
