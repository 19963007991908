import React from 'react';
import { ListViewHeader } from '@components';
import { ListViewContainer } from './ListViewLayout.styled';

type ListViewLayoutProps = {
  headerText?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
};

export const ListViewLayout = ({
  headerText,
  children,
  fullWidth = true,
}: ListViewLayoutProps) => (
  <>
    {headerText && <ListViewHeader headerText={headerText} fullWidth={fullWidth} />}
    <ListViewContainer elevation={1} data-testid="list-view-container">
      {children}
    </ListViewContainer>
  </>
);
