import { User } from '@gql/types/graphql';
import { RadioOptions } from '../shared';

export enum ModifyTeamMembersFormUserRoles {
  buyer = 'buyer',
  operator = 'operator',
}

export interface ModifyTeamMembersFormFields {
  userRole: ModifyTeamMembersFormUserRoles;
  operators: (User | null)[];
  primaryOperatorId?: string;
  buyers: (User | null)[];
  primaryBuyerId?: string;
  viewOnly: (User | null)[];
  nosEmailDistribution?: User[];
}

export const userRoleOptions: RadioOptions[] = [
  { value: ModifyTeamMembersFormUserRoles.operator, label: 'Operator' },
  { value: ModifyTeamMembersFormUserRoles.buyer, label: 'Buyer' },
];
