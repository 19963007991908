import { graphql } from '@gql/types';

export const GET_COMPANIES = graphql(`
  query GetCompanies($permission: CompanyPermission) {
    companies(permission: $permission) {
      id
      name
      jdeCompanyNumber
      subledgerAutomationCode
      lobType
      isActive
    }
  }
`);
