import { SnackbarType } from '@components';

import React from 'react';

export type Notification = {
  type: SnackbarType; // add different notification types as needed
  text: React.ReactNode | string;
  duration?: number | null;
} | null;

const NotificationContext = React.createContext<Notification>(null);
const NotificationDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<Notification>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};
function NotificationProvider({ children }: ProviderProps) {
  const [notification, setNotification] = React.useState<Notification>(null);

  return (
    <NotificationContext.Provider value={notification}>
      <NotificationDispatchContext.Provider value={setNotification}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationContext.Provider>
  );
}

export {
  NotificationProvider,
  NotificationContext,
  NotificationDispatchContext,
};
