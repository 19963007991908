import {
  AncillaryCost,
  BoxOfficeFeeInput,
  GetProfitLossCategoriesQuery,
  Venue,
} from '@gql/types/graphql';

import { CalcType } from '@types';
import { KeyOrKeyArray } from 'src/components/shared/SearchBar/SearchBar.models';
import { BoxOfficeFeeRow } from './tableShapes';
import { ReferenceSheetItem } from './types';

interface PlCategory {
  id: string;
  name: string;
}

export const getPlCategoryMap = (data: GetProfitLossCategoriesQuery | undefined) => {
  const plCategoryMap: Map<string, Array<PlCategory>> = new Map();
  data?.profitLossCategories?.forEach((item) => {
    if (item?.category) {
      const category = {
        id: item.id as string,
        name: item.subCategory as string,
      };
      if (!plCategoryMap.get(item.category)) {
        plCategoryMap.set(item.category, []);
      }
      plCategoryMap.get(item.category)?.push(category);
    }
  });

  return plCategoryMap;
};

export const buildPlCategoryPayload = (key: string, entry: PlCategory[]) => {
  const tableData = entry.map((item) => [item.id, item.name]);
  const tableName = `${key.replace(/[^a-zA-Z0-9]+/g, '')}ListTbl`;

  const payload = {
    tableData: {
      name: tableName,
      data: tableData,
    },
    cleanInsert: true,
    batchInsert: true,
  };

  return payload;
};

export const retainTemplateOrderForPlRefTable = (
  unorderedRefData: ReferenceSheetItem[],
  unpopulatedRefTable: ReferenceSheetItem[],
) => {
  const remainingItems: (ReferenceSheetItem | null)[] = [...unorderedRefData];
  const orderedTableData: ReferenceSheetItem[] = [];

  unpopulatedRefTable.forEach((unpopulatedItem) => {
    const found = unorderedRefData.findIndex((unorderedItem) => unorderedItem.name === unpopulatedItem.name);
    if (found >= 0) {
      orderedTableData.push(unorderedRefData[found]);
    }
    remainingItems.splice(found, 1, null);
  });

  return [...orderedTableData, ...remainingItems.filter((item): item is ReferenceSheetItem => item !== null)];
};

export const generateBoxOfficeFeeItems = (feeTableData: BoxOfficeFeeRow[], feeRefTable: ReferenceSheetItem[]) => {
  const boxOfficeFees: BoxOfficeFeeInput[] = [];

  feeTableData.forEach((item) => {
    const { category, total, ...boxOfficeFeeInput } = item;
    const id = feeRefTable.find((cat) => cat.name === category)?.id;

    if (id) {
      boxOfficeFeeInput.profitLossCategoryId = id;
      boxOfficeFees.push(boxOfficeFeeInput);
    } else {
      // TODO: Warning for if we can't find ID for item?
    }
  });

  return boxOfficeFees;
};

export interface AncillarySheetValue extends Record<string, unknown> {
  'Ancillary Earnings': string,
  'Calc. Type': CalcType,
  'Notes': string,
  'Co-Pro 1': number,
  'Co-Pro 2': number,
  'Per Ticket': number,
  'Projected': number,
  'Gross Potential': number,
  'Converted Per Ticket': number,
  'Converted Projected': number,
  'Converted Potential': number,
}

export const generateAncillaryCostItems = (
  ancillaryItems: AncillarySheetValue[],
  ancillaryRefTable: ReferenceSheetItem[],
): AncillaryCost[] => {
  const ancillaryCosts: AncillaryCost[] = ancillaryItems.reduce<AncillaryCost[]>((acc, item) => {
    const id = ancillaryRefTable.find((cat) => cat.name === item['Ancillary Earnings'])?.id;
    if (!id) {
      return acc;
    }

    acc.push({
      id,
      amount: item['Per Ticket'],
      calcType: item['Calc. Type'],
      notes: item.Notes !== undefined ? String(item.Notes) : '',
      coPro1: item['Co-Pro 1'],
      coPro2: item['Co-Pro 2'],
    });
    return acc;
  }, []);

  return ancillaryCosts;
};

export const getSafeMarketName = (marketName: string) => (marketName ? marketName.replace(/[^a-zA-Z0-9]+/g, '') : '');

export const generateTableName = (
  marketOrPrefixName: string,
  tableIdentifier: string,
  showNumber?: number,
  showId?: string,
): string => {
  let safeShowId = '';
  let safeShowNumber = '';

  if (showId) {
    safeShowId = `_${showId.replaceAll('-', '_')}`;
  }

  if (showNumber !== undefined) {
    safeShowNumber = `_${showNumber}`;
  }

  return `${getSafeMarketName(marketOrPrefixName)}_${tableIdentifier}${safeShowNumber}${safeShowId}`;
};

export const replaceKeysWithValues = (row: string | string[], keyValuePairs: [string, string][]) => {
  if (Array.isArray(row)) {
    return row.map((item) => {
      let cell = item;
      keyValuePairs.forEach(([key, value]) => {
        if (cell.indexOf(key) >= 0) {
          cell = cell.replaceAll(key, value);
        }
      });
      return cell;
    });
  }

  let cell = row;
  keyValuePairs.forEach(([key, value]) => {
    if (cell.indexOf(key) >= 0) {
      cell = cell.replaceAll(key, value);
    }
  });
  return cell;
};

export const convertToBaseOrUndefined = (value: number | undefined, exchangeRate: number): number | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return value / exchangeRate;
};

export const venueRegionDisplayFieldGetter = (venue: Venue): KeyOrKeyArray<Venue> => {
  if (['USA', 'CAN'].includes(venue?.countryCode || '')) {
    return ['city', 'stateCode'];
  }
  return ['city', 'country'];
};

/**
 * Formats currency value for cell
 * @param currencyString currency symbol (e.g. '€') or ISO code (e.g. 'EUR')
 * @param leftAligned if true, currencyString is far-left-aligned in cell; if false, aligned just left of value
 * @param displayDecimals if true, two decimal places displayed; if false, rounded to nearest dollar
 * @param isoCode formatting helper to insert spaces as appropriate for ISO codes
 * @returns format for currency cell
 */
export const formatCurrencyCell = (
  currencyString: string,
  leftAligned: boolean,
  displayDecimals: boolean,
  isoCode?: boolean,
) => {
  const baseFormat = `_([$${currencyString}]${leftAligned ? ' *' : ' '}`;
  const numberFormat = `#,##0${displayDecimals ? '.00' : ''}`;
  const finalFormat = {
    positive: `${baseFormat} ${numberFormat}`,
    negative: `${baseFormat} (${numberFormat})`,
  };
  return `${finalFormat.positive}_);${finalFormat.negative};${isoCode ? ' ' : ''}""??_);_(@_)`;
};
