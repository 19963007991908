import { ChipContainer, TitleTypography } from './DetailsPageTitle.styled';
import { StatusChip } from '../shared';

type StatusTypes = {
  title: string;
};

type DetailsPageTitleProps = {
  title: string;
  statuses?: Array<StatusTypes>;
};

export function DetailsPageTitle(props: DetailsPageTitleProps) {
  const { title, statuses } = props;

  return (
    <div data-testid="details-page-title-container">
      <TitleTypography variant="h5" data-testid="details-page-title">
        {title}
      </TitleTypography>
      <ChipContainer data-testid="details-page-title-chip-container">
        {statuses?.map((status, index) => (
          <StatusChip key={`status-chip-${index}`} status={status.title} />
        ))}
      </ChipContainer>
    </div>
  );
}
