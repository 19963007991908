import { TabBox } from './TabPanel.styled';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  idPrefix: string;
}

export function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, idPrefix, ...other
  } = props;
  return (
    <>
      {value === index && (
        <TabBox
          role="tabpanel"
          hidden={value !== index}
          id={`${idPrefix}-tabpanel-${index}`}
          aria-labelledby={`${idPrefix}-tab-${index}`}
          {...other}
        >
          {children}
        </TabBox>
      )}
    </>
  );
}
