/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { EnvStatusContext, ExcelErrorType } from '@providers';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Divider, Grid } from '@mui/material';
import {
  DetailsMetadataItem, LastSyncedFooter, MetadataObject, ProtectedComponent, StatusChip,
} from '@components';
import { AegResourceTypes, UserPermissions } from '@types';
import { useQuery } from '@apollo/client';
import { GET_TOUR_SETTLEMENT_DATA } from '@gql/queries/tours';
import { Tour } from '@gql/types/graphql';
import {
  useAegTourSettlementArtist, useAegTourSettlementFar, useExcelErrorHandler,
} from '@hooks';
import { getTimezoneDate } from '@utils/stringHelpers';
import {
  ItemStyled,
} from './TourSettlementContainer.styled';
import {
  ContainerStyled, TypographyStyled, EventMetadata,
} from '../NosSettlementContainer/NosSettlementContainer.styled';

interface TourSettlementContainerProps {
  isInternal?: boolean; // 'internal' means 'FAR'
}
/**
 * This is a shared component between TourSettlementFAR and TourSettlementArtist.
 * @param isInternal - 'internal' means 'FAR'. default: false
 * @returns
 */
export const TourSettlementContainer = ({ isInternal = false }: TourSettlementContainerProps) => {
  const { envStatus } = useContext(EnvStatusContext);
  const { tourId, offerId } = envStatus;
  const titleLabel = isInternal ? 'FAR' : 'Artist';
  const [initCompleted, setInitCompleted] = useState(false);
  const { initTourSettlementArtistWorkbook } = useAegTourSettlementArtist();
  const { initTourSettlementFARWorkbook } = useAegTourSettlementFar();
  const setErrorState = useExcelErrorHandler();

  const { data } = useQuery(GET_TOUR_SETTLEMENT_DATA, {
    variables: { tourId: tourId ?? '', offerId: offerId ?? '' },
  });

  useEffect(() => {
    const initWorkbook = async () => {
      try {
        if (isInternal) {
          await initTourSettlementFARWorkbook();
        } else {
          await initTourSettlementArtistWorkbook();
        }

        setInitCompleted(true);
      } catch (error) {
        setErrorState({
          type: ExcelErrorType.InitFail,
          message: error as string,
        }, 'Init tour settlement workbook error.');
      }
    };

    void initWorkbook();
  }, [tourId, offerId]);

  const metadata: MetadataObject[] = useMemo(() => {
    if (data && data?.tour) {
      const tour = data?.tour as Tour;
      const confirmedOffer = tour?.offers?.find((offer) => offer?.id === offerId);
      const firstEventId = confirmedOffer?.eventOrder || '';
      const firstEvent = confirmedOffer?.events?.find((event) => event?.id === firstEventId[0]);

      const [firstEventDate] = getTimezoneDate(firstEvent?.date, firstEvent?.venue?.timezone);

      return [
        {
          title: 'Headliner',
          value: tour?.headliner?.name || '',
        },
        {
          title: 'Company',
          value: tour?.company?.name || '',
        },
        {
          title: 'Buyer',
          value: tour?.primaryBuyer?.name || '',
        },
        {
          title: 'First Event Date',
          value: firstEventDate,
        },
        {
          title: 'Tour Status',
          element: <StatusChip status={confirmedOffer?.status || 'On Tour'}/>,
        },
        {
          title: 'Submitted by',
          value: firstEvent?.preparedBy || '',
        },
        {
          title: 'Submitted on',
          value: firstEvent?.preparedOn || '',
        },
      ];
    }
    return [];
  }, [data]);

  const metadataRowItems = metadata.map((metadataItem, index) => (
    <ItemStyled key={index}>
      <DetailsMetadataItem
        metadata={metadataItem}
        first={index === 0}
      />
    </ItemStyled>
  ));
  return initCompleted ? (
    <>
      <ProtectedComponent
        checkPermission={{
          permission: UserPermissions.TourSettle,
          resourceType: AegResourceTypes.Tour,
          resourceId: envStatus.tourId,
        }}
        navigateAway={'/unauthorized'}
      >
        <ContainerStyled data-testid="settlement-container">
          <TypographyStyled variant="h6" data-testid="event-details-header">
            Tour Settlement - {titleLabel}
          </TypographyStyled>
          <Divider />
          <EventMetadata>
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                data-testid="event-details-metadata"
                flexWrap="wrap"
              >
                {metadataRowItems}
            </Grid>
          </EventMetadata>
        </ContainerStyled>
        <LastSyncedFooter lastSynced={undefined}/>
      </ProtectedComponent>
    </>
  ) : <>Loading...</>;
};
