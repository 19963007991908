import { BoxOfficeFee } from '@gql/types/graphql';

export type BoxOfficeFeeRow = BoxOfficeFee & {
  category: string
  total: number
};

export const BOX_OFFICE_FEE_TABLE_ROW: BoxOfficeFeeRow = {
  category: '',
  amount: 0,
  formula: '',
  notes: '',
  total: 0,
};
