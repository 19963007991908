import Avatar from '@mui/material/Avatar';
import { StyledChip } from './AvatarChip.styled';

export type ChipOption = {
  labelProperty: string;
  includeAvatar?: boolean;
};

interface AvatarChipProps {
  tag: any;
  chipOption: ChipOption;
}

export function AvatarChip(props: AvatarChipProps) {
  const { labelProperty, includeAvatar } = props.chipOption;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { [labelProperty]: label } = props.tag;

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { chipOption, ...rest } = props;

  const getInitials = () => {
    const stringLabel = label as string;
    const stringArray = stringLabel.split(' ');
    const initials = stringArray[0].charAt(0) + stringArray[stringArray.length - 1].charAt(0);
    return initials.toUpperCase();
  };

  return (
      <StyledChip
        avatar={includeAvatar ? <Avatar>{getInitials()}</Avatar> : <></>}
        label={label as string}
        {...rest}
      />
  );
}
