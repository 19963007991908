import React from 'react';

import { EnvConfig } from '@types';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';

type FeatureGateProps = {
  configFlag: keyof EnvConfig['flags'];
  children: React.ReactNode;
  disableFlag?: boolean; // If disable is true, the component will be disabled when feature flag is raised
};

export function FeatureGate({ configFlag, children, disableFlag = false }: FeatureGateProps) {
  const { getFlag } = useFeatureFlags();
  const flagRaised = getFlag(configFlag);

  // If disable flag is true, hide the component only when feature flag is raised
  if (disableFlag) {
    return flagRaised ? <></> : children;
  }

  // Only show component when feature flag is raised
  return flagRaised ? children : <></>;
}
