import { Dispatch, createContext, useState } from 'react';
import { AddInErrorWarning } from '../../views/ErrorPage/AddIn/AddInErrorWarning';
import { CloneFailWarning } from '../../views/ErrorPage/AddIn/CloneFailWarning';
import { AddInCompatibilityWarning } from '../../views/ErrorPage/AddIn/CompatibilityWarning';
import { InitializeFailWarning } from '../../views/ErrorPage/AddIn/InitializeFailWarning';
import { AddInNotSyncedWarning } from '../../views/ErrorPage/AddIn/NotSyncedWarning';
import { TemplateUpdatedWarning } from '../../views/ErrorPage/AddIn/TemplateUpdatedWarning';

export enum ExcelErrorType {
  AegContext = 'aegContextMissing',
  CannotLoad = 'cannotLoad',
  CloneFail = 'cloneFail',
  FileUpdate = 'templateFileUpdated',
  InitFail = 'initializationFailure',
  NotSynced = 'notSyncedWithModernElvis',
}

export type ExcelErrorState = {
  type: ExcelErrorType;
  message?: string;
};

export const ExcelErrorStateContext = createContext<Dispatch<ExcelErrorState | null>>(() => null);

type Props = Readonly<{
  children?: React.ReactNode;
}>;

export function ExcelErrorPageProvider({ children }: Props) {
  const [state, setState] = useState<ExcelErrorState | null>(null);

  if (state) {
    switch (state.type) {
      case ExcelErrorType.AegContext:
        return <AddInCompatibilityWarning />;
      case ExcelErrorType.CloneFail:
        return <CloneFailWarning />;
      case ExcelErrorType.FileUpdate:
        return <TemplateUpdatedWarning />;
      case ExcelErrorType.InitFail:
        return <InitializeFailWarning />;
      case ExcelErrorType.NotSynced:
        return <AddInNotSyncedWarning />;
      case ExcelErrorType.CannotLoad:
      default: // Default case should never happen
        return <AddInErrorWarning />;
    }
  }

  return (
    <ExcelErrorStateContext.Provider value={setState}>
      {children}
    </ExcelErrorStateContext.Provider>
  );
}
