import {
  ApolloClient,
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  from,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { AegAggregator, EnvConfig } from '@types';
import { GetToursPageQuery, GetBundlesPageQuery } from '@gql/types/graphql';

type CreateApolloClientProps = {
  envConfig: EnvConfig;
  getBearerToken: () => Promise<string | void | null>;
};

type MergeOptions = { args: { page?: number; limit?: number } | null };

const createApolloClientCache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tours: {
            keyArgs: ['tourSearch'], // ensures queries with different tourSearch values are cached separately
            merge(
              existing: GetToursPageQuery['tours'][],
              incoming: GetToursPageQuery['tours'][],
              { args }: MergeOptions,
            ) {
              const page = args?.page ?? 1;
              const limit = args?.limit ?? 50;
              const offset = page * limit;
              const merged = existing ? existing.slice(0) : [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            },
          },
          bundles: {
            keyArgs: ['bundleSearch'], // ensures queries with different bundleSearch values are cached separately
            merge(
              existing: GetBundlesPageQuery['bundles'][],
              incoming: GetBundlesPageQuery['bundles'][],
              { args }: MergeOptions,
            ) {
              const page = args?.page ?? 1;
              const limit = args?.limit ?? 50;
              const offset = page * limit;
              const merged = existing ? existing.slice(0) : [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < incoming.length; ++i) {
                merged[offset + i] = incoming[i];
              }
              return merged;
            },
          },
        },
      },
      // prevent phase ids like "modeling" from being normalized as they are shared between expenses/boxOffices phases
      BoxOfficePhase: {
        keyFields: false,
      },
      ExpensePhase: {
        keyFields: false,
      },
      // prevent PLCat ids from being normalized as they are shared between expenses
      FixedCost: {
        keyFields: false,
      },
      VariableCost: {
        keyFields: false,
      },
      AncillaryCost: {
        keyFields: false,
      },
    },
  });

const shareApolloSettings = (connectToDevTools: boolean): Partial<ApolloClientOptions<NormalizedCacheObject>> => ({
  queryDeduplication: true,
  connectToDevTools,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});

const aggregatorToUrl = (clientName: AegAggregator, envConfig: EnvConfig) => {
  switch (clientName) {
    case AegAggregator.GTAGG:
      return envConfig.GLOBAL_TOURS_ENDPOINT;
    case AegAggregator.OOAGG:
      return envConfig.ONE_OFFS_ENDPOINT;
    default:
      // defaulting to global-tours due all queries not having the agg field yet
      return envConfig.GLOBAL_TOURS_ENDPOINT;
  }
};

// eslint-disable-next-line max-lines-per-function
export const createApolloClient = ({ envConfig, getBearerToken }: CreateApolloClientProps) => {
  const httpLink = createHttpLink({
    uri: ({ getContext }) => {
      const { clientName } = getContext();
      return aggregatorToUrl(clientName as AegAggregator, envConfig);
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getBearerToken();

    return {
      /* eslint-disable  @typescript-eslint/no-unsafe-assignment */
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  const globalToursClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: createApolloClientCache(),
    ...shareApolloSettings(envConfig.ENABLE_APOLLO_DEV_TOOLS),
  });

  return {
    globalToursClient,
  };
};

// eslint-disable-next-line max-lines-per-function
export const createBatchApolloClient = ({ envConfig, getBearerToken }: CreateApolloClientProps) => {
  const httpLink = new BatchHttpLink({
    uri: envConfig.GLOBAL_TOURS_ENDPOINT,
    batchMax: 100,
    batchInterval: 50,
    batchDebounce: true,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getBearerToken();

    return {
      /* eslint-disable  @typescript-eslint/no-unsafe-assignment */
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  const globalToursClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: createApolloClientCache(),
    ...shareApolloSettings(envConfig.ENABLE_APOLLO_DEV_TOOLS),
  });

  return {
    globalToursClient,
  };
};
