/* eslint-disable max-lines-per-function */
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CustomTheme, styled } from '@mui/material/styles';

import { leftPinnedColumn } from '@utils/datagridHelpers';

export const ContainerStyled = styled('div')({
  overflowX: 'auto',
});

export const DataGridWithTotalsStyled = styled(DataGridPro, {
  shouldForwardProp: (prop) => prop !== 'scrollable',
})(({ theme, scrollable }: { theme?: CustomTheme, scrollable?: boolean }) => ({
  borderRadius: theme?.spacing(0),
  border: 'none !important',
  // If the table is not scrollable, min width should be set to fit content to overflow the container instead
  '&': scrollable ? {} : {
    minWidth: 'fit-content',
    width: '100%',
  },
  '& .disabled-cell': {
    backgroundColor: theme?.palette?.input?.disabled?.background,
    borderBottom: 'solid !important',
    borderWidth: `${theme?.spacing(0.125) ?? ''} !important`,
    borderColor: `${theme?.palette?.secondary?.light ?? ''} !important`,
  },
  '& .bottom-row, & .totals-row, & .MuiDataGrid-columnHeader': {
    '& .MuiDataGrid-cell[data-field="__detail_panel_toggle__"], &[data-field="__detail_panel_toggle__"]': {
      display: 'none',
    },
    '& .MuiDataGrid-cell[data-field="name"], &[data-field="name"]': {
      minWidth: `calc(${leftPinnedColumn.minWidth}px + ${theme?.spacing(6.25) ?? ''}) !important`,
    },
  },
  '& .totals-row': {
    fontWeight: 500,
    '&, & .disabled-cell': {
      backgroundColor: `${theme?.palette?.primary?.states?.hover ?? ''} !important`,
    },
    width: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: `${theme?.palette?.primary?.states?.hover ?? ''} !important`,
    },
  },
  '& .group-row': {
    fontWeight: `${500} !important`,
    backgroundColor: `${theme?.palette?.primary?.states?.selected ?? ''} !important`,
    width: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: `${theme?.palette?.primary?.states?.selected ?? ''} !important`,
    },
    '& .MuiDataGrid-cell[data-field="__detail_panel_toggle__"]': {
      display: 'none',
    },
  },
  [`
    & .MuiDataGrid-cell:focus-within,
    & .MuiDataGrid-cell:focus,
    & .MuiDataGrid-columnHeader:focus-within,
    & .MuiDataGrid-columnHeader:focus
  `]: {
    outline: 'none',
  },
  '& .MuiDataGrid-cell': {
    border: theme?.spacing(0),
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textWrap: 'pretty',
    lineHeight: 'normal',
  },
  '& .MuiDataGrid-row': {
    borderBottom: 'solid',
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette?.secondary?.light,
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme?.palette?.info?.states?.hover,
    },
  },
  '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme?.palette?.primary?.states?.hover,
    border: 'none',
    borderBottom: 'solid',
    borderWidth: theme?.spacing(0.125),
    borderRadius: theme?.spacing(0),
    borderColor: theme?.palette?.secondary?.light,
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders--left': {
    boxShadow: 'none',
    borderRight: 'solid',
    borderRadius: theme?.spacing(0),
    borderWidth: theme?.spacing(0.125),
    borderColor: theme?.palette?.secondary?.light,
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      paddingLeft: theme?.spacing(1.25),
    },
  },
  '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
    paddingLeft: theme?.spacing(3),
  },
  '& .MuiDataGrid-cellContent': {
    textWrap: 'pretty',
    lineHeight: 'normal',
    height: '100%',
    overflowY: 'auto !important',
    alignContent: 'center',
  },
}));
