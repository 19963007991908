import { graphql } from '@gql/types';

export const BUNDLES_PAGE_FRAGMENT = graphql(`
  fragment BundlesPageFields on Bundle {
    id
    name
    headliners {
      artist {
        id
        name
      }
    }
    team {
      buyers {
        id
        name
      }
    }
    company {
      id
      name
    }
    status
    startDate
  }
`);

export const EVENT_FRAGMENT = graphql(`
  fragment EventFields on Event {
    id
    status
    isActive
    date
    sellableCapacity
    subledgerId
    subledgerStatus
    subledgerPayload
    primaryBuyer {
      id
      name
    }
    venue {
      id
      name
      city
      stateCode
      timezone
    }
    space {
      id
      name
      maxCapacity
    }
    shows {
      id
      name
      showDateTime
    }
  }
`);

export const GET_BUNDLES_PAGE = graphql(`
  query GetBundlesPage($page: Int, $limit: Int, $bundleSearch: BundleSearch) {
    bundles(page: $page, limit: $limit, bundleSearch: $bundleSearch) {
      ...BundlesPageFields
    }
  }
`);

export const SEARCH_BUNDLES = graphql(`
  query SearchBundles($searchTerm: String!) {
    searchBundles(searchTerm: $searchTerm) {
      id
      name
    }
  }
`);

export const GET_BUNDLE = graphql(`
  query GetBundle($bundleId: ID!) {
    bundle(bundleId: $bundleId) {
      id
      name
      status
      primaryOfferId
      tourNotes
      headliners {
        artist {
          id
          name
        }
        agency {
          id
          name
        }
        agents {
          id
          name
          email
          phone
        }
      }
      company {
        id
        name
      }
      offers {
        id
        name
        status
        crossed
        offerEventRouting {
          event {
            ...EventFields
          }
          isActive
        }
      }
      team {
        buyers {
          id
          name
          email
        }
        operators {
          id
          name
          email
        }
        viewOnlyUsers {
          id
          name
          email
        }
      }
      genres {
        id
        name
      }
    }
  }
`);

export const GET_BUNDLE_OFFER = graphql(`
  query BundleOffer($bundleId: ID!, $bundleOfferId: ID!) {
    bundleOffer(bundleId: $bundleId, bundleOfferId: $bundleOfferId) {
      id
      offerEventRouting {
        isActive
        scenarios {
          eventId
          scenarioId
          projectionType
          projectionAmount
        }
        event {
          id
          name
          venue {
            id
            name
            city
            stateCode
          }
        }
      }
    }
  }
`);

export const GET_BUNDLE_OFFER_FINANCES = graphql(`
  query GetBundleOfferFinances($bundleId: ID!, $bundleOfferId: ID!) {
    bundleOffer(bundleId: $bundleId, bundleOfferId: $bundleOfferId) {
      id
      boxOffice {
        maxShows
        totals {
          capacity {
            average
            total
          }
          kills {
            average
            total
          }
          comps {
            average
            total
          }
          sellableCapacity {
            average
            total
          }
          price
          grossPotential {
            average
            total
          }
          netGrossPotential {
            average
            total
          }
        }
        tiers {
          id
          name
          localOfferTotal
          boxOffice {
            capacity {
              average
              total
            }
            kills {
              average
              total
            }
            comps {
              average
              total
            }
            sellableCapacity {
              average
              total
            }
            price
            grossPotential {
              average
              total
            }
            netGrossPotential {
              average
              total
            }
          }
          eventOffers {
            id
            updatedDate
            showsCount
            venue {
              name
              city
              stateCode
            }
            boxOffice {
              name
              capacity
              kills
              comps
              sellableCapacity
              price
              grossPotential
              netGrossPotential
            }
          }
        }
      }
    }
  }
`);
