/* eslint-disable max-len */
import { Event, ExpensePhase } from '@gql/types/graphql';
import { EventStatus } from '@types';
import { Phases } from '@utils/excel/shared/constants';
import { toStringOrEmptyString, getTimezoneDate } from '@utils/stringHelpers';
import { toNumberOrUndefined } from '@utils/numberHelpers';

type ExchangeRateVariableAndTotal = string | number | undefined;

interface GetExchangeRateVariableAndTotalType {
  exchangeRate: ExchangeRateVariableAndTotal,
  variable: ExchangeRateVariableAndTotal,
  total: ExchangeRateVariableAndTotal,
}

// Column count: 6
export const createEventSectionForShowDetail = (event: Event) => {
  const [formattedEventDate] = getTimezoneDate(event?.date, event?.venue?.timezone);

  const result = [
    // Column: Event ID, index 0, column A
    toStringOrEmptyString(event?.id),
    // Column: Phase, index 1, column B
    toStringOrEmptyString(event?.status),
    // Column: Date, index 2, column C
    formattedEventDate,
    // Column: City, index 3, column D
    toStringOrEmptyString(event?.venue?.city),
    // Column: Venue, index 4, column E
    toStringOrEmptyString(event?.space?.name),
    // Column: Shows, index 5, column F
    toNumberOrUndefined(event?.shows?.length),
  ];
  return result;
};

// Column count: 13
export const createActualProjectedSectionForShowDetail = (event: Event) => {
  if (event.status === EventStatus.Confirmed) {
    return [
      // Column: Capacity, index 6, column G
      toNumberOrUndefined(event?.projectedBoxOfficeOverview?.totalAvailableCapacity),
      // Column: Sold, index 7, column H
      toNumberOrUndefined(event?.projectedBoxOfficeOverview?.totalSellableCapacity),
      // Column: Gross Receipts, index 8, column I
      toNumberOrUndefined(event?.projectedBoxOfficeOverview?.totalGrossToBase),
      // Column: Net Gross Receipts, index 9, column J
      toNumberOrUndefined(event?.projectedNetShowReceipts?.netGrossReceiptsTotalToBase),
      // Column: Adjustments 1, index 10, column K
      toNumberOrUndefined(''),
      // Column: Adjustments 2, index 11, column L
      toNumberOrUndefined(''),
      // Column: Adjustments 3, index 12, column M
      toNumberOrUndefined(''),
      // Column: Adjustments 4, index 13, column N
      toNumberOrUndefined(''),
      // Column: Adjustments 5, index 14, column O
      toNumberOrUndefined(''),
      // Column: Expenses, index 15, column P
      toNumberOrUndefined(Math.abs(event?.projectedNetShowReceipts?.budgetedShowExpensesToBase || 0)),
      // Column: Net Show Receipts, index 16, column Q
      toNumberOrUndefined(event?.projectedNetShowReceipts?.netShowReceiptsTotalToBase),
      // Column: Guarantee, index 17, column R
      toNumberOrUndefined(event?.projectedArtistOffer?.guaranteeToBase),
      // Column: Artist Earnings, index 18, column S
      toNumberOrUndefined(event?.projectedArtistOffer?.artistEarningsToBase),
    ];
  }

  if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
    const showAdjustments = event?.primaryNosSettlement?.artistSettlement?.netShowReceipts?.showAdjustments || [];
    return [
      // Column: Capacity, index 6, column G
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.actualBoxOfficeOverview?.totalAvailableCapacity),
      // Column: Sold, index 7, column H
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.actualBoxOfficeOverview?.totalSellableCapacity),
      // Column: Gross Receipts, index 8, column I
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.actualBoxOfficeOverview?.totalGrossToBase),
      // Column: Net Gross Receipts, index 9, column J
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.netShowReceipts?.netGrossReceiptsTotalToBase),
      // Column: Adjustments 1, index 10, column K
      toNumberOrUndefined(showAdjustments[0]?.adjustmentTotalToBase),
      // Column: Adjustments 2, index 11, column L
      toNumberOrUndefined(showAdjustments[1]?.adjustmentTotalToBase),
      // Column: Adjustments 3, index 12, column M
      toNumberOrUndefined(showAdjustments[2]?.adjustmentTotalToBase),
      // Column: Adjustments 4, index 13, column N
      toNumberOrUndefined(showAdjustments[3]?.adjustmentTotalToBase),
      // Column: Adjustments 5, index 14, column O
      toNumberOrUndefined(showAdjustments[4]?.adjustmentTotalToBase),
      // Column: Expenses, index 15, column P
      toNumberOrUndefined(Math.abs(event?.primaryNosSettlement?.artistSettlement?.netShowReceipts?.netShowExpensesTotalToBase || 0)),
      // Column: Net Show Receipts, index 16, column Q
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.netShowReceipts?.netShowReceiptsTotalToBase),
      // Column: Guarantee, index 17, column R
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.artistSettlement?.guaranteeToBase),
      // Column: Artist Earnings, index 18, column S
      toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.artistSettlement?.artistNosSettlementTotalToBase),
    ];
  }

  return Array(13).fill('') as string[];
};

// Column count: 8
export const createBudgetSectionForShowDetail = (event: Event) => {
  const totalSellableCapacity = () => {
    if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
      return event?.primaryNosSettlement?.artistSettlement?.actualBoxOfficeOverview?.totalAvailableCapacity;
    }
    if (event.status === EventStatus.Confirmed || event.status === EventStatus.Canceled) {
      return event?.projectedBoxOfficeOverview?.totalSellableCapacity;
    }
    return '';
  };

  return [
    // Column: Capacity, index 19, column T
    toNumberOrUndefined(totalSellableCapacity()),
    // Column: Sold, index 20, column U
    toNumberOrUndefined(event?.projectedSales),
    // Column: Gross Receipts, index 21, column V
    toNumberOrUndefined(event?.projectedShowIncome?.grossShowReceiptsToBase),
    // Column: Net Gross Receipts, index 22, column W
    toNumberOrUndefined(event?.projectedShowIncome?.netGrossReceiptsToBase),
    // Column: Adjustments, index 23, column X
    toNumberOrUndefined(''),
    // Column: Expenses, index 24, column Y
    toNumberOrUndefined(event?.projectedNetShowReceipts?.netShowExpensesTotalToBase),
    // Column: Net Show Receipts, index 25, column Z
    toNumberOrUndefined(event?.projectedNetShowReceipts?.sequentialNetToPoolToBase),
    // Column: Guarantee, index 26, column AA
    toNumberOrUndefined(event?.projectedArtistOffer?.guaranteeToBase),
  ];
};

// Column count: 15
export const createCostingTotalsSectionForShowDetail = (event: Event) => {
  const versionSuffix = event.primaryNosVersion && event.primaryNosVersion > 1 ? `_v${event.primaryNosVersion}` : '';
  const expensePhaseId = event.status === EventStatus.Confirmed ? Phases.MODELING : `${Phases.ARTIST_SETTLEMENT}${versionSuffix}`;
  const expensePhase: ExpensePhase | undefined = event.expense?.phases?.find((phase) => phase.id === expensePhaseId);

  const getFixedCategoryItemByCategoryId = (categoryId: string) =>
    expensePhase?.fixedCategoryItems?.find((item) => item.categoryId === categoryId);

  const getExchangeRateVariableAndTotal = (): GetExchangeRateVariableAndTotalType => {
    if (event.status === EventStatus.NOSSettled || event.status === EventStatus.AccountingSettled) {
      return {
        exchangeRate: toNumberOrUndefined(event?.primaryNosSettlement?.artistSettlement?.exchangeRate),
        variable: toNumberOrUndefined(expensePhase?.totalVariableCostsToBase),
        total: toNumberOrUndefined(expensePhase?.totalBudgetVariableFixedCosts),
      };
    }
    if (event.status === EventStatus.Confirmed) {
      return {
        exchangeRate: toNumberOrUndefined(event.exchangeRate),
        variable: toNumberOrUndefined(expensePhase?.totalVariableCostsToBase),
        total: '=SUM(ShowDetailArtist[@[costingTotalsAdvertising]:[costingTotalsVariable]])',
      };
    }
    return {
      exchangeRate: '',
      variable: '',
      total: '',
    };
  };

  const { exchangeRate, variable, total } = getExchangeRateVariableAndTotal();

  if (event.status === EventStatus.Confirmed || event.status === EventStatus.NOSSettled || event?.status === EventStatus.AccountingSettled) {
    return [
      // Column: Exchange Rate, index 27, column AB
      exchangeRate,
      // Column: Advertising, index 28, column AC
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Advertising')?.totalBudgetToBase),
      // Column: Artist Prorates, index 29, column AD
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Artist Prorates')?.totalBudgetToBase),
      // Column: Production Prorates, index 30, column AE
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Production Prorates 1')?.totalBudgetToBase),
      // Column: Support, index 31, column AF
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Support')?.totalBudgetToBase),
      // Column: Rent, index 32, column AG
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Rent')?.totalBudgetToBase),
      // Column: Stagehands, index 33, column AH
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Stagehands')?.totalBudgetToBase),
      // Column: Catering, index 34, column AI
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Catering')?.totalBudgetToBase),
      // Column: Staffing, index 35, column AJ
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Staffing')?.totalBudgetToBase),
      // Column: Venue, index 36, column AK
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Venue')?.totalBudgetToBase),
      // Column: Production, index 37, column AL
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Production')?.totalBudgetToBase),
      // Column: Transport, index 38, column AM
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Band Transport')?.totalBudgetToBase),
      // Column: Other, index 39, column AN
      toNumberOrUndefined(getFixedCategoryItemByCategoryId('Other Costs')?.totalBudgetToBase),
      // Column: Variable, index 40, column AO
      variable,
      // Column: Total, index 41, column AP
      total,
    ];
  }

  return Array(15).fill('') as string[];
};

export const generateShowDetailRowForArtist = (
  event: Event,
) => {
  // must be 42 columns
  const showDetailValues = [
    // 6 columns
    ...createEventSectionForShowDetail(event),
    // 13 columns
    ...createActualProjectedSectionForShowDetail(event),
    // 8 columns
    ...createBudgetSectionForShowDetail(event),
    // 15 columns
    ...createCostingTotalsSectionForShowDetail(event),
  ];

  return showDetailValues;
};
