export const configurationTypeOptions = [
  { value: '180', label: '180°' },
  { value: '220', label: '220°' },
  { value: '240', label: '240°' },
  { value: '270', label: '270°' },
  { value: '360 (End)', label: '360° (End)' },
  { value: '360 (Round)', label: '360° (Round)' },
  { value: 'Other', label: 'Other' },
];

export const getConfigurationTypeLabel = (configType: string | undefined | null) => {
  if (configurationTypeOptions.some((option) => option.value === configType)) {
    return configurationTypeOptions.find((option) => option.value === configType)?.label;
  }
  return configType || '';
};
