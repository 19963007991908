/* eslint-disable max-lines-per-function */

import { ShowAdjustmentInput } from '@gql/types/graphql';
import {
  AS_BOX_OFFICE_SHEET_NAME,
  AS_SETTLEMENT_SHEET_NAME,
  CATERING_SHEET_NAME,
  CO_PRO_SETTLEMENT_SHEET_NAME,
  EXPENSES_BUDGET_TABLE_NAME_KEY,
  EXPENSES_BREAKDOWN_TABLE_NAME_KEY,
  FAR_BOX_OFFICE_SHEET_NAME,
  FAR_SETTLEMENT_SHEET_NAME,
  OTHER_SHEET_NAME,
  PREMIUM_SHEET_NAME,
  PRODUCTION_SHEET_NAME,
  RENT_SHEET_NAME,
  SETTLEMENT_ADJUSTMENTS_BUILDER,
  SETTLEMENT_SUMMARY_SHEET_NAME,
  STAFFING_SHEET_NAME,
  STAGEHANDS_SHEET_NAME,
  SUPPORT_SETTLEMENT_SHEET_NAME,
  SUPPORT_SHEET_NAME,
  TRANSPORT_SHEET_NAME,
  VENUE_SHEET_NAME,
  WH_TAXES_SHEET_NAME,
} from '@utils/excel/nosSettlement/constants';
import { toNumberOrUndefined } from '@utils/numberHelpers';
import { toStringOrUndefined } from '@utils/stringHelpers';
import { formatCurrencyCell, replaceKeysWithValues } from '../shared/helpers';
import { NAMED_RANGES } from './constants';

export const getNum = (key: string, namedRangeValues: Record<string, string | number | undefined>) =>
  toNumberOrUndefined(namedRangeValues[key]);
export const getString = (key: string, namedRangeValues: Record<string, string | number | undefined>) =>
  toStringOrUndefined(namedRangeValues[key]);

export const getShowAdjustmentData = (
  namedRangeValues: Record<string, string | number | undefined>,
): ShowAdjustmentInput[] => {
  const showAdjustmentData: ShowAdjustmentInput[] = [];

  for (let i = 0; i < SETTLEMENT_ADJUSTMENTS_BUILDER.MAX_TOTAL_ADJUSTMENT; i += 1) {
    showAdjustmentData.push({
      adjustmentName: getString(SETTLEMENT_ADJUSTMENTS_BUILDER.TEXT(i + 1), namedRangeValues),
      adjustmentRate: getString(SETTLEMENT_ADJUSTMENTS_BUILDER.RATE(i + 1), namedRangeValues),
      adjustmentTotal: getNum(SETTLEMENT_ADJUSTMENTS_BUILDER.TOTAL(i + 1), namedRangeValues),
      adjustmentTotalToBase: getNum(SETTLEMENT_ADJUSTMENTS_BUILDER.TOTAL_TO_BASE(i + 1), namedRangeValues),
    });
  }
  return showAdjustmentData;
};

export const getExpensesFormula = (tableName: string, formula: string): string => {
  const tableNameBreakdown = tableName.replace(/Budget$/, 'Breakdown');

  const excelFormula = replaceKeysWithValues(formula, [
    [EXPENSES_BUDGET_TABLE_NAME_KEY, tableName],
    [EXPENSES_BREAKDOWN_TABLE_NAME_KEY, tableNameBreakdown],
  ]);

  return excelFormula as string;
};

export const generateWorkbookInitCurrencyRanges = (baseCurrencySymbol: string, localCurrencyISO: string) => {
  const baseCurrencyRoundedFormat = formatCurrencyCell(baseCurrencySymbol, true, false);
  const localCurrencyRoundedFormat = formatCurrencyCell(localCurrencyISO, true, false);
  const baseCurrencyDecimalFormat = formatCurrencyCell(baseCurrencySymbol, true, true);
  const localCurrencyDecimalFormat = formatCurrencyCell(localCurrencyISO, true, true);
  const localCurrencyCenteredDecimalFormat = formatCurrencyCell(localCurrencyISO, false, true, true);

  const allNamedRangeAssignments = [
    {
      sheet: AS_SETTLEMENT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.ARTIST_SETTLEMENT.LOCAL_CURRENCY_VARIABLE_COSTS_RATES,
          format: localCurrencyCenteredDecimalFormat,
        }]
        .concat(NAMED_RANGES.ARTIST_SETTLEMENT.BASE_CURRENCY_DECIMAL.map(
          (range) => ({ range, format: baseCurrencyDecimalFormat }),
        ))
        .concat(NAMED_RANGES.ARTIST_SETTLEMENT.BASE_CURRENCY_ROUNDED.map(
          (range) => ({ range, format: baseCurrencyRoundedFormat }),
        ))
        .concat(NAMED_RANGES.ARTIST_SETTLEMENT.LOCAL_CURRENCY_DECIMAL.map(
          (range) => ({ range, format: localCurrencyDecimalFormat }),
        ))
        .concat(NAMED_RANGES.ARTIST_SETTLEMENT.LOCAL_CURRENCY_ROUNDED.map(
          (range) => ({ range, format: localCurrencyRoundedFormat }),
        )),
    },
    {
      sheet: AS_BOX_OFFICE_SHEET_NAME,
      namedRangeAssignments: NAMED_RANGES.AS_BOX_OFFICE.LOCAL_CURRENCY_DECIMAL.map(
        (range) => ({ range, format: localCurrencyDecimalFormat }),
      ),
    },
    {
      sheet: CATERING_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.CATERING.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: CO_PRO_SETTLEMENT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.CO_PRO_SETTLEMENT.BASE_CURRENCY_ROUNDED,
          format: baseCurrencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.CO_PRO_SETTLEMENT.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: FAR_BOX_OFFICE_SHEET_NAME,
      namedRangeAssignments: NAMED_RANGES.FAR_BOX_OFFICE.LOCAL_CURRENCY_DECIMAL.map(
        (range) => ({ range, format: localCurrencyDecimalFormat }),
      ),
    },
    {
      sheet: FAR_SETTLEMENT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.FAR_SETTLEMENT.LOCAL_CURRENCY_VARIABLE_COSTS_RATES,
          format: localCurrencyCenteredDecimalFormat,
        }]
        .concat(NAMED_RANGES.FAR_SETTLEMENT.BASE_CURRENCY_DECIMAL.map(
          (range) => ({ range, format: baseCurrencyDecimalFormat }),
        ))
        .concat(NAMED_RANGES.FAR_SETTLEMENT.BASE_CURRENCY_ROUNDED.map(
          (range) => ({ range, format: baseCurrencyRoundedFormat }),
        ))
        .concat(NAMED_RANGES.FAR_SETTLEMENT.LOCAL_CURRENCY_DECIMAL.map(
          (range) => ({ range, format: localCurrencyDecimalFormat }),
        ))
        .concat(NAMED_RANGES.FAR_SETTLEMENT.LOCAL_CURRENCY_ROUNDED.map(
          (range) => ({ range, format: localCurrencyRoundedFormat }),
        )),
    },
    {
      sheet: OTHER_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.OTHER.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: PREMIUM_SHEET_NAME,
      namedRangeAssignments: NAMED_RANGES.PREMIUM.BASE_CURRENCY_ROUNDED.map(
        (range) => ({ range, format: baseCurrencyRoundedFormat }),
      )
        .concat(NAMED_RANGES.PREMIUM.LOCAL_CURRENCY_ROUNDED.map(
          (range) => ({ range, format: localCurrencyRoundedFormat }),
        )),
    },
    {
      sheet: PRODUCTION_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.PRODUCTION.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: RENT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.RENT.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    // TODO: Un-Comment when Settlement Email Template named ranges have been corrected in Excel
    // {
    //   sheet: SETTLEMENT_EMAIL_TEMPLATE_SHEET_NAME,
    //   namedRangeAssignments: [
    //     {
    //       range: NAMED_RANGES.SETTLEMENT_EMAIL_TEMPLATE.BASE_CURRENCY_ROUNDED,
    //       format: baseCurrencyRoundedFormat,
    //     },
    //   ],
    // },
    {
      sheet: SETTLEMENT_SUMMARY_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.NOS_SETTLEMENT_SUMMARY.BASE_CURRENCY_ROUNDED,
          format: baseCurrencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.NOS_SETTLEMENT_SUMMARY.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.NOS_SETTLEMENT_SUMMARY.LOCAL_CURRENCY_DECIMAL,
          format: localCurrencyDecimalFormat,
        },
        {
          range: NAMED_RANGES.NOS_SETTLEMENT_SUMMARY.BASE_CURRENCY_DECIMAL,
          format: baseCurrencyDecimalFormat,
        },
      ],
    },
    {
      sheet: STAFFING_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.STAFFING.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: STAGEHANDS_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.STAGEHANDS.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: SUPPORT_SETTLEMENT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.SUPPORT_SETTLEMENT.BASE_CURRENCY_ROUNDED,
          format: baseCurrencyRoundedFormat,
        },
        {
          range: NAMED_RANGES.SUPPORT_SETTLEMENT.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: SUPPORT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.SUPPORT.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: TRANSPORT_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.TRANSPORT.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: VENUE_SHEET_NAME,
      namedRangeAssignments: [
        {
          range: NAMED_RANGES.VENUE.LOCAL_CURRENCY_ROUNDED,
          format: localCurrencyRoundedFormat,
        },
      ],
    },
    {
      sheet: WH_TAXES_SHEET_NAME,
      namedRangeAssignments: NAMED_RANGES.WH_TAXES.LOCAL_CURRENCY_DECIMAL.map(
        (range) => ({ range, format: localCurrencyDecimalFormat }),
      ),
    },
  ];

  return allNamedRangeAssignments;
};
