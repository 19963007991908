import {
  Box, Button, ButtonProps, Tab, TabProps, Typography,
} from '@mui/material';
import { GridFilterPanel } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

export const StyledTab = styled(Tab)<TabProps>(() => ({
  textTransform: 'none',
  fontWeight: 700,
}));

export const CreateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(1, 2.75),
  display: 'inline-flex',
  marginRight: theme.spacing(2),
}));

export const AutocompleteContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(6),
  '& .MuiAutocomplete-root': {
    width: '100%',
    height: '100%',
  },
  '& .MuiAutocomplete-popper': {
    minWidth: '350px',
  },
}));

export const GridFilterPanelStyled = styled(GridFilterPanel)({
  '& .MuiDataGrid-filterFormValueInput': {
    minWidth: '350px',
    position: 'unset',
  },
});

export const DateRangeContainer = styled(Box)(({
  display: 'flex',
}));

export const DateRangeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.1),
}));
