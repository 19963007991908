import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const PageContainer = styled(Box)({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2.5, 0),
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.dark,
}));

export const ContentContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WarningMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.spacing(3),
  textAlign: 'center',
  margin: theme.spacing(0, 2),
}));
