import {
  array,
  object,
  string,
  ValidationError,
} from 'yup';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const recipientFields = {
  name: string(),
  email: string()
    .required("Please enter the agent's email")
    .test('is-valid-email', 'Invalid email format', (value) => value === '' || emailRegex.test(value)),
};

const recipientValidationSchema = object().shape(recipientFields);

const validateEmails = (
  value: { name?: string, email: string }[],
  createError: (params: { message: string; path: string, params: Record<string, unknown> }) => ValidationError,
  path: string,
): boolean | ValidationError => {
  const indices: number[] = [];

  value?.forEach((recipient, index) => {
    if (!recipientValidationSchema.isValidSync(recipient)) {
      indices.push(index);
    }
  });

  return indices.length
    ? createError({
      message: 'Please remove improperly formatted recipients.',
      path,
      params: { indices },
    })
    : true;
};

export const confirmationEmailValidationSchema = object({
  toRecipients: array().of(recipientValidationSchema).test(
    'valid-emails',
    'All emails must be valid',
    (value, { createError, path }) => validateEmails(value ?? [], createError, path),
  ),
  ccRecipients: array().of(recipientValidationSchema).test(
    'valid-emails',
    'All emails must be valid',
    (value, { createError, path }) => validateEmails(value ?? [], createError, path),
  ),
  comments: string(),
}).test(
  'at-least-one-recipient',
  'Either toRecipients or ccRecipients must contain at least one value',
  (value) => {
    const { toRecipients, ccRecipients } = value;
    if (!toRecipients?.length && !ccRecipients?.length) {
      return new ValidationError(
        [new ValidationError(
          'Email must have at least one recipient in the "To" or "CC" field',
          null,
          'toRecipients',
        ),
        new ValidationError(
          'Email must have at least one recipient in the "To" or "CC" field',
          null,
          'ccRecipients',
        )],
      );
    }
    return true;
  },
);
