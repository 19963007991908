/* eslint-disable max-lines-per-function */
import { ListView } from '@components';
import LinearProgress from '@mui/material/LinearProgress';
import {
  FilterColumnsArgs, GetColumnForNewFilterArgs, GridColDef, GridFilterItem, GridFilterModel, GridSortModel,
} from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { GridFilterPanelStyled } from './FilteringListView.styled';

export type ListViewFilterItem = { fieldName: string ; values: string[] };
export type ListViewFilter = {
  gridFilter?: GridFilterItem[];
  queryFilter?: ListViewFilterItem[];
  sort?: { fieldName: string ; order: 'asc' | 'desc' };
};

export type ListViewFilterColumn = GridColDef & { singleFilterAllowed?: boolean };

type FilteringListViewProps<T> = Readonly<{
  // Other components
  searchComponent?: React.ReactNode;
  actions?: React.ReactNode;

  // Mandatory properties
  columns: ListViewFilterColumn[];
  filterModel: ListViewFilter | undefined;
  isLoadingData: boolean;
  rows: T[];

  // Optional properties
  disableVirtualization?: boolean;
  fetchPage?: (page: number) => void;
  isLoadingFilters?: boolean;
  noResultsLabel?: string;

  // Events
  onFilterChange: (filter: ListViewFilter) => void;

  // Mappers
  mapFilterToSearchParams?: (gridFilter: GridFilterItem[]) => URLSearchParams;
  mapSearchParamsToFilter?: (params: URLSearchParams) => GridFilterItem[];
}>;

export function FilteringListView<T>({
  searchComponent,
  actions,
  columns,
  filterModel,
  isLoadingData,
  rows,
  disableVirtualization = false,
  fetchPage,
  isLoadingFilters,
  noResultsLabel = 'No results found.',
  onFilterChange,
  mapFilterToSearchParams,
  mapSearchParamsToFilter,
} : FilteringListViewProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const prevDataLength = useRef(0);

  // Pagination functions
  const fetchNextPage = () => {
    if (isLoadingData || rows.length === prevDataLength.current) {
      return;
    }

    prevDataLength.current = rows.length;
    const nextPage = page + 1;
    setPage(nextPage);

    fetchPage?.(nextPage);
  };

  // Sorting functions
  const onSortModelChange = (sortModel: GridSortModel) => {
    // Current assumption is we will only have one level of sorting; sortModel natively supports array of fields
    if (sortModel.length) {
      onFilterChange({
        ...filterModel,
        sort: {
          fieldName: sortModel[0].field,
          order: sortModel[0].sort as ('asc' | 'desc'),
        },
      });
    } else {
      onFilterChange({
        ...filterModel,
        sort: undefined,
      });
    }
  };

  // Filtering functions
  const updateQueryAndSearchParams = (filters: GridFilterItem[], skipSearchParamsUpdate = false) => {
    const combinedItems = filters
      .reduce((acc, { field, value }) => {
        if (!value) return acc;

        const pushValue = (queryValue?: string) => {
          if (!queryValue?.length) return;

          if (!acc[field]) {
            acc[field] = {
              fieldName: field,
              values: [queryValue],
            };
          } else {
            acc[field].values.push(queryValue);
          }
        };

        // Since value might be an array, making it always be an array avoid having to repeat the handling code
        const valueArray = Array.isArray(value) ? value : [value];
        valueArray.forEach((rawValue) => {
          const entity = rawValue as { id?: string };
          const date = rawValue as Dayjs;

          if (entity?.id) {
            pushValue(entity.id);
          } else if (date?.toISOString) {
            if (date.isValid()) {
              pushValue(date.toISOString());
            }
          } else {
            pushValue(rawValue as string);
          }
        });

        return acc;
      }, {} as Record<string, ListViewFilterItem>);

    const queryFilter = Object.values(combinedItems);

    let newSearchParams: URLSearchParams | undefined;
    if (mapFilterToSearchParams && !skipSearchParamsUpdate) {
      const mappedParams = mapFilterToSearchParams(filters);
      if (mappedParams.toString() !== searchParams.toString()) {
        newSearchParams = mappedParams;
      }
    }

    return {
      queryFilter,
      newSearchParams,
    };
  };

  const onFilterModelChange = (model: GridFilterModel, skipSearchParamsUpdate = false) => {
    const { queryFilter, newSearchParams } = updateQueryAndSearchParams(model.items, skipSearchParamsUpdate);

    onFilterChange({
      ...filterModel,
      queryFilter,
      gridFilter: model.items,
    });

    if (newSearchParams) {
      setSearchParams(newSearchParams);
    }
  };

  useEffect(() => {
    const newFilter = mapSearchParamsToFilter?.(searchParams);
    if (newFilter) {
      onFilterModelChange({ items: newFilter }, true);
    }
  }, [searchParams, isLoadingFilters]);

  // Invoked when the parent component changes the filter e.g. because of a SearchBar input
  useEffect(() => {
    if (!filterModel?.gridFilter) return;

    const { queryFilter, newSearchParams } = updateQueryAndSearchParams(filterModel.gridFilter);

    onFilterChange({
      ...filterModel,
      queryFilter,
    });

    if (newSearchParams && newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams);
    }
  }, [filterModel?.gridFilter]);

  // Filter window-related functions
  const filterColumns = ({ field, columns: columnState, currentFilters }: FilterColumnsArgs) =>
    columnState
      .filter(
        (colDef) => {
          const column = colDef as unknown as ListViewFilterColumn;
          if (!column.filterable) return false;

          if (column.field === field) return true;

          if (column.singleFilterAllowed && currentFilters.find((f) => f.field === column.field)) {
            return false;
          }

          return true;
        },
      )
      .map((column) => column.field);

  const getColumnForNewFilter = ({ columns: columnState, currentFilters }: GetColumnForNewFilterArgs) =>
    columnState
      .find((colDef) => {
        const column = colDef as unknown as ListViewFilterColumn;
        if (!column.filterable) return false;

        if (column.singleFilterAllowed && currentFilters.find((f) => f.field === column.field)) {
          return false;
        }

        return true;
      })?.field;

  return (
    <ListView
        searchComponent={searchComponent}
        actions={actions}
        datagrid={{
          rows,
          columns,
          gridInfoText: noResultsLabel,
          onRowsScrollEnd: fetchNextPage,
          slots: {
            loadingOverlay: LinearProgress,
            filterPanel: (props) => <GridFilterPanelStyled {...props}
              logicOperators={[]}
              filterFormProps={{ filterColumns }}
              getColumnForNewFilter={ getColumnForNewFilter }
            />,
          },
          isPaginationLoading: isLoadingData,
          // hideFooter: true, // TODO: Implement to remove "Total Rows" footer after full pagination implementation
          filterModel: { items: filterModel?.gridFilter ?? [] },
          filterMode: 'server',
          sortingMode: 'server',
          onFilterModelChange: (model) => onFilterModelChange(model),
          onSortModelChange,
          disableVirtualization,
        }} />
  );
}
