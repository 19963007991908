import { strWithoutSpecialCharsRegex } from '@utils/stringHelpers';
import * as yup from 'yup';

export const bundleOfferFormValidationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required('Please enter a bundle offer name.')
    .matches(
      strWithoutSpecialCharsRegex,
      'Some special characters are not allowed',
    ),
  crossed: yup.boolean().default(false),
});
