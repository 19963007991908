import { isDefined } from '@utils/arrayHelpers';
import { ArtistInformation } from '@types';

export const getHeadlinerInfoFromSelectedHeadliners = (headlinerGroups: ArtistInformation[]) => {
  const selectedHeadliners = headlinerGroups.map((group) => group.headliner).filter(isDefined);
  const selectedHeadlinerNames = selectedHeadliners.map((headliner) => headliner.name).filter(isDefined);
  const newEventName = selectedHeadlinerNames.join(' & ');

  return {
    selectedHeadliners,
    selectedHeadlinerNames,
    newEventName,
  };
};
