/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { EnvConfig } from '@types';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

interface FeatureFlagRouteProps {
  featureFlag: keyof EnvConfig['flags'];
  redirectTo?: string;
}

export const FeatureFlagLayout = (props: FeatureFlagRouteProps) => {
  const navigate = useNavigate();
  const { featureFlag, redirectTo } = props;
  const { getFlag } = useFeatureFlags();
  const flagRaised = getFlag(featureFlag);

  useEffect(() => {
    if (flagRaised) {
      return;
    }
    if (redirectTo) {
      if (redirectTo.includes('http')) {
        window.open(redirectTo, '_blank');
        navigate(-1);
      } else {
        navigate(redirectTo);
      }
    } else {
      navigate(-1);
    }
  }, [flagRaised]);

  if (flagRaised) {
    return <Outlet />;
  }

  return null;
};
