/* eslint-disable max-lines-per-function */
import { HTMLAttributes, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FormStylingMode, InputContainer } from '@components';
import Autocomplete from '@mui/material/Autocomplete';
import { QueryBySearchTerm, QueryResponse, TypenameResponse } from '@types';
import { kebabCase } from '@utils/stringHelpers';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { OptionContainer, SearchContainer } from './SearchAutocomplete.styled';

export type SimpleSearchBarProps<Query extends TypenameResponse, Entity> = {
  defaultValue?: string;
  query: QueryBySearchTerm<Query>;
  getOptions: (data: QueryResponse<Query>) => (Entity | null)[];
  getOptionLabel: (entity: Entity) => string;
  placeholder?: string;
  onSubmit?: (currentValue: string) => void;
  onSelect?: (selectedOption: Entity) => void;
  error?: boolean;
  helperText?: string;
  formStylingMode?: FormStylingMode;
  clearOnBlur?: boolean;
  value?: any;
  inputAdornment?: JSX.Element;
  testId?: string;
  inputTestId?: string;
};

/**
 * This is mostly a stripped-down version of {@link ../SearchBar} that
 * caters specifically to the needs of the Bundles and Tours pages;
 */
export function SearchAutocomplete<Q extends TypenameResponse, E>({
  defaultValue,
  query,
  getOptions,
  getOptionLabel,
  placeholder,
  onSubmit,
  onSelect,
  error,
  helperText,
  formStylingMode,
  clearOnBlur,
  value,
  inputAdornment,
  testId,
  inputTestId,
}: Readonly<SimpleSearchBarProps<Q, E>>) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearchTerm(defaultValue ?? '');
  }, [defaultValue]);

  const { data: searchResponse, loading: searchLoading } = useQuery(query, {
    variables: { searchTerm },
    errorPolicy: 'all',
    skip: searchTerm.length === 0,
  });

  const options = searchResponse ? getOptions(searchResponse)?.filter((option) => option) as E[] : [];

  const toOptionLabel = (option: E | string): string =>
    (typeof option === 'string' ? option : getOptionLabel(option)) ?? '';

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: E | string) => {
    const optionText = toOptionLabel(option);
    const matches = match(optionText, searchTerm, { insideWords: true });
    const parts = parse(optionText, matches);

    return (
      <OptionContainer key={kebabCase(optionText)} {...props}>
        {/* Without this wrapping span, CSS removes spaces at the beginning of each part */}
        <span>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 700 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </span>
      </OptionContainer>
    );
  };

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, changedValue: string) => {
    if (!event) return;

    const trimmedValue = changedValue.trim();
    if (trimmedValue.length > 0) {
      setSearchTerm(trimmedValue);
      setIsOpen(true);
    } else {
      setSearchTerm('');
      setIsOpen(false);

      if (event.type === 'click') {
        // The user clicked the Clear button
        onSubmit?.('');
      }
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit?.(searchTerm);
      setIsOpen(false);
    }
  };

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, changedValue: E | string | null) => {
    if (typeof changedValue !== 'string') {
      onSelect?.(changedValue as E);
      setIsOpen(false);
    }
  };

  return (
    <SearchContainer formStylingMode={formStylingMode ?? FormStylingMode.DEFAULT}>
      <Autocomplete
        data-testid={testId}
        clearOnBlur={clearOnBlur}
        disablePortal
        loading={searchLoading}
        loadingText={'Loading'}
        onBlur={() => setIsOpen(false)}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onInputChange={handleInputChange}
        onKeyUp={handleKeyUp}
        onChange={handleChange}
        options={options}
        getOptionLabel={toOptionLabel}
        renderOption={renderOption}
        freeSolo
        autoHighlight={false}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value={value ?? defaultValue}
        renderInput={(params) => (
          <InputContainer
            {...params}
            error={error}
            helperText={helperText}
            inputProps={{
              'data-testid': inputTestId,
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: inputAdornment,
              endAdornment: params.InputProps.endAdornment,
            }}
            placeholder={placeholder}
          />
        )}
      />
    </SearchContainer>
  );
}
