import { graphql } from '@gql/types';

export const CREATE_NOS_WORKBOOK = graphql(`
  mutation CreateNOSSettlement($tourId: ID!, $eventId: ID!) {
    createNOSSettlement(tourId: $tourId, eventId: $eventId) {
      id
      event {
        id
        nosSettlementVersions{
          id
          isPrimary
          version
          workbookItemId
        }
      }
    }
  }
`);

export const SEND_NOS_EMAIL = graphql(`
   mutation SendNosSettlementEmail($nosSettlementEmailData: nosSettlementEmailInput!) {
     sendNosSettlementEmail(nosSettlementEmailData: $nosSettlementEmailData) {
      type
       id
     }
   }
 `);
