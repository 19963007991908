import { ReactNode } from 'react';

export const CONFIRM_OFFER_CONFIRMATION_DIALOG = (businessUnit: string) => ({
  TITLES: {
    dialogTitle: 'Confirm Business Unit',
    alertTitle: `Before confirming, please confirm that this offer will use the Business Unit “${businessUnit}”`,
  },
  SUBMIT: 'Confirm Offer',
  CANCEL: 'Cancel',
});

export const INVALID_OFFER_CONFIRMATION_DIALOG = (requirements: ReactNode[]) => {
  const qty = requirements.length > 1 ? 'are a few issues' : 'is an issue';
  const alertTitle = `There ${qty} with this offer`;
  return {
    TITLES: {
      dialogTitle: 'Offer Issues',
      alertTitle,
      alertList: requirements,
    },
    SUBMIT: 'Okay',
  };
};

export const SORT_BY_SHOW_DATE_CONFIRMATION_DIALOG = () => ({
  TITLES: {
    dialogTitle: 'Are you sure you want to sort the worksheets by date?',
    // eslint-disable-next-line max-len
    alertTitle: 'This will organize the market sheets and show detail table to match the default routing tab order by date. This process may take some time.',
  },
  SUBMIT: 'Confirm',
  CANCEL: 'Cancel',
});

export const SORT_BY_MARKET_CONFIRMATION_DIALOG = () => ({
  TITLES: {
    dialogTitle: 'Are you sure you want to sort the worksheets by market?',
    // eslint-disable-next-line max-len
    alertTitle: 'This will organize the market sheets and show detail table by market (city). This process may take some time.',
  },
  SUBMIT: 'Confirm',
  CANCEL: 'Cancel',
});
