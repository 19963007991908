/* eslint-disable max-lines-per-function */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  CompanyPermission, ModifyParentTourInput, ParentTour, TourFieldsFragment,
} from '@gql/types/graphql';
import {
  DetailsPageTitle, ProtectedComponent, CreateTourDrawer, Loading, ListView,
  AegDrawer,
  EditParentTourForm,
} from '@components';
import { PageDetailLayout } from '@layouts/PageDetailLayout';
import { useNavigateOnError } from '@hooks';
import { BreadcrumbDispatchContext } from '@providers';
import { GET_PARENT_TOUR } from '@gql/queries/parentTours';
import { MODIFY_PARENT_TOUR } from '@gql/mutations/parentTours';
import { AegResourceTypes, TourFormTypes } from '@types';
import { Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getSharedTourColumns } from '@utils/datagridHelpers';
import {
  CreateButtonContainer, CreateText, ButtonStyled, CreateButton, ActionButtonStyled,
} from './ParentTourDetails.styled';

export function ParentTourDetails() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  const [isCreateChildDrawerOpen, setIsCreateChildDrawerOpen] = React.useState(false);
  const [isEditTourDrawerOpen, setIsEditTourDrawerOpen] = React.useState(false);
  const { id: parentTourId } = useParams();
  const theme = useTheme();

  const {
    data: parentTourData,
    error,
    loading,
  } = useQuery(GET_PARENT_TOUR, {
    variables: { parentTourId: parentTourId ?? '' },
  });
  const parentTourDetails = parentTourData?.parentTour;

  const navigateOnError = useNavigateOnError();
  useEffect(() => { navigateOnError(error); }, [error]);

  useEffect(() => {
    if (parentTourData && !error) {
      setBreadcrumbs([
        {
          text: 'Tours',
          href: '/tours/',
        },
        {
          text: parentTourData.parentTour?.name || '',
          href: `/multi-leg-tour/${parentTourId || ''}`,
        },
      ]);
    }
  }, [parentTourData]);

  const actions = (
    <ActionButtonStyled
      variant="outlined"
      data-testid="edit-button"
      onClick={() => setIsEditTourDrawerOpen(true)}
    >
      Edit
    </ActionButtonStyled>
  );

  let gridComponent;

  if (loading) {
    gridComponent = <Loading data-testid="loading-icon" />;
  }

  if (!loading && parentTourDetails?.tours?.length === 0) {
    gridComponent = (
      <ProtectedComponent
        checkPermission={{
          permission: CompanyPermission.CreateTours,
          resourceType: AegResourceTypes.Company,
        }}
      >
        <CreateButtonContainer>
          <CreateText>Select Create a Tour to create a tour leg and begin adding events.</CreateText>
          <ButtonStyled
            data-testid="add-event-button"
            variant="contained"
            onClick={() => setIsCreateChildDrawerOpen(true)}
            startIcon={<Add />}
          >
            Create a Tour
          </ButtonStyled>
        </CreateButtonContainer>
      </ProtectedComponent>
    );
  }

  if (!loading && parentTourDetails?.tours?.length) {
    gridComponent = (
      <ListView
        datagrid={{
          rows: parentTourDetails?.tours,
          columns: getSharedTourColumns<TourFieldsFragment>(),
        }}
        actions={
          <ProtectedComponent
            checkPermission={{
              permission: CompanyPermission.CreateTours,
              resourceType: AegResourceTypes.Company,
            }}
          >
            <CreateButton
              data-testid="create-button"
              variant="contained"
              onClick={() => setIsCreateChildDrawerOpen(true)}
              startIcon={<Add />}
            >
              Create Tour
            </CreateButton>
          </ProtectedComponent>
        }
      />
    );
  }

  const [updateTour, { loading: savingTour }] = useMutation(MODIFY_PARENT_TOUR, {
    onCompleted() {
      setIsEditTourDrawerOpen(false);
    },
  });

  const handleUpdateTour = React.useCallback(
    async (parentTour: ModifyParentTourInput) => {
      await updateTour({
        variables: {
          parentTour,
        },
      });
    },
    [updateTour],
  );

  return (
    <>
      <PageDetailLayout
        pageTitle={<DetailsPageTitle title={(parentTourDetails?.name) ?? ''} />}
        actions={actions}
        pageTitleColSize={8}
        metaColSize={0}
        actionsColSize={4}
        pageContent={gridComponent}
        pageContentStyle={{
          height: parentTourDetails?.tours?.length ? 'initial' : theme.spacing(34.5),
          padding: parentTourDetails?.tours?.length && theme.spacing(0),
        }}
      />
      <CreateTourDrawer
        isDrawerOpen={isCreateChildDrawerOpen}
        closeDrawer={() => setIsCreateChildDrawerOpen(false)}
        defaults={{ tourType: TourFormTypes.tour, parentTour: parentTourDetails as ParentTour }} />
      <AegDrawer
        openDrawer={!!parentTourDetails && isEditTourDrawerOpen}
        anchorValue="right"
        onClose={() => setIsEditTourDrawerOpen(false)}
      >
        <EditParentTourForm
          onFormSubmit={handleUpdateTour}
          isFormSaving={savingTour}
          tour={parentTourDetails as ParentTour}
        />
      </AegDrawer>
    </>
  );
}
