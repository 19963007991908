import { Typography, Button } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_WORKBOOK_VERSION_INFO } from 'src/gql/queries/excel';
import { useCallbackOnWindowFocus } from 'src/hooks/useCallbackOnWindowFocus';
import { FlexRow, StyledButton, StyledContainer } from './TemplateVersionWarningBanner.styled';

export function TemplateVersionWarningBanner({ tourId, offerId }:{ tourId: string, offerId: string }) {
  const { data: workbookInfo, refetch } = useQuery(GET_WORKBOOK_VERSION_INFO, {
    variables: {
      tourId,
      offerId,
    },
  });

  useCallbackOnWindowFocus(refetch);

  if (
    !workbookInfo?.getVersionInformation || workbookInfo?.getVersionInformation?.isCurrent
  ) return null;

  return (
    <StyledContainer>
      <Typography>
        This Offer is using an old model template version. Would you like to update to the latest version?
      </Typography>
      <FlexRow>
        <StyledButton color="secondary" variant="contained">Update to the Latest Version</StyledButton>
        <Button variant="text">Dismiss</Button>
      </FlexRow>
    </StyledContainer>
  );
}
