import { Bundle, User } from '@gql/types/graphql';

export interface ConfirmationEmailFormFields {
  toRecipients: User[];
  ccRecipients: User[];
  comments: string;
}

export const getConfirmationEmailDefaults = (bundleDetails: Bundle): ConfirmationEmailFormFields => ({
  toRecipients: [],
  ccRecipients: [...(bundleDetails?.team?.buyers || [])
    .concat(bundleDetails?.team?.operators || [])
    .concat(bundleDetails?.team?.viewOnlyUsers || [])],
  comments: '',
});
