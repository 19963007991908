export enum EventType {
  Promoted = 'Promoted',
  ThirdParty = '3rd Party',
  NoRiskBookingFee = 'No Risk - Booking Fee',
  NoRiskCasino = 'No Risk - Casino',
  InternalCoPro = 'Internal Co-Pro',
  VenuePrograms = 'Venue Programs',
  SpecialEvents = 'Special Events',
}

export interface OfferEvent {
  id: string;
  isActive: boolean;
}
