import * as React from 'react';
import { BreadcrumbDispatchContext } from '@providers';

export function Festivals() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  React.useEffect(() => {
    setBreadcrumbs([
      {
        text: 'Festivals',
        href: '/festivals/',
      },
    ]);
  });

  return <div>Festivals Page is Under Construction</div>;
}
