import { useContext } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ExcelErrorStateContext, ExcelErrorState } from '@providers';
import { logError } from '@services/telemetry-service';

export function useExcelErrorHandler() {
  const appInsights = useAppInsightsContext();
  const setErrorState = useContext(ExcelErrorStateContext);

  return (state: ExcelErrorState, logMessage?: string) => {
    const errorMessage = logMessage ?? state?.message ?? 'Generic Add-In Error';
    // eslint-disable-next-line no-console
    console.error(`${errorMessage}: ${JSON.stringify({ ...state }, null, 2)}`);
    logError(appInsights, errorMessage, state);
    setErrorState(state);
  };
}
