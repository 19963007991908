import React from 'react';

export type EnvironmentStatus = {
  mode: 'web' | 'excel';
  launchMode?: string;
  isExcelReady: boolean;
  isAutomationEnabled: boolean;
  automationTourId?: string | null;
  automationOfferId?: string | null;
  automationEventId?: string | null;
  automationSettlementType?: string | null;
  automationToken?: string | null;
  tourId?: string;
  offerId?: string;
  eventId?: string;
  nosId?: string
};

type EnvironmentStatusContext = {
  envStatus: EnvironmentStatus;
  updateEnvStatusProperty: (data: Partial<EnvironmentStatus>) => void;
};

const EnvStatusContext = React.createContext<EnvironmentStatusContext>({
  envStatus: {
    mode: 'web',
    isExcelReady: false,
    isAutomationEnabled: false,
  },
  updateEnvStatusProperty: () => {},
});

type ProviderProps = {
  environmentStatus: EnvironmentStatus;
  children: React.ReactNode;
};

function EnvStatusProvider({ children, environmentStatus }: ProviderProps) {
  const [envStatus, setEnvironmentStatus] = React.useState<EnvironmentStatus>(environmentStatus);

  const updateEnvStatusProperty = (data: Partial<EnvironmentStatus>) => setEnvironmentStatus((env) => ({
    ...env,
    ...data,
  }));

  return (
    <EnvStatusContext.Provider value={{ envStatus, updateEnvStatusProperty }}>
      {children}
    </EnvStatusContext.Provider>
  );
}

export { EnvStatusProvider, EnvStatusContext };
