/* eslint-disable max-lines-per-function */
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { ReactNode, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { ApolloError, useQuery } from '@apollo/client';
import { Artist, CompanyPermission, Genre } from '@gql/types/graphql';
import { GET_GENRES } from '@gql/queries/artists';
import { GET_COMPANIES } from '@gql/queries/companies';
import { useYupValidationResolver } from '@hooks';
import { logError } from '@services/telemetry-service';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Add from '@mui/icons-material/Add';
import {
  DropdownInput,
  FormTextInput,
  AutocompleteInput,
  FormErrorComponent,
} from '../shared';
import { createBundleValidationSchema } from './BundleForm.schema';
import {
  ButtonStyled,
  mapDropdownOptions,
} from '../shared/Form';
import {
  CREATE_BUNDLE_DEFAULTS,
  CREATE_BUNDLE_TEXT,
  BundleFormFields,
} from './BundleForm.models';
import { HeadlinerFields } from '../HeadlinerFields/HeadlinerFields';
import { AddHeadlinerButton } from './BundleForm.styled';

const MAX_CHARS_BUNDLE_NAME = 75;
interface BundleFormProps {
  onFormSubmit: (data: BundleFormFields) => void;
  isFormSaving: boolean; // Push saving state into form
  bundleFormData?: BundleFormFields;
  mutationError?: ApolloError;
  children?: ReactNode;
}

export function BundleForm({
  onFormSubmit,
  isFormSaving,
  bundleFormData,
  mutationError,
  children,
}: Readonly<BundleFormProps>) {
  const appInsights = useAppInsightsContext();
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);

  const resolver = useYupValidationResolver(createBundleValidationSchema);

  const formMethods = useForm<BundleFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: bundleFormData || CREATE_BUNDLE_DEFAULTS,
  });

  const {
    control, reset, handleSubmit, setValue, getValues,
  } = formMethods;

  const { loading: genresLoading, data: genresData } = useQuery(GET_GENRES);

  const { data: companiesData } = useQuery(GET_COMPANIES, {
    variables: { permission: CompanyPermission.ModifyBundles },
  });

  const handleInvalidSubmit = () => setIsFormErrorOpen(false);

  const handleValidSubmit = (formData: BundleFormFields) => {
    try {
      onFormSubmit(formData);
      reset();
    } catch (error) {
      setIsFormErrorOpen(true);
      logError(appInsights, 'BundleForm submit error', error);
    }
  };

  const onSelectHeadliner = (headliner: Artist) => {
    const headlinerSubGenres = headliner?.subGenres ?? [];
    const currentGenres = getValues('genres') ?? [];
    const allGenres = [...currentGenres, ...headlinerSubGenres];

    const uniqueGenres = Array.from(new Map(allGenres.map((genre) => [genre?.id, genre])).values()) as Genre[];
    setValue('genres', uniqueGenres);
  };

  const { fields: headlinersArray, append, remove } = useFieldArray({
    name: 'headliners',
    control,
  });

  const handleAddHeadliner = () => {
    append({ headliner: null, agency: null, agents: [] });
  };

  const handleRemoveHeadliner = (headlinerIndex: number) => () => {
    remove(headlinerIndex);
  };

  return (
    <>
      <FormErrorComponent
        isDismissable={true}
        error={mutationError}
        isOpen={isFormErrorOpen}
        setIsOpen={setIsFormErrorOpen}
      />
      {children}
      <FormProvider {...formMethods}>
        <form onSubmit={(e) => {
          void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
        }}>
          <>
          <div>
          {headlinersArray.map((field, idx) => (
            <HeadlinerFields
              onSelectHeadliner={onSelectHeadliner}
              fieldName={`headliners[${idx}]`}
              key={field.id}
              testId={String(idx)}
              removable={ headlinersArray.length > 1 }
              onRemove={handleRemoveHeadliner(idx)}
            />
          ))}
          </div>
          <div >
            <AddHeadlinerButton variant="outlined"
              startIcon={<Add />}
              onClick={handleAddHeadliner}
              data-testid={'add-headliner-button'}
            >
              Add Headliner
            </AddHeadlinerButton>
          </div>

            <FormTextInput
              control={control}
              fieldName="name"
              label="Bundle Name"
              isRequired
              placeholderText="Enter Bundle Name"
              maxLength={MAX_CHARS_BUNDLE_NAME}
            />
            <AutocompleteInput
              chipOption={{ labelProperty: 'name' }}
              disableClearable={true}
              areOptionsLoading={genresLoading}
              fieldName="genres"
              label='Genre'
              control={control}
              options={genresData?.genres || []}
              dataDisplayField={{ primary: 'name' }}
              dataFilterField={'id'}
              isRequired={false}
              placeholderText='Select Genre'
            />

            <DropdownInput
              fieldName={'companyId'}
              control={control}
              label={'Company'}
              placeholderText={'Select Company'}
              options={mapDropdownOptions(companiesData?.companies || [])}
              isRequired
            />

            <FormTextInput
              control={control}
              fieldName="tourNotes"
              label="Tour Notes"
              placeholderText="Enter Tour Notes"
              minRow={4}
              maxRow={4}
              infoTooltip="Notes filled here will be included in Tour's Confirmation Email"
            />

            <ButtonStyled
              variant="contained"
              data-testid="create-button-form"
              type="submit"
              disabled={isFormSaving}
            >
              {CREATE_BUNDLE_TEXT.submitText}
            </ButtonStyled>
          </>
        </form>
      </FormProvider>
      <DevTool control={control} />
    </>
  );
}
