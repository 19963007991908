import { useContext } from 'react';
import { VenueDatagrid } from '@components';
import { kebabCase } from '@utils/stringHelpers';
import { VenueManagementContext } from '@providers';
import { VARIABLE_COSTS, DEFAULT_VARIABLE_COSTS } from '@utils/venueHelpers';
import { VenueDataGridContainer, VenueGridTitle } from './VenueDatagrids.styled';
import { VenueGridProps, variableCostsColumns } from './VenueDatagrids.models';

export const VariableCostGrid = ({ disableVirtualization }: VenueGridProps) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space || !context.data?.venue) return null;
  const { setFeeItem, data: { space } } = context;

  const variableCosts = space.fees?.find(
    (f) => f.name === VARIABLE_COSTS,
  );

  const rows = DEFAULT_VARIABLE_COSTS.map((defaultVariableCost, index) => {
    const existingCost = variableCosts?.items?.find((cost) => cost?.subCategory === defaultVariableCost.subCategory);
    return {
      ...defaultVariableCost,
      ...existingCost,
      feeName: variableCosts?.name || VARIABLE_COSTS,
      id: `cost-item-${kebabCase(defaultVariableCost.subCategory)}-${index}`,
      type: existingCost?.type || defaultVariableCost.type,
      profitLossCategoryId: existingCost?.profitLossCategoryId || '',
    };
  });

  return (
    <VenueDataGridContainer>
      <VenueGridTitle variant="h6" data-testid="variable-costs-grid-title">Variable Costs</VenueGridTitle>
      <VenueDatagrid
        autoHeight
        columns={variableCostsColumns}
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        hideFooter
        rows={rows}
        processRowUpdate={setFeeItem}
        data-testid="variable-costs-grid"
        editMode="row"
      />
    </VenueDataGridContainer>
  );
};
