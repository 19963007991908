import { useContext, useMemo } from 'react';
import { EnvStatusContext } from '@providers';
import { ExcelWorkbookLaunchMode } from '@types';
import { TaskPaneContainer } from './TaskPane.styled';
import { NosSettlementView } from './NosSettlement.view';
import { ModelingView } from './Modeling.view';
import { TourSettlementFARView } from './TourSettlementFAR.view';
import { TourSettlementArtistView } from './TourSettlementArtist.view';

export function TaskPane() {
  const { envStatus } = useContext(EnvStatusContext);

  const renderTaskpane = useMemo(() => {
    switch (envStatus.launchMode) {
      case ExcelWorkbookLaunchMode.MODELING:
        return <ModelingView />;
      case ExcelWorkbookLaunchMode.NOS_SETTLEMENT:
        return <NosSettlementView />;
      case ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_FAR:
        return <TourSettlementFARView />;
      case ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_ARTIST:
        return <TourSettlementArtistView />;
      default:
        return null;
    }
  }, [envStatus.launchMode]);

  return (
    <TaskPaneContainer>
      {renderTaskpane}
    </TaskPaneContainer>
  );
}
