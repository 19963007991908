/* eslint-disable max-lines-per-function */
import {
  Alert,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DialogDispatchContext } from '@providers';
import { ReactNode, useContext } from 'react';
import { kebabCase } from '@utils/stringHelpers';
import {
  AlertList,
  ButtonStyled,
  LoadingIconStyled,
  DialogActionsContainer,
  DialogDescription,
} from './AegDialog.styled';

export function AegDialog({
  isLoading = false,
  rightSubmit,
  titles,
  description,
  submit,
  cancel,
  ...rest
}: {
  isLoading?: boolean;
  rightSubmit?: boolean;
  titles?: {
    dialogTitle: string;
    alertTitle: string;
    alertList?: ReactNode[];
  },
  description?: React.ReactNode;
  submit?: {
    text: string;
    action: () => void;
  },
  cancel?: {
    text: string;
    action: () => void;
  },
} & Omit<DialogProps, 'open'>) {
  const setDialog = useContext(DialogDispatchContext);

  const onClose = () => {
    setDialog(null);
  };

  const parseAndBoldText = (text: string): string => text.replace(/<strong>(.*?)<\/strong>/g, '<strong>$1</strong>');

  return (
    <Dialog
      onClose={onClose}
      {...rest}
      open={submit !== undefined}
    >
      <DialogTitle variant="h6" data-testid="dialog-title">
        {titles?.dialogTitle}
      </DialogTitle>
      {description && (
        <DialogDescription data-testid="dialog-description">{description}</DialogDescription>
      )}
      {(titles?.alertTitle || titles?.alertList) ? (
        <DialogContent data-testid="dialog-content">
          <Alert
            severity="warning"
            data-testid="alert-message"
          >
            <Typography>
              {titles?.alertTitle && parseAndBoldText(titles.alertTitle).split(' ').map((word, index) => (
                <span key={`${kebabCase(titles.alertTitle)}-${index}`}>
                  {word.includes('<strong>') ? <strong>{word.replace(/<\/?strong>/g, '')}</strong> : word}{' '}
                </span>
              ))}
            </Typography>
            {titles?.alertList && (
              <AlertList data-testid="alert-list">
                {titles?.alertList.map((item, index) => (
                  <li key={`alert-list-item-${index}`}>{item}</li>
                ))}
              </AlertList>
            )}
          </Alert>
        </DialogContent>
      ) : <br />}
      <DialogActionsContainer data-testid="dialog-actions">
        {rightSubmit ? (
          <>
            {cancel && <ButtonStyled
              variant="outlined"
              data-testid="cancel-dialog-button"
              type="submit"
              onClick={cancel?.action}
              disabled={isLoading}
            >
              {cancel?.text}
            </ButtonStyled>}
            <ButtonStyled
              variant="contained"
              data-testid="submit-dialog-button"
              type="submit"
              onClick={submit?.action}
              disabled={isLoading}
            >
              <div>{submit?.text}</div>
              {isLoading && <LoadingIconStyled size={24} />}
            </ButtonStyled>
          </>
        ) : (
          <>
            <ButtonStyled
              variant="contained"
              data-testid="submit-dialog-button"
              type="submit"
              onClick={submit?.action}
              disabled={isLoading}
            >
              <div>{submit?.text}</div>
              {isLoading && <LoadingIconStyled size={24} />}
            </ButtonStyled>
            {cancel && <ButtonStyled
              variant="outlined"
              data-testid="cancel-dialog-button"
              type="submit"
              onClick={cancel?.action}
              disabled={isLoading}
            >
              {cancel?.text}
            </ButtonStyled>}
          </>
        )}
      </DialogActionsContainer>
    </Dialog>
  );
}
