/* eslint-disable max-lines-per-function */
import {
  DetailsMetadataItem,
  ProtectedComponent,
  ConfigurationGrid,
  FixedCostGrid,
  TaxesGrid,
  TicketFeesGrid,
  VariableCostGrid,
  MailOutlinedIcon,
} from '@components';
import {
  Button, Divider, Grid,
} from '@mui/material';
import { BreadcrumbDispatchContext, VenueManagementContext } from '@providers';
import { useContext, useEffect, useMemo } from 'react';
import { AegResourceTypes, UserPermissions } from '@types';
import { getTimezoneDate } from '@utils/stringHelpers';
import {
  ButtonContainer,
  StickyHeader,
  NotesContainer,
  NotesInput,
  Title,
  VenueDataContainer,
  VenueEditRequestButton,
  VenueName,
  FlexContainer,
  LastUpdated,
  MetadataGrid,
  FlexGridItem,
  HeaderGrid,
} from './VenueManagement.styled';

export function VenueManagement() {
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const venueContext = useContext(VenueManagementContext);
  if (!venueContext?.data?.venue || !venueContext.data.space) return null;

  const {
    data: { space, venue },
    gridErrors,
    isDirty,
    saveData,
    cancelData,
    setVenueNote,
  } = venueContext;

  const validationErrors = Object.values(gridErrors).reduce((acc, val) => acc + val, 0) > 0;

  const metadataComponents = useMemo(() => {
    if (!venueContext?.data?.venue) return [];
    const notAvailable = 'Not Available';
    const state = venue?.stateCode && venue.state ? `${venue?.stateCode} - ${venue?.state}` : notAvailable;
    const metadata = [
      { title: 'City', value: venue?.city || notAvailable },
      { title: 'State', value: state },
      { title: 'Country', value: venue?.country || notAvailable },
      { title: 'DMA', value: venue?.metroArea || notAvailable },
      { title: 'Max. Capacity', value: space?.maxCapacity?.toLocaleString() || notAvailable },
      { title: 'Currency', value: venue?.localCurrencyCode || notAvailable },
    ];

    return metadata.map((item) => <DetailsMetadataItem metadata={item} key={`metadata-${item.title}`} />);
  }, [venueContext?.data?.venue]);

  const requestVenueEditLink = useMemo(() => {
    if (!venueContext?.data?.venue) return null;
    const notAvailable = 'Not Available';
    const name = venue?.name || notAvailable;
    const city = venue?.city || notAvailable;
    const state = venue?.stateCode && venue.state ? `${venue?.stateCode} - ${venue?.state}` : notAvailable;
    const country = venue?.country || notAvailable;

    const recipient = encodeURIComponent('bookingsupport@aegpresents.com');
    const rawSubject = `Request a Venue Update - ${name} - ${city}, ${state}, ${country}`;
    const subject = encodeURIComponent(rawSubject);
    return `mailto:${recipient}?subject=${subject}`;
  }, [venueContext?.data?.venue]);

  useEffect(() => {
    setBreadcrumbs([
      { href: '/venues', text: 'Venue Management' },
      { href: '', text: space?.name || '' },
    ]);

    if (space?.name) {
      document.title = `${space.name} - Modern Elvis`;
    } else {
      document.title = 'Modern Elvis';
    }
  }, []);

  return (
    <ProtectedComponent
      checkPermission={{
        permission: UserPermissions.ManageVenueQD,
        resourceType: AegResourceTypes.System,
      }}
      navigateAway={'/unauthorized'}
    >
      <HeaderGrid container>
        <Grid item xs={3}>
          <VenueName variant="h5" data-testid="space-details-page-title">{space?.name}</VenueName>
        </Grid>
        <Grid item xs={7}>
        <MetadataGrid container spacing={3}>
          {metadataComponents}
        </MetadataGrid>
        </Grid>
        <FlexGridItem item xs={2}>
          <VenueEditRequestButton
            component="a"
            href={requestVenueEditLink}
            startIcon={<MailOutlinedIcon />}
            variant="outlined">
            Request a Venue Edit
          </VenueEditRequestButton>
        </FlexGridItem>
      </HeaderGrid>
      <VenueDataContainer>
        <StickyHeader>
          <FlexContainer>
            <Title variant="h6">Managed Data</Title>
            <ButtonContainer>
              <Button variant="text" disabled={!isDirty} onClick={cancelData}>
                Cancel
              </Button>
              <Button variant="contained" disabled={!isDirty || validationErrors} onClick={saveData}>
                Save
              </Button>
            </ButtonContainer>
          </FlexContainer>
          <Divider />
        </StickyHeader>
        <NotesContainer>
          <Title variant="body2" data-testid="note-input-title">Venue Note</Title>
          <NotesInput
            inputProps={{ 'data-testid': 'venue-notes' }}
            rows={3}
            multiline
            value={space.description}
            placeholder="Enter notes"
            onChange={setVenueNote}
            data-testid="note-input"
          />
        </NotesContainer>
        {/* Venue Data Grids */}
        <ConfigurationGrid />
        <FixedCostGrid />
        <VariableCostGrid />
        <TaxesGrid />
        <TicketFeesGrid />
        {/* End Venue Data Grids */}
      </VenueDataContainer>
      <LastUpdated>Last updated {getTimezoneDate(space?.updatedDate, venue.timezone)[0]}</LastUpdated>
    </ProtectedComponent>
  );
}
