/* eslint-disable @typescript-eslint/no-unused-vars, max-lines-per-function */
/* eslint-disable no-param-reassign, @typescript-eslint/no-misused-promises */
import { useState, useMemo, useEffect } from 'react';
import {
  FormProvider, SubmitErrorHandler, SubmitHandler, useFieldArray, useForm,
} from 'react-hook-form';
import { useYupValidationResolver } from '@hooks';
import { Artist, Genre } from '@gql/types/graphql';
import Add from '@mui/icons-material/Add';
import { useQuery } from '@apollo/client';
import {
  AutocompleteInput, ButtonStyled, DropdownInput, FormTextInput,
  mapDropdownOptions, HeadlinerFields,
} from '@components';
import { AegAggregator, EventType } from '@types';
import { isDefined, isNotNull } from '@utils/arrayHelpers';
import { GET_COMPANIES } from '@ooagg-gql/queries/companies';
import { GET_GENRES } from '@ooagg-gql/queries/artists';
import {
  CREATE_EVENT_TEXT,
  CREATE_EVENT_DEFAULTS,
  EventFormFields,
  CreateEventFormErrors,
} from './EventForm.models';
import { createEventValidationSchema } from './EventForm.schema';
import { getHeadlinerInfoFromSelectedHeadliners } from './EventForm.util';
import { AddHeadlinerButton } from './EventForm.styled';

interface EventFormProps {
  eventFormData?: EventFormFields;
  onFormSubmit: SubmitHandler<EventFormFields>;
}

/**
 * GenreMap is a map of main genre ids to their respective genres (both main and sub genres)
 */
type GenreMap = Record<string, Genre[]>;

export function EventForm({
  eventFormData,
  onFormSubmit,
}: EventFormProps) {
  const [genreSearchVal, setGenreSearchVal] = useState<string>('');
  const [headlinerList, setHeadlinerList] = useState<Artist[]>([]);
  // const [selectedHeadlinerIds, setSelectedHeadlinerIds] = useState<string[]>([]);
  const [isFormErrorOpen, setIsFormErrorOpen] = useState(false);
  const resolver = useYupValidationResolver(createEventValidationSchema);

  const defaultValues = useMemo(() => eventFormData || CREATE_EVENT_DEFAULTS, []);

  const [genreInputList, setGenreInputList] = useState<Genre[]>(eventFormData?.genres || CREATE_EVENT_DEFAULTS.genres);
  const [genreOptions, setGenreOptions] = useState<Genre[]>([]);

  const { loading: genresLoading, data: genresData } = useQuery(GET_GENRES, {
    onCompleted: (data) => {
      if (!genreOptions.length && !genreSearchVal) {
        // set default genre options. initial list will satisfy MUI options requirements.
        // genreInputFilterOutList will be used to handle which results display in the autocomplete options.
        const allGenres = data.genres?.flatMap<Genre>((genre) => (genre ? [genre] : [])) || [];
        setGenreOptions(allGenres);
      }
    },
    variables: { searchTerm: genreSearchVal },
    context: {
      clientName: AegAggregator.OOAGG,
    },
  });

  const useFormMethods = useForm<EventFormFields>({
    resolver,
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const {
    control, handleSubmit, setValue, watch,
  } = useFormMethods;

  const { fields: headlinersArray, append, remove } = useFieldArray({
    name: 'headliners',
    control,
  });

  const handleAddEmptyHeadliner = () => {
    append({ headliner: null, agency: null, agents: [] });
  };

  const watchHeadliners = watch('headliners');
  const {
    selectedHeadliners,
    newEventName,
  } = getHeadlinerInfoFromSelectedHeadliners(watchHeadliners);
  const selectedHeadlinerNames = selectedHeadliners
    .map((h) => h?.name)
    .filter(isDefined);

  const { data: companiesData } = useQuery(GET_COMPANIES, {
    context: { clientName: AegAggregator.OOAGG },
  });

  const handleInvalidSubmit: SubmitErrorHandler<CreateEventFormErrors> = (_error) => { };

  const handleValidSubmit = (formData: EventFormFields) => {
    formData.name = formData.name.trim();
    onFormSubmit(formData);
  };

  const genreInputPlaceholder = useMemo(
    () => (genreInputList.length > 0 ? '' : CREATE_EVENT_TEXT.genrePlaceholder),
    [genreInputList],
  );

  const genreInputFilterOutList = useMemo(
    () => {
      const genresDataGenreIds = genresData?.genres?.flatMap((genre) => (genre?.id ? [genre.id] : [])) || [];

      // filter out genres that are not in the search response.
      const genreOptionsIds = genreOptions.flatMap((genre) => {
        // its a main genre
        if (!genre.mainGenre) {
          return [genre.id];
        }
        // if search value exists, filter out genres that are not in the search response.
        if (genreSearchVal && !genresDataGenreIds.includes(genre.id)) {
          return [genre.id];
        }
        return [];
      });

      return genreOptionsIds;
    },
    [genresData, genreOptions],
  );

  const setGenreList = (genres: Genre[]) => {
    // group genres by main genre id
    const genreGrouped2 = genres.reduce<GenreMap>((genreMap, genre) => {
      const mainGenreId = genre.mainGenre?.id ?? genre.id;
      const isMainGenre = !genre.mainGenre;
      if (genreMap[mainGenreId]) {
        const genreExists = genreMap[mainGenreId].find((subGenre) => subGenre.id === genre.id);
        if (!genreExists) {
          genreMap[mainGenreId][isMainGenre ? 'unshift' : 'push'](genre);
        }
      } else {
        Object.assign(genreMap, { [mainGenreId]: [genre] });
      }
      return genreMap;
    }, {});

    // sort genre groups by main genre first, and flatten.
    const newGenreList = Object.values(genreGrouped2).flatMap((genreList) => {
      if (genreList && genreList.length > 0) {
        genreList.sort((a, b) => {
          if (a.mainGenre) {
            return 1;
          }
          if (b.mainGenre) {
            return -1;
          }
          return 0;
        });
        return genreList;
      }
      return [];
    });
    setGenreInputList(newGenreList);
  };

  useEffect(() => {
    setValue('name', newEventName);
  }, [selectedHeadlinerNames.length]);

  const updateHeadlinersAndGenres = () => {
    const watchedHeadliners = watch('headliners').map((group) => group.headliner);
    const watchedHeadlinerIds = watchedHeadliners.map((headliner) => headliner?.id);
    const headlinerToRemove = headlinerList.find((headliner) => !watchedHeadlinerIds.includes(headliner.id));
    let newGenreList : Genre[] = genreInputList;
    if (headlinerToRemove && headlinerToRemove.id) {
      // newGenreList is genreInputList without headlinerToRemove genres
      const genreIdsToRemove = [
        ...(headlinerToRemove?.subGenres?.map((g) => g?.id) ?? []),
        ...(headlinerToRemove?.genres?.map((g) => g?.id) ?? []),
      ];
      newGenreList = genreInputList.filter((genre) => !genreIdsToRemove.includes(genre.id));
    }
    // update headliner list
    const newHeadlinerList = watchedHeadliners.filter(isDefined);
    setHeadlinerList(newHeadlinerList);

    newHeadlinerList.forEach((headliner) => {
      const headlinerGenres = [...(headliner?.subGenres ?? []), ...(headliner?.genres ?? [])].filter(isNotNull);
      const existingNewGenreListIds = newGenreList.map((genre) => genre.id);
      const newGenresToAdd = headlinerGenres.filter((genre) => !existingNewGenreListIds.includes(genre.id));
      newGenreList = [
        ...newGenreList,
        ...newGenresToAdd,
      ];
    });
    setGenreList(newGenreList);
  };

  const onSelectHeadliner = (_headliner: Artist) => {
    updateHeadlinersAndGenres();
  };

  // when a Headliner section is removed
  const handleRemoveHeadliner = (headlinerIndex: number) => () => {
    remove(headlinerIndex);
    updateHeadlinersAndGenres();
  };

  useEffect(() => {
    if (genreInputList) {
      setValue('genres', genreInputList);
    }
  }, [genreInputList]);

  return (
    <FormProvider {...useFormMethods} >
      <form data-testid="EventForm" onSubmit={(e) => {
        void handleSubmit(handleValidSubmit, handleInvalidSubmit)(e);
      }}>
        <div>
          {headlinersArray.map((field, idx) => (<HeadlinerFields
            onSelectHeadliner={onSelectHeadliner}
            fieldName={`headliners[${idx}]`}
            key={field.id}
            testId={String(idx)}
            removable={ headlinersArray.length > 1 }
            onRemove={handleRemoveHeadliner(idx)}
            contextClientName={AegAggregator.OOAGG}
          />))}
        </div>
        <div >
          <AddHeadlinerButton variant="outlined"
            startIcon={<Add />}
            onClick={handleAddEmptyHeadliner}
            data-testid={'add-headliner-button'}
          >
            Add Headliner
          </AddHeadlinerButton>
        </div>
        <FormTextInput
          control={control}
          fieldName="name"
          label={CREATE_EVENT_TEXT.eventNameLabel}
          isRequired
          placeholderText={CREATE_EVENT_TEXT.eventNamePlaceholder}
        />
        <DropdownInput
          fieldName="type"
          control={control}
          label={CREATE_EVENT_TEXT.eventTypeLabel}
          options={Object.values(EventType).map((type) => ({ label: type, value: type }))}
          placeholderText={CREATE_EVENT_TEXT.eventTypePlaceholder}
          isRequired
        />
        <DropdownInput
          fieldName="companyId"
          control={control}
          label={CREATE_EVENT_TEXT.companyLabel}
          options={mapDropdownOptions(companiesData?.companies || [])}
          placeholderText={CREATE_EVENT_TEXT.companyPlaceholder}
          isRequired
        />
        <AutocompleteInput<Genre[]>
          fieldName="genres"
          control={control}
          label={CREATE_EVENT_TEXT.genreLabel}
          areOptionsLoading={genresLoading}
          options={genreOptions}
          filterOut={genreInputFilterOutList}
          dataDisplayField={{ primary: 'name' }}
          chipOption={{ labelProperty: 'name' }}
          disableClearable
          dataFilterField="id"
          placeholderText={genreInputPlaceholder}
          defaultValue={CREATE_EVENT_DEFAULTS.genres}
          onInputChange={(data) => setGenreSearchVal(data.toString())}
          onSelect={setGenreList}
          isRequired
        />
        <FormTextInput
          control={control}
          fieldName="notes"
          label={CREATE_EVENT_TEXT.eventNotesLabel}
          isRequired={false}
          minRow={2}
          placeholderText={CREATE_EVENT_TEXT.eventNotesPlaceholder}
        />
        <ButtonStyled variant="contained" data-testid="create-button-form" type="submit" disabled={false}>
          {CREATE_EVENT_TEXT.submitButton}
        </ButtonStyled>
      </form>
    </FormProvider>
  );
}
