import { VenueDatagrid } from '@components';
import { useContext } from 'react';
import { VenueManagementContext } from '@providers';
import { DEFAULT_TICKET_FEES, TICKET_FEES } from '@utils/venueHelpers';
import { VenueDataGridContainer, VenueGridTitle } from './VenueDatagrids.styled';
import { VenueGridProps, ticketFeesColumns } from './VenueDatagrids.models';

export const TicketFeesGrid = ({ disableVirtualization }: VenueGridProps) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space) return null;
  const { setFeeItem, data: { space } } = context;

  const spaceTicketFees = space.fees?.find(
    (fee) => fee.name === TICKET_FEES,
  );

  const rows = DEFAULT_TICKET_FEES.map((defaultTicketFee, index) => {
    const existingTicketFee = spaceTicketFees?.items?.find((fee) => fee?.subCategory === defaultTicketFee.subCategory);
    return {
      ...defaultTicketFee,
      ...existingTicketFee,
      feeName: spaceTicketFees?.name || TICKET_FEES,
      id: `ticket-fee-item-${defaultTicketFee.subCategory}-${index}`,
      type: existingTicketFee?.type || defaultTicketFee.type,
      profitLossCategoryId: existingTicketFee?.profitLossCategoryId || '',
      formula: existingTicketFee?.formula || defaultTicketFee.formula,
    };
  });

  return (
    <VenueDataGridContainer>
      <VenueGridTitle variant="h6" data-testid="ticket-fees-grid-title">Ticket Fees</VenueGridTitle>
      <VenueDatagrid
        autoHeight
        columns={ticketFeesColumns}
        disableVirtualization={disableVirtualization}
        hideFooter
        disableRowSelectionOnClick
        rows={rows}
        processRowUpdate={setFeeItem}
        data-testid="ticket-fees-grid"
        editMode="row"
      />
    </VenueDataGridContainer>
  );
};
