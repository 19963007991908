export const tourOptions = [
  { value: 'Tour - Arena', label: 'Tour' },
  { value: 'multiLegTour', label: 'Multi-Leg Tour' },
];

export const crossedOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const roleOptions = [
  { value: 'buyer', label: 'Buyer' },
  { value: 'operator', label: 'Operator' },
];

export const venueOptions = [{ value: 'arena', label: 'Arena' }]; // Temporary
export const visibilityOptions = [
  { value: 'visible to me', label: 'Visible to Me' },
  {
    value: 'visible to all in my company',
    label: 'Visible to All in my Company',
  },
]; // Temporary

export const statusOptions = [
  { value: 'Draft', label: 'Draft' },
  { value: 'Pending approval', label: 'Pending Approval' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Sent to Agent', label: 'Sent to Agent' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'On Tour', label: 'On Tour' },
  { value: 'Settlement In Progress', label: 'Settlement In Progress' },
  { value: 'Accounting Settled', label: 'Accounting Settled' },
  { value: 'Dead', label: 'Dead' },
  { value: 'Canceled', label: 'Canceled' },
];

export const baseCurrencyOptions = [
  { value: 'USD', label: 'USD - U.S. Dollar' },
  { value: 'CAD', label: 'CAD - Canadian Dollar' },
  { value: 'GBP', label: 'GBP - British Pound ' },
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'AUD', label: 'AUD - Australian Dollar' },
];
