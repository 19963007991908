import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ModifyTeamMembersForm, ModifyTeamMembersFormInput } from '@components';
import { ADD_BUNDLE } from '@gql/mutations/bundles';
import { AddBundleInput } from '@gql/types/graphql';
import { WebUserContext } from '@providers';
import { AegDrawer } from '../shared';
import {
  BundleFormFields, CREATE_BUNDLE_TEXT, TEAM_MEMBERS_BUNDLE_TEXT, getDefaultValues,
} from '../BundleForm/BundleForm.models';
import { BundleForm } from '../BundleForm/BundleForm';
import { CreateBundleDrawerHeader } from './CreateBundleDrawerHeader';

enum AddBundleFormSteps {
  ONE,
  TWO,
}

// eslint-disable-next-line max-lines-per-function
export const CreateBundleDrawer = ({
  isDrawerOpen,
  closeDrawer,
}: {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
}) => {
  const navigate = useNavigate();
  const user = useContext(WebUserContext);
  const defaultValues = getDefaultValues(user);
  const [formStep, setFormStep] = useState<AddBundleFormSteps>(AddBundleFormSteps.ONE);
  const [bundleFormData, setBundleFormData] = useState<BundleFormFields | null>(null);

  const [
    createBundle,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(ADD_BUNDLE, {
    errorPolicy: 'all',
    onCompleted(data) {
      navigate(`/bundles/${data?.addBundle?.id ?? ''}`);
    },
  });

  const handleClose = () => {
    closeDrawer();
    setFormStep(AddBundleFormSteps.ONE);
    setBundleFormData(null);
  };

  const handleStepOne = (formData: BundleFormFields) => {
    setBundleFormData(formData);
    setFormStep(AddBundleFormSteps.TWO);
  };

  const handleStepTwo = async (teamData: ModifyTeamMembersFormInput) => {
    if (mutationLoading) {
      return;
    }

    if (!bundleFormData?.headliners.length) {
      setFormStep(AddBundleFormSteps.ONE);
      return;
    }

    const trimmedTourNotes = bundleFormData.tourNotes.trim();
    const bundle: AddBundleInput = {
      headliners: bundleFormData.headliners.map((artistInfo) => ({
        artistId: artistInfo.headliner?.id as string,
        agencyId: artistInfo.agency?.id as string,
        agentIds: artistInfo.agents?.map((agent) => agent.id).filter((id) => id !== undefined && id !== null) ?? [],
      })),
      name: bundleFormData.name,
      genreIds: bundleFormData.genres.map((genre) => genre.id),
      companyId: bundleFormData.companyId,
      team: {
        buyerIds: teamData.additionalBuyerIds,
        operatorIds: teamData.additionalOperatorIds,
        viewOnlyIds: teamData.viewOnlyIds?.length ? teamData.viewOnlyIds : undefined,
      },
      tourNotes: trimmedTourNotes.length > 0 ? trimmedTourNotes : undefined,
    };

    await createBundle({
      variables: {
        bundle,
      },
    });
  };

  return (
    <AegDrawer
      anchorValue={'right'}
      openDrawer={isDrawerOpen}
      onClose={handleClose}
    >
      {formStep === AddBundleFormSteps.ONE && (
        <>
          <CreateBundleDrawerHeader
            title={CREATE_BUNDLE_TEXT.title}
            subTitle={CREATE_BUNDLE_TEXT.subTitle}
          />
          <BundleForm
            bundleFormData={bundleFormData ?? undefined}
            onFormSubmit={handleStepOne}
            isFormSaving={mutationLoading}
            mutationError={mutationError}
          />
        </>
      )}

      {formStep === AddBundleFormSteps.TWO && (
        <>
          <CreateBundleDrawerHeader
            title={TEAM_MEMBERS_BUNDLE_TEXT.title}
            subTitle={TEAM_MEMBERS_BUNDLE_TEXT.subTitle}
          />
          <ModifyTeamMembersForm
            defaultValues={defaultValues}
            onPrevious={() => setFormStep(AddBundleFormSteps.ONE)}
            onFormSubmit={handleStepTwo}
            isFormSaving={mutationLoading}
            isAddMemberFlow
          />
        </>
      )}
    </AegDrawer>
  );
};
