/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable max-lines-per-function */
import React, {
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import { Box, Typography, Switch } from '@mui/material';
import {
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from '@mui/x-data-grid-pro';
import {
  AltRowColorGrid,
  ShowTable,
  StatusChip,
  JDEEventSubledgerStatus,
  FeatureGate,
  NOSVersionsTable,
  CloseIconGrey,
  ProtectedComponent,
} from '@components';
import { Event, Show, User } from '@gql/types/graphql';
import { EventStatus, PermissionCheck } from '@types';
import { expandColumn, manageExpandedRows } from '@utils/datagridHelpers';
import { getTimezoneDate } from '@utils/stringHelpers';
import { sortEventsByDateActive, sortEventsByDate } from '@utils/eventHelpers';
import { getMarketDisplayName } from '@utils/venueHelpers';
import featureFlags from '@utils/featureFlags';
import {
  EventsContainer, StyledNoEventsTypography, StyledButton, SwitchContainer,
} from './EventsList.styled';
import { ShowsGridData } from '../shared/ShowTable/ShowTable.types';
import { EventListTableActions } from './EventListTableActions';

dayjs.extend(tz);

type EventListProps = {
  events?: Event[];
  columns?: string[];
  columnHeaderOverrides?: Record<string, string>;
  sortByDate?: boolean;
  noEventsFoundText: string;
  removeEventPermission?: PermissionCheck;
  onActivateEvent?: (eventId: string, isActive: boolean) => void;
  onRemoveEvent?: (eventId: string) => void;
};

export function EventsList({
  events,
  columns,
  columnHeaderOverrides,
  sortByDate = false,
  noEventsFoundText,
  removeEventPermission,
  onActivateEvent,
  onRemoveEvent,
}: EventListProps) {
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);

  const expandRowClick = (gridRowId: GridRowId) => {
    manageExpandedRows(gridRowId, expandedRows, setExpandedRows);
  };

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }: { row: ShowsGridData }) => (
      <Box>
        <ShowTable row={row} />
        <FeatureGate configFlag={featureFlags.NOS_SETTLEMENT_VERSIONING}>
          <NOSVersionsTable eventId={row.id}/>
        </FeatureGate>
      </Box>
    ),
    [],
  );

  const eventData = useMemo(() => {
    if (!events) {
      return [];
    }

    const sortedEvents = sortByDate
      ? sortEventsByDate(events)
      : sortEventsByDateActive(events);

    // TODO: Add subledger and capacity data when available
    return sortedEvents.map((event: Event) => {
      const {
        id, date, status, shows, venue, space, subledgerId, subledgerStatus,
        subledgerPayload, isSettledInOldElvis, workbookFileId, isActive, primaryBuyer,
      } = event;

      const venueName = space?.name || '';

      const market = getMarketDisplayName(venue);

      return {
        id: id ?? '',
        capacity: event.sellableCapacity || event.space?.maxCapacity || 'Not Available',
        market,
        firstEventDate: date as string ?? '',
        shows: shows as Show[] ?? [],
        status: status as EventStatus ?? '',
        subledgerId,
        subledgerStatus,
        subledgerPayload,
        venueName,
        timezone: event.venue?.timezone ?? null,
        isSettledInOldElvis,
        workbookFileId,
        active: isActive,
        primaryBuyer: primaryBuyer as User ?? [],
      };
    });
  }, [events]);

  const getExpandColumn = () => expandColumn(expandedRows, expandRowClick);

  const getVenueColumn = (): GridColDef<ShowsGridData> => ({
    field: 'venueName',
    headerName: 'Venue',
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.venueName}
      </Typography>
    ),
  });

  const getMarketColumn = (): GridColDef<ShowsGridData> => ({
    field: 'market',
    headerName: 'Market',
    flex: 1,
    renderCell: (params) => (
      <Typography>
        {params.row.market}
      </Typography>
    ),
  });

  const getShowCountColumn = (): GridColDef<ShowsGridData> => ({
    field: 'showCount',
    headerName: 'Show(s)',
    flex: 0.6,
    renderCell: (params) => (
      <Typography> {params.row.shows.length} </Typography>
    ),
  });

  const getFirstEventDateColumn = (): GridColDef<ShowsGridData> => ({
    field: 'firstEventDate',
    headerName: 'First Event Date',
    flex: 0.8,
    renderCell: (params) => (
      <Typography>
        {params.row.firstEventDate
          ? getTimezoneDate(params.row.firstEventDate, params.row.timezone)[0]
          : 'TBD'
        }
      </Typography>
    ),
  });

  const getLastUpdatedColumn = (): GridColDef<ShowsGridData> => ({
    field: 'updatedDate',
    headerName: 'Last Updated',
    flex: 0.8,
    renderCell: (params) => params.row.updatedDate ?? 'TBD',
  });

  const getCapacityColumn = (): GridColDef<ShowsGridData> => ({
    field: 'capacity',
    headerName: 'Capacity',
    flex: 0.6,
    renderCell: (params) => (
      <Typography>
        {params.row.capacity}
      </Typography>
    ),
  });

  const getSubledgerColumn = (): GridColDef<ShowsGridData> => ({
    field: 'subledger',
    headerName: 'Subledger',
    flex: 0.8,
    renderCell: (params: GridCellParams<GridRowModel>) => (
      <JDEEventSubledgerStatus
        eventId={params.row.id as string}
        subledgerId={params.row.subledgerId as string}
        subledgerStatus={params.row.subledgerStatus as string}
      />
    ),
  });

  const getBuyerColumn = (): GridColDef<ShowsGridData> => ({
    field: 'buyerIds',
    headerName: 'Buyer',
    flex: 0.8,
    renderCell: (params) => params.row.primaryBuyer?.name ?? '',
  });

  const getStatusColumn = (): GridColDef<ShowsGridData> => ({
    field: 'status',
    headerName: columnHeaderOverrides?.status || 'Event Status',
    flex: 0.8,
    renderCell: (params) => (
      <StatusChip status={params.row.status as string} />
    ),
  });

  const getActiveColumn = (): GridColDef<ShowsGridData> => ({
    field: 'active',
    headerName: 'Active',
    flex: 0.5,
    renderCell: (params) => (
      <SwitchContainer>
        <Switch
          data-testid={`toggle-active-event-${params.row.id}`}
          checked={params.row.active}
          onChange={onActivateEvent ? (e) => onActivateEvent(params.row.id, e.target.checked) : undefined}
        />
      </SwitchContainer>
    ),
  });

  const getRemoveActionColumn = (): GridColDef<ShowsGridData> => ({
    field: 'removeAction',
    type: 'actions',
    width: 10,
    resizable: false,
    sortable: false,
    filterable: false,
    getActions: (params) => [
      <ProtectedComponent
        checkPermission={removeEventPermission as PermissionCheck}
      >
        <StyledButton
          data-testid={`remove-event-${params.row.id}`}
          onClick={onRemoveEvent ? () => onRemoveEvent(params.row.id) : undefined}
        >
          <CloseIconGrey />
        </StyledButton>
      </ProtectedComponent>,
    ],
  });

  const getMoreActionsColumn = (): GridColDef<ShowsGridData> => ({
    field: 'moreActions',
    type: 'actions',
    resizable: false,
    width: 10,
    getActions: EventListTableActions,
  });

  const columnsList: GridColDef<ShowsGridData>[] = [
    getVenueColumn(),
    getMarketColumn(),
    getShowCountColumn(),
    getFirstEventDateColumn(),
    getLastUpdatedColumn(),
    getCapacityColumn(),
    getSubledgerColumn(),
    getBuyerColumn(),
    getStatusColumn(),
    getActiveColumn(),
    getMoreActionsColumn(),
    getRemoveActionColumn(),
  ].filter((column) => !columns || columns.includes(column.field));

  const filteredColumns: GridColDef<ShowsGridData>[] = [
    ...getExpandColumn(), // expand column (must be first)
    ...columnsList,
  ];

  return (
    <EventsContainer data-testid="events-container">
      {events && events.length ? (
        <AltRowColorGrid
          hideFooter
          rows={eventData}
          columns={filteredColumns}
          data-testid="events-data-grid"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          getRowId={(row) => row.id as string}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          initialState={{
            pinnedColumns: {
              right: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'moreActions', 'removeAction'],
            },
          }}
        />
      ) : (
        <StyledNoEventsTypography>
          {noEventsFoundText}
        </StyledNoEventsTypography>
      )}
    </EventsContainer>
  );
}
