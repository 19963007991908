import { VenueManagementContext } from '@providers';
import { SpaceConfiguration, TierScaling } from '@gql/types/graphql';
import { CondensedGrid } from '@components';
import { useContext, useMemo } from 'react';
import { PriceTierContainer } from '../VenueDatagrids.styled';
import { priceTierColumns } from '../VenueDatagrids.models';

export const PriceTierTable = ({
  row: parentRow,
  disableVirtualization = false,
}: {
  row: SpaceConfiguration;
  disableVirtualization?: boolean;
}) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space || !context.data?.venue) return null;
  const { setPriceTier } = context;

  const rows = useMemo(() => {
    const tierRow = (tier: TierScaling | null) => ({
      ...tier,
      configurationId: parentRow?.id,
    });

    if (parentRow.tierScaling?.length) {
      return parentRow.tierScaling.map(tierRow);
    }

    return [];
  }, [parentRow.tierScaling]);

  return (
    <PriceTierContainer>
      <CondensedGrid
        autoHeight
        columns={priceTierColumns}
        rowHeight={40}
        hideFooter={true}
        rows={rows}
        columnHeaderHeight={40}
        disableVirtualization={disableVirtualization}
        disableRowSelectionOnClick
        data-testid="pricing-tier-grid"
        processRowUpdate={setPriceTier}
      />
    </PriceTierContainer>
  );
};
