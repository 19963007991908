import { graphql } from '@gql/types';

export const GET_WORKBOOK_VERSION_INFO = graphql(`
  query GetWorkbookVersionInfo($tourId: String!, $offerId: String!) {
    getVersionInformation(tourId: $tourId, offerId: $offerId) {
      id
      version
      isCurrent
    }
  }
`);
