import { ErrorOption } from 'react-hook-form';
import { Genre, User } from '@gql/types/graphql';
import { ArtistInformation } from '@types';
import { ModifyTeamMembersFormFields, ModifyTeamMembersFormUserRoles } from '@components';

export const CREATE_BUNDLE_TEXT = {
  title: 'Create a Bundle',
  subTitle: 'Please provide the details below to create a new bundle.',
  submitText: 'Next',
};

export const TEAM_MEMBERS_BUNDLE_TEXT = {
  title: 'Create a Bundle: Add Team Members',
  subTitle: 'Operators and Buyers will have immediate access and edit rights to this bundle.',
  submitText: 'Create',
};

export const CREATE_BUNDLE_DEFAULTS: BundleFormFields = {
  companyId: '',
  genres: [],
  headliners: [{
    headliner: null,
    agents: [],
    agency: null,
  }],
  name: '',
  tourNotes: '',
};

export interface BundleFormFields {
  companyId: string;
  genres: Genre[];
  headliners: ArtistInformation[];
  name: string;
  tourNotes: string;
}

export type CreateBundleFormErrors = {
  companyId: ErrorOption[];
  name: ErrorOption[];
  headliner: ErrorOption[];
  genres: ErrorOption[];
  agents: ErrorOption[];
  agency: ErrorOption[];
};

export const getDefaultValues = (user: User | null): ModifyTeamMembersFormFields => ({
  userRole: ModifyTeamMembersFormUserRoles.operator,
  operators: [
    {
      id: user?.id || '',
      email: user?.email || '',
      name: user?.name || '',
    },
  ],
  primaryOperatorId: '',
  buyers: [],
  primaryBuyerId: '',
  viewOnly: [],
});
