/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

// Initialize Workbook Keys
export const TOUR_SETTLEMENT_ARTIST_INITIALIZED_KEY = 'tour_settlement_artist_workbook_initialized';

export const ARTIST_SETTLEMENT_PHASE = 'artistSettlement';

export const SHEET_NAMES = {
  DEAL_SUMMARY: 'Deal Summary',
  SHOW_DETAIL: 'Show Detail',
  LATERAL_SUM: 'Lateral Sum',
};

export const TABLE_NAMES = {
  SHOW_DETAIL_TABLES: {
    SHOW_DETAIL_ARTIST: 'ShowDetailArtist',
  },
};

export const NAMED_RANGES = {
  DEAL_SUMMARY: {
    TOUR_NAME: 'TourName',
    COMMENTS: 'Comments',
    TOTAL_POOL_INCOME: {
      MAX_MANUAL_ADJUSTMENTS: 8,
      PREMIUM_TICKETING_REVENUE_SETTLED: 'TotalPoolIncomePremiumTicketingRevenue_Settled',
      PREMIUM_TICKETING_REVENUE_NON_SETTLED: 'TotalPoolIncomePremiumTicketingRevenue_NonSettled',
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalPoolIncomeManualAdjustment_Text_',
        SETTLED: 'TotalPoolIncomeManualAdjustment_Settled_',
        NON_SETTLED: 'TotalPoolIncomeManualAdjustment_NonSettled_',
      },
    },
    TOTAL_ARTIST_EARNINGS: {
      MAX_MANUAL_ADJUSTMENTS: 6,
      SPLIT_POINT_SETTLEMENT: 'TotalArtistEarningsSplitPoint_Settlement',
      TOTAL_SPLIT_POINT_SETTLEMENT: 'TotalArtistEarningsTotalSplitPoint_Settlement',
      MANUAL_ADJUSTMENTS: {
        NAME: 'TotalArtistEarningsManualAdjustment_Text_',
        SETTLED: 'TotalArtistEarningsManualAdjustment_Settled_',
        NON_SETTLED: 'TotalArtistEarningsManualAdjustment_NonSettled_',
      },
    },
  },
};
