import * as yup from 'yup';
import { UserRoles } from '@types';

const sharedFields = {
  user: yup.object().shape({
    name: yup.string().required('Please enter a user.'),
  }),
};

export const addAdminValidation = () => yup.object().shape(sharedFields);

export const addUserValidation = () => yup.object().shape({
  ...sharedFields,
  role: yup.string().required('Please select a role.'),
  company: yup.string().when('role', {
    is: (role: string) => role !== UserRoles.VenueAdmin,
    then: (sch) => sch.required('Please select a company.'),
    otherwise: (sch) => sch.notRequired(),
  }),
});
