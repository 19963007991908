import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { ElvisTheme } from '@services/elvis-theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EnvConfigProvider } from '@providers';
import { EnvConfig } from '@types';
import { ModernElvisRoutes } from './routes';

type AppProps = {
  envConfig: EnvConfig;
};

export function App({ envConfig }: AppProps) {
  return (
    <ThemeProvider theme={ElvisTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EnvConfigProvider envConfig={envConfig}>
          <CssBaseline />
          <BrowserRouter>
            <ModernElvisRoutes />
          </BrowserRouter>
        </EnvConfigProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
