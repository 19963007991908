import { styled } from '@mui/material/styles';
import { Button, DialogActions, CircularProgress } from '@mui/material';

export const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const ButtonStyled = styled(Button)({
  textTransform: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:disabled > div': {
    color: 'transparent',
  },
});

export const LoadingIconStyled = styled(CircularProgress)({
  color: 'inherit',
  position: 'absolute',
});

export const AlertList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  margin: 0,
}));

export const DialogDescription = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
}));
