/* eslint-disable max-lines-per-function */
import { MouseEvent, useState } from 'react';
import { SwitchInput } from '@components';
import { GET_BUNDLE_OFFER_SUMMARY_DOWNLOAD_URL } from '@gql/mutations/pdf';
import { Button, Popover } from '@mui/material';
import Download from '@mui/icons-material/Download';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import { ContentContainer, EyebrowLabel, StyledDivider } from './PDFDownloadButton.styled';
import { PDFDownloadLink } from './PDFDownloadButton.link';

type PDFDownloadButtonProps = {
  bundleId: string,
  offerId: string,
};

export function PDFDownloadButton({ bundleId, offerId }: Readonly<PDFDownloadButtonProps>) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const [includeEventDates, setIncludeEventDates] = useState(true);
  const [includeLocalBuyers, setIncludeLocalBuyers] = useState(true);
  const [includeInactiveOffers, setIncludeInactiveOffers] = useState(true);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleCloseAnimationExited = () => {
    /* If we reset the state in handleClose it makes the animation weird,
       so we wait for the animation to finish before doing it. */
    setIncludeEventDates(true);
    setIncludeLocalBuyers(true);
    setIncludeInactiveOffers(true);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleButtonClick}
        endIcon={<KeyboardArrowDown />}
        >
          Download PDF
      </Button>
      <Popover
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={Boolean(anchorElement)}
        onTransitionExited={handleCloseAnimationExited}
      >
        <ContentContainer>
          <EyebrowLabel variant="caption-small">Customize</EyebrowLabel>
          <SwitchInput
            checked={includeEventDates}
            label='Include Event Dates'
            onChange={setIncludeEventDates}
          />
          <SwitchInput
            checked={includeLocalBuyers}
            label='Include Local Buyers'
            onChange={setIncludeLocalBuyers}
          />
          <SwitchInput
            checked={includeInactiveOffers}
            label='Include Inactive Offers'
            onChange={setIncludeInactiveOffers}
          />
        </ContentContainer>
        <StyledDivider />
        <ContentContainer direction="row">
          {/* TODO: Use PDFDownloadLink here, should be implemented in SANE-8184 */}
          <Button
            variant="text"
            size="large"
            endIcon={<Download />}
            disabled
          >
            Offer Detail
          </Button>
          <PDFDownloadLink
            field='getBundleOfferSummaryDownloadUrl'
            mutation={GET_BUNDLE_OFFER_SUMMARY_DOWNLOAD_URL}
            mutationInput={{
              bundleId,
              offerId,
              userTimezone: dayjs.tz.guess(),
              includeEventDates,
              includeInactiveOffers,
              includeLocalBuyers,
            }}
            title='Offer Summary'
          />
        </ContentContainer>
      </Popover>
    </>
  );
}
