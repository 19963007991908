import { SpaceConfiguration } from '@gql/types/graphql';
import { GridCellParams, GridRowParams } from '@mui/x-data-grid-pro';

export interface VenueSearchProps {
  showGridInfo?: boolean;
  disableMarketSearch?: boolean;
  onVenueSelect?: VenueRowHandler;
  venueSelectLabel: string;
  onVenueRowClick?: VenueRowHandler;
}

export type VenueRowHandler = (
  params: GridRowParams<VenueGridData> | GridCellParams<VenueGridData>,
  spaceConfigurationId?:string
) => Promise<void> | void;

export type VenueGridData = {
  id: string | null | undefined;
  venueId: string | null | undefined;
  venueName: string;
  market: string;
  maxCapacity: string | undefined;
  configurations: SpaceConfiguration[] | undefined;
  updatedDate?: string;
  spaceType: string | undefined;
  metroArea: string | undefined;
};

export enum VenueSearchOptions {
  name = 'name',
  market = 'market',
}
