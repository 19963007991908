import { FeeItem, SpaceConfiguration } from '@gql/types/graphql';
import { ValidationErrorInfo } from './ValidationErrorInfo';

export interface FeeItemRow {
  id: string
  feeName: string;
  category?: string | null;
  categoryParent?: string | null;
  formula?: string | null;
  max?: string | number | null;
  min?: string | number | null;
  note?: string | null;
  profitLossCategoryId: string;
  subCategory?: string | null;
  type: string;
  value?: string | number | null;
}

export interface SpaceConfigWithErrors extends SpaceConfiguration {
  errors?: ValidationErrorInfo[];
}

export interface FeeItemWithErrors extends FeeItem {
  errors?: ValidationErrorInfo[];
}

export enum FeeItemTypes {
  PERCENTAGE = 'Percentage',
  FIXED_AMOUNT = 'Fixed Amount',
}

export enum ConfigGridFields {
  NAME = 'name',
  CONFIG_TYPE = 'configType',
  TIERS = 'tiers',
  CAPACITY = 'capacity',
  DESCRIPTION = 'description',
}
