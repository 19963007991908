import {
  Button, ButtonProps, styled,
} from '@mui/material';

export const CreateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(1, 2.75),
  display: 'inline-flex',
  marginRight: theme.spacing(2),
}));
