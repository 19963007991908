import { GridColDef } from '@mui/x-data-grid-pro';

import {
  StatusChip,
  VenueNameCell,
  LeftPinnedCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import { EventProjection } from '@types';
import { getUserTimezoneDate } from '@utils/stringHelpers';
import { formatNumberToCurrency, formatNumberToPercentage } from '@utils/numberHelpers';
import { leftPinnedColumn } from '@utils/datagridHelpers';

export type ProjectionRow = EventProjection & {
  isTotalsRow?: boolean;
  isBottomRow?: boolean;
  name?: string;
};
type ProjectionColumns = GridColDef<ProjectionRow>[];

export const projectionColumns: ProjectionColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    headerName: 'Event',
    renderCell: ({ row }) => (row.isTotalsRow ? (
      <TextWrapPrettyCell fontWeight={500}>{row.name}</TextWrapPrettyCell>
    ) : (
      <LeftPinnedCell>
        <VenueNameCell venue={row.venue} />
        <EventOfferActionsCell
          updatedDate={row.updatedDate}
          // eventId={row.eventId}
          offerId={row.offerId}
          // settlementId={row.settlementId}
        />
      </LeftPinnedCell>
    )),
  },
  {
    field: 'showsCount',
    headerName: 'Shows',
    flex: 0.035,
    minWidth: 85,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.035,
    minWidth: 120,
    renderCell: ({ row }) => !row.isTotalsRow && <StatusChip status={row.status} />,
  },
  {
    field: 'date',
    headerName: 'Date',
    flex: 0.035,
    minWidth: 120,
    valueGetter: ({ row }) => getUserTimezoneDate(row.date, 'L')[0],
  },
  {
    field: 'sellableCapacity',
    headerName: 'Sellable Capacity',
    flex: 0.035,
    minWidth: 155,
    valueGetter: ({ row }) => row.sellableCapacity?.toLocaleString(),
  },
  {
    field: 'ticketSales',
    headerName: 'Ticket Sales',
    flex: 0.035,
    valueGetter: ({ row }) => row.ticketSales?.toLocaleString(),
    minWidth: 125,
  },
  {
    field: 'salesPercentage',
    headerName: 'Sales %',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToPercentage(row.salesPercentage),
    minWidth: 90,
  },
  {
    field: 'method',
    headerName: 'Method',
    flex: 0.035,
    minWidth: 85,
  },
  {
    field: 'gross',
    headerName: 'Gross',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.gross),
    minWidth: 130,
  },
  {
    field: 'averageGrossPrice',
    headerName: 'Avg Gross Price',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.averageGrossPrice, {
      nullishValue: '',
    }),
    minWidth: 150,
  },
  {
    field: 'netGross',
    headerName: 'Net Gross',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.netGross),
    minWidth: 125,
  },
  {
    field: 'averageNetPrice',
    headerName: 'Avg Net Price',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.averageNetPrice, {
      nullishValue: '',
    }),
    minWidth: 140,
  },
  {
    field: 'poolAdjustments',
    headerName: 'Pool Adjustments',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.poolAdjustments),
    minWidth: 160,
  },
  {
    field: 'fixedCosts',
    headerName: 'Fixed Costs',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.fixedCosts),
    minWidth: 130,
  },
  {
    field: 'variableCosts',
    headerName: 'Variable Costs',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.variableCosts),
    minWidth: 130,
  },
  {
    field: 'totalShowExpenses',
    headerName: 'Total Show Expenses',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.totalShowExpenses),
    minWidth: 175,
  },
  {
    field: 'netShowReceipts',
    headerName: 'Net Show Receipts',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.netShowReceipts),
    minWidth: 155,
  },
  {
    field: 'guarantee',
    headerName: 'Guarantee',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.guarantee),
    minWidth: 130,
  },
  {
    field: 'overage',
    headerName: 'Overage',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.overage),
    minWidth: 130,
  },
  {
    field: 'artistEarnings',
    headerName: 'Artist Earnings',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.artistEarnings),
    minWidth: 140,
  },
  {
    field: 'promoterProfit',
    headerName: 'Promoter Profit',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.promoterProfit),
    minWidth: 145,
  },
  {
    field: 'ancillaries',
    headerName: 'Ancillaries',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.ancillaries),
    minWidth: 130,
  },
  {
    field: 'eventPL',
    headerName: 'Event P&L',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.eventPL),
    minWidth: 130,
  },
  {
    field: 'coProShare',
    headerName: 'Co-Pro Share',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.coProShare),
    minWidth: 135,
  },
  {
    field: 'aegPL',
    headerName: 'AEG P&L',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.aegPL),
    minWidth: 125,
  },
  {
    field: 'avgBreakEven',
    headerName: 'Avg Break-Even',
    flex: 0.035,
    valueGetter: ({ row }) => row.avgBreakEven.toLocaleString(),
    minWidth: 145,
  },
  {
    field: 'seqBreakEven',
    headerName: 'Seq Break-Even',
    flex: 0.035,
    valueGetter: ({ row }) => row.seqBreakEven.toLocaleString(),
    minWidth: 145,
  },
  {
    field: 'bonusAllocation',
    headerName: 'Bonus Allocation',
    flex: 0.035,
    valueGetter: ({ row }) => formatNumberToCurrency(row.bonusAllocation, {
      zeroValue: '-',
    }),
    minWidth: 150,
  },
];
