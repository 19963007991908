import React from 'react';

export interface Breadcrumb {
  text: string;
  href: string;
}

// Create two context:
// BreadcrumbContext: to query the context state
// BreadcrumbDispatchContext: to mutate the context state
const BreadcrumbContext = React.createContext<Breadcrumb[]>([]);
const BreadcrumbDispatchContext = React.createContext<
React.Dispatch<React.SetStateAction<Breadcrumb[]>>
>(() => {});

type ProviderProps = {
  children: React.ReactNode;
};
function BreadcrumbProvider({ children }: ProviderProps) {
  const [breadCrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([]);

  return (
    <BreadcrumbContext.Provider value={breadCrumbs}>
      <BreadcrumbDispatchContext.Provider value={setBreadcrumbs}>
        {children}
      </BreadcrumbDispatchContext.Provider>
    </BreadcrumbContext.Provider>
  );
}

export { BreadcrumbProvider, BreadcrumbContext, BreadcrumbDispatchContext };
