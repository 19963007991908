import {
  CustomTheme, TableCell, TableRow, styled,
} from '@mui/material';

export const ColoredTableRow = styled(TableRow)(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.states?.hover,
}));

export const BorderlessLastTableRow = styled(TableRow)(({ theme }: { theme?: CustomTheme }) => ({
  borderColor: theme?.palette.primary.main,
  '&:last-of-type td, &:last-of-type th': { border: 0 },
}));

export const NOSTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1.75),
  paddingBottom: theme.spacing(1.75),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  '&:first-of-type': {
    paddingLeft: theme.spacing(1),
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(1),
  },
  '&:only-of-type': {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
  },
}));
