import { graphql } from '@gql/types';

export const SYNC_MODELING_EVENT_DATA = graphql(`
  mutation SyncModelingWorkbookEventData(
    # expense
    $expense: ExpenseInput!
    # event
    $event: ModifyEventInput!
  ) {
    modifyEvent(event: $event) {
      id
    }
    addOrUpdateEventExpense(expense: $expense) {
      id
    }
  }
`);

export const SYNC_MODELING_BOX_OFFICE_DATA = graphql(`
  mutation SyncModelingWorkbookBoxOfficeData(
    # Box Office
    $boxOfficeId: ID!
    $boxOfficePhaseId: ID!
    $boxOfficePhase: ModifyBoxOfficePhaseInput!
  ) {
    addOrUpdateBoxOfficePhase(
      boxOfficeId: $boxOfficeId
      boxOfficePhaseId: $boxOfficePhaseId
      boxOfficePhase: $boxOfficePhase
    ) {
      id
    }
  }
`);

export const SYNC_MODELING_OFFER_DATA = graphql(`
  mutation SyncModelingWorkbookOfferData(
    # Offer
    $tourId: ID!,
    $offerId: ID!,
    $offer: ModifyOfferInput!,
    # $eventIdsToUpdateStatus: [ID]
    # tour financial summary
    $summary: TourFinancialSummaryInput!
  ) {
    modifyOffer(
      tourId: $tourId,
      offerId: $offerId,
      offer: $offer,
    ) {
      id
      events {
        id
        status
      }
    }
    addOrUpdateTourFinancialSummary(summary: $summary) {
      id
    }
  }
`);
