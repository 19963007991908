import { ErrorOption } from 'react-hook-form';
import { ArtistInformation } from '@types';
import { Bundle, Genre } from '@gql/types/graphql';

export const MAX_CHARS_BUNDLE_NAME = 75;

export const EDIT_BUNDLE_TEXT = {
  title: 'Edit Bundle Details',
  subTitle: 'Edit the details below to update the bundle.',
  submitText: 'Save',
};

export interface EditBundleFormFields {
  id: string;
  companyId: string;
  name: string;
  genres: Genre[];
  headliners: ArtistInformation[];
  tourNotes: string;
}

export type EditBundleFormErrors = {
  companyId: ErrorOption[];
  name: ErrorOption[];
  headliner: ErrorOption[];
  genres: ErrorOption[];
  agency: ErrorOption[];
  agents: ErrorOption[];
};

export const getDefaultValues = (bundle: Bundle): EditBundleFormFields => {
  const {
    id, name, headliners, company, genres,
  } = bundle;

  const headlinersMapped = headliners?.map((headliner) => ({
    headliner: {
      id: headliner.artist?.id ?? '',
      name: headliner.artist?.name ?? '',
    },
    agency: {
      id: headliner.agency?.id ?? '',
      name: headliner.agency?.name ?? '',
    },
    agents: headliner.agents?.map((agent) => ({
      id: agent?.id ?? '',
      name: agent?.name ?? '',
      email: agent?.email ?? '',
      phone: agent?.phone ?? '',
    })) ?? [],
  }));

  return {
    id,
    name: name ?? '',
    companyId: company?.id ?? '',
    headliners: headlinersMapped ?? [],
    genres: genres ?? [],
    tourNotes: bundle.tourNotes ?? '',
  };
};
