import { graphql } from '@ooagg-gql/types';

export const SEARCH_USERS = graphql(`
  query SearchUsers($searchTerm: String!) {
    users(searchTerm: $searchTerm) {
      id
      name
      email
    }
  }
`);

export const GET_BOOKERS_BY_COMPANY_ID = graphql(`
  query GetBookersByCompanyId ($companyId: String!) {
    getBookersByCompanyId (companyId: $companyId) {
      id
      name
      email
    }
  }
`);
