import { graphql } from '@gql/types';

export const ADD_AGENT = graphql(`
    mutation CreateAgent($agent: AddAgentInput!) {
        addAgent(agent: $agent) {
            id
            name
            email
            phone
        }
    }
`);

export const UPDATE_AGENT = graphql(`
    mutation ModifyAgent($agent: ModifyAgentInput!) {
        modifyAgent(agent: $agent) {
            id
            name
            email
            phone
        }
    }
`);
