import { graphql } from '@gql/types';

export const GET_NOS_SETTLEMENT_DOWNLOAD_URL = graphql(`
  query GetNosSettlementDownloadUrl($nosSettlementId: ID!) {
    getNOSSettlementDownloadUrl(nosSettlementId: $nosSettlementId) {
      url
    }
  }
`);

export const GET_PRIMARY_NOS_SETTLEMENT_DOWNLOAD_URL = graphql(`
  query GetPrimaryNosSettlementDownloadUrl($eventId: ID!) {
    getPrimaryNOSSettlementDownloadUrl(eventId: $eventId) {
      url
    }
  }
`);

export const GET_NOS_SETTLEMENT_LAUNCH_URL = graphql(`
  query GetNosSettlementLaunchUrl($nosSettlementId: ID!) {
    getNOSSettlementLaunchUrl(nosSettlementId: $nosSettlementId) {
      url
    }
  }
`);

export const GET_PRIMARY_NOS_SETTLEMENT_LAUNCH_URL = graphql(`
  query GetPrimaryNOSSettlementLaunchUrl($eventId: ID!) {
    getPrimaryNOSSettlementLaunchUrl(eventId: $eventId) {
      url
    }
  }
`);

export const GET_NOS_WITH_EVENT = graphql(`
query GetEventWithNOSId($nosId: ID!) {
  nosSettlement(nosSettlementId: $nosId) {
    id
    artistSettlement {
      localCurrency
      baseCurrency
      artistSettlement {
        guarantee
        pctOfNetPoolEarnings
        artistAdjustmentNote
        artistAdjustmentTotal
      }
      notesAndComments
    }
    internalSettlement {
      localCurrency
      baseCurrency
    }
    populatedShows {
      id
      showDateTime
      boxOffice {
        id
        phases {
          id
          fees {
            profitLossCategoryId
            formula
            amount
            notes
          }
        }
      }
    }
    populatedExpensePhase {
      id
      fixedCategoryItems {
        categoryId
        items {
          id
          budget
          notes
        }
      }
      variableCategoryItems {
        categoryId
        items {
          id
          rate
          minimum
          maximum
          calculatedCost
        }
      }
    }
    event {
      id
      name
      status
      date
      headliner {
        id
        name
      }
      exchangeRate
      baseCurrency
      localCurrency
      venue {
        id
        name
        city
        stateCode
        timezone
        country
      }
      space {
        id
        name
      }
      tourInformation {
        numberOfTrucks
        tourName
      }
    }
  }
}
`);

export const GET_NOS_LAUNCH_URL = graphql(`
  query GetNosLaunchUrl($nosSettlementId: ID!) {
    getNOSSettlementLaunchUrl(nosSettlementId: $nosSettlementId) {
      url
    }
  }
`);

export const GET_NOS_DETAILS = graphql(`
  query GetNosWithEventForAddin($nosId: ID!) {
    nosSettlement(nosSettlementId: $nosId) {
      id
      submittedBy {
        name
      }
      submittedOn
      updatedDate
      event {
        date
        space {
          name
          type
        }
        status
        venue {
          city
          stateCode
          timezone
        }
      }
    }
  }
`);

export const CAN_CREATE_NOS_VERSION = graphql(`
  query CanCreateNOSVersion($eventId: ID!) {
    canCreateNOSVersion(eventId: $eventId)
  }
`);

export const NOS_SETTLEMENTS_EMAIL_MODAL = graphql(`
  query NOSSettlementEmail(
    $tourId: ID!,
    $expenseId: ID!,
    $nosSettlementId: ID!,
    $expensePhaseId: String!,
  ) {
    tour(tourId: $tourId) {
      id
      currency
      nosEmailDistributionList {
        id
        name
        email
      }
    }
    expense(expenseId: $expenseId) {
      id
      phases(id: $expensePhaseId) {
        id
        ancillaryCategoryItems {
          total
        }
      }
    }
    nosSettlement(nosSettlementId: $nosSettlementId) {
      id
      artistSettlement {
        netShowReceipts {
          netGrossReceiptsTotalToBase
          netShowReceiptsTotalToBase
        }
        artistSettlement {
          artistNosSettlementTotalToBase
        }
        actualBoxOfficeOverview {
          totalSellableCapacity
          totalGrossToBase
        }
      }
      internalSettlement {
        aegSettlement {
          netProfitOrLossToAegTotalToBase
        }
        netToPool {
          projected
          actual
          variance
        }
      }
      event {
        baseCurrency
      }
    }
  }
`);
