import { useState } from 'react';
import { Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './Accordion.styled';

export interface AccordionProps {
  header: string;
  buttonComponent?: React.ReactNode;
  showDivider?: boolean;
  isDisabled?: boolean;
  isExpanded?: boolean;
  children: React.ReactNode;
  headerBottomComponent?: React.ReactNode;
}

export function Accordion({
  header,
  buttonComponent,
  showDivider = false,
  isDisabled = false,
  isExpanded = false,
  children,
  headerBottomComponent,
}: AccordionProps) {
  const [accordionOpen, setAccordionOpen] = useState(isExpanded);

  return (
  <StyledAccordion disableGutters disabled={isDisabled} expanded={accordionOpen}>
    <StyledAccordionSummary
     expandIcon={
      <ExpandMoreIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => setAccordionOpen(!accordionOpen)} />
      }
      aria-controls="panel1-content"
      id="panel1-header"
    >
      {header}
      {buttonComponent}
    </StyledAccordionSummary>
    {headerBottomComponent}
    {showDivider && <Divider />}
    <StyledAccordionDetails>
      {children}
    </StyledAccordionDetails>
  </StyledAccordion>
  );
}
