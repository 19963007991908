/* eslint-disable arrow-body-style */
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { StatusChip } from '../../shared';

import {
  TourName,
  Header,
  HorizontalRule,
  RowContainer,
  OfferStatusChip,
} from './TaskPaneHeader.styled';

type TaskPaneHeaderProps = {
  loading: boolean;
  actions: ReactNode;
  tourName?: string;
  offerName?: string | null;
  offerStatus?: string | null;
  children?: React.ReactNode;
};

export const TaskPaneHeader = ({
  loading,
  actions,
  tourName,
  offerName,
  offerStatus,
}: TaskPaneHeaderProps) => {
  return loading ? null : (
    <>
      <Header>
        <TourName variant="h6" data-testid="tour-name">
          {tourName}
        </TourName>
        {actions}
      </Header>
      <HorizontalRule />
      <RowContainer>
        <Typography variant="body1-normal" data-testid="offer-name">
          {offerName}
        </Typography>
        <OfferStatusChip data-testid="offer-status">
          <StatusChip status={offerStatus as string} />
        </OfferStatusChip>
      </RowContainer>
    </>
  );
};
