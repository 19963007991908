import {
  PROFIT_LOSS_REF_SHEET_NAME,
  PROFIT_LOSS_REF_SHEET_TABLES,
  TABLE_IDENTIFIERS,
  EXPENSE_CATEGORY_IDS,
  WorkbookReferences,
} from './constants';
import { generateTableName } from './helpers';
import { ParseTableFunction, ReferenceSheetItem, SheetTables } from './types';

export const initializeTableGetter = (parseTable: ParseTableFunction) => async (
  sheetName: string,
  tableName: string,
): Promise<ReferenceSheetItem[]> => {
  const response = await parseTable(sheetName, tableName);
  if (!response) {
    console.log(
      'getDataFromReferenceTable parseTable failure for',
      sheetName,
      tableName,
    );
    return [];
  }
  return response.map((item) => {
    const objectValues = Object.values(item);
    return {
      id: objectValues?.[0] as string,
      name: objectValues?.[1] as string,
    };
  });
};

// eslint-disable-next-line max-lines-per-function
export const AEGSharedTables = (
  parseTable: ParseTableFunction,
  workbookRef: string, // WorkbookReferences.MODELING or WorkbookReferences.NOS_SETTLEMENT
): SheetTables => {
  const getDataFromReferenceTable = initializeTableGetter(parseTable);
  const { FIXED_COSTS_REF_TABLE } = PROFIT_LOSS_REF_SHEET_TABLES;
  const fixedExpenseTableNames = TABLE_IDENTIFIERS.fixedExpenseCategories[workbookRef];
  const variableExpenseTableNames = TABLE_IDENTIFIERS.variableExpenseCategories[workbookRef];

  const { VARIABLE_COST, FIXED_COST_PARENT_CATEGORIES } = EXPENSE_CATEGORY_IDS;

  const tables = {
    advertising: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.advertising),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.ADVERTISING,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.ADVERTISING,
    }),
    artistProrates: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.artistProrates),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.ARTIST_PRORATE,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.ARTIST_PRORATES,
    }),
    catering: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.catering),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.CATERING,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.CATERING,
    }),
    otherCosts: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.otherCosts),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.OTHER_COSTS,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.OTHER_COSTS,
    }),
    production: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.production),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.PRODUCTION,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.PRODUCTION,
    }),
    productionProrates: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.productionProrates),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.PRODUCTION_PRORATES,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.PRODUCTION_PRORATES,
    }),
    rent: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.rent),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.RENT,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.RENT,
    }),
    staffing: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.staffing),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.STAFFING,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.STAFFING,
    }),
    stagehands: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.stagehands),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.STAGEHANDS,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.STAGEHANDS,
    }),
    supports: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.supports),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.SUPPORTS,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.SUPPORTS,
    }),
    transport: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.transport),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.TRANSPORT,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.TRANSPORT,
    }),
    venue: async (sheetName: string) => ({
      name: generateTableName(sheetName, fixedExpenseTableNames.venue),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        FIXED_COSTS_REF_TABLE.VENUE,
      ),
      categoryId: FIXED_COST_PARENT_CATEGORIES.VENUE,
    }),
  };

  if (workbookRef === WorkbookReferences.NOS_SETTLEMENT_BREAKDOWN) {
    return {
      ...tables,
      variableCosts: async (sheetName: string) => ({
        name: generateTableName(sheetName, variableExpenseTableNames.variableCosts),
        data: [],
        referenceTable: await getDataFromReferenceTable(
          PROFIT_LOSS_REF_SHEET_NAME,
          PROFIT_LOSS_REF_SHEET_TABLES.VARIABLE_COSTS,
        ),
        categoryId: VARIABLE_COST,
      }),
    };
  }

  return tables;
};
