import React, { useContext } from 'react';
import {
  WebUserProvider,
  EnvConfigContext,
  BreadcrumbProvider,
  NotificationProvider,
  EnvStatusProvider,
  DialogProvider,
} from '@providers';
import { createMsalConfig } from '@services/msal-config';
import { MsalProvider } from '@azure/msal-react';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@services/apollo-client';
import { Outlet } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, initializeAppInsights } from '@services/telemetry-service';

export const WebContextLayout = () => {
  const envConfig = useContext(EnvConfigContext);
  initializeAppInsights('WebContext', envConfig.APP_INSIGHTS_INSTRUMENTATION_KEY);

  const msalConfig = createMsalConfig(envConfig);
  const apolloConfig = createApolloClient({
    envConfig,
    getBearerToken: msalConfig.getBearerToken,
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <EnvStatusProvider
        environmentStatus={{
          mode: 'web',
          isAutomationEnabled: false,
          isExcelReady: false,
        }}
      >
        <MsalProvider instance={msalConfig.msalInstance}>
          <NotificationProvider>
            <DialogProvider>
              <BreadcrumbProvider>
                <ApolloProvider client={apolloConfig.globalToursClient}>
                  <WebUserProvider
                  intercomEnabled={envConfig.INTERCOM_ENABLED}
                  intercomAppId={envConfig.INTERCOM_APP_ID}
                  >
                    <Outlet />
                  </WebUserProvider>
                </ApolloProvider>
              </BreadcrumbProvider>
            </DialogProvider>
          </NotificationProvider>
        </MsalProvider>
      </EnvStatusProvider>
    </AppInsightsContext.Provider>
  );
};
