import { TourFormTypes } from '@types';

export interface TourFormText {
  title: string;
  subTitle: string;
  submitText: string;
}

export const CREATE_TOUR_TEXT = {
  title: 'Create a Tour',
  subTitle: 'Please provide the details below to create a new tour.',
  submitText: 'Next',
};

export const MEMBER_FORM_TEXT = {
  title: 'Create a Tour: Add Team Members',
  subTitle:
    'Operators and Buyers will have immediate access and edit rights to this tour.',
  submitText: 'Create',
};

export const tourOptions = [
  { value: TourFormTypes.tour, label: 'Tour' },
  { value: TourFormTypes.multiLeg, label: 'Multi-Leg Tour' },
];
