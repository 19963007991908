import { useLazyQuery, useMutation } from '@apollo/client';
import {
  SnackBarListItem, SnackbarList, SnackbarType, SnackbarTitle,
} from '@components';
import { SHARE_WITH_MARKETING } from '@gql/mutations/maestro';
import { GET_TOUR_AND_OFFER_ONLY } from '@gql/queries/tours';
import { ConfirmationInfo } from '@gql/types/graphql';
import { Box } from '@mui/material';
import { NotificationDispatchContext } from '@providers';
import { OfferStatus } from '@types';
import { useCallback, useContext } from 'react';

// eslint-disable-next-line max-lines-per-function
export function useMaestro(tourId?: string | null, offerId?: string | null) {
  const setNotification = useContext(NotificationDispatchContext);
  const [getTour] = useLazyQuery(GET_TOUR_AND_OFFER_ONLY, {
    fetchPolicy: 'cache-first',
  });

  const [
    shareWithMarketing,
  ] = useMutation(SHARE_WITH_MARKETING, {
    errorPolicy: 'ignore',

    async onError() {
      const tourQuery = await getTour({
        variables: { tourId: tourId ?? '', offerId },
      });
      const tour = tourQuery.data?.tour;
      const offer = tour?.offers?.find((off) => off?.id === offerId);

      if (offer?.status === OfferStatus.Draft) {
        return;
      }

      const snackbarMessage = (
        <>
          <span>We were unable to share your Routing Data with M.</span>
          <br/><br/>
          {offer?.status === OfferStatus.RoutingConfirmed
          && <span>To resolve the issue, please Share Routing with Marketing again.</span>}
          {offer?.status !== OfferStatus.RoutingConfirmed
          && <span>To resolve the issue, please Confirm your events again.</span>}
        </>
      );

      setNotification({
        type: SnackbarType.ERROR,
        text: snackbarMessage,
      });
    },
  });

  /**
   * _**IMPORTANT!**_ This function is used to share data with marketing. It will
   * internally handle errors from the mutation it calls and alert the user.
   * If you are performing an action that will sync excel data, there is no
   * need to explicitly call this function. Any other actions that will mutate a
   * tour, offer, event, or show and will not sync with modern elvis either
   * before or after completed need to call this function directly
   * after execution in an onComplete method.
   */

  const handleShareWithMarketing = useCallback(
    async (canShareWithMarketing?: ConfirmationInfo | null) => {
      if (canShareWithMarketing?.requirements?.length) {
        setNotification({
          type: SnackbarType.WARNING,
          duration: null,
          text: (
            <Box>
              <SnackbarTitle>We were unable to share this tour with marketing.</SnackbarTitle>
              <SnackbarTitle>
                To resolve this issue, please make the following changes and retry.
              </SnackbarTitle>
              <SnackbarList>
                { canShareWithMarketing?.requirements?.map(
                  (item, index) => <SnackBarListItem key={`m-requirement-info-${index}`}>
                      {`${index + 1}. ${item}`}
                    </SnackBarListItem>,
                )}
              </SnackbarList>
            </Box>
          ),
        });

        return;
      }

      if (tourId && offerId) {
        await shareWithMarketing({
          variables: {
            tourId,
            offerId,
          },
        });
      } else {
        console.warn('No tourId or offerId found.');
      }
    },
    [shareWithMarketing, tourId, offerId],
  );

  return {
    handleShareWithMarketing,
  };
}
