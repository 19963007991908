import { VenueDatagrid } from '@components';
import { useContext } from 'react';
import { VenueManagementContext } from '@providers';
import { FeeItemRow } from '@types';
import { DEFAULT_TAXES, TAXES } from '@utils/venueHelpers';
import { kebabCase } from '@utils/stringHelpers';
import { VenueDataGridContainer, VenueGridTitle } from './VenueDatagrids.styled';
import { VenueGridProps, taxesColumns } from './VenueDatagrids.models';

export const TaxesGrid = ({ disableVirtualization }: VenueGridProps) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space) return null;
  const { setFeeItem, data: { space } } = context;

  const spaceTaxes = space.fees?.find(
    (fee) => fee.name === TAXES,
  );

  const rows: FeeItemRow[] = DEFAULT_TAXES.map((defaultFee, index) => {
    const existingFee = spaceTaxes?.items?.find((tax) => tax?.subCategory === defaultFee.subCategory);
    return ({
      ...defaultFee,
      ...existingFee,
      formula: existingFee?.formula || defaultFee.formula,
      type: existingFee?.type || defaultFee.type,
      feeName: spaceTaxes?.name || TAXES,
      id: `tax-item-${kebabCase(defaultFee.subCategory as string)}-${index}`,
      profitLossCategoryId: existingFee?.profitLossCategoryId || '',
    });
  });

  return (
    <VenueDataGridContainer>
      <VenueGridTitle variant="h6" data-testid="taxes-grid-title">Taxes</VenueGridTitle>
      <VenueDatagrid
        autoHeight
        disableVirtualization={disableVirtualization}
        hideFooter
        disableRowSelectionOnClick
        rows={rows || []}
        columns={taxesColumns}
        processRowUpdate={setFeeItem}
        data-testid="taxes-grid"
        editMode="row"
      />
    </VenueDataGridContainer>
  );
};
