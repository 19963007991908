/* eslint-disable max-lines-per-function */
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  DetailsMetadataItem,
  DetailsPageTitle,
  DropdownButton,
} from '@components';
import { PageDetailLayout, WrapperType } from '@layouts/PageDetailLayout';
import {
  BreadcrumbDispatchContext,
} from '@providers';
import { getTimezoneDate } from '@utils/stringHelpers';
import { OneOffSummary } from '../../components/OneOffSummary/OneOffSummary';
import { OneOff, mockOneOffDetailData } from './mockOneOffDetails';

enum OneOffDropdownOptions {
  CancelEvent,
  KillEvent,
}

export function OneOffDetails() {
  // Remove state and mock data after API integration
  // View bundle details for implementation example
  const [oneOffDetails, setOneOffDetails] = useState<OneOff | null>(mockOneOffDetailData);

  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);

  const oneOffId = useParams().id ?? '';

  const metadataItems = useMemo(() => {
    if (!oneOffDetails) return [];

    const {
      ticketCount,
      company,
      primaryBooker,
      eventType,
      genre,
      firstShow,
      eventNotes,
    } = oneOffDetails;

    const metadata = [
      {
        title: 'Ticket Count',
        value: ticketCount?.toLocaleString() || '',
      },
      {
        title: 'Company',
        value: company?.name ?? '',
      },
      {
        title: 'Primary Booker',
        value: primaryBooker?.name ?? '',
      },
      {
        title: 'Event Type',
        value: eventType || '',
      },
      {
        title: 'Genre',
        value: genre || '',
      },
      {
        title: 'First Show',
        value: getTimezoneDate(firstShow)[0],
      },
      {
        title: 'Event Notes',
        value: eventNotes || '',
      },
    ];

    return metadata.map((metadataItem, index) => (
      <DetailsMetadataItem
      key={`details-metadata-item-${index}`}
      metadata={metadataItem}
      last={index === metadata.length - 1}
    />
    ));
  }, [oneOffDetails]);

  const handleDropdownMenu = (value: OneOffDropdownOptions) => {
    if (!oneOffDetails) {
      return;
    }
    switch (value) {
      case OneOffDropdownOptions.CancelEvent:
        break;
      case OneOffDropdownOptions.KillEvent:
        break;
      default:
        break;
    }
  };

  const actions = (
      <DropdownButton
        label="Actions"
        onSelect={handleDropdownMenu}
        dropdownOptions={[
          {
            label: 'Cancel Event',
            value: OneOffDropdownOptions.CancelEvent,
          },
          {
            label: 'Kill Event',
            value: OneOffDropdownOptions.KillEvent,
          },
        ]}
      />
  );

  // Remove this useEffect after API integration and add logic to onCompleted from query
  // View bundle details for implementation example
  useEffect(() => {
    const breadcrumbs = [
      {
        text: 'One-Offs',
        href: '/one-offs/',
      },
    ];
    if (oneOffDetails?.name) {
      document.title = `${oneOffDetails.name} - Modern Elvis`;
      breadcrumbs.push({
        text: oneOffDetails.name,
        href: `/one-offs/${oneOffId || ''}`,
      });
    } else {
      document.title = 'Modern Elvis';
    }
    setBreadcrumbs(breadcrumbs);
  }, [oneOffDetails]);

  // Include loading state from apollo query after API integration
  if (!oneOffDetails) {
    setBreadcrumbs([]);
    return <div>Loading...</div>;
  }

  return (
    <PageDetailLayout
      pageTitle={
        <DetailsPageTitle
          title={oneOffDetails?.name || ''}
          statuses={[{ title: oneOffDetails?.status || '' }]}
        />
      }
      pageTitleColSize={2.5}
      metaColSize={8.5}
      actionsColSize={1}
      metadata={metadataItems}
      actions={actions}
      pageContent={<OneOffSummary oneOffDetails={oneOffDetails} />}
      pageContentStyle={{ height: 'auto', padding: '0' }}
      wrapperType={WrapperType.Div}
      />
  );
}
