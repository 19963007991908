import { Box, styled, Typography } from '@mui/material';

export const DateRangeContainer = styled(Box)({
  display: 'flex',
});

export const DateRangeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(2.1),
}));
