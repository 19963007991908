import { graphql } from '@gql/types';

export const TIER_SCALING_FRAGMENT = graphql(`
  fragment TierScalingFields on TierScaling {
    name
    capacity
  }
`);

export const GET_VENUES = graphql(`
  query GetVenues($searchTerm: String, $filter: VenueFilter) {
    venues(searchTerm: $searchTerm, filter: $filter) {
      id
      name
      isActive
      status
      city
      state
      stateCode
      country
      countryCode
      timezone
      localCurrencyCode
      metroArea
      spaces {
        id
        isActive
        name
        description
        maxCapacity
        type
        configurations {
          id
          name
          type
          description
          isDefault
          isActive
          totalCapacity
          tierScaling {
            ...TierScalingFields
          }
        }
        updatedDate
      }
    }
  }
`);

export const GET_VENUE = graphql(`
  query GetVenue($venueId: String!) {
    venue(venueId: $venueId) {
      id
      name
      isActive
      status
      city
      state
      stateCode
      country
      countryCode
      timezone
      localCurrencyCode
      spaces {
        id
        isActive
        name
        description
        maxCapacity
        configurations {
          id
          name
          type
          description
          isDefault
          isActive
          totalCapacity
          tierScaling {
            ...TierScalingFields
          }
        }
        updatedDate
      }
    }
  }
`);

export const GET_VENUE_AND_QD_DATA = graphql(`
  query GetVenueAndQDData($venueId: String!, $spaceId: String!) {
    venueAndSpace(venueId: $venueId, spaceId: $spaceId) {
      venue {
        id
        name
        city
        state
        stateCode
        country
        countryCode
        timezone
        localCurrencyCode
        metroArea
      }
      space {
        id
        isActive
        name
        description
        maxCapacity
        type
        configurations {
          id
          name
          type
          description
          isDefault
          isActive
          totalCapacity
          tierScaling {
            name
            capacity
            id
          }
        }
        fees {
          name
          items{
            profitLossCategoryId
            value
            category
            categoryParent
            subCategory
            type
            min
            max
            formula
            note
          }
        }
        updatedDate
      }
    }
  }
`);

export const GET_COUNTRIES = graphql(`
  query GetCountries {
    countries {
      name
      states {
        name
        cities
        stateCode
      }
      cities
      countryCode
    }
  }
`);
