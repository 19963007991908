import * as React from 'react';
import { BreadcrumbDispatchContext } from '@providers';

export function Users() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  React.useEffect(() => {
    setBreadcrumbs([
      {
        text: 'Users',
        href: '/users/',
      },
    ]);
  });

  return <div>Users Page is Under Construction</div>;
}
