import * as React from 'react';
import { BreadcrumbDispatchContext } from '@providers';

export function Notifications() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  setBreadcrumbs([
    {
      text: 'Notifications',
      href: '/notifications/',
    },
  ]);

  return <div>Notifications Page is Under Construction</div>;
}
