import { styled } from '@mui/material/styles';
import { Paper, Typography, Button } from '@mui/material';

export const ContentContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

export const GenericMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  textAlign: 'center',
  margin: '.75rem 1rem',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: '1rem',
  padding: theme.spacing(0.5, 1),
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.5),
  fontSize: theme.spacing(1.5),
  textTransform: 'capitalize',
}));
