import { graphql } from '@gql/types';

export const BUNDLE_FRAGMENT = graphql(`
  fragment BundleFields on Bundle {
    id
    name
    status
    primaryOfferId
    headliners {
      artist {
        id
        name
      }
      agency {
        id
        name
      }
      agents {
        id
        name
        email
        phone
      }
    }
    company {
      id
      name
    }
    offers {
      id
      name
      status
      crossed
      offerEventRouting {
        event {
          ...EventFields
        }
        isActive
      }
    }
    team {
      buyers {
        id
        name
        email
      }
      operators {
        id
        name
        email
      }
      viewOnlyUsers {
        id
        name
        email
      }
    }
    genres {
      id
      name
    }
  }
`);

export const ADD_BUNDLE = graphql(`
  mutation CreateBundle($bundle: AddBundleInput!) {
    addBundle(bundle: $bundle) {
      id
    }
  }
`);

export const UPDATE_BUNDLE = graphql(`
  mutation ModifyBundle($bundle: ModifyBundleInput!) {
    modifyBundle(bundle: $bundle) {
      id
      name
      tourNotes
      headliners {
        artist {
          id
          name
        }
        agency {
          id
          name
        }
        agents {
          id
          name
          email
          phone
        }
      }
      company {
        id
        name
      }
      genres {
        id
        name
      }
    }
  }
`);

export const CREATE_BUNDLE_OFFER = graphql(`
  mutation CreateBundleOffer($bundleId: ID!, $bundleOffer: CreateBundleOfferInput!) {
    createBundleOffer(bundleId: $bundleId, bundleOffer: $bundleOffer) {
      ...BundleFields
    }
  }
`);

export const EDIT_BUNDLE_OFFER = graphql(`
  mutation ModifyBundleOffer($bundleId: ID!, $bundleOffer: ModifyBundleOfferInput!) {
    modifyBundleOffer(bundleId: $bundleId, bundleOffer: $bundleOffer) {
      ...BundleFields
    }
  }
`);

export const CONFIRM_BUNDLE = graphql(`
  mutation ConfirmBundle($bundleId: ID!, $bundleOfferId: ID!) {
    confirmBundle(bundleId: $bundleId, bundleOfferId: $bundleOfferId) {
      id
      status
      primaryOfferId
      offers {
        id
        status
      }
    }
  }
`);

export const SEND_BUNDLE_CONFIRMATION_EMAIL = graphql(`
  mutation SendBundleConfirmationEmail($bundlesConfirmationEmailData: BundlesConfirmationEmailInput!) {
    sendBundlesConfirmationEmail(bundlesConfirmationEmailData: $bundlesConfirmationEmailData) {
      id
    }
  }  
 `);

export const UNCONFIRM_BUNDLE = graphql(`
  mutation UnconfirmBundle($bundleId: ID!, $bundleOfferId: ID!) {
    unconfirmBundle(bundleId: $bundleId, bundleOfferId: $bundleOfferId) {
      id
      status
      primaryOfferId
      offers {
        id
        status
      }
    }
  }
`);

export const ADD_EVENTS_TO_BUNDLE = graphql(`
  mutation AddEventsToBundle($bundleId: ID!, $bundleOfferId: ID!, $bundleEvent: [BundleEventInput!]!) {
    addEventsToBundle(bundleId: $bundleId, bundleOfferId: $bundleOfferId, bundleEvent: $bundleEvent) {
      id
      status
      offerEventRouting {
        event {
          ...EventFields
        }
        isActive
      }
    }
  }
`);

export const REMOVE_BUNDLE_EVENT = graphql(`
  mutation RemoveBundleEvent($bundleId: ID!, $bundleOfferId: ID!, $eventId: ID!) {
    removeBundleEvent(bundleId: $bundleId, bundleOfferId: $bundleOfferId, eventId: $eventId) {
      id
      offerEventRouting {
        event {
          id
        }
      }
    }
  }
`);

export const TOGGLE_ACTIVE_EVENT = graphql(`
  mutation ToggleActiveEvent($bundleId: ID!, $bundleOfferId: ID!, $eventId: ID!, $isActive: Boolean!) {
    toggleActiveEvent(
      bundleId: $bundleId, 
      bundleOfferId: $bundleOfferId, 
      eventId: $eventId, 
      isActive: $isActive
    ) {
      id
      offerEventRouting {
        event {
          id
        }
        isActive
      }
    }
  }
`);

export const MODIFY_BUNDLE_TEAM = graphql(`
  mutation ModifyBundleTeam($bundleId: ID!, $bundleTeam: BundleTeamInformationInput!) {
    modifyBundleTeam(bundleId: $bundleId, bundleTeam: $bundleTeam) {
      id
      team {
        buyers {
          id
          name
          email
        }
        operators {
          id
          name
          email
        }
        viewOnlyUsers {
          id
          name
          email
        }
      }
    }
  }
`);

export const MODIFY_OFFER_SCENARIO = graphql(`
  mutation ModifyOfferScenario($bundleId: ID!, $bundleOfferId: ID!, $scenarioId: ID!, $scenarios: [ScenarioInput!]!) {
    modifyOfferScenario(
      bundleId: $bundleId,
      bundleOfferId: $bundleOfferId,
      scenarioId: $scenarioId,
      scenarios: $scenarios
    ) {
      id
      offerEventRouting {
        isActive
        scenarios {
          eventId
          scenarioId
          projectionType
          projectionAmount
        }
        event {
          id
          name
          venue {
            id
            name
            city
            stateCode
          }
        }
      }
    }
  }
`);
