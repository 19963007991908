enum FeatureFlags {
  BUNDLES = 'BUNDLES',
  CAN_UPDATE_VENUE = 'CAN_UPDATE_VENUE',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  NOS_SETTLEMENT_EMAIL = 'NOS_SETTLEMENT_EMAIL',
  NOS_SETTLEMENT_VERSIONING = 'NOS_SETTLEMENT_VERSIONING',
  ONE_OFFS_EVENTS_LIST = 'ONE_OFFS_EVENTS_LIST',
  CAN_REFRESH_WORKBOOK = 'CAN_REFRESH_WORKBOOK',
  DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON = 'DISPLAY_DOWNLOAD_SETTLEMENT_BUTTON',
}

export default FeatureFlags;
