import { graphql } from '@gql/types';

export const TOUR_FRAGMENT = graphql(`
  fragment TourFields on Tour {
    id
    name
    primaryOfferId
    startDate
    headliner {
      name
      id
    }
    company {
      id
      name
    }
    primaryBuyer {
      id
      name
    }
    offers {
      id
      status
    }
  }
`);

export const GET_PARENT_TOUR = graphql(`
  query GetParentTour($parentTourId: ID!) {
    parentTour(parentTourId: $parentTourId) {
      id
      name
      numberOfTours
      tours {
        ...TourFields
      }
    }
  }
`);

export const SEARCH_PARENT_TOURS = graphql(`
  query SearchParentTours($searchTerm: String) {
    parentTours(searchTerm: $searchTerm) {
      name
      id
      numberOfTours
    }
  }
`);
