/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import {
  AddOfferInput,
  ModifyOfferInput,
  ModifyTourInput,
  Offer,
  Tour,
} from '@gql/types/graphql';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import {
  AegDrawer,
  DetailsMetadataItem,
  DetailsPageTitle,
  DropdownButton,
  MetadataObject,
  OfferForm,
  OfferFormType,
  SelectWithPrimaryOptions,
  EditTourForm,
  ProtectedComponent,
  AegDialog,
  ModifyTourTeamMembers,
  Loading,
  JDETourSubledgerStatus,
  SnackbarType,
} from '@components';
import { PageDetailLayout } from '@layouts/PageDetailLayout';
import { useCallbackOnWindowFocus, useMaestro, useNavigateOnError } from '@hooks';
import {
  BreadcrumbDispatchContext,
  NotificationDispatchContext,
} from '@providers';
import { GET_TOUR } from '@gql/queries/tours';
import {
  ADD_OFFER, CLONE_OFFER, UPDATE_OFFER, UPDATE_TOUR, LAUNCH_EXCEL,
} from '@gql/mutations/tours';
import { SubmitHandler } from 'react-hook-form';
import {
  OfferStatus, AegResourceTypes, UserPermissions, ExcelWorkbookLaunchMode,
} from '@types';
import { getNetworkErrorInfo } from '@utils/errorHelpers';
import { getExcelWorkbookLaunchUrl } from '@utils/stringHelpers';
import { TourSummary } from './TourSummary/TourSummary';
import { TourSummaryHeader } from './TourSummaryHeader/TourSummaryHeader';
import { DialogStyled } from './TourDetails.styled';

type TourMetadata = Array<MetadataObject>;

enum TourSummaryHeaderDropdownOptions {
  EditTour,
  CreateOffer,
  Team,
  Version,
  CloneTour,
  LaunchArtistSettlement,
  LaunchTourSettlement,
}

const offerFormInputType = (
  offerInput: ModifyOfferInput | AddOfferInput,
): offerInput is ModifyOfferInput => (offerInput as ModifyOfferInput).id !== undefined;

export function TourDetails() {
  // Get the ID and fetch the tour information
  const appInsights = useAppInsightsContext();
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  const { id: tourId } = useParams();
  const [tourDetails, setTourDetails] = useState<Tour | null>(null);
  const [tourMetadata, setTourMetadata] = useState<TourMetadata | null>(null);
  const [currentOffer, setCurrentOffer] = useState<Offer | null>(null);
  const [openEditTour, setOpenEditTour] = useState(false);
  const [openModifyTeam, setOpenManageTeam] = useState(false);
  const [openOfferFormFlyout, setOpenOfferFormFlyout] = useState(false);
  const [cloneOfferConfimOpen, setCloneOfferConfirmOpen] = useState(false);
  const [offerFormType, setOfferFormType] = useState<OfferFormType>(
    OfferFormType.create,
  );
  const [formOffer, setFormOffer] = useState<Offer | null>(null);
  const setNotification = useContext(NotificationDispatchContext);
  const { handleShareWithMarketing } = useMaestro(tourId, currentOffer?.id);

  const handleOfferPane = (duplicate?: 'duplicate') => {
    if (!currentOffer) {
      return;
    }
    setOfferFormType(duplicate ? OfferFormType.duplicate : OfferFormType.edit);
    if (!formOffer || formOffer.id !== currentOffer.id) {
      setFormOffer(currentOffer);
    }
    setOpenOfferFormFlyout(true);
  };

  const handleOfferChange = (offerId: string) => {
    if (!tourDetails) {
      return;
    }
    const newOffer = tourDetails.offers?.find((offer) => offer?.id === offerId);
    if (newOffer) {
      setCurrentOffer(newOffer);
    }
  };

  const {
    data: tourData,
    error,
    loading,
    refetch: refetchTourData,
  } = useQuery(GET_TOUR, {
    errorPolicy: 'all',
    variables: { tourId: tourId ?? '' },
  });

  const syncAddInChanges = async () => {
    const refetchedTour = await refetchTourData();
    const tour = refetchedTour.data?.tour as Tour;
    if (tour) {
      setTourDetails(tour);
    }
  };

  useCallbackOnWindowFocus(syncAddInChanges);

  const navigateOnError = useNavigateOnError();
  useEffect(() => {
    navigateOnError(error);
  }, [error]);

  const [launchExcelWorkbook] = useMutation(LAUNCH_EXCEL);

  const launchExcel = (excelWorkbookLaunchMode: string, offerId: string) => async () => {
    if (offerId && tourId) {
      const response = await launchExcelWorkbook({
        variables: {
          launchExcel: {
            tourId,
            offerId,
            mode: excelWorkbookLaunchMode,
          },
        },
      });

      window.location.href = getExcelWorkbookLaunchUrl(response.data?.launchExcelWorkbook?.url as string);
    }
  };

  const setNotificationOnError = (e: ApolloError) => {
    const info = getNetworkErrorInfo(e);
    if (info) {
      setNotification({
        text: info.message,
        type: SnackbarType.ERROR,
        duration: 6000,
      });
    }
  };

  const [updateTour, { loading: savingTour }] = useMutation(UPDATE_TOUR, {
    async onCompleted() {
      setOpenEditTour(false);
      setOpenOfferFormFlyout(false);
      await refetchTourData();
      await handleShareWithMarketing();
    },
  });

  const handleUpdateTour = React.useCallback(
    async (tour: ModifyTourInput) => {
      await updateTour({
        variables: {
          tour,
        },
      });
    },
    [updateTour],
  );

  useEffect(() => {
    if (tourDetails?.name) {
      document.title = `${tourDetails.name} - Modern Elvis`;
    } else {
      document.title = 'Modern Elvis';
    }
  }, [tourDetails]);

  const handleManageTeamCompleted = async () => {
    setOpenManageTeam(false);
    setOpenOfferFormFlyout(false);
    await refetchTourData();
  };

  const [updateOffer, { loading: savingUpdateOffer }] = useMutation(
    UPDATE_OFFER,
    {
      async onCompleted(data) {
        // TODO: invalidate offer data
        setOpenEditTour(false);
        setOpenOfferFormFlyout(false);
        if (tourDetails && tourDetails.offers && data.modifyOffer) {
          setTourDetails({
            ...tourDetails,
            offers: [
              ...(tourDetails?.offers.filter(
                (offer) => offer && offer.id !== data.modifyOffer?.id,
              ) || []),
              data.modifyOffer,
            ],
          });
          setFormOffer(data.modifyOffer);
          setCurrentOffer(data.modifyOffer);
          await handleShareWithMarketing();
        }
      },
    },
  );

  const handleUpdateOffer = React.useCallback(
    async (offer: ModifyOfferInput) => {
      await updateOffer({
        variables: {
          offer,
          offerId: offer.id,
          tourId: tourId as string,
        },
      });
    },
    [updateOffer],
  );

  const [addOffer, { loading: savingAddOffer, error: addOfferError }] = useMutation(ADD_OFFER, {
    onCompleted(data) {
      if (tourDetails && data.addOfferToTour) {
        setTourDetails({
          ...tourDetails,
          offers: [...(tourDetails?.offers || []), data.addOfferToTour],
        });
        setCurrentOffer(data.addOfferToTour);
      }
      setOpenEditTour(false);
      setOpenOfferFormFlyout(false);
    },
  });

  const handleAddOffer = React.useCallback(
    async (offer: AddOfferInput) => {
      await addOffer({
        variables: {
          offer,
          tourId: tourId as string,
        },
      });
    },
    [updateTour],
  );

  const [cloneOffer, { loading: cloningOffer, error: cloneOfferError }] = useMutation(CLONE_OFFER, {
    onError: setNotificationOnError,
    onCompleted(data) {
      if (tourDetails && data.cloneOffer) {
        setTourDetails({
          ...tourDetails,
          offers: [...(tourDetails?.offers || []), data.cloneOffer],
        });
        setCurrentOffer(data.cloneOffer);
      }
      setOpenEditTour(false);
      setCloneOfferConfirmOpen(false);
      setNotification({
        type: SnackbarType.SUCCESS,
        text: `${currentOffer?.name as string} has been duplicated`,
        duration: 6000,
      });
    },
  });

  const handleDuplicateOffer = async () => {
    if (!currentOffer) {
      return;
    }
    if (currentOffer?.name.length > 44) {
      setNotification({
        type: SnackbarType.ERROR,
        text: 'Duplicating the offer resulted in too many characters. Shorten the offer name and try again',
        duration: 6000,
      });
      setCloneOfferConfirmOpen(false);
      return;
    }
    await cloneOffer({
      variables: {
        tourId: tourId as string,
        offerId: currentOffer?.id as string,
      },
    });
  };

  const handleOfferForm: SubmitHandler<
  ModifyOfferInput | AddOfferInput
  > = async (offerOutput) => {
    if (offerFormInputType(offerOutput)) {
      if (offerFormType === OfferFormType.duplicate) {
        await handleDuplicateOffer();
        return;
      }
      await handleUpdateOffer(offerOutput);
    } else {
      await handleAddOffer(offerOutput);
    }
  };

  const handleSetPrimaryOffer = async (offerId: string, name: string) => {
    if (!offerId || !name) {
      return;
    }

    try {
      await handleUpdateTour({
        id: tourDetails?.id as string,
        primaryOfferId: offerId,
      });
      setNotification({
        type: SnackbarType.SUCCESS,
        text: `${name} has been set as Primary for this tour`,
        duration: 6000,
      });
    } catch (e) {
      logError(appInsights, 'setPrimaryOffer error', e);
      setNotificationOnError(e);
    }
  };

  const handleOfferStatusChange = async (status: OfferStatus) => {
    if (savingUpdateOffer) {
      return;
    }
    await handleUpdateOffer({
      id: currentOffer?.id as string,
      status,
    });
  };

  const handleDropdownMenu = (value: TourSummaryHeaderDropdownOptions) => {
    if (!tourDetails) {
      return;
    }
    switch (value) {
      case TourSummaryHeaderDropdownOptions.EditTour:
        setOpenEditTour(true);
        break;
      case TourSummaryHeaderDropdownOptions.CreateOffer:
        setOfferFormType(OfferFormType.create);
        setFormOffer(null);
        setOpenOfferFormFlyout(true);
        break;
      case TourSummaryHeaderDropdownOptions.Team:
        setOpenManageTeam(true);
        break;
      case TourSummaryHeaderDropdownOptions.CloneTour:
        break;
      case TourSummaryHeaderDropdownOptions.LaunchArtistSettlement:
        void launchExcel(ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_ARTIST, currentOffer?.id as string)();
        break;
      case TourSummaryHeaderDropdownOptions.LaunchTourSettlement:
        void launchExcel(ExcelWorkbookLaunchMode.TOUR_SETTLEMENT_FAR, currentOffer?.id as string)();
        break;
      default:
        break;
    }
  };

  const mapTourToMetadata = () => {
    if (!tourDetails) {
      return;
    }

    const metadataSort = (a: string, b: string) => (a.toLowerCase().localeCompare(b.toLowerCase()));

    const metadata = [];
    const {
      headliner,
      parentTour,
      company,
      coHeadliners,
      primaryBuyer,
      primaryOperator,
      additionalBuyers,
      additionalOperators,
    } = tourDetails;
    metadata.push({
      title: 'Headliner',
      value: headliner?.name || '',
    });
    metadata.push({
      title: 'Co-Headliners',
      value: coHeadliners?.length
        ? coHeadliners.map((coHeadliner) => coHeadliner?.name ?? '').sort(metadataSort)
        : [],
      showAll: true,
    });
    metadata.push({
      title: 'Company',
      value: company?.name ?? '',
    });
    metadata.push({
      title: 'Multi-Leg Tour',
      value: parentTour?.name,
      link: parentTour?.id ? `/multi-leg-tour/${parentTour.id}` : '',
    });
    metadata.push({
      title: 'Buyer',
      value: [
        primaryBuyer?.name ?? '',
        ...(additionalBuyers?.length
          ? additionalBuyers.map((additionalBuyer) => additionalBuyer?.name ?? '')
          : []
        ),
      ].sort(metadataSort),
      showAll: true,
    });
    metadata.push({
      title: 'Operator',
      value: [
        primaryOperator?.name ?? '',
        ...(additionalOperators?.length
          ? additionalOperators.map((additionalOperator) => additionalOperator?.name ?? '')
          : []
        ),
      ].sort(metadataSort),
      showAll: true,
    });
    setTourMetadata(metadata);
  };

  const setCurrentOfferState = () => {
    if (!tourDetails) {
      return;
    }
    const { offers, primaryOfferId } = tourDetails;
    const offerId = currentOffer?.id || primaryOfferId;
    const targetOffer = offers?.find((o) => o?.id === offerId);
    if (targetOffer) {
      setCurrentOffer(targetOffer);
    }
  };

  const isPrimaryOfferDisabled = React.useMemo(
    () => currentOffer?.id === tourDetails?.primaryOfferId,
    [currentOffer?.id, tourDetails?.primaryOfferId],
  );

  const getOfferItems = React.useMemo((): SelectWithPrimaryOptions[] => {
    if (!tourDetails || !tourDetails.offers) {
      return [];
    }
    return tourDetails.offers.map((offerData, index) => ({
      label: offerData?.name || `Offer ${index + 1}`,
      value: offerData?.id || index.toString(),
      primary: offerData?.id === tourDetails.primaryOfferId,
    }));
  }, [tourDetails]);

  useEffect(() => {
    mapTourToMetadata();
    setCurrentOfferState();
    const breadcrumbs = [
      {
        text: 'Tours',
        href: '/tours/',
      },
    ];

    if (tourDetails?.parentTour?.id) {
      breadcrumbs.push({
        text: tourDetails.parentTour.name,
        href: `/multi-leg-tour/${tourDetails.parentTour.id}`,
      });
    }

    if (tourDetails?.name) {
      breadcrumbs.push({
        text: tourDetails.name,
        href: `/tours/${tourId || ''}`,
      });
    }

    setBreadcrumbs(breadcrumbs);
  }, [tourDetails]);

  useEffect(() => {
    if (tourData?.tour) {
      setTourDetails(tourData?.tour as Tour);
    }
  }, [tourData]);

  if (loading || !tourDetails || !currentOffer) {
    // TODO: Create Page Loading component
    return <div>Loading...</div>;
  }

  const { status = '' } = currentOffer;
  const isOfferConfirmed = status === OfferStatus.Confirmed
    || status === OfferStatus.AccountingSettled
    || status === OfferStatus.Canceled
    || status === OfferStatus.Dead
    || status === OfferStatus.OnTour
    || status === OfferStatus.SettlementInProgress;
  const isOfferDraft = status === OfferStatus.Draft;

  const actions = (
    <>
      <ProtectedComponent
        checkPermission={{
          permission: UserPermissions.UpdateTour,
          resourceType: AegResourceTypes.Tour,
          resourceId: tourId,
        }}
      >
        <DropdownButton
          label="Actions"
          onSelect={handleDropdownMenu}
          dropdownOptions={[
            {
              label: 'Edit Tour',
              value: TourSummaryHeaderDropdownOptions.EditTour,
            },
            {
              label: 'Manage Team',
              value: TourSummaryHeaderDropdownOptions.Team,
            },
            {
              label: 'Create New Offer',
              value: TourSummaryHeaderDropdownOptions.CreateOffer,
              divider: true,
            },
            {
              label: 'Launch Artist Settlement',
              // TODO: include the statuses that come after Confirmed and On Tour
              disabled: !isOfferConfirmed,
              value: TourSummaryHeaderDropdownOptions.LaunchArtistSettlement,
            },
            {
              label: 'Launch Tour Settlement',
              // TODO: include the statuses that come after Confirmed and On Tour
              disabled: !isOfferConfirmed,
              value: TourSummaryHeaderDropdownOptions.LaunchTourSettlement,
            },
          ]}
        ></DropdownButton>
      </ProtectedComponent>
    </>
  );

  const metadataItems = tourMetadata?.map((metadataItem, index) => (
    <DetailsMetadataItem
      key={index}
      metadata={metadataItem}
    />
  ));

  const dialogProps = {
    titles: {
      dialogTitle: `Are you sure you want to duplicate ${currentOffer?.name || ''}?`,
      alertTitle: 'Please ensure the workbook you are duplicating has been recently synced to Modern Elvis.',
    },
    submit: {
      text: 'Duplicate',
      action: handleDuplicateOffer,
    },
    cancel: {
      text: 'Cancel',
      action: () => setCloneOfferConfirmOpen(false),
    },
  };

  return (
    <>
      <PageDetailLayout
        pageTitle={<DetailsPageTitle title={tourDetails?.name || ''} />}
        pageTitleColSize={2.5}
        metaColSize={8.5}
        actionsColSize={1}
        metadata={metadataItems}
        subledger={<JDETourSubledgerStatus
          tourId={tourDetails.id || ''}
          subledgerId={tourDetails?.subledgerId || ''}
          subledgerStatus={tourDetails.subledgerStatus || ''}
        />}
        actions={actions}
        pageContentHeader={
          <TourSummaryHeader
            tourId={tourId as string}
            offerSelectValue={currentOffer.id as string}
            offer={currentOffer}
            offerItems={getOfferItems}
            offerChange={handleOfferChange}
            offerStatusChange={handleOfferStatusChange}
            handleEditOffer={() => handleOfferPane()}
            handleCloneOffer={() => setCloneOfferConfirmOpen(true)}
            isOfferDraft={isOfferDraft}
            isPrimaryOfferDisabled={isPrimaryOfferDisabled}
            updatePrimaryOffer={handleSetPrimaryOffer}
          />
        }
        pageContent={<TourSummary events={currentOffer.events} />}
        />
        {cloneOfferConfimOpen && (
          <AegDialog
            isLoading={cloningOffer}
            rightSubmit={true}
            titles={dialogProps.titles}
            cancel={dialogProps.cancel}
            submit={dialogProps.submit}
          >
          </AegDialog>
        )}
        <DialogStyled open={cloningOffer}
          id="confirm-clone-offer"
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              marginLeft: '240px',
            },
          }}
        >
          <Loading />
        </DialogStyled>
      <AegDrawer
        openDrawer={openEditTour}
        anchorValue="right"
        onClose={() => setOpenEditTour(false)}
      >
        <EditTourForm
          onFormSubmit={handleUpdateTour}
          isFormSaving={savingTour}
          tour={
            {
              ...tourDetails,
              visibility: 'visible to me',
              role: 'operator',
            } as Tour
          }
        />
      </AegDrawer>
      <AegDrawer
        openDrawer={openModifyTeam}
        anchorValue="right"
        onClose={() => setOpenManageTeam(false)}
      >
        <ModifyTourTeamMembers closeDrawer={handleManageTeamCompleted} tourDetails={tourDetails}/>
      </AegDrawer>
      <AegDrawer
        openDrawer={openOfferFormFlyout}
        anchorValue="right"
        onClose={() => setOpenOfferFormFlyout(false)}
      >
        <OfferForm
          offer={formOffer || undefined}
          type={offerFormType}
          isFormSaving={savingAddOffer || savingUpdateOffer}
          onFormSubmit={handleOfferForm}
          mutationError={addOfferError}
        />
      </AegDrawer>
    </>
  );
}
