import {
  Button, CustomTheme, Typography, styled,
} from '@mui/material';
import SwapHoriz from '@mui/icons-material/SwapHoriz';

export const SwitchContainer = styled('div')(({ theme }) => ({
  marginLeft: theme?.spacing(-1.25),
}));

export const AddEventTab = styled('div')(
  ({ theme }: { theme?: CustomTheme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme?.spacing(5),
    flexDirection: 'column',
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
  }),
);

export const ButtonStyled = styled(Button)(({ theme }: { theme?: CustomTheme }) => ({
  textTransform: 'capitalize',
  fontSize: theme?.spacing(1.625),
  width: 'fit-content',
}));

export const AddEventText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light[300],
  margin: theme.spacing(2, 0),
  fontSize: theme.spacing(2),
}));

export const VenueSwapStyled = styled('div')(({ theme }: { theme?: CustomTheme }) => ({
  marginBottom: theme?.spacing(2),
}));

export const SwapHorizStyled = styled(SwapHoriz)(({ theme }: { theme?: CustomTheme }) => ({
  margin: theme?.spacing(0, 0.625),
  verticalAlign: 'top',
}));

export const MarketLink = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: 'pointer',
}));
