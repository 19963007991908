import { useContext } from 'react';
import {
  DialogDispatchContext, EnvStatusContext, NotificationDispatchContext,
} from '@providers';
import { useMaestro, useLoading } from '@hooks';
import { getNetworkErrorInfo } from '@utils/errorHelpers';
import { ExcelWorkbookLaunchMode } from '@types';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@services/telemetry-service';
import { SnackbarType } from '../components/Snackbar/Snackbar';

export function useAddInAction() {
  const appInsights = useAppInsightsContext();
  const setNotification = useContext(NotificationDispatchContext);
  const setDialog = useContext(DialogDispatchContext);
  const { displayLoadingSpinner, hideLoadingSpinner } = useLoading();
  const {
    envStatus: { offerId, tourId, launchMode },
  } = useContext(EnvStatusContext);

  const { handleShareWithMarketing } = useMaestro(tourId, offerId);

  const handleAddInAction = async (callback: () => Promise<void>, successText: string, errorText?: string) => {
    try {
      displayLoadingSpinner();
      await callback();
      hideLoadingSpinner();
      setNotification({
        type: SnackbarType.SUCCESS,
        text: successText,
        duration: 6000,
      });
    } catch (error) {
      hideLoadingSpinner();
      logError(appInsights, 'Error in handleAddInAction:', error);
      const info = getNetworkErrorInfo(error);
      if (info?.status === 400) {
        let message;
        if (info.formattedMessage?.length) {
          message = info.formattedMessage[0].title;
        } else if (typeof info.message === 'string') {
          message = info.message;
        } else {
          message = info.message?.[0];
        }

        setDialog({
          // TODO: Update AegDialog to support multiple error message blocks
          titles: {
            dialogTitle: 'Invalid Value',
            alertTitle: message,
            alertList: info.formattedMessage?.length ? info.formattedMessage[0].fields : undefined,
          },
          submit: {
            text: 'Okay',
            action: () => setDialog(null),
          },
        });
      } else {
        let errorMsg = info?.message || 'Something went wrong. Please try again later.';
        if (error instanceof OfficeExtension.Error) {
          errorMsg = 'Something went wrong with Excel. Please contact support.';
        }
        setNotification({
          type: SnackbarType.ERROR,
          text: errorText || errorMsg,
          duration: 6000,
        });
      }
    }

    if (launchMode === ExcelWorkbookLaunchMode.MODELING) {
      // Call outside of try catch to avoid additional loading time
      void handleShareWithMarketing();
    }
  };

  return {
    handleAddInAction,
  };
}
