import { Artist, ParentTour } from '@gql/types/graphql';
import { FormError } from '@types';

export const CREATE_TOUR_TEXT = {
  title: 'Create a Tour',
  subTitle: 'Please provide the details below to create a new tour.',
  submitText: 'Next',
};

export const CREATE_TOUR_DEFAULTS: TourFormFields = {
  companyId: '',
  name: '',
  headliner: null,
  coHeadliners: null,
  supports: null,
  isCrossed: 'yes',
  configuration: '',
  isMultiLeg: false,
  numberOfTrucks: null,
  parentTour: null,
  currency: '',
};

export interface TourFormFields {
  companyId: string;
  // status: string;
  // visibility: string;
  // venueType: string;
  isMultiLeg: boolean;
  isCrossed: string;
  numberOfTrucks: number | null;
  configuration: string;
  parentTour: ParentTour | null;
  name: string;
  headliner: Artist | null;
  coHeadliners: Artist[] | null;
  supports: Artist[] | null;
  currency: string;
}

export type CreateTourFormErrors = {
  companyId: FormError[];
  name: FormError[];
  headliner: FormError[];
  coHeadliners: FormError[];
  supports: FormError[];
};
