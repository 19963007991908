import {
  PROFIT_LOSS_REF_SHEET_NAME,
  PROFIT_LOSS_REF_SHEET_TABLES,
  WorkbookReferences,
} from '../shared/constants';
import { generateTableName } from '../shared/helpers';
import {
  initializeTableGetter, AEGSharedTables,
} from '../shared/tableLibrary';
import { ParseTableFunction, SheetTableData } from '../shared/types';
import { AS_VARIABLE_COST_TABLE_NAME } from './constants';

// eslint-disable-next-line max-lines-per-function
export const AegNOSSettlement = (parseTable: ParseTableFunction) => {
  const getDataFromReferenceTable = initializeTableGetter(parseTable);

  const boxOfficeFeeTables = async (tablePrefix: string, boxOfficeId: string) => ({
    preTax: ({
      name: generateTableName(tablePrefix, 'PreTaxFees', undefined, boxOfficeId),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        PROFIT_LOSS_REF_SHEET_TABLES.PRE_TAX,
      ),
    }),
    tax: ({
      name: generateTableName(tablePrefix, 'Taxes', undefined, boxOfficeId),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        PROFIT_LOSS_REF_SHEET_TABLES.TAX,
      ),
    }),
    postTax: ({
      name: generateTableName(tablePrefix, 'PostTaxFees', undefined, boxOfficeId),
      data: [],
      referenceTable: await getDataFromReferenceTable(
        PROFIT_LOSS_REF_SHEET_NAME,
        PROFIT_LOSS_REF_SHEET_TABLES.POST_TAX,
      ),
    }),
  }) as { [key:string]: SheetTableData };

  const variableCostBudgetTable = async () => ({
    name: AS_VARIABLE_COST_TABLE_NAME,
    data: [],
    referenceTable: await getDataFromReferenceTable(
      PROFIT_LOSS_REF_SHEET_NAME,
      PROFIT_LOSS_REF_SHEET_TABLES.VARIABLE_COSTS,
    ),
  });

  const expenseBreakdowns = AEGSharedTables(parseTable, WorkbookReferences.NOS_SETTLEMENT_BREAKDOWN);
  const expenseBudget = AEGSharedTables(parseTable, WorkbookReferences.NOS_SETTLEMENT_BUDGET);

  const settlementTables = {
    boxOfficeFeeTables,
    variableCostBudgetTable,
    expenseBreakdowns,
    expenseBudget,
  };

  return {
    settlementTables,
  };
};
