import { useContext } from 'react';
import { VenueDatagrid } from '@components';
import { VenueManagementContext } from '@providers';
import { FIXED_COSTS, DEFAULT_FIXED_COSTS } from '@utils/venueHelpers';
import { kebabCase } from '@utils/stringHelpers';
import { VenueDataGridContainer, VenueGridTitle } from './VenueDatagrids.styled';
import { VenueGridProps, fixedCostsColumns } from './VenueDatagrids.models';

export const FixedCostGrid = ({ disableVirtualization }: VenueGridProps) => {
  const context = useContext(VenueManagementContext);
  if (!context.data?.space) return null;
  const { setFeeItem, data: { space } } = context;

  const fixedCost = space.fees?.find(
    (fee) => fee.name === FIXED_COSTS,
  );

  const rows = DEFAULT_FIXED_COSTS.map((item, index) => {
    const existingCosts = fixedCost?.items?.find((cost) => cost?.subCategory === item.subCategory);
    return {
      ...item,
      ...existingCosts,
      type: existingCosts?.type || item.type,
      feeName: fixedCost?.name || FIXED_COSTS,
      id: `cost-item-${kebabCase(item.subCategory)}-${index}`,
      profitLossCategoryId: existingCosts?.profitLossCategoryId || '',
    };
  });

  return (
    <VenueDataGridContainer>
      <VenueGridTitle variant="h6" data-testid="fixed-costs-grid-title">Fixed Costs</VenueGridTitle>
      <VenueDatagrid
        autoHeight
        columns={fixedCostsColumns}
        data-testid="fixed-costs-grid"
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        editMode="row"
        hideFooter
        processRowUpdate={setFeeItem}
        rows={rows}
      />
    </VenueDataGridContainer>
  );
};
