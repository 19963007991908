import { Outlet, useSearchParams } from 'react-router-dom';
import {
  DialogProvider,
  EnvConfigContext,
  EnvStatusProvider,
  ExcelErrorPageProvider,
  ExcelUserProvider,
  LoadingProvider,
  NotificationProvider,
} from '@providers';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { initializeAppInsights, reactPlugin } from '@services/telemetry-service';
import { useContext } from 'react';

export const ExcelContextLayout = () => {
  const envConfig = useContext(EnvConfigContext);
  initializeAppInsights('WebContext', envConfig.APP_INSIGHTS_INSTRUMENTATION_KEY);

  const [searchParams] = useSearchParams();
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <EnvStatusProvider
        environmentStatus={{
          mode: 'excel',
          isAutomationEnabled: searchParams.get('isAutomationEnabled') === 'true',
          isExcelReady: false,
          automationTourId: searchParams.get('tourId'),
          automationOfferId: searchParams.get('offerId') || null,
          automationEventId: searchParams.get('eventId') || null,
          automationToken: searchParams.get('token'),
          automationSettlementType: searchParams.get('settlementType') || null,
        }}
      >
        <NotificationProvider>
          <DialogProvider>
            <LoadingProvider>
              <ExcelUserProvider>
                <ExcelErrorPageProvider>
                  <Outlet />
                </ExcelErrorPageProvider>
              </ExcelUserProvider>
            </LoadingProvider>
          </DialogProvider>
        </NotificationProvider>
      </EnvStatusProvider>
    </AppInsightsContext.Provider>
  );
};
