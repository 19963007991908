import { Box, styled } from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { CustomTheme } from '@mui/material/styles';

export const StyledLoading = styled(Box)(({ theme }: { theme?: CustomTheme }) => ({
  padding: theme?.spacing(3),
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
}));

// SpinnerTooltip not currently used but saving styling reference for future usage
// See: https://github.com/AEG-Presents/aegp-elvis-web-app/pull/427#discussion_r1374808945
export const SpinnerTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }: { theme?: CustomTheme }) => ({
  marginTop: 0,
  '& .MuiTooltip-tooltip': {
    height: theme?.spacing(3),
    width: theme?.spacing(3.75),
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom': {
    marginTop: '-5px !important',
  },
  '& .MuiBox-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiCircularProgress-root': {
    position: 'absolute',
    top: 0,
    color: theme?.palette.primary.contrast,
  },
}));
