/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-lines-per-function */
import { useMemo } from 'react';
import { AegAutocomplete, InputContainer } from '@components';
import { GET_COUNTRIES } from '@gql/queries/venues';
import { useQuery } from '@apollo/client';
import { Country, State } from '@gql/types/graphql';
import {
  SearchContainer,
  SearchContainerMarket,
  StateCityContainer,
  SearchStateCity,
} from './SearchMarkets.styled';
import { SearchProps } from './SearchMarkets.models';
import { orderCountries } from './SearchMarket.helpers';

interface SearchMarketsProps<T> {
  search: SearchProps<T>;
}

export function SearchMarkets<T>({ search }: SearchMarketsProps<T>) {
  const {
    query,
    displayFields,
    onSelect,
    resultKey,
    searchTerm,
    onMarketSearch,
  } = search;

  const { data: countriesData, loading: countriesLoading } = useQuery(GET_COUNTRIES);

  const countriesOptions = useMemo(() => {
    if (countriesData) {
      return (countriesData as { countries: Country[] }).countries;
    }
    return [];
  }, [countriesData]);

  useQuery(query, {
    variables: {
      searchTerm: '',
      filter: {
        countryCode: searchTerm?.country?.countryCode,
        stateCode: searchTerm?.state?.stateCode || '',
        city: searchTerm?.city || '',
      },
    },
    errorPolicy: 'ignore',
    skip: !searchTerm?.state?.stateCode && !searchTerm?.city,
    onCompleted: (data) => {
      if (data && onSelect) {
        onSelect(data[resultKey]);
      }
    },
  });

  return (
    <SearchContainerMarket>
      <SearchContainer>
        <AegAutocomplete
          testId='search-countries-autocomplete'
          options={orderCountries(countriesOptions)}
          areOptionsLoading={countriesLoading}
          dataDisplayField={displayFields}
          placeholderText='Select Country'
          InputComponent={InputContainer}
          defaultValue={null}
          value={searchTerm?.country}
          forcePopupIcon
          getOptionLabel={(option: any) => option.name as string}
          disableClearable
          onSelect={(...params) => {
            const [selectedOption] = params;
            if (selectedOption && typeof selectedOption !== 'string') {
              onMarketSearch({
                country: selectedOption as Country,
                state: null,
                city: null,
              });
            }
          }}
        />
      </SearchContainer>

      {searchTerm?.country && (
        <StateCityContainer>

          {searchTerm?.country?.states && searchTerm?.country.states.length > 0 && (
            <SearchStateCity>
              <AegAutocomplete
                testId='search-states-autocomplete'
                options={
                  searchTerm?.country?.states
                    ? (searchTerm.country.states.sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))) : []
                }
                dataDisplayField={displayFields}
                placeholderText='Select State / Province'
                InputComponent={InputContainer}
                defaultValue={null}
                value={searchTerm?.state}
                forcePopupIcon
                getOptionLabel={(option: State) => option.name as string}
                disableClearable
                onSelect={(...params) => {
                  const [selectedOption] = params;
                  if (selectedOption && typeof selectedOption !== 'string') {
                    onMarketSearch({
                      country: searchTerm.country,
                      state: selectedOption as State,
                      city: null,
                    });
                  }
                }}
              />
            </SearchStateCity>
          )}

          <SearchStateCity>
            <AegAutocomplete
              disabled={!searchTerm?.country?.cities?.length && !searchTerm?.state?.cities?.length}
              testId='search-cities-autocomplete'
              options={
                [...(searchTerm?.state?.cities || searchTerm?.country?.cities || [])]
                  .filter((city): city is string => typeof city === 'string')
                  .sort((a, b) => a.localeCompare(b))
              }
              dataDisplayField={displayFields}
              placeholderText='Select City'
              InputComponent={InputContainer}
              defaultValue={null}
              value={searchTerm?.city}
              forcePopupIcon
              getOptionLabel={(option: string) => option}
              disableClearable
              onSelect={(...params) => {
                const [selectedOption] = params;
                if (selectedOption && typeof selectedOption === 'string') {
                  onMarketSearch({
                    ...searchTerm,
                    city: selectedOption,
                  });
                }
              }}
            />
          </SearchStateCity>
        </StateCityContainer>
      )}
    </SearchContainerMarket>
  );
}
