import React, { PropsWithChildren, useState } from 'react';
import { Venue } from '@gql/types/graphql';
import { MarketSearch } from '../../components/SearchMarkets/SearchMarkets.models';
import { VenueSearchOptions } from '../../components/excel/VenueSearch/VenueSearch.types';

export type ModelingSearchContextData = {
  venueSearch: Venue | null;
  setVenueSearch: (venue: Venue | null) => void;
  marketSearch: MarketSearch | null;
  setMarketSearch: (marketSearch: MarketSearch | null) => void;
  venueSearchOption: VenueSearchOptions;
  setVenueSearchOption: (option: VenueSearchOptions) => void;
};

const ModelingSearchContext = React.createContext<ModelingSearchContextData>({
  venueSearch: null,
  setVenueSearch: () => { },
  marketSearch: null,
  setMarketSearch: () => { },
  venueSearchOption: VenueSearchOptions.name,
  setVenueSearchOption: () => { },
});

const ModelingSearchProvider = ({ children }: PropsWithChildren) => {
  const [venueSearch, setVenueSearch] = useState<Venue | null>(null);
  const [marketSearch, setMarketSearch] = useState<MarketSearch | null>(null);
  const [venueSearchOption, setVenueSearchOption] = useState<VenueSearchOptions>(VenueSearchOptions.name);

  return (
    <ModelingSearchContext.Provider value={{
      venueSearch,
      setVenueSearch,
      marketSearch,
      setMarketSearch,
      venueSearchOption,
      setVenueSearchOption,
    }}>
      {children}
    </ModelingSearchContext.Provider>
  );
};

export {
  ModelingSearchProvider,
  ModelingSearchContext,
};
