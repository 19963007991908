import * as React from 'react';
import { BreadcrumbDispatchContext } from '@providers';

export function Settings() {
  const setBreadcrumbs = React.useContext(BreadcrumbDispatchContext);
  setBreadcrumbs([
    {
      text: 'Settings',
      href: '/settings/',
    },
  ]);

  return <div>Settings Page is Under Construction</div>;
}
