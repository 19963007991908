// lint exceptions in place because errors and row params are always of an 'any' type
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { appendEllipses } from '@utils/stringHelpers';
import { configurationTypeOptions } from '@utils/configurationTypeHelpers';
import { ConfigGridFields, RenderCellType, ValidationErrorInfo } from '@types';
import { PlaceholderText, ValidationError, ValidationTooltip } from './VenueDatagrids.styled';

// eslint-disable-next-line max-lines-per-function
export const RenderCell = (
  params: GridRenderCellParams,
  type: string,
  placeholder?: string | null,
  maxLength?: number,
) => {
  const fieldErrors: ValidationErrorInfo[] = params.row.errors?.filter(
    (e: ValidationErrorInfo) => e.field === params.field,
  ) || [];
  let hasError;
  if (params.field === ConfigGridFields.NAME || params.field === ConfigGridFields.CONFIG_TYPE) {
    hasError = fieldErrors?.length && fieldErrors?.flatMap((e) => e.ids).includes(params.row.id);
  } else {
    hasError = fieldErrors?.length;
  }

  if (['', null, undefined].includes(params.value) && !hasError) {
    return <PlaceholderText>{placeholder}</PlaceholderText>;
  }

  const numberValue = Number(params.value);
  const isNumber = !Number.isNaN(numberValue);

  const formattedValue = () => {
    switch (type) {
      case RenderCellType.CONFIG_TYPE:
        if (configurationTypeOptions.some((option) => option.value === params.value)) {
          return configurationTypeOptions.find((option) => option.value === params.value)?.label;
        }
        break;
      case RenderCellType.CURRENCY:
        if (!Number.isNaN(Number(params.value))) {
          // TODO: Update currency to venue currency (SANE-6696)
          return `${numberValue.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}`;
        }
        break;
      case RenderCellType.NUMBER:
        if (isNumber) {
          return numberValue.toLocaleString();
        }
        break;
      case RenderCellType.PERCENT:
        // TODO: Update to support internationalization (SANE-6696)
        if (!Number.isNaN(Number(params.value))) {
          const decimalPlaces = (params.value.toString().split('.')[1] || '').length;
          let formattedPct;
          if (decimalPlaces <= 1) {
            formattedPct = Number(params.value).toFixed(decimalPlaces);
          } else {
            formattedPct = Number(params.value).toFixed(2);
          }
          return `${formattedPct.toLocaleString()}%`;
        }
        break;
      case RenderCellType.TEXT:
        if (params.value?.length) {
          return appendEllipses(params.value as string, maxLength || params.value.length);
        }
        break;
      default:
        return String(params.value);
    }
    return String(params.value);
  };

  if (hasError) {
    const errorMessage: string = params.row.errors?.find((e: ValidationErrorInfo) => e.field === params.field)?.message;
    return <ValidationTooltip title={errorMessage}>
      <ValidationError>
        {
          (params.field === 'value' && ['', null, undefined].includes(params.value))
            ? <PlaceholderText>{placeholder}</PlaceholderText>
            : formattedValue()
        }
      </ValidationError>
    </ValidationTooltip>;
  }

  return <>{formattedValue()}</>;
};
