import type { AutocompleteOption, ChipOption, DisplayFields } from '@components';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { kebabCase } from '@utils/stringHelpers';
import { AegAutocomplete } from '@components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InputLabel, InputContainer, InputLabelContainer } from './Form.styled';

type AutoCompleteOnSelect = AutocompleteOption | AutocompleteOption[];

type AutocompleteInputProps<
  TFieldValues extends FieldValues = any,
  TContext = any,
  TItem extends AutoCompleteOnSelect = AutoCompleteOnSelect,
> = {
  /** @required the field's property path from the type T passed into useForm<T>()  */
  fieldName: string;
  /** @required the control object returned from useForm<T>()  */
  control: Control<TFieldValues, TContext>;
  label?: string;
  /** @required options to select from the suggestion dropdown */
  options: Array<any>;
  areOptionsLoading?: boolean;
  /** @required property displayed in the Input component */
  dataDisplayField: DisplayFields;
  dataFilterField?: string;
  filterOut?: Array<string>;
  placeholderText?: string;
  onInputChange?: React.Dispatch<React.SetStateAction<string>>; // update state in parent component
  onSelect?: (
    selectedItem: TItem,
    event?: React.SyntheticEvent<Element, Event>,
  ) => void;
  isRequired?: boolean;
  defaultValue?: any;
  minimumCharacters?: number;
  showInfo?: boolean;
  inputAdornment?: JSX.Element;
  disabled?: boolean;
  forcePopupIcon?: boolean;
  disableClearable?: boolean;
  removeClearIcon?: boolean;
  chipOption?: ChipOption;
};

export const AutocompleteInput = <T extends AutoCompleteOnSelect>({
  fieldName,
  control,
  label,
  options,
  areOptionsLoading,
  dataDisplayField,
  dataFilterField,
  filterOut,
  placeholderText,
  onInputChange,
  onSelect,
  isRequired,
  defaultValue,
  minimumCharacters,
  showInfo = false,
  inputAdornment,
  disabled,
  forcePopupIcon,
  disableClearable,
  removeClearIcon = false,
  chipOption,
}: AutocompleteInputProps<any, any, T>) => (
  <>
    {label && (
      <InputLabelContainer>
        <InputLabel
          required={isRequired}
          data-testid={`${kebabCase(label)}-label`}
        >
          {label}
        </InputLabel>{' '}
        {showInfo ? <InfoOutlinedIcon /> : null}
      </InputLabelContainer>
    )}
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, formState }) => (
        <AegAutocomplete
          disabled={disabled}
          defaultValue={(defaultValue || formState.defaultValues?.[fieldName]) as AutocompleteOption}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={value}
          options={options}
          areOptionsLoading={areOptionsLoading}
          InputComponent={InputContainer}
          dataDisplayField={dataDisplayField}
          dataFilterField={dataFilterField}
          filterOut={filterOut}
          onInputChange={onInputChange}
          onSelect={onSelect}
          onChange={onChange}
          testId={`${kebabCase(fieldName)}-autocomplete`}
          placeholderText={placeholderText}
          error={formState.errors[fieldName] !== undefined}
          helperText={formState.errors[fieldName]?.message?.toString()}
          minimumCharacters={minimumCharacters}
          inputAdornment={inputAdornment}
          forcePopupIcon={forcePopupIcon}
          disableClearable={disableClearable}
          removeClearIcon={removeClearIcon}
          chipOption={chipOption}
        />
      )}
    />
  </>
  );
