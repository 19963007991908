import { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '@hooks';
import { UserModel } from '@types';
import { User } from '@gql/types/graphql';
import { useQuery } from '@apollo/client';
import { SEARCH_USERS } from '@gql/queries/users';
import { Divider } from '@mui/material';
import { EnvStatusContext } from '@providers';
import { FormTextInput } from '../Form/FormTextInput';
import {
  ButtonContainer,
  FormAndSummaryContainer,
  EmailFormBoxStyled,
  ChildrenBoxStyled,
  ButtonStyled,
  AlertStyled,
  HeaderFlexContainer,
  TypographyStyled,
} from './SendEmailForm.styled';
import { SendEmailFormFields } from './SendEmailForm.models';
import { createSendEmailFormValidationSchema } from './SendEmailForm.schema';
import { AutocompleteInput } from '../Form/AutocompleteInput';

interface SendEmailProps {
  defaultValues?: SendEmailFormFields;
  warningMessageOnValidForm?: string;
  errorMessageOnInvalidToRecipients?: string;
  onSend: (data: SendEmailFormFields) => void;
  onDiscard?: () => void;
  children?: React.ReactNode;
  title?: string;
}

// eslint-disable-next-line max-lines-per-function
export function SendEmailForm({
  warningMessageOnValidForm,
  errorMessageOnInvalidToRecipients,
  defaultValues = {
    ccUsers: [],
    toUsers: [],
    comments: '',
  },
  children,
  title,
  onSend,
  onDiscard,
}: SendEmailProps) {
  const [userSearchVal, setUserSearchVal] = useState('');
  const validationSchema = useMemo(createSendEmailFormValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm<SendEmailFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver,
    defaultValues,
  });
  const toUsersFormValue = watch('toUsers');
  const ccUsersFormValue = watch('ccUsers');

  const { loading: usersLoading, data: usersData } = useQuery(SEARCH_USERS, {
    errorPolicy: 'all',
    variables: { searchTerm: userSearchVal },
  });

  const userOptions = (): UserModel[] => {
    if (usersLoading || !usersData || !usersData.users) {
      return [];
    }
    return usersData.users
      .filter((userItem): userItem is User => userItem !== null)
      .map((userItem) => ({ ...userItem, userId: userItem.id }));
  };

  const filterOutNullUserData = (fieldValues: UserModel[]) => {
    const validUserIds = fieldValues
      ?.filter((user): user is UserModel => user !== null && user.email !== null)
      .map((user) => user.id);
    return validUserIds;
  };

  const filterOutToUsers = useMemo(() => filterOutNullUserData(toUsersFormValue), [toUsersFormValue, usersData]);

  const filterOutCCUsers = useMemo(() => filterOutNullUserData(ccUsersFormValue), [ccUsersFormValue, usersData]);

  const onSubmit = handleSubmit((data) => {
    if (!errors.toUsers && toUsersFormValue?.length > 0) {
      onSend(data);
      reset();
    }
  });

  const { envStatus } = useContext(EnvStatusContext);
  const { mode } = envStatus;

  const isExcelMode = mode === 'excel';

  const buttonContainer = (
    <ButtonContainer isExcelMode={isExcelMode}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <ButtonStyled variant="contained" data-testid="send-email-submit-button" type="submit" onClick={onSubmit}>
        Send Email
      </ButtonStyled>
      <ButtonStyled
        variant={isExcelMode ? 'text' : 'outlined'}
        data-testid="send-email-discard-button"
        type="button"
        onClick={onDiscard}
      >
        Discard
      </ButtonStyled>
    </ButtonContainer>
  );

  return (
    <>
      {isExcelMode && (
        <>
          <HeaderFlexContainer>
            <TypographyStyled variant="h6" data-testid="send-email-title-text">
              {title}
            </TypographyStyled>
            {buttonContainer}
          </HeaderFlexContainer>
          <Divider />
        </>
      )}
      <FormAndSummaryContainer>
        {isValid && warningMessageOnValidForm && (
          <AlertStyled severity="warning" data-testid="send-email-warning-alert">
            {warningMessageOnValidForm}
          </AlertStyled>
        )}

        {errors.toUsers && (
          <AlertStyled severity="error" data-testid="send-email-error-alert">
            {errorMessageOnInvalidToRecipients || errors.toUsers.message}
          </AlertStyled>
        )}
        <EmailFormBoxStyled>
          <form data-testid="send-email-form">
            <AutocompleteInput
              label="To"
              chipOption={{ labelProperty: 'name', includeAvatar: true }}
              disableClearable={true}
              areOptionsLoading={usersLoading}
              fieldName="toUsers"
              control={control}
              options={userOptions()}
              dataDisplayField={{ primary: 'name', secondary: 'email' }}
              dataFilterField={'id'}
              filterOut={filterOutToUsers?.concat(filterOutCCUsers)}
              onInputChange={setUserSearchVal}
              defaultValue={defaultValues?.toUsers}
            />
            <AutocompleteInput
              label="CC"
              chipOption={{ labelProperty: 'name', includeAvatar: true }}
              disableClearable={true}
              areOptionsLoading={usersLoading}
              fieldName="ccUsers"
              control={control}
              options={userOptions()}
              dataDisplayField={{ primary: 'name', secondary: 'email' }}
              dataFilterField={'id'}
              filterOut={filterOutCCUsers?.concat(filterOutToUsers)}
              onInputChange={setUserSearchVal}
              defaultValue={defaultValues?.ccUsers}
            />

            <FormTextInput control={control} fieldName="comments" label="Comments" minRow={5} />
          </form>
        </EmailFormBoxStyled>
        <ChildrenBoxStyled>{children}</ChildrenBoxStyled>
      </FormAndSummaryContainer>
      {!isExcelMode && buttonContainer}
    </>
  );
}
