import { Typography } from '@mui/material';
import { SubHeader } from './CreateBundleDrawer.styled';

interface CreateBundleDrawerHeaderProps {
  title: string;
  subTitle: string;
}

export const CreateBundleDrawerHeader = ({ title, subTitle }: CreateBundleDrawerHeaderProps) => (
    <>
      <Typography variant="h5" data-testid="create-bundles-form-header-text">
        {title}
      </Typography>
      <SubHeader
        variant="body2"
        data-testid="create-bundles-form-sub-header-text"
      >
        {subTitle}
      </SubHeader>
    </>
);
